
// TODO: ubah error ke per field pada form
import { ref, onMounted, reactive, Ref, defineComponent} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { tipsMoneyReason } from './uang_tips'
import { Money3Component } from 'v-money3';

export default defineComponent({
  name: 'UangTipsForm',
  components: { money3: Money3Component },
  data() {
    return {
      waroengInfo : {},
      currencyFormat : 'id-ID',
      currencyPrefix : 'IDR',
    } 
  },
  mounted() {
        axios.get('m-waroeng/waroeng-info')
            .then(resp => {
                this.waroengInfo = resp.data
                if(resp.data.currency == 'RM'){
                    this.currencyFormat = 'ms-MY'
                    this.currencyPrefix = 'MYR'

                    this.config.decimal = '.'
                    this.config.thousands = ','
                    this.config.prefix = 'RM '
                    this.config.precision = 2
                }
            }).catch(err => console.log(err))
    },
  setup() {
    // const cashierId = ref('')
    // const cashierName = ref('')
    const description = ref('')
    const amountDebit = ref(0)
    const users = ref([])
    const btnSaveText = ref('Simpan')
    const errorSummary: Ref<Array<string>> = ref([])
    const router = useRouter()
    const store = useStore()

    onMounted(async () => {
      window.setTimeout( "loadingComponentMutasi()" ,750);
      try {
        const resp = await axios.get('/users')
        users.value = resp.data

      } catch(error) {
        await router.push({ name: 'loginPage' }) 
      }
    })

    const resetForm = () => {
      // cashierId.value = ''
      // cashierName.value = ''
      amountDebit.value = 0
      description.value = ''

      errorSummary.value = []
    }

    const saveUangTips = () => {
      // const tips = parseFloat((document.getElementById('nominal_tips') as HTMLInputElement).value);
      
      // const tips = parseFloat(document.getElementById("nominal_tips").value as HTMLElement | null) ;
      // const employee = store.state['user'].employee
      // const warung = store.state['warung'].warung
      // const area = store.state['warung'].area
      // const user = store.state['user'].currentUser
      const data = { 
        // id_kasir: cashierId.value, 
        keterangan: description.value, 
        amount_debit: amountDebit.value,
        // m_karyawan_id: employee.m_karyawan_id,
        // m_karyawan_nama: employee.m_karyawan_nama,
        // id_waroeng: warung.m_w_id,
        // nama_waroeng: warung.m_w_nama,
        // nama_area: area.m_area_nama,
        // nama_kasir: cashierName.value
      }

      if (description.value == '' || amountDebit.value == 0) {
        return false;
      }

      btnSaveText.value = 'Menyimpan..'
      axios.post('/recap-uang-tips', data)
      .then(resp => {
        console.log(resp)
        btnSaveText.value = 'Simpan'
        resetForm()
        store.dispatch('uang_tips/setReloadData', true)
      })
      .catch(err => {
        btnSaveText.value = 'Simpan'
        const errmsg = err.response.data.meta.message
        const statusCode = err.response.status

        if (statusCode === 422) {
          let k: keyof typeof errmsg
          const sError: Array<string> = []
          for (k in errmsg) {
            sError.push(errmsg[k].join(''))
          }
          errorSummary.value = sError
        }
      })
    }

    const btnBatalClicked = () => {
      store.dispatch('uang_tips/setEditStatus', false)
    }

    // Event saat drop daftar user diubah
    // const updateCashierName = (obj: any) => {
    //   const selIndex = obj.target.selectedIndex
    //   let name = ''
    //   for( const v of obj.target) {
    //     if (v.selected) {
    //       name = v.innerText
    //     }
    //   }

    //   cashierName.value = name
    // }

    const { listReason } = tipsMoneyReason()

    return {
      // cashierId,
      // cashierName,
      description,
      amountDebit,
      // users,
      errorSummary,
      btnSaveText,
      listReason,
      saveUangTips,
      btnBatalClicked,
      config: {
          decimal: ',',
          thousands: '.',
          prefix: 'Rp ',
          suffix: '',
          precision: 0,
          masked: false
      },
      // updateCashierName
    }
  },
  beforeRouteEnter(to: any, from: any, next: any) {
    next()
  }
});
