
import { useStore } from 'vuex';
import { computed, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Datepicker from 'vue3-datepicker';

export default {
  name: 'ModalTransaction',
  components: {
    Datepicker
  },
  emits: ['on-hide'],
  props: ['showModal'],
  setup(props: any, context: any) {
    const store = useStore();
    const router = useRouter();
    
    const notaNumber = ref('');
    const notaNumberClass = ref('form-control keyboard');
    const transactionDate = ref(new Date());
    const modalVisible = computed(() => props.showModal);
    const modalClass = computed(() => {
      if (props.showModal) {
        return 'modal fade show';
      }
      return 'modal fade';
    });

    onMounted(async () => {
      window.setTimeout( "loadingComponentOrder()" ,750);
    });

    const modalStyle = computed(() => {
      if (props.showModal) {
        return 'display: block;';
      }
      return 'display: none;';
    });

    const resetForm = () => {
      transactionDate.value = new Date();
      notaNumber.value = '';
    };

    const hideModal = () => {
      context.emit('on-hide')
      resetForm()
    };

    const showHistory = () => {
      const nota = (document.getElementById('nomer_nota') as HTMLInputElement).value
      
      console.log(nota)
      // if (notaNumber.value.trim() == '') {
      //   return 'form-control keyboard is-invalid'
      // }
      // return 'form-control keyboard is-valid'
      if (nota.trim() == '') {
        // this.notaNumberClass = 'form-control keyboard is-invalid';
        return false
      }

      const tDate = transactionDate.value.toString();
      const nNumber = nota.toString();
      store.dispatch('history/setHistoryDate', transactionDate);
      router.push({ name: 'HistoryByDate', params: { transactionDate: tDate, notaNumber: nNumber } });
      
      hideModal();
      resetForm();
    };

    return {
      notaNumber,
      transactionDate,
      modalVisible,
      modalClass,
      modalStyle,
      notaNumberClass,
      hideModal,
      showHistory
    }
  }
};
