const state = () => ({
  autoRefresh: false
})

const getters = {
  autoRefresh: (state: any, getters: any, rootState: any) => {
    return state.autoRefresh
  }
}

const actions = {
  async enableAutoRefresh({ commit, state }: any) {
    commit('setAutoRefresh', true);
  },
  async disableAutoRefresh({ commit, state }: any) {
    commit('setAutoRefresh', false);
  }
}

const mutations = {
  setAutoRefresh(state: any, value: boolean) {
    state.autoRefresh = value;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
