const state = () => ({
  expressTable: [],
})

const getters = {
  expressTable: (state: any, getters: any, rootState: any) => {
    return state.expressTable
  }
}

const actions = {
  async setExpressTable({ commit, state }: any, data: Array<any>) {
    commit('setExpressTable', data)
  }
}

const mutations = {
  setExpressTable(state: any, data: Array<any>) {
    state.expressTable = data
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
