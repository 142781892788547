<template>
    <div>
        <Nav></Nav>
        <section class="py-4 section-app" style="background: #FEE4B5;">
            <div class="container-fluid">
                <div class="row">
                    <div v-for="layar in allLayar" :key="layar.id" class="col-lg-4 mb-3">
                        <div class="card h-100">
                            <div class="card-header bg-accent text-white font-weight-bold">Plotting Produksi Layar {{layar.layar_name}}</div>
                            <div class="card-body">
                                <h6 class="text-center mb-4">Plotting Menu</h6>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="card-header bg-accent text-white font-weight-bold">Layar Kiri</div>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <tbody v-if="layar.leftPlot.length>0">
                                                    <tr v-for="(plot, index) in layar.leftPlot" :key="index">
                                                        <td class="font-weight-bold">{{plot}}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else>
                                                    <tr>
                                                        <td>Belum di set</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="card-header bg-accent text-white font-weight-bold">Layar Kanan</div>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <table class="table">
                                                <tbody v-if="layar.rightPlot.length>0">
                                                    <tr v-for="(plot, index) in layar.rightPlot" :key="index">
                                                        <td class="font-weight-bold">{{plot}}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else>
                                                    <tr>
                                                        <td>Belum di set</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <h6 class="text-center mb-4">Plotting Tipe Transaksi</h6>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="card-header bg-accent text-white font-weight-bold">Layar Kiri</div>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <tbody v-if="layar.leftType.length>0">
                                                    <tr v-for="(type, index) in layar.leftType" :key="index">
                                                        <td class="font-weight-bold">{{type}}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else>
                                                    <tr>
                                                        <td>Belum di set</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="card-header bg-accent text-white font-weight-bold">Layar Kanan</div>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <tbody v-if="layar.rightType.length>0">
                                                    <tr v-for="(type, index) in layar.rightType" :key="index">
                                                        <td class="font-weight-bold">{{type}}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else>
                                                    <tr>
                                                        <td>Belum di set</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4"></div>
                                    <div class="col-lg-4">
                                        <button  style="width: 100%" v-on:click="setUpdateModal(layar)" class="btn btn-success"  data-toggle="modal" data-target="#modal-update-plotting"  data-backdrop="static">Update</button>
                                    </div>
                                    <div class="col-lg-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
        </section>

        <!-- Modal Update Plotting-->
        <div class="modal fade" id="modal-update-plotting">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Update Plotting Layar {{selectedLayarUpdate.layar_name}}</h5>
                        <button id="closeModalPlotting" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="form" id="formUpdateLayar" @submit.prevent="updateLayar(selectedLayarUpdate)" method="post">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <label class="font-weight-bold" for="select_left_produksi">Produksi Layar Kiri</label>
                                        <select name="left_plot_produksi[]" id="select_left_produksi" class="form-control select2" multiple>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="font-weight-bold" for="select_left_type">Tipe Transaksi Layar Kiri</label>
                                        <select name="left_type_transaction[]" id="select_left_type" class="form-control select2" multiple>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="font-weight-bold" for="select_right_produksi">Produksi Layar Kanan</label>
                                        <select name="right_plot_produksi[]" id="select_right_produksi" class="form-control select2" multiple>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="font-weight-bold" for="select_right_type">Tipe Transaksi Layar Kanan</label>
                                        <select name="right_type_transaction[]" id="select_right_type" class="form-control select2" multiple>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-light" data-dismiss="modal">Batal</button>
                            <button type="submit" class="btn btn-accent" >Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
        
</template>
<script lang="js" >
    import {onMounted, ref} from 'vue';
    import axios from 'axios';
    import{useRouter} from 'vue-router';
    import Nav from '@/components/secure/odcr/Nav.vue'
    import moment from 'moment'

    export default {
        name: "Plotting",
        components: {
            Nav
        },
        data(){
            return{
                allLayar: [],
                allTypeTransaction: [],
                allTypeTransactionIds: [],
                allPlotProduksi: [],
                allPlotProduksiIds: [],
                selectedLayarUpdate: {}
            }
        },
        mounted() {
            
            window.setTimeout( "loadingComponentOrder()" ,750);
            document.getElementById("plottingNavigation").classList.add("active");
            this.loadTypeTransaction()

        },
        methods: {
            loadTypeTransaction(){
                axios.get('/m-layar-produksi/type-name').then(res => {
                    
                    this.allTypeTransaction = res.data.data.name
                    this.allTypeTransactionIds = res.data.data.id
                    this.loadPlottingLayar()
                    
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            loadPlottingLayar(){
                axios.get('/m-layar-produksi/plot-name').then(res => {
                    
                    this.allPlotProduksi = res.data.data.name
                    this.allPlotProduksiIds = res.data.data.id
                    
                    this.loadLayar()
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            loadLayar(){
                axios.get('/m-layar-produksi/all').then(res => {

                    console.log(res);
                    
                                        
                    const newLayar = []
                    const allPlotProduksi = this.allPlotProduksi
                    const allTypeTransaction = this.allTypeTransaction
                    res.data.data.forEach(function(e) {
                        const layar = JSON.parse(JSON.stringify(e));

                        layar.leftPlot = []
                        layar.rightPlot = []
                        layar.leftType = []
                        layar.rightType = []
                        layar.leftPlotIds = []
                        layar.leftTypeIds = []
                        layar.rightPlotIds = []
                        layar.rightTypeIds = []

                        
                        newLayar.push(layar)

                        layar.plotting.forEach(function(e2) {
                            const plotting = JSON.parse(JSON.stringify(e2));
                            if (plotting.side=='left') {
                                if (plotting.m_plotting_type_plot_produksi_id) {
                                    const plotIds = JSON.parse(plotting.m_plotting_type_plot_produksi_id);
                                    
                                    plotIds.forEach(function(e3) {
                                        layar.leftPlot.push(allPlotProduksi[e3])
                                        layar.leftPlotIds[e3] = '1'
                                    })    
                                }

                                if (plotting.m_plotting_type_transaction_id) {
                                    const typeIds = JSON.parse(plotting.m_plotting_type_transaction_id);
                                    
                                    typeIds.forEach(function(e3) {
                                        layar.leftType.push(allTypeTransaction[e3])
                                        layar.leftTypeIds[e3] = '1'
                                    })    
                                }
                                
                                
                            } else if (plotting.side=='right') {
                                if (plotting.m_plotting_type_plot_produksi_id) {
                                    const plotIds = JSON.parse(plotting.m_plotting_type_plot_produksi_id);
                                    
                                    plotIds.forEach(function(e3) {
                                        layar.rightPlot.push(allPlotProduksi[e3])
                                        layar.rightPlotIds[e3] = '1'
                                    })    
                                }

                                if (plotting.m_plotting_type_transaction_id) {
                                    const typeIds = JSON.parse(plotting.m_plotting_type_transaction_id);
                                    
                                    typeIds.forEach(function(e3) {
                                        layar.rightType.push(allTypeTransaction[e3])
                                        layar.rightTypeIds[e3] = '1'
                                    })    
                                }
                                  
                            }
                            
                        })
                    });

                    this.allLayar = newLayar

                }).catch ((err) => {
                    console.log(err);
                })
            },
            updateLayar(layar){
                const formIdTransaction = document.getElementById('formUpdateLayar');
                const formSubmitTableTransaction = new FormData(formIdTransaction);

                formIdTransaction.reset()
                formSubmitTableTransaction.append('id', layar.id)
                for (const pair of formSubmitTableTransaction.entries()) {
                    console.log(pair[0]+ ', ' + pair[1]) 
                }

                axios.post('/plot-produksi/set', formSubmitTableTransaction).then(res => {
                    this.loadLayar()
                    document.getElementById('closeModalPlotting').click()
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            setUpdateModal(layar){
                this.selectedLayarUpdate = layar
                
                const allPlotProduksiIds = this.allPlotProduksiIds
                const allTypeTransactionIds = this.allTypeTransactionIds

                let leftPlottingHtml = ''
                const leftPlottingElement = document.getElementById('select_left_produksi')
                allPlotProduksiIds.forEach(function(e) {

                    const plot = JSON.parse(JSON.stringify(e))
                    
                    let selected
                    if (layar.leftPlotIds[plot.m_plot_produksi_id]) {
                        selected = "selected"
                        
                    } else selected = ""

                    const html = "<option value="+plot.m_plot_produksi_id+" "+selected+">"+plot.m_plot_produksi_nama+"</option>"
                    leftPlottingHtml+=html
                    
                });

                leftPlottingElement.innerHTML = ""
                leftPlottingElement.innerHTML = leftPlottingHtml

                let leftTypeHtml = ''
                allTypeTransactionIds.forEach(function(e) {

                    const type = JSON.parse(JSON.stringify(e))
                    
                    const selected = layar.leftTypeIds[type.id] ? 'selected' : ''
                  
                    // console.log(layar.leftTypeIds[type.id], selected);
                
                    const html = "<option value="+type.id+" "+selected+">"+type.name+"</option>"
                    leftTypeHtml+=html
                    
                });
                document.getElementById('select_left_type').innerHTML = ''
                document.getElementById('select_left_type').innerHTML = leftTypeHtml

                let rightPlottingHtml = ''
                allPlotProduksiIds.forEach(function(e) {

                    const plot = JSON.parse(JSON.stringify(e))
                    
                    let selected
                    if (layar.rightPlotIds[plot.m_plot_produksi_id]) {
                        selected = "selected"
                        
                    } else selected = ""

                    const html = "<option value="+plot.m_plot_produksi_id+" "+selected+">"+plot.m_plot_produksi_nama+"</option>"
                    rightPlottingHtml+=html
                    
                });
                document.getElementById('select_right_produksi').innerHTML = ''

                document.getElementById('select_right_produksi').innerHTML = rightPlottingHtml

                let rightTypeHtml = ""
                allTypeTransactionIds.forEach(function(e) {

                    const type = JSON.parse(JSON.stringify(e))
                    
                    let selected
                    if (layar.rightTypeIds[type.id]) {
                        selected = "selected"
                        
                    } else selected = ""

                    const html = "<option value="+type.id+" "+selected+">"+type.name+"</option>"
                    rightTypeHtml+=html
                    
                });
                document.getElementById('select_right_type').innerHTML = ''
                document.getElementById('select_right_type').innerHTML = rightTypeHtml
            }
        },
        setup() {
            const user = ref(null);
            const router = useRouter();

            onMounted( async () => {
                try {
                    const response = await axios.get('/user');
                    user.value = response.data.data;
                } catch (e) {
                    await router.push({ name: 'loginPage' });
                }
            });

            return {
                user,
            }

        },
    }
</script>
