<template>
    <section>
        <div class="modal fade" id="modal-diskon">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Diskon Transaksi</h6>
                        <button id="close-modal-diskon" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group text-center">
                            <h2 class="m-0 text-accent">Total Bayar : {{total}}</h2>
                        </div>
                        <div class="form-group">
                            <label for="printer_type">Jenis Potongan</label>
                            <select class="form-control option"
                                v-model="discountType" v-on:change="changeLabel()">
                                <option value="voucher">Voucher</option>
                                <option value="diskon">Diskon Nominal</option>
                                <option value="diskonPercent">Diskon %</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="">{{ labelDiskon }}</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <!-- <input id="nominal_diskon" v-model="discountNominal" type="text" class="form-control keyboard numeric"> -->
                                    <money3 v-model.number="discountNominal" v-bind="config" class="form-control"></money3>

                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-on:click="save()" class="btn btn-accent" >Simpan</button>
                    </div>
                </div>
            </div>
        </div>

        
        
    </section>
    
</template>

<script lang="js">
import {roundTo, roundToUp, roundToDown} from 'round-to';
import axios from 'axios';
// import { useStore } from 'vuex'
// import { useRouter, useRoute } from 'vue-router';
import '@formatjs/intl-numberformat';
import { formatMoney } from '@/mixins/app';
import { Money3Component } from 'v-money3';

export default {
    name: "PopupDiskon",
    components: { money3: Money3Component },

    props: [
        'total'
    ],
    data(){
        return{
            labelDiskon: 'Nominal',
            discountType: 'voucher',
            discountNominal: '',
            config: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 0,
                masked: false
            },
            waroengInfo : {},
        }
    },
    mounted() {
        axios.get('m-waroeng/waroeng-info')
        .then(resp => {
            this.waroengInfo = resp.data
            if(this.waroengInfo.currency == 'RM'){
                this.config.decimal = '.'
                this.config.thousands = ','
                this.config.precision = 2
            }
        }).catch(err => console.log(err))
        window.setTimeout( "loadingComponent()" ,750);
    },
    
    methods: {
        changeLabel(){
            if (this.discountType == 'diskonPercent') {
                this.labelDiskon = 'Persentase Diskon';
            } else {
                this.labelDiskon = 'Nominal';
            }
        },
        save(){
            // const nominal = document.getElementById("nominal_diskon").value;
            if (this.discountType == 'diskonPercent') {
                const percent = this.discountNominal;
                const newDiskon = (percent/100)*this.total
                this.discountNominal = newDiskon
            }
            const result = {
                    discountType: this.discountType,
                    discountNominal: this.discountNominal
                }
            
            // validate
            if (this.discountNominal == '' || this.discountNominal == 0) {
                this.$swal.fire({
                        title:"Transaksi Gagal!", 
                        text:"Nominal Belum Diisi", 
                        icon:"warning",
                        showCancelButton: false,
                        confirmButtonColor: '#653332',
                        confirmButtonText: 'Ok'
                    })
            }else{
                if (this.discountType == 'voucher') {
                    if (this.total < this.discountNominal ) {
                        this.$swal.fire({
                            title:"Transaksi Gagal!", 
                            text:"Nominal transaksi kurang dari Voucher", 
                            icon:"warning",
                            showCancelButton: false,
                            confirmButtonColor: '#653332',
                            confirmButtonText: 'Ok'
                        })
                    
                    }else{
                        this.$emit('saveDiskon', result)
                        this.discountType = 'voucher';
                        this.discountNominal = 0;
                        document.getElementById("close-modal-diskon").click();
                    }
                }else{
                    if (this.discountNominal > this.total) {
                        this.$swal.fire({
                            title:"Transaksi gagal!", 
                            text:"Diskon Melebihi Total Bayar", 
                            icon:"warning",
                            showCancelButton: false,
                            confirmButtonColor: '#653332',
                            confirmButtonText: 'Ok'
                        })
                    }else{
                        this.$emit('saveDiskon', result)
                        this.discountType = 'voucher';
                        this.discountNominal = 0;
                        document.getElementById("close-modal-diskon").click();
                    }
                }
            }
        }
    }
}
</script>
