const state = () => ({
  modalVisible: false,
  mutationModal: [],
  mutationModalId: 0,
  currentMutModal: null
})

const getters = {
  modalVisible: (state: any, getters: any, rootState: any) => {
    return state.modalVisible
  },
  mutationModal: (state: any, getters: any, rootState: any) => {
    return state.mutationModal
  },
  mutationModalId: (state: any, getters: any, rootState: any) => {
    return state.mutationModalId
  },
  currentMutModal: (state: any, getters: any, rootState: any) => {
    return state.currentMutModal
  }
}

const actions = {
  async showModal({ commit, state }: any) {
    commit('setModalVisible', true)
  },
  async hideModal({ commit, state }: any) {
    commit('setModalVisible', false)
  },
  async setMutationModal({ commit, state }: any, value: any[]) {
    commit('setMutationModal', value)
  },
  async setMutationModalId({ commit, state }: any, value: number) {
    commit('setMutationModalId', value)
  },
  async setCurrentMutModal({ commit, state }: any, value: any) {
    commit('setCurrentMutModal', value)
  }
}

const mutations = {
  setModalVisible(state: any, value: boolean) {
    state.modalVisible = value
  },
  setMutationModal(state: any, value: any[]) {
    state.mutationModal = value
  },
  setMutationModalId(state: any, value: number) {
    state.mutationModalId = value
  },
  setCurrentMutModal(state: any, value: any) {
    state.currentMutModal = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
