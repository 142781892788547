
import { computed, ref, defineComponent, watch, Ref } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'
import '@formatjs/intl-numberformat';

interface UangTips {
  r_u_t_nominal: string;
  r_u_t_id: string;
  r_u_t_keterangan: string;
}

export default defineComponent({
  name: 'UangTipsView',
  emits: ['on-delete'],
  setup(props, context) {
    const store = useStore()
    const uangTips: Ref<Array<UangTips>> = ref([])
    const dataEmpty = computed(() => uangTips.value.length == 0)
    const total = computed(() => {
      if (uangTips.value.length > 0) {
        let sum = 0
        uangTips.value.forEach(a => sum += parseFloat(a.r_u_t_nominal))
        return sum
      }

      return 0
    })

    const reloadData = computed(() => store.state['uang_tips'].reloadData)
    watch(reloadData, (newData, oldData) => {
      if (newData === true) {
        axios.get('/recap-uang-tips').then(resp => {
          uangTips.value = resp.data
          store.dispatch('uang_tips/setReloadData', false)
        }).catch ((err) => {
          console.log(err)
        })
      }
    })

    axios.get('/recap-uang-tips').then(resp => {
      uangTips.value = resp.data
      store.dispatch('uang_tips/setReloadData', false)
    }).catch ((err) => {
      console.log(err)
    })

    // const editUangTips = (id: string) => {
    //   axios.get('/recap-uang-tips/' + id).then(resp => {
    //     store.dispatch('uang_tips/setUangTips', resp.data)
    //     store.dispatch('uang_tips/setEditStatus', true)
    //   }).catch ((err) => {
    //     console.log(err)
    //   })
    // }

    // const deleteUangTips = (id: string) => {
    //   context.emit('on-delete', id)
    //   // store.dispatch('uang_tips/setCurrentId', id)
    //   // store.dispatch('modal/showModal')
    //   // store.dispatch('confirm_modal/showModal')
    // }

    return {
      uangTips,
      dataEmpty,
      total,
      // editUangTips,
      // deleteUangTips
    }
  },
  data(){
    return{
      currencyMw : 'Rp',
      currencyFormat : 'id-ID',
      currencyPrefix : 'IDR',
    }
  },
  mounted() {
        axios.get('m-waroeng/waroeng-info')
        .then(resp => {
          this.currencyMw = resp.data.currency
            if(resp.data.currency == 'RM'){
              this.currencyFormat = 'ms-MY'
              this.currencyPrefix = 'MYR'
            }
        }).catch(err => console.log(err))
        window.setTimeout( "loadingComponent()" ,750);
    },
  methods:{
    currency(price,prefix){
      const nominal = parseFloat(price);
      const format = this.currencyFormat
      const pref = this.currencyPrefix
      const prefView = (prefix != '') ? this.currencyMw : '';

      const rupiah = new Intl.NumberFormat(format, {
          style: 'currency', //decimal,currency,percent
          currency: pref,
      }).format(nominal);
      
      return prefView+' '+rupiah;
    },
  }
})
