const state = () => ({
  uangTips: {},
  isEdit: false,
  currentId: '',
  reloadData: false
})

const getters = {
  isEdit: (state: any, getters: any, rootState: any) => {
    return state.isEdit
  },
  uangTips: (state: any, getters: any, rootState: any) => {
    return state.uangTips
  },
  currentId: (state: any, getters: any, rootState: any) => {
    return state.currentId
  },
  reloadData: (state: any, getters: any, rootState: any) => {
    return state.reloadData
  }
}

const actions = {
  async setEditStatus({ commit, state }: any, value: boolean) {
    commit('setEditStatus', value)
  },
  async setUangTips({ commit, state }: any, data: any) {
    commit('setUangTips', data)
  },
  async setCurrentId({ commit, state }: any, id: string) {
    commit('setCurrentId', id)
  },
  async setReloadData({ commit, state }: any, value: boolean) {
    commit('setReloadData', value)
  }
}

const mutations = {
  setEditStatus(state: any, value: boolean) {
    state.isEdit = value
  },
  setUangTips(state: any, data: any) {
    state.uangTips = data
  },
  setCurrentId(state: any, id: string) {
    state.currentId = id
  },
  setReloadData(state: any, value: boolean) {
    state.reloadData = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
