<template>

    <section>
        <div class="modal fade" id="modal-reduce-menu">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Kurangi Menu</h6>
                        <button id="close-modal-reduce-menu" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group text-center">
                            <h2 class="m-0 text-accent">Menu : {{menu.m_produk_nama}}</h2>
                        </div>
                        <hr/>
                        <div class="form-group">
                            <label for="">Masukkan Jumlah Pengurangan Menu</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <input min="1" :max="menu.tmp_transaction_detail_qty" id="input_qty" class="form-control numeric" v-model="qty" v-on:input="setQty()">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="col-form-label col-4">Pilih Alasan</label>
                            <div class="col-8">
                                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                    <label class="btn btn-secondary active">
                                        <input v-on:click="submit('Kesalahan Input')" type="radio" name="cash-bayar">Kesalahan Input
                                    </label>
                                    <label class="btn btn-secondary active">
                                        <input v-on:click="submit('Dibatalkan Konsumen')" type="radio" name="cash-bayar">Dibatalkan Konsumen
                                    </label>
                                </div>
                            </div>      
                        </div>
                        <hr/>
                        
                    </div>
                </div>
            </div>
        </div>

    </section>
    
</template>

<script lang="js">
    export default {
        name: "PopupReduceMenu",
        props: [
            'menu'
        ],
        data(){
            return{
                reason: '',
                qty: 1
            }
        },
        mounted() {
            
            window.setTimeout( "loadingComponentOrder()" ,750);
        },
        methods: {
            setQty(){
                console.log(this.qty);
                
            },
            submit(reason){
                const value = document.getElementById('input_qty').value
                if (value>this.menu.tmp_transaction_detail_qty) {
                    this.$swal.fire({
                        title:"Error", 
                        text:'Quantity pengurangan lebih dari quantity menu', 
                        icon:"warning",
                        showCancelButton: false,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'Tutup'
                    })
                } else {
                    document.getElementById('close-modal-reduce-menu').click()
                    const result = {
                        transactionMenuId: this.menu.tmp_transaction_detail_id,
                        reason: reason,
                        qty: value
                    }
                    this.$emit('onReduce', result)
                }
            }
        }
    }
</script>

<style scoped>

</style>