import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-backdrop fade show"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VoucherModal = _resolveComponent("VoucherModal")!
  const _component_InputVoucherModal = _resolveComponent("InputVoucherModal")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_component_VoucherModal, {
      onOnVoucher: _ctx.onVoucher,
      onOnHide: _ctx.onVoucherHide,
      modalShow: _ctx.voucherModalShow
    }, null, 8, ["onOnVoucher", "onOnHide", "modalShow"]),
    _createVNode(_component_InputVoucherModal, {
      onOnHide: _ctx.onInputVoucherHide,
      modalShow: _ctx.inputVoucherModalShow
    }, null, 8, ["onOnHide", "modalShow"]),
    (_ctx.modalVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true)
  ]))
}