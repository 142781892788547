
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'

export default defineComponent({
  name: 'InputVoucherModal',
  props: ['modalShow'],
  emits: ['on-hide'],
  setup(props, { emit }) {
    const store = useStore()
    const kppType = ref(0)
    const kppTypeErr = ref('')
    const nominalVoucher = ref(0)
    const voucherCode = ref('')
    const voucherErr = ref('')
    const discountValue = ref(0)
    const discountErr = ref('')
    const modalVisible = computed(() => props.modalShow)
    const voucherStyle = computed(() => {
      if (voucherErr.value) {
        return 'display: block;'
      }
      return 'display: none;'
    })
    const discountStyle = computed(() => {
      if (discountErr.value) {
        return 'display: block;'
      }
      return 'display: none;'
    })
    const kppTypeStyle = computed(() => {
      if (kppTypeErr.value) {
        return 'display: block;'
      }
      return 'display: none;'
    })

    const payTotal = computed(() => {
      const total = store.state['voucher'].payTotal
      if (nominalVoucher.value > 0 && nominalVoucher.value < total && kppType.value == 1) {
        return total - nominalVoucher.value
      } else if (nominalVoucher.value > 0 && nominalVoucher.value > total && kppType.value == 1) {
        return 0
      } else if (discountValue.value > 0 && kppType.value == 2) {
        return total - discountValue.value
      }

      return total
    })
    const voucherType = computed(() => store.state['voucher'].voucherType)
    watch(voucherType, (newValue, oldValue) => {
      store.dispatch('voucher/setVoucherType', newValue)
      kppType.value = newValue
    })

    watch(kppType, (newValue, oldValue) => {
      if (newValue == 1 || newValue == 2) {
        kppTypeErr.value = ''
      }
    })

    const hideModal = () => {
      kppType.value = 0
      voucherCode.value = ''
      discountValue.value = 0
      
      emit('on-hide')
    }

    const cancel = () => {
      hideModal()
    }

    const submit = () => {
      if (kppType.value == 0) {
        kppTypeErr.value = 'Pilih Jenis!'
        return false
      } else if (kppType.value == 2) {
        store.dispatch('voucher/setPayTotal', payTotal.value)
        store.dispatch('voucher/setDiscount', discountValue.value)
        hideModal()
        return false
      }

      axios.get('/voucher/get-voucher', { params: { voucher_code: voucherCode.value }})
      .then(resp => {
        nominalVoucher.value = resp.data.m_voucher_value
        store.dispatch('voucher/setVoucherValue', nominalVoucher.value)
        store.dispatch('voucher/setPayTotal', payTotal.value)
        hideModal()
      })
      .catch(err => {
        if (err.response) {
          voucherErr.value = err.response.data.msg
        } else {
          console.log(err)
        }
      })
    }

    return {
      submit,
      hideModal,
      cancel,
      kppType,
      kppTypeStyle,
      kppTypeErr,
      voucherCode,
      discountStyle,
      discountErr,
      discountValue,
      payTotal,
      voucherErr,
      voucherStyle,
      modalVisible
    }
  },
  mounted() {
    window.setTimeout( "loadingComponentOrder()" ,750);
  }
})
