/**
 * Kompisi fungsi2 untuk komponen App
 *
 * @author Agus Susilo<smartgdi@gmail.com>
 */

import { useStore } from 'vuex'
import { onMounted } from 'vue'
import axios from 'axios'

export const AUTO_REFRESH_DURATION = 2000
export const sleep = (time: number) => {
  return new Promise(resolve => setTimeout(resolve, time))
}

export const resetOjolStatus = () => {
  const store = useStore()

  onMounted(() => {
    store.dispatch('order/setIsOnlineOrder', false)
    store.dispatch('order/setVendorName', '')
  })
}

export const loadData = (userId: string | null) => {
  const store = useStore()
  const token = localStorage.getItem('token') || ''

  const loadWarung = () => {
    const warung = store.state['warung'].warung
    const area = store.state['warung'].area
    if (warung != null) {
      return
    }

    // axios.get('/warung')
    // .then(resp => {
    //     store.dispatch('warung/setWarung', resp.data.data[0])
    //     store.dispatch('warung/setArea', resp.data.data[0].m_area)
    // })
    // .catch(err => {
    //   console.log(err)
    // })
  }

  const loadEmployee = (userId: string|null) => {
    const employee = store.state['user'].employee
    if (employee !== null) {
      return
    }
    
    // axios.get('/karyawan/by-user-id/' + userId)
    // .then(resp => {
    //   if (resp.data.error == 0) {
    //     store.dispatch('user/setEmployee', resp.data.result)
    //   }
    // })
    // .catch(err => {
    //   console.log(err)
    // })
  }

  const setUserId = (userId: string|null) => {
    const uidState = store.state['user'].currentUserId
    if (uidState.length > 0) {
      return
    }
    
    store.dispatch('user/setCurrentUserId', userId)
  }

  // Get user yg aktif
  const loadUser = () => {
    const userState = store.state['user'].currentUser
    if (userState !== null) {
      return
    }
    
    axios.get('/user').then(resp => {                    
      store.dispatch('user/setCurrentUser', resp.data.data)
    }).catch ((err) => {
      console.log(err)
    })
  }

  onMounted(() => {
    setUserId(userId)
    loadData(userId)
    loadEmployee(userId)
    loadUser()
    loadWarung()
  })
}

export const formatMoney = (value: number, style = 'currency', currency = 'IDR') => {
  let val = value
  if (value === undefined) {
    val = 0
  }

  return Intl.NumberFormat('id-ID', { style, currency }).format(val)
}
