
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'Modal',
  emits: ['on-confirm', 'on-hide'],
  props: ['title', 'bodyText', 'modalSize', 'modalShow'],
  setup(props: any, context: any) {
    const modalVisible = computed(() => props.modalShow)
    const modalSizeClass = computed(() => {
      const ms = 'modal-dialog modal-dialog-centered'
      if (props.modalSize === 'small') {
        return `${ms} modal-sm`
      } else if (props.modalSize === 'medium') {
        return `${ms} modal-md`
      } else if (props.modalSize == 'xlarge') {
        return `${ms} modal-xl`
      }
      return `${ms} modal-lg`
    })

    const onConfirm = () => {
      context.emit('on-confirm')
    }

    const onHide = () => {
      context.emit('on-hide')
    }

    return {
      modalVisible,
      modalSizeClass,
      onHide,
      onConfirm
    }
  }
});
