<template>
    <div>
        <Nav></Nav>

        <section class="py-4 section-app bg-danger">
            <div class="container-fluid">
                <div class="layout-pesanan d-flex" style="height:calc(100vh - 100px);">
                    <div v-if="page>1" class="button-wrap pr-4 align-self-center">
                        <button v-on:click="loadPrevPage()" class="btn btn-action btn-prev" type="button"><i class="feather-chevron-left"></i></button>
                    </div>
                    <div class="layanan-pesanan-wrapper w-100">
                        <div v-for="(table) in tables" :key="table.m_transaction_table_id" class="card card-pesanan">
                            <div class="card-header">
                                <h5 class="text-center font-weight-bold">
                                    <span v-if="table.name=='Dine In'">Meja </span> 
                                    {{table.tableName}}
                                </h5>
                            </div>
                            <div class="card-body h-100 overflow-auto">
                                <table width="100%">
                                    <tr>
                                        <td width="120">Nomor Nota</td>
                                        <td width="10">:</td>
                                        <td>{{table.m_transaction_table_note_number}}</td>
                                    </tr>
                                    <tr>
                                        <td>Nama Big Boss</td>
                                        <td>:</td>
                                        <td>{{table.m_transaction_table_customer_name}}</td>
                                    </tr>
                                    <tr>
                                        <td>Jam Order</td>
                                        <td>:</td>
                                        <td>{{table.m_transaction_table_order_time}}</td>
                                    </tr>
                                    <tr>
                                        <td>Pelayan Input</td>
                                        <td>:</td>
                                        <td>{{table.user_input.name}}</td>
                                    </tr>
                                </table>
                                <div class="mt-4">
                                    <div class="card">
                                        <div v-for="(menu, index) in table.menus" :key="index">
                                            <div class="card-header bg-accent text-white text-center">{{menu.name}}</div>
                                            <div class="table-responsive">
                                                <table class="table m-0">
                                                    <tr v-for="(item, indexItem) in menu.items" :key="item.id">
                                                        <template v-if="indexItem==0 || indexItem%2==0">
                                                            <td>
                                                                <span >{{item.m_menu_nama}}</span>
                                                            </td>
                                                            <td width="30" class="p-0"></td>
                                                            <td width="30" class="p-0">
                                                                <a v-if="item.is_served==0" href="#" data-toggle="modal" data-target="#modal-kembalikan-menu"><i class="fa fa-times-circle fa-2x text-danger"></i></a>
                                                                <a v-else href="#" data-toggle="modal" data-target="#modal-kembalikan-menu"><i class="fa fa-check-circle fa-2x text-success"></i></a>
                                                            </td>
                                                        </template>

                                                        <template v-else>
                                                            <td>{{item.m_menu_nama}}</td>
                                                            <td width="30" class="p-0">
                                                                <a v-if="item.is_served==0" href="#" data-toggle="modal" data-target="#modal-kembalikan-menu"><i class="fa fa-times-circle fa-2x text-danger"></i></a>
                                                                <a v-else href="#" data-toggle="modal" data-target="#modal-kembalikan-menu"><i class="fa fa-check-circle fa-2x text-success"></i></a>
                                                            </td>
                                                            <td width="30" class="p-0"></td>
                                                        </template>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-if="page<lastPage" class="button-wrap pl-4 align-self-center">
                        <button v-on:click="loadNextPage()" class="btn btn-action btn-next" type="button"><i class="feather-chevron-right"></i></button>
                    </div>
                </div>
                
            </div>
        </section>
    </div>
</template>
<script lang="js" >
    import {onMounted, ref} from 'vue';
    import axios from 'axios';
    import{useRouter} from 'vue-router';
    import Nav from '@/components/secure/odcr/Nav.vue'

    export default {
        name: "HalAmbil",
        components: {
            Nav
        },
        data(){
            return{
                page: 1,
                allMenuJenis: [],
                tables: [],
                lastPage: 1,
                tableNames: []
            }
        },
        mounted() {
            this.loadMenuJenis()
            window.setTimeout( "loadingComponentOrder()" ,750);
            document.getElementById("pesanAktifNavigation").classList.add("active");
        },
        methods: {
            loadPrevPage(){
                this.page-=1
                this.loadTableDishes()
            },
            loadNextPage(){
                this.page+=1
                this.loadTableDishes()
            },
            loadTableDishes(){
                axios.post('/m-dish-status/active?page='+this.page, {
                    type: this.$route.params.type
                }).then(res => {
                    this.lastPage = res.data.data.last_page
                    const allJenis = this.allMenuJenis
                    const allTableName = this.tableNames
                    console.log(res)
                    
                    res.data.data.data.forEach(function(table)  {

                        const menus = []
                        let complete = true
                        allJenis.forEach(function (jenis) {
                            const newItems = []
                            table.m_dish_status.forEach(function(menu) {
                                
                                if (menu.m_dish_status_menu_jenis_id == jenis.m_menu_jenis_id) {
                                    newItems.push(menu)
                                }
                                if (menu.is_served==0) {
                                    complete = false
                                }
                            });
                            if (newItems.length>0) {
                                menus.push({
                                    name: jenis.m_menu_jenis_nama,
                                    items: newItems
                                })
                            }
                        });

                        const tableIds = JSON.parse(table.m_transaction_table_table_list)
                        const tableName = []

                        if (tableIds) {
                            tableIds.forEach(function(id) {
                                tableName.push(allTableName[id])
                            });    
                        } else {
                            tableName.push(table.name+' - '+table.m_transaction_table_customer_name)
                        }
                        


                        table.tableName = tableName.join(", ")
                        table.complete = complete
                        table.menus = menus
                        
                    });
                    this.tables = res.data.data.data
                    
                    
                }).catch ((err) => {
                    console.log(err);
                })
            },
            loadMenuJenis(){
                axios.get('/m-menu-jenis').then(res => {
                    
                    this.allMenuJenis = res.data
                    this.loadTableName();
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            loadTableName(){
                axios.get('/m-transaction-table/table-name').then(res => {
                    
                    this.tableNames = res.data.data                    
                    
                    this.loadTableDishes();
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            setDishServed(id){
                axios.get('/m-dish-status/set-served/'+id).then(res => {
                    
                    console.log(res)
                    if (res.data.meta.code==200) {
                        this.loadTableDishes()
                    }
                }).catch ((err) => {
                    console.log(err);
                })
            },
            popupNotServeDish(id){
                const func = this.setDishNotServed
                this.$swal.fire({
                    text:"Apa Anda yakin ingin mengembalikan menu?", 
                    icon:"warning",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Iya',
                    cancelButtonText: 'Tidak'
                }).then(function (success) {
                    if (success.isConfirmed) {
                        func(id)
                    }
                })
            },
            setDishNotServed(id){
                axios.get('/m-dish-status/set-not-served/'+id).then(res => {
                    
                    if (res.data.meta.code==200) {
                        this.loadTableDishes()
                    }
                }).catch ((err) => {
                    console.log(err);
                })
            },
            setDishDelivered(id){
                axios.get('/m-dish-status/set-delivered?transaction_id='+id+'&type='+this.$route.params.type).then(res => {
                    console.log(res);
                    
                    if (res.data.meta.code==200) {
                        this.loadTableDishes()
                    }
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            }
        },
        setup() {
            const user = ref(null);
            const router = useRouter();

            onMounted( async () => {
                try {
                    const response = await axios.get('/user');
                    user.value = response.data.data;
                } catch (e) {
                    await router.push({ name: 'loginPage' });
                }
            });

            return {
                user
            }

        },
    }
</script>
