
import { defineComponent, ref, computed } from 'vue'
import { useHistory } from '@/mixins/history'
import history from '@/store/modules/history'

export default defineComponent({
  name: 'HistoryDetail',
  props: ['historyDetails', 'typeMenu', 'showDetail'],
  setup(props) {
    const historyDetail = computed(() => props.historyDetails)
    const menuJenis = computed(() => props.typeMenu)
    const showHistoryDetail = computed(() => props.showDetail)
    const discountVal = computed(() => {
      return historyDetail.value.transaction.global_discount
    })
    const hasDiscount = computed(() => {
      if (parseFloat(historyDetail.value.transaction.global_discount) > 0) {
        return true
      }
      return false
    })
    const voucherVal = computed(() => {
      return historyDetail.value.transaction.nominal_voucher
    })
    const hasVoucher = computed(() => {
      if (parseFloat(historyDetail.value.transaction.nominal_voucher) > 0) {
        return true
      }
      return false
    })
    const getPrice = (qty: number, total: any) => {
      return parseFloat(total) / qty
    }

    return {
      historyDetail,
      menuJenis,
      showHistoryDetail,
      discountVal,
      hasDiscount,
      voucherVal,
      hasVoucher,
      getPrice
    }
  }
})
