<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-accent" id="navbar"></nav>

    <router-view />

</template>

<script>
    export default {
        name: "Secure",

        setup() {


            return {

            }
        }
    }
</script>

<style scoped>
    @import "../../assets/css/bootstrap.min.css";
    @import "../../assets/css/app.css";
    @import "../../assets/plugins/feather-icons/feather.css";
    @import "../../assets/plugins/font-awesome/css/font-awesome.min.css";
    @import "../../assets/plugins/timepicki/css/timepicki.css";
    @import "../../assets/plugins/select2/select2.min.css";
    @import "../../assets/plugins/select2/select2-bootstrap.min.css";
    @import "../../assets/plugins/keyboard/keyboard-dark.css";
</style>