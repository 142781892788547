import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Login from "@/components/public/Login.vue";
import Secure from "@/components/secure/Secure.vue";
import Dashboard from "@/components/secure/dashboard/Dashboard.vue";
import Odcr from "@/components/secure/odcr/Odcr.vue"
import HalTata from "@/components/secure/odcr/HalTata.vue"
import AmbilLanding from "@/components/secure/ambil/Landing.vue"
import HalAmbil from "@/components/secure/ambil/HalAmbil.vue"
import PesanAktif from "@/components/secure/pesanAktif/PesanAktif.vue"
import KitchenOrder from "@/components/secure/odcr/KitchenOrder.vue"
import OnlineOrder from "@/components/secure/onlineOrder/OnlineOrder.vue";
import OdcrOnline from "@/components/secure/odcr/OdcrOnline.vue";
import Mutation from "@/components/secure/mutation/Mutation.vue";
import History from "@/components/secure/history/History.vue";
import HistoryByDate from "@/components/secure/history/HistoryByDate.vue";
import Stock from "@/components/secure/stock/Stock.vue";
import OrderMenu from "@/components/secure/dashboard/Order.vue";
import Plotting from "@/components/secure/produksi/Plotting.vue";
import Layar from "@/components/secure/produksi/Layar.vue";
import splitMenuOrder from "@/components/secure/dashboard/splitMenuOrder.vue";
import UangTips from "@/components/secure/mutation/UangTips.vue";
import Modal from "@/components/secure/odcr/Modal.vue";
import Shift from "@/components/secure/odcr/Shift.vue";
import UpdateData from "@/components/secure/UpdateData.vue";
import Refund from "@/components/secure/dashboard/Refund.vue";
import OrderOnlineVue from '@/components/secure/dashboard/OrderOnline.vue';

const hasToken = localStorage.getItem('token') || localStorage.getItem('token') != null;

const beforeEnter = (to: any, from: any, next: any) => {
  if (!localStorage.getItem('token') || localStorage.getItem('token') == null) {
    next({ name: 'loginPage' });
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  { 
    path: '/login',  
    name: 'loginPage',
    beforeEnter: (to, from, next) => {
      if(localStorage.getItem('token') || localStorage.getItem('token') != null){
        next({ name: 'dashboardPage' })
      } else {
        next();
      }
    },
    component: Login 
  },
  {
    path: '',
    name: 'homePage',
    component: Secure,
    beforeEnter: (to, from, next) => {
      if(localStorage.getItem('token') && localStorage.getItem('token') != null){
        next({ name: 'dashboardPage' })
      }
      else next({ name: 'loginPage'})
    }
  },
  { 
    path: '/dashboard',  
    name: 'dashboardPage',
    beforeEnter: beforeEnter,
    component: Dashboard 
  },
  { 
    path: '/order/:transactionId',  
    name: 'orderMenu',
    beforeEnter: beforeEnter,
    component: OrderMenu 
  },
  { 
    path: '/order/split-menu/:transactionId',  
    name: 'splitMenuOrder',
    beforeEnter: (to, from, next) => {
      if(!localStorage.getItem('token') || localStorage.getItem('token') == null){
        next({ name: 'loginPage' })
      }
      next()
    },
    component: splitMenuOrder 
  },
  { 
    path: '/ojol',  
    name: 'onlineOrderPage',
    beforeEnter: (to, from, next) => {
      if(!localStorage.getItem('token') || localStorage.getItem('token') == null){
        next({ name: 'loginPage' })
      }
      next()
    },
    component: OnlineOrder 
  },
  { 
    path: '/online-order',  
    name: 'onlineOrderQrPage',
    beforeEnter: (to, from, next) => {
      if(!localStorage.getItem('token') || localStorage.getItem('token') == null){
        next({ name: 'loginPage' })
      }
      next()
    },
    component: OrderOnlineVue 
  },
  { 
    path: '/odcr-ojol',  
    name: 'odcrOnline',
    beforeEnter: (to, from, next) => {
      if(!localStorage.getItem('token') || localStorage.getItem('token') == null){
        next({ name: 'loginPage' })
      }
      next()
    },
    component: OdcrOnline 
  },
  { 
    path: '/mutasi',  
    name: 'mutationPage',
    /*
    beforeEnter: (to, from, next) => {
      if(!localStorage.getItem('token') || localStorage.getItem('token') == null){
        next({ name: 'loginPage' })
      }
      next()
    },
    */
    beforeEnter: beforeEnter,
    component: Mutation 
  },
  { 
    path: '/history',  
    name: 'historyPage',
    beforeEnter: beforeEnter,
    component: History 
  },
  { 
    path: '/history-bydate/:transactionDate/:notaNumber',  
    name: 'HistoryByDate',
    beforeEnter: beforeEnter,
    component: HistoryByDate
  },
  { 
    path: '/refund/:rekapTransaksiId',  
    name: 'Refund',
    beforeEnter: beforeEnter,
    component: Refund
  },
  { 
    path: '/stock',  
    name: 'stockPage',
    beforeEnter: (to, from, next) => {
      if(!localStorage.getItem('token') || localStorage.getItem('token') == null){
        next({ name: 'loginPage' })
      }
      next()
    },
    component: Stock 
  },
  { 
    path: '/odcr',  
    name: 'odcr',
    beforeEnter: (to, from, next) => {
      if(!localStorage.getItem('token') || localStorage.getItem('token') == null){
        next({ name: 'loginPage' })
      }
      next()
    },
    component: Odcr 
  },
  { 
    path: '/kitchen-order/:transactionId',  
    name: 'kitchenOrder',
    beforeEnter: beforeEnter,
    component: KitchenOrder 
  },
  { 
    path: '/hal-tata/:type',  
    name: 'halTata',
    beforeEnter: beforeEnter,
    component: HalTata 
  },
  { 
    path: '/ambil-landing',  
    name: 'ambilLanding',
    beforeEnter: beforeEnter,
    component: AmbilLanding 
  },
  { 
    path: '/hal-ambil/:type',  
    name: 'halAmbil',
    beforeEnter: beforeEnter,
    component: HalAmbil 
  },
  { 
    path: '/plotting',  
    name: 'plotting',
    beforeEnter: beforeEnter,
    component: Plotting 
  },
  { 
    path: '/layar-produksi/:layarId',  
    name: 'layarProduksi',
    beforeEnter: beforeEnter,
    component: Layar 
  }
  ,
  { 
    path: '/pesan-aktif',  
    name: 'pesanAktif',
    beforeEnter: beforeEnter,
    component: PesanAktif
  },
  { 
    path: '/uangtips',  
    name: 'UangTips',
    component: UangTips,
    beforeEnter: beforeEnter
  },
  { 
    path: '/modal',  
    name: 'Modal',
    component: Modal,
    beforeEnter: beforeEnter
  },
  { 
    path: '/change-shift',  
    name: 'Shift',
    component: Shift,
    beforeEnter: beforeEnter
  },
  { 
    path: '/update-data',  
    name: 'UpdateData',
    component: UpdateData,
    beforeEnter: beforeEnter
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // console.log({to});
  // console.log({from});
  next();
});

export default router;
