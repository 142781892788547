<template>
    <section>
        <div class="modal fade" id="modal-printer">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Setting Printer</h6>
                        <button id="close-modal-printer" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="printer_location">Lokasi Printer</label>
                            <select class="form-control option"
                                v-model="printerLocation" v-on:change="getPrinterInfo()">
                                <option v-if="userRole != 'opp'" value="kasir">Cashier</option>
                                <option value="repeat">Repeat Order</option>
                                <option value="kitchen">Kitchen</option>
                                <option value="bar">Bar</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <!-- <input v-model="printerLocation" type="hidden" name="printer_location" id="printer_location" required readonly> -->
                            <label for="printer_type">Jenis Koneksi</label>
                            <select class="form-control option"
                                v-model="printerType" v-on:change="getListPrinter()">
                                <option value="usb-unix">USB-Linux</option>
                                <option value="usb-cups">USB-CUPS</option>
                                <option value="network">Network</option>
                                <option value="windows-sharing">Windows-Sharing</option>
                                <option value="android-image">Android Printer</option>
                                <option value="android-image-vps">Android Printer Online</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="">
                                Nama/IP Printer 
                                <span class="text-right text-nowrap text-danger">
                                    {{ listPrinter }}
                                </span>
                            </label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <input id="printer_address" v-model="printerAddress" type="text" class="form-control keyboard" :readonly="addressStatus">
                                </div>
                                
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="printer_type">Lebar Kertas</label>
                            <select class="form-control option"
                                v-model="printerWidth" >
                                <option value="405">58 mm</option>
                                <option value="600">80 mm</option>
                            </select>
                        </div>
                        <div class="form-group" v-if="printerLocation != 'kasir'">
                            <label for="group_one">Group 1</label>
                            <Multiselect
                                v-model="groupOne"
                                mode="tags"
                                :close-on-select="false"
                                :searchable="true"
                                :create-option="false"
                                :options="this.productCategory"
                                />
                        </div>
                        <div class="form-group" v-if="printerLocation != 'kasir'">
                            <label for="group_one">Group 2</label>
                            <Multiselect
                                v-model="groupTwo"
                                mode="tags"
                                :close-on-select="false"
                                :searchable="true"
                                :create-option="false"
                                :options="productCategory"
                                />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <button v-on:click="tesPrinter()" class="btn btn-warning" >Test Printer</button> -->
                        <button v-on:click="save()" class="btn btn-accent" >Simpan</button>
                    </div>
                </div>
            </div>
        </div>

        
        
    </section>
    
</template>
<script lang="js">
import axios from 'axios';
import '@formatjs/intl-numberformat';
import { formatMoney } from '@/mixins/app';
import Multiselect from '@vueform/multiselect';

export default {
    name: "Dashboard",
    components: {
      Multiselect,
    },
    data(){
        return{
            printerId: 0,
            printerLocation: 'kasir',
            printerType: '',
            printerAddress: '',
            printerWidth: 405,
            listPrinter: "",
            addressStatus: false,
            userRole: 'kasir',
            groupOne: [],
            groupTwo: [],
            productCategory: [
            { value: 'minum', label: 'Minum' },
    { value: 'lauk', label: 'Lauk' },
    { value: 'sambal', label: 'Sambal' },
  ]
        }
    },
    beforeMount(){
        // this.getCategory()
    },
    mounted() {
        this.userRole = localStorage.getItem('user_role');
        const location = (this.userRole == 'opp') ? 'repeat' : 'kasir';
        this.printerLocation = location
        this.getPrinterInfo()
        this.getCategory()
        // console.log(this.productCategory)
        // const formDefault = new FormData()
        // formDefault.append('printer_location', location)
        // axios.post('/printer-setting',formDefault).then(res => {
        //     if (res.status == 200) {
        //         this.printerId = res.data.data.printer_setting_id
        //         this.printerLocation = res.data.data.printer_setting_location
        //         this.printerType = res.data.data.printer_setting_type
        //         this.printerAddress = res.data.data.printer_setting_address
        //         this.printerWidth = res.data.data.printer_setting_width
        //         if (res.data.data.printer_setting_type == 'android-image') {
        //             this.addressStatus = true
        //         }
        //         const groupSatu = res.data.data.printer_setting_group_one
        //         const groupDua = res.data.data.printer_setting_group_two
        //         this.groupOne = (groupSatu != null) ? JSON.parse(groupSatu) : []
        //         this.groupTwo = (groupDua != null) ? JSON.parse(groupDua) : []
        //         this.getListPrinter()
        //     }
            
        // }).catch ((err) => {
        //     console.log(err);
        // })
        // window.setTimeout( "loadingComponent()" ,750);
    },
    
    methods: {
        getPrinterInfo(){
            axios.get('/get-printer-info/'+this.printerLocation).then(res => {
                if (res.status == 200) {
                    this.printerId = res.data.data.printer_setting_id
                    this.printerType = res.data.data.printer_setting_type
                    this.printerAddress = res.data.data.printer_setting_address
                    this.printerWidth = res.data.data.printer_setting_width
                    if (res.data.data.printer_setting_type == 'android-image' || res.data.data.printer_setting_type == 'android-image-vps') {
                        this.addressStatus = true
                    }
                    const groupSatu = res.data.data.printer_setting_group_one
                    const groupDua = res.data.data.printer_setting_group_two
                    this.groupOne = (groupSatu != null) ? JSON.parse(groupSatu) : []
                    this.groupTwo = (groupDua != null) ? JSON.parse(groupDua) : []

                    this.getListPrinter()
                }

                if (res.status == 201) {
                    this.printerId = 0
                    this.printerType = ''
                    this.printerAddress = ''
                    this.printerWidth = 405
                    this.addressStatus = false
                    this.groupOne = []
                    this.groupTwo = []
                    this.getListPrinter()
                }
                
            }).catch ((err) => {
                console.log(err);
            })
        },
        tesPrinter(){
            axios.get('/tes-cetak').then(res => {
                if(res.data.type == 'android-image' || res.data.type == 'android-image-vps'){
                    location.href = res.data.struct
                }
                console.log(res)
            }).catch ((err) => {
                console.log(err);
            })
        },
        getListPrinter(){
            if(this.printerType == 'usb-unix'){
                axios.get('/list-printer').then(res => {
                    this.listPrinter = res.data
                    // console.log(res)
                }).catch ((err) => {
                    console.log(err);
                })
            }else{
                this.listPrinter = "";
                if (this.printerType == 'android-image' || this.printerType == 'android-image-vps') {
                    this.printerAddress = 'waroengss'
                    this.addressStatus = true
                } else {
                    this.printerAddress == ''
                    this.addressStatus = false
                }
            }
        },
        getCategory(){
            // const listCategory = [];
            this.productCategory = [];
            axios.get('/jenis-produk').then(res => {
                res.data.forEach(element => {
                    // console.log(element.m_jenis_produk_nama)
                    this.productCategory.push({
                                value: element.m_jenis_produk_id,
                                label: element.m_jenis_produk_nama
                            });
                });
            }).catch ((err) => {
                console.log(err);
            })
            // this.productCategory = listCategory
        },
        save(){
            // const address = document.getElementById("printer_address").value;

            const formData = new FormData()
            formData.append('printer_id', this.printerId)
            formData.append('printer_type', this.printerType)
            formData.append('printer_address', this.printerAddress)
            formData.append('printer_location', this.printerLocation)
            formData.append('printer_width', this.printerWidth)
            formData.append('printer_group1', this.groupOne)
            formData.append('printer_group2', this.groupTwo)
            
            axios.post('/printer-setting',formData).then(res => {
                console.log(res)
                axios.get('/tes-cetak/'+this.printerLocation).then(resp => {
                    console.log(resp)
                    if(resp.data.type == 'android-image' || resp.data.type == 'android-image-vps'){
                        location.href = resp.data.struct
                    }
                    if(resp.data.status == 200){
                        document.getElementById("close-modal-printer").click()
                    }
                    if(resp.data.status == 500){
                        this.$swal.fire({
                        title:"Terjadi Kesalahan!", 
                        text:"Pastikan Informasi dan Printer Sudah Benar", 
                        icon:"warning",
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                        })
                    }
                }).catch ((err) => {
                    console.log(err);
                })

            }).catch ((err) => {
                console.log(err);
            })
        }
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
