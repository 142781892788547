const state = () => ({
  nav: [
    { name: 'dashboard', pages: ['dashboardPage'] },
    { name: 'onlineOrder', pages: ['onlineOrderPage'] },
    { name: 'drawer', pages: [] },
    { name: 'onlineOrder', pages: ['mutationPage', 'UangTips'] },
    { name: 'history', pages: ['historyPage', 'HistoryByDate'] },
  ],
  activeClass: '',
});

const getters = {
  activeClass: (state: any, getters: any, rootState: any) => {
    return state.activeClass;
  }
};

const actions = {
  async setActiveClass({ commit, state }: any, className: string) {
    commit('setActiveClass', className);
  }
};

const mutations = {
  setActiveClass(state: any, className: string) {
    state.activeClass = className;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
