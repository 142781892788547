const state = () => ({
  modal: {},
  promo: {},
  spendings: {},
  payment: {},
  sales: {},
  regSales: {},
  ojolSales: {},
  moneyTips: {},
  freeReturn: []
})

const getters = {
  modal: (state: any, getters: any, rootState: any) => {
    return state.modal
  },
  promo: (state: any, getters: any, rootState: any) => {
    return state.promo
  },
  spendings: (state: any, getters: any, rootState: any) => {
    return state.spendings
  },
  payment: (state: any, getters: any, rootState: any) => {
    return state.payment
  },
  sales: (state: any, getters: any, rootState: any) => {
    return state.sales
  },
  regSales: (state: any, getters: any, rootState: any) => {
    return state.regSales
  },
  ojolSales: (state: any, getters: any, rootState: any) => {
    return state.ojolSales
  },
  moneyTips: (state: any, getters: any, rootState: any) => {
    return state.moneyTips
  },
  freeReturn: (state: any, getters: any, rootState: any) => {
    return state.freeReturn
  }
}

const actions = {
  async setModal({ commit, state }: any, value: any) {
    commit('setModal', value)
  },
  async setPromo({ commit, state }: any, value: any) {
    commit('setPromo', value)
  },
  async setSpendings({ commit, state }: any, value: any) {
    commit('setSpendings', value)
  },
  async setPayment({ commit, state }: any, value: any) {
    commit('setPayment', value)
  },
  async setSales({commit, state }: any, value: any) {
    commit('setSales', value)
  },
  async setRegSales({commit, state }: any, value: any) {
    commit('setRegSales', value)
  },
  async setOjolSales({commit, state }: any, value: any) {
    commit('setOjolSales', value)
  },
  async setMoneyTips({commit, state }: any, value: any) {
    commit('setMoneyTips', value)
  },
  async setFreeReturn({commit, state }: any, value: any) {
    commit('setFreeReturn', value)
  }
}

const mutations = {
  setModal(state: any, value: any) {
    state.modal = value
  },
  setPromo(state: any, value: any) {
    state.promo = value
  },
  setSpendings(state: any, value: any) {
    state.spendings = value
  },
  setPayment(state: any, value: any) {
    state.payment = value
  },
  setSales(state: any, value: any) {
    state.sales = value
  },
  setRegSales(state: any, value: any) {
    state.regSales = value
  },
  setOjolSales(state: any, value: any) {
    state.ojolSales = value
  },
  setMoneyTips(state: any, value: any) {
    state.moneyTips = value
  },
  setFreeReturn(state: any, value: any) {
    state.freeReturn = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
