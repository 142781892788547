<template>
    <div>
        <Nav></Nav>

        <section class="py-4 section-app">
            <div class="container-fluid">
                <div class="row mx-n2">
                    <div class="col-lg-4 px-2">
                        <div class="card h-100">
                            <div class="card-header bg-accent text-white font-weight-bold">
                              Form Transaksi Kas
                            </div>
                            <div class="card-body">
                              <form>
                                <!-- <div class="form-group">
                                    <label for="">Uang Modal</label>
                                    <input type="text" class="form-control" placeholder="Jumlah uang modal. ex: 1000" 
                                    v-model="mutModal.modalMoney">
                                    <div class="invalid-feedback">Jumlah uang modal harus diisi</div>
                                </div>-->

                                <div class="form-group">
                                    <label for="mutationType">Jenis Transaksi</label>
                                    <select :class="'form-control option' + inputClass('mutationType')" 
                                      v-model="mutModal.mutationType" id="mutationType" @change="clearError('mutationType')">
                                        <option value="kredit">Kas Keluar</option>
                                        <option value="debit">Kas Masuk</option>
                                    </select>
                                    <div class="invalid-feedback">{{ errorMessage('mutationType') }}</div>
                                </div>
                                <div class="form-group">
                                    <label for="mutationGroup">Group Transaksi</label>
                                    <select :class="'form-control option' + inputClass('mutationGroup')" 
                                      v-model="mutModal.mutationGroup" id="mutationGroup" @change="clearError('mutationGroup')">
                                        <option value="Reguler">Reguler</option>
                                        <option value="Ojol">Ojol</option>
                                    </select>
                                    <div class="invalid-feedback">{{ errorMessage('mutationType') }}</div>
                                </div>

                                <!-- <div class="form-group">
                                    <label for="">Pengguna</label>
                                    <select :class="'form-control ' + inputClass('nama_jabatan')" style="width: 100%" 
                                      v-model="mutModal.user" @change="clearError('nama_jabatan')">
                                        <option v-for="user in jabatans" :key="user.m_jabatan_id" 
                                          :value="user.m_jabatan_nama">{{ user.m_jabatan_nama }}</option>
                                    </select>
                                    <div class="invalid-feedback">{{ errorMessage('nama_jabatan') }}</div>
                                </div> -->

                                <div class="form-group">
                                    <label for="">Nama PIC</label>
                                    <input type="text" id="pic_name" :class="'form-control keyboard' + inputClass('nama_pengguna')" v-model="mutModal.picName" 
                                      @keyup="clearError('nama_pengguna')">
                                      
                                    <div class="invalid-feedback">{{ errorMessage('nama_pengguna') }}</div>
                                </div>

                                <div class="form-group">
                                    <label for="">Jumlah Uang</label>
                                    <!-- <input type="text" id="nominal_trans" :class="'form-control keyboard numeric' + inputClass('totalMoney')" 
                                      placeholder="Jumlah uang. ex: 1000" 
                                      v-model="mutModal.totalMoney" 
                                      @keyup="clearError('totalMoney')"> -->
            <money3 v-model.number="mutModal.totalMoney" v-bind="config" :class="'form-control keyboard numeric' + inputClass('totalMoney')" @keyup="clearError('totalMoney')" id="nominal_trans"></money3>

                                    <div class="invalid-feedback">{{ errorMessage('totalMoney') }}</div>
                                </div>

                                <div class="form-group">
                                    <label for="">Keterangan</label>
                                    <textarea cols="30" id="deskripsi_trans" rows="5" :class="'form-control keyboard' + inputClass('keterangan')" 
                                      v-model="mutModal.desc" @keyup="clearError('keterangan')"></textarea>
                                    <div class="invalid-feedback">{{ errorMessage('keterangan') }}</div>
                                </div>
                              </form>
                            </div>

                            <div class="card-footer">
                                <button class="btn btn-light" type="button" @click="onCancel">Batal</button>
                                <button class="btn btn-primary" type="button" @click.prevent="save">{{ btnSaveLabel }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 px-2">
                        <div class="card h-100">
                            <div class="card-header bg-accent text-white font-weight-bold">History Kas</div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead class="bg-dark text-white">
                                        <tr>
                                            <th>No</th>
                                            <!-- <th>Pengguna</th>
                                            <th>Nama PIC</th> -->
                                            <th>Kas Masuk</th>
                                            <th>Kas Keluar</th>
                                            <th>Keterangan</th>
                                            <!-- <th>Aksi</th> -->
                                        </tr>
                                    </thead>

                                    <tbody v-if="mutModalEmpty">
                                      <tr>
                                        <td colspan="7" class="text-center text-muted">Belum ada data</td>
                                      </tr>
                                    </tbody>

                                    <tbody v-if="!mutModalEmpty">
                                      <tr v-for="(item, index) in mutationModalList" :key="item.r_m_m_id">
                                        <td>{{ index + 1 }}</td>
                                        <!-- <td>{{ item.nama_jabatan }}</td>
                                        <td>{{ item.nama_pengguna }}</td> -->
                                        <td>{{ currency(item.r_m_m_debit,'') }}</td>
                                        <td>{{ currency(item.r_m_m_kredit,'') }}</td>
                                        <td>{{ item.r_m_m_keterangan }}</td>
                                        <!-- <td v-if="debitEqZero(item.debit)">
                                          <a href="" class="btn btn-primary" @click.prevent="calcRemainingModal(item.id)">Sisa</a>
                                        </td> -->
                                        <!-- <td v-if="!debitEqZero(item.debit)">
                                          <a href="" class="btn btn-primary" @click.prevent="showConfirmModal(item.r_m_m_id)">Hapus</a>
                                        </td> -->
                                      </tr>
                                    </tbody>

                                    <tfoot class="bg-light">
                                        <td colspan="7" class="text-center text-muted">TOTAL PENGELUARAN : {{ currency(totalSpending,'Rp') }}</td>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    <!-- <RemainingModal @on-save-remaining-modal="saveRemainingModal" @on-hide="remModalHide" 
      :modalShow="remModalShow" 
      ref="remModalEl" /> -->
    <!-- <ConfirmModal modalSize="medium" title="Hapus Mutasi Modal" bodyText="Anda yakin?" 
      @on-confirm="deleteMutModal"
      @on-hide="hideConfirmModal" 
      :modalShow="confirmModalShow" /> -->
    </div>
</template>

<script lang="js">
import { onMounted, ref, computed, reactive } from 'vue';
import axios from 'axios';
// import{useRouter} from 'vue-router';
import Nav from '@/components/secure/dashboard/Nav.vue'
// import RemainingModal from '@/components/secure/mutation/RemainingModal.vue'
// import ConfirmModal from '@/components/secure/Modal.vue'
import { useStore } from 'vuex'
import '@formatjs/intl-numberformat';
import { Money3Component } from 'v-money3';

export default {
  name: "Dashboard",
  components: {
      Nav,
      money3: Money3Component
      // RemainingModal,
      // ConfirmModal,
  },
  data() {
    return {} 
  },
    mounted() {
        document.getElementById("mutationOrderNavigation").classList.add("active");
        window.setTimeout( "loadingComponentMutasi()" ,750);
        axios.get('m-waroeng/waroeng-info')
            .then(resp => {
                this.waroengInfo = resp.data
                if(this.waroengInfo.currency == 'RM'){
                    this.currencyFormat = 'ms-MY'
                    this.currencyPrefix = 'MYR'

                    this.config.decimal = '.'
                    this.config.thousands = ','
                    this.config.prefix = 'RM '
                    this.config.precision = 2
                }
            }).catch(err => console.log(err))
    },
    methods: {
      currency(price,prefix){
        const nominal = parseFloat(price);
        const format = this.currencyFormat
        const pref = this.currencyPrefix
        const prefView = (prefix != '') ? this.waroengInfo.currency : '';

        const rupiah = new Intl.NumberFormat(format, {
            style: 'currency', //decimal,currency,percent
            currency: pref,
        }).format(nominal);
        
        return rupiah;
      },
    },
    setup() {
      const user = ref(null);
      // const router = useRouter();
      const store = useStore()
      // const warung = ref({})
      // const area = ref({})
      const remModal = ref({})
      const mutModal = reactive({
        // modalMoney: 0,
        mutationType: '',
        mutationGroup: 'Reguler',
        // user: '',
        picName: '',
        totalMoney: 0,
        desc: ''
      })
      // const jabatans = ref([]) 
      const mutationModalList = ref([])
      const mutModalEmpty = computed(() => mutationModalList.value.length === 0)
      const errors = ref([])
      // const confirmModalShow = ref(false)
      const btnSaveLabel = ref('Simpan')
      const remModalShow = ref(false)
      // const remModalHide = () => {
      //   remModalShow.value = false
      // }

      const reloadDataList = () => {
        axios.get('/recap-mutasi-modal').then(resp => {
          mutationModalList.value = resp.data
          store.dispatch('mutation/setMutationModal', resp.data)
        })
        .catch(err => console.log)
      }
      
      const resetForm = () => {
        for(const [key, val] of Object.entries(mutModal)) {
          // if (key === 'modalMoney') {
          //   continue;
          // }

          mutModal[key] = ''
        }
      }

      const save = () => {
        const params = {
          nama_pengguna: document.getElementById("pic_name").value,
          debit: 0,
          kredit: 0,
          group: mutModal.mutationGroup,
          keterangan: document.getElementById("deskripsi_trans").value
        }
        
        const typeCredit = document.getElementById("mutationType").value == 'kredit'
        const typeDebit = document.getElementById("mutationType").value == 'debit'
        if (typeCredit) {
          // params.kredit = parseFloat(document.getElementById("nominal_trans").value)
          params.kredit = parseFloat(mutModal.totalMoney)
        } else if (typeDebit) {
          // params.debit = parseFloat(document.getElementById("nominal_trans").value)
          params.debit = parseFloat(mutModal.totalMoney)
        }
        console.log('nama pengguna: '+params.kredit)
        btnSaveLabel.value = 'Loading..'
        if (mutModal.totalMoney > 0) {  
          axios.post('/recap-mutasi-modal', params)
          .then(resp => {
            // console.log(resp)
            axios.get('/cashier/cash-out/'+resp.data).then(res => {
              // console.log(res.data.struct)
              if (res.data.error == 0) {
                if(res.data.struct != ''){
                  location.href = res.data.struct
                }
              }
            })
            resetForm()
            reloadDataList()
            btnSaveLabel.value = 'Simpan'
          })
          .catch(err => {
            btnSaveLabel.value = 'Simpan'
            if (err.response) {
              const res = { ...err.response.data.errors }
              if (document.getElementById("mutationType").value == '') {
                res['mutationType'] = ['Pilih jenis transaksi!']
              }
              
              if (parseFloat(mutModal.totalMoney) < 1) {
                res['totalMoney'] = ['Jumlah uang harus diisi']
              }
              errors.value = res 
            }
          })
        }
      }

      const inputClass = (fieldName) => {
        let className = ''
        for (const [key, item] of Object.entries(errors.value)) {
          if (key === fieldName) {
            className = 'is-invalid'
            break;
          }
        }

        return className
      }

      const errorMessage = (fieldName) => {
        let result = ''
        for (const [key, val] of Object.entries(errors.value)) {
          if (key === fieldName) {
            result = val.join('')
            break;
          }
        }

        return result
      }

      const clearError = (fieldName) => {
        const deleted = Object.entries(errors.value).reduce((acc, value) => {
          const [propName, propValue] = value
          if (propName !== fieldName) {
            const obj = {}
            obj[propName] = propValue
            acc = {...acc, ...obj}
          }
          return acc
        }, {})
        errors.value = deleted
      }

      const totalSpending = computed(() => {
        let total = 0
        let totalCredit = 0
        let totalDebit = 0

        if (mutationModalList.value.length == 0) {
          return total
        }

        for (const val of mutationModalList.value) {
          if (parseFloat(val.r_m_m_debit) === 0) {
            totalCredit += parseFloat(val.r_m_m_kredit)
          }
          totalDebit += parseFloat(val.r_m_m_debit)
        }
        total = totalCredit - totalDebit
        
        return total
      })

      // const mutModalId = ref('')
      // const deleteMutModal = () => {
      //   axios.delete(`/recap-mutasi-modal/${mutModalId.value}`).then(resp => {
      //     reloadDataList()
      //     confirmModalShow.value = false
      //   })
      //   .catch(err => console.log)
      // }
      // const hideConfirmModal = () => {
      //   confirmModalShow.value = false
      // }

      // const showConfirmModal = (id) => {
      //   mutModalId.value = id
      //   confirmModalShow.value = true 
      // }

      onMounted( async () => {
        try {
          const response = await axios.get('/user');
          user.value = response.data.data;

          // const jabatanResp = await axios.get('/jabatan')
          // jabatans.value = jabatanResp.data

          // const warungResp = await axios.get('/warung')
          // warung.value = warungResp.data.data[0]
          // area.value = warungResp.data.data[0].m_area

          // const remModalResp = await axios.get('recap-mutasi-modal/remaining-modal')
          // remModal.value = remModalResp.data
          // mutModal.modalMoney = remModalResp.remaining_modal

          const mutModalResp = await axios.get('recap-mutasi-modal')
          mutationModalList.value = mutModalResp.data
          store.dispatch('mutation/setMutationModal', mutModalResp.data)
        } catch (e) {
          console.log(e)
          // await router.push({ name: 'loginPage' });
        }
      });

      // const remModalEl = ref({})
      // const calcRemainingModal = (id) => {
      //   remModalEl.value.onResetForm()
      //   const currentData = mutationModalList.value.filter(item => item.id === id)
      //   if (currentData.length > 0) {
      //     store.dispatch('mutation/setCurrentMutModal', currentData[0])
      //   }
      //   remModalShow.value = true
      // }
      
      const debitEqZero = (debit) => {
        const debitVal = parseFloat(debit)
        if (!isNaN(debitVal) && debitVal === 0) {
          return true
        }
        return false
      }

      // const saveRemainingModal = ([model, remainingMoney]) => {
      //   remModalShow.value = false
      //   remModalEl.value.onResetForm()

      //   const params = {}
      //   for (const [keyName, val] of Object.entries(model)) {
      //     params[keyName] = val
      //   }
      //   params.keterangan = `Sisa Penggunaan Uang ${params.nama_jabatan}`
      //   params.kredit = remainingMoney
      //   delete params['created_at']
      //   delete params['updated_at']
      //   delete params['deleted_at']

      //   axios.post('/recap-mutasi-modal', params).then(resp => {
      //     reloadDataList()
      //   })
      //   .catch(err => console.log)
      // }

      const onCancel = () => {
        resetForm()
      }

      return {
        user,
        mutModal,
        save,
        // jabatans,
        // warung,
        // area,
        remModal,
        mutationModalList,
        mutModalEmpty,
        // calcRemainingModal,
        totalSpending,
        // deleteMutModal,
        // showConfirmModal,
        debitEqZero,
        // saveRemainingModal,
        errors,
        inputClass,
        errorMessage,
        clearError,
        // hideConfirmModal,
        // confirmModalShow,
        onCancel,
        btnSaveLabel,
        remModalShow,
        config: {
          decimal: ',',
          thousands: '.',
          prefix: 'Rp ',
          suffix: '',
          precision: 0,
          masked: false,
      },
      waroengInfo : {},
      currencyFormat : 'id-ID',
      currencyPrefix : 'IDR',
        // remModalHide,
        // remModalEl
      }
    },
}
</script>
