<template>
    <div>
        <Nav></Nav>

        <section class="py-4 section-app">
            <div class="container-fluid">
                <div class="row mx-n2">
                    <div class="col-lg-6 px-2">
                        <div class="card bg-light">
                            <div class="card-body p-3">
                                <div class="row mx-n2">
                                    <div class="col-lg-6 px-2">
                                        <table width="100%">
                                            <tr>
                                                <td width="100">Nomor Meja</td>
                                                <td width="10">:</td>
                                                <td><strong>{{ notaDetailList.tmp_transaction_table_name_list }}</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Nomor Nota</td>
                                                <td>:</td>
                                                <td><strong>{{ notaDetailList.tmp_transaction_note_number }}</strong></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-lg-6 px-2">
                                        <table width="100%">
                                            <tr>
                                                <td width="100">Nama Bigboss</td>
                                                <td width="10">:</td>
                                                <td><strong>{{ notaDetailList.tmp_transaction_customer_name }}</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Kasir</td>
                                                <td>:</td>
                                                <td><strong>{{ notaDetailList.tmp_transaction_by }}</strong></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mt-2">
                            <div class="table-responsive" style="max-height: calc(100vh - 175.5px); height: 100vh">
                                <table class="table table-menu m-0 font-summary">
                                    <thead class="bg-dark text-white">
                                        <tr>
                                            <th>#</th>
                                            <th>Nama Menu</th>
                                            <th class="text-center" style="padding-left: 0px">Qty</th>
                                            <th class="text-right">Harga</th>
                                            <th class="text-right">Total</th>
                                            <th class="text-right">Split</th>
                                        </tr>
                                    </thead>
                                    <!-- <tbody>
                                        <tr>
                                            <td colspan="6" class="bg-accent text-white">Minuman</td>
                                        </tr>
                                        <tr v-for="(listMenus, index) in notaListMenu" :key="listMenus.tmp_transaction_detail_id" >
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ listMenus.m_menu.m_menu_nama }}</td>
                                            <td class="text-right">{{ listMenus.tmp_transaction_detail_qty }}</td>
                                            <td class="text-right text-nowrap">{{ parseFloat(listMenus.m_menu.tmp_transaction_detail_price) }}</td>
                                            <td class="text-right text-nowrap">{{ listMenus.m_menu.tmp_transaction_detail_price * listMenus.tmp_transaction_detail_qty }}</td>
                                            <td class="text-nowrap text-right">
                                                <a v-on:click="splitMenu(this.$route.params.transactionId, uuidChildNota, listMenus.m_menu.m_menu_id)" href="javascript:void(0)" class="text-accent text-decoration-none h6" data-toggle="modal" data-target="#modal-edit-menu"><i class="fa fa-arrow-right"></i></a>
                                            </td>
                                        </tr>
                                    </tbody> -->
                                    <tbody v-for="menuJenis in cart" :key="menuJenis.id">
                                        
                                        <tr>
                                            <td colspan="6" class="bg-accent text-white">{{menuJenis.name}}</td>
                                        </tr>
                                        <tr v-for="(listMenus, index) in menuJenis.items" :key="listMenus.tmp_transaction_detail_id" >
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ listMenus.m_produk_nama }}
                                            <span v-if="listMenus.tmp_transaction_detail_status != 0" class="text-right text-nowrap text-danger">{{listMenus.tmp_transaction_detail_reasone_approve}}</span>
                                            </td>
                                            <td class="text-center">{{ listMenus.tmp_transaction_detail_qty }}</td>
                                            <td class="text-right text-nowrap">{{ currency(parseFloat(listMenus.tmp_transaction_detail_price ),'') }}</td>
                                            <td class="text-right text-nowrap">{{ currency(listMenus.tmp_transaction_detail_price*listMenus.tmp_transaction_detail_qty,'') }}</td>
                                            <td  class="text-nowrap text-right" style="padding-right: 10px">
                                                <a v-on:click="addToSplitMenu(listMenus)" href="javascript:void(0)" class="text-accent text-decoration-none h6" data-toggle="modal" data-target="#modal-edit-menu"><i class="fa fa-arrow-right"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- <div class="card mt-2">
                            <table class="table table-menu table-condensed m-0">
                                <tr>
                                    <td class="text-right"><strong>Sub Total</strong></td>
                                    <td width="5" class="px-0"><strong>:</strong></td>
                                    <td width="10"><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger" width="150">{{ subTotalNotaListMenu }}</td>
                                    <td width="10"></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><strong>Pajak Resto (10%)</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger">{{ pajakNotaListMenu }}</td>
                                    <td class="p-0 px-2"><a href="javascript:void(0)" v-on:click="setNullPajak()" class="text-accent text-decoration-none h5"><i class="fa fa-trash-o"></i></a></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><strong>Service Charge ({{ ServiceNotaListMenu }}%)</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger">{{ ServiceNotaListMenuPrice }}</td>
                                    <td class="p-0 px-2"><a href="javascript:void(0)" v-on:click="setServiceCharge()" class="text-primary text-decoration-none h5"><i class="fa fa-plus-circle"></i></a></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><strong>Tarik Tunai</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger">{{ TarikTunaiNotaListMenu }}</td>
                                    <td class="p-0 px-2"><a href="javascript:void(0)" v-on:click="setTarikTunai()" class="text-primary text-decoration-none h5"><i class="fa fa-plus-circle"></i></a></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><strong>Total Bayar</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger">{{ TotalNotaListMenu }}</td>
                                    <td class="p-0 px-2"></td>
                                </tr>
                            </table>
                        </div>

                        <div class="card mt-2">
                            <div class="card-body p-2">
                                <div class="row mx-n1 justify-content-center">
                                    <div class="col-auto px-1 mb-1">
                                        <button class="btn btn-danger font-weight-bold btn-sm" type="button" v-on:click="deleteTransaction()"><i class="fa fa-trash-o"></i></button>
                                    </div>
                                    <div class="col-auto px-1 mb-1">
                                        <button class="btn btn-warning font-weight-bold btn-sm" type="button" v-on:click="backToMenu()">KELUARKAN MENU</button>
                                    </div>
                                    <div class="col-auto px-1 mb-1">
                                        <button class="btn btn-primary font-weight-bold btn-sm" type="button" v-on:click="saveTransaction()">SIMPAN</button>
                                    </div>
                                    <div class="col-auto px-1 mb-1">
                                        <button class="btn btn-success font-weight-bold btn-sm" type="button">BAYAR</button>
                                    </div>
                                    <div class="col-auto px-1 mb-1" style="display:none">
                                        <a v-on:click="splitMenuOrder()" href="javascript:void(0)" class="btn btn-success font-weight-bold btn-sm">SPLIT</a>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                    </div>
                    <div class="col-lg-6 mt-4 mt-lg-0 px-2">
                        
                        <div class="card bg-light">
                            <div class="card-body p-3">
                                <div class="row mx-n2">
                                    <div class="col-lg-6 px-2">
                                        <table width="100%">
                                            <tr>
                                                <td width="100">Nomor Meja</td>
                                                <td width="10">:</td>
                                                <td><strong>{{ notaDetailList.tmp_transaction_table_name_list }}</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Nomor Nota</td>
                                                <td>:</td>
                                                <td><strong>{{ splitNotaNumber }}</strong></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-lg-6 px-2">
                                        <table width="100%">
                                            <tr>
                                                <td width="100">Nama Bigboss</td>
                                                <td width="10">:</td>
                                                <td><strong>{{ notaDetailList.tmp_transaction_customer_name }}</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Kasir</td>
                                                <td>:</td>
                                                <td><strong>{{ notaDetailList.tmp_transaction_by }}</strong></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mt-2">
                            <div class="table-responsive" style="max-height: calc(100vh - 350px);  height: 100vh">
                                <table class="table table-menu m-0 font-summary">
                                    <thead class="bg-dark text-white">
                                        <tr>
                                            <th class="text-left">Split</th>
                                            <th>#</th>
                                            <th>Nama Menu</th>
                                            <th class="text-right">Qty</th>
                                            <th class="text-right">Harga</th>
                                            <th class="text-right">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="menuJenis in splitCart" :key="menuJenis.id">
                                        
                                        <tr>
                                            <td colspan="6" class="bg-accent text-white">{{menuJenis.name}}</td>
                                        </tr>
                                        <tr v-for="(listMenus, index) in menuJenis.items" :key="listMenus.tmp_transaction_detail_id" >
                                            <td class="text-nowrap text-left" style="padding-left: 10px">
                                                <a v-on:click="removeFromSplitMenu(listMenus)" href="javascript:void(0)" class="text-accent text-decoration-none h6" data-toggle="modal" data-target="#modal-edit-menu"><i class="fa fa-arrow-left"></i></a>
                                            </td>
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ listMenus.m_produk_nama }}
                                                <span v-if="listMenus.tmp_transaction_detail_status != 0" class="text-right text-nowrap text-danger">{{listMenus.tmp_transaction_detail_reasone_approve}}</span>
                                            </td>
                                            <td class="text-center">{{ listMenus.tmp_transaction_detail_qty }}</td>
                                            <td class="text-right text-nowrap">{{ currency(parseFloat(listMenus.tmp_transaction_detail_price),'') }}</td>
                                            <td class="text-right text-nowrap">{{ currency(listMenus.tmp_transaction_detail_price * listMenus.tmp_transaction_detail_qty,'') }}</td>
                                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="card mt-2">
                            <table class="table table-menu table-condensed m-0 font-summary">
                                <tr>
                                    <td class="text-right"><strong>Sub Total</strong></td>
                                    <td width="5" class="px-0"><strong>:</strong></td>
                                    <td width="10"><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger" width="150">{{ currency(splitSubTotal,'') }}</td>
                                    <td width="10"></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><strong>Pajak Resto ({{this.currentPajak * 100}}%)</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger">{{ currency(splitPajak,'') }}</td>
                                    <template v-if="notaDetailList.pajak>0">
                                        <template v-if="currentPajak>0">
                                            <td class="p-0 px-2"><a href="javascript:void(0)" v-on:click="setNullPajak()" class="text-accent text-decoration-none h5"><i class="fa fa-trash-o"></i></a></td>
                                        </template>
                                        <template v-else>
                                            <td class="p-0 px-2"><a href="javascript:void(0)" v-on:click="setPajak()" class="text-primary text-decoration-none h5"><i class="fa fa-plus-circle"></i></a></td>
                                        </template>
                                    </template>
                                </tr>
                                <tr>
                                    <td class="text-right"><strong>Service Charge ({{ this.currentService * 100 }}%)</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger">{{ currency(splitServiceCharge,'') }}</td>
                                    <template v-if="notaDetailList.service_charge>0">
                                        <template v-if="currentService>0">
                                            <td class="p-0 px-2"><a href="javascript:void(0)" v-on:click="setNullService()" class="text-accent text-decoration-none h5"><i class="fa fa-trash-o"></i></a></td>
                                        </template>
                                        <template v-else>
                                            <td class="p-0 px-2"><a href="javascript:void(0)" v-on:click="setService()" class="text-primary text-decoration-none h5"><i class="fa fa-plus-circle"></i></a></td>
                                        </template>
                                    </template>
                                </tr>
                                <tr>
                                    <td class="text-right"><strong>Tarik Tunai</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger">{{ currency(splitTarikTunai,'') }}</td>
                                    <td class="p-0 px-2">
                                        <a href="#modal-input-tunai-split"
                                            data-toggle="modal"
                                            data-target="#modal-input-tunai-split"
                                            data-dismiss="modal"
                                            class="text-primary text-decoration-none h5"
                                        ><i class="fa fa-plus-circle"></i
                                        ></a>
                                    </td>
                                </tr>
                                <tr v-if="discount > 0">
                                    <td class="text-right"><strong>Diskon</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger">
                                        {{ currency(discount,'') }}
                                    </td>
                                    <td class="p-0 px-2">
                                        <a href="" @click.prevent="removeDiskon" title="Hapus Diskon" 
                                        class="text-accent text-decoration-none h5" alt="Hapus Diskon">
                                        <i class="fa fa-trash-o"></i></a>
                                    </td>
                                    </tr>
                                    <tr v-if="voucherValue > 0">
                                    <td class="text-right"><strong>Voucher</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger">
                                        {{ currency(voucherValue,'') }}
                                    </td>
                                    <td class="p-0 px-2">
                                        <a href="" @click.prevent="removeVoucher" title="Hapus Diskon" 
                                        class="text-accent text-decoration-none h5" alt="Hapus Diskon">
                                        <i class="fa fa-trash-o"></i></a>
                                    </td>
                                    </tr>
                                <tr>
                                    <td class="text-right"><strong>Total Bayar</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger">{{ currency(splitTotal,'') }}</td>
                                    <td class="p-0 px-2"></td>
                                </tr>
                            </table>
                        </div>

                        <div class="card mt-2">
                            <div class="card-body p-2">
                                <div class="row mx-n1 justify-content-center">
                                    <div class="col-auto px-1 mb-1">
                                        <button class="btn btn-danger font-weight-bold btn-sm" type="button" v-on:click="cancelSplit()">BATAL</button>
                                    </div>
                                    <div class="col-auto px-1 mb-1">
                                        <button
                                        v-if="splitTotal > 0"
                                        class="btn btn-secondary font-weight-bold btn-sm"
                                        data-toggle="modal"
                                        data-target="#modal-diskon"
                                        data-dismiss="modal"
                                        type="button"
                                        >
                                        DISKON
                                        </button>
                                    </div>
                                    <div class="col-auto px-1 mb-1">
                                        <button v-if="splitTotal > 0" class="btn btn-success font-weight-bold btn-sm" type="button" data-toggle="modal" data-target="#modal-bayar" data-dismiss="modal" >BAYAR</button>
                                        <button v-if="splitTotal <= 0" class="btn btn-success font-weight-bold btn-sm disabled" type="button">BAYAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
        </section>
        <section>
        <div class="modal fade" id="modal-input-tunai-split">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Tarik Tunai</h6>
                        <button id="close-modal-tunai-split" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        
                        <div class="form-group">
                            <label for="">Masukkan Jumlah Uang</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <input id="input_tarik_tunai_split" type="text" class="form-control numeric">
                                </div>
                                
                                    <div class="col-auto px-1">
                                        
                                        <button v-on:click="setTarikTunaiChild()" class="btn btn-primary btn-confirm-cash">Simpan</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </section>
    <section>
      <button id="show-modal-struk" data-toggle="modal" data-backdrop="static" data-target="#modal-struk"/>
        <div class="modal fade" id="modal-struk" data-backdrop="static">
          <button id="close-modal-struk" type="button" class="close" data-dismiss="modal" aria-label="Close"/>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Cetak/Kirim Struk</h6>
                        <!-- <button id="close-modal-struk" type="button" class="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button> -->
                    </div>
                    <div class="modal-body py-4">
                        <div class="form-group">
                            <label for="">NIK/ID Member</label>
                            <div class="row mx-n1">
                            <div class="col px-1">
                                <input v-model="memberId" id="member_id" type="text" class="form-control numeric" minlength="5" v-on:keyup="searchMember()">
                            </div>
                            <button 
                            class="btn btn-accent"
                            type="button"
                            v-on:click="searchMember()"><i class="fa fa-search"></i>
                            </button>
                            <div class="col px-1">
                                <input type="text" class="form-control" v-model="memberName" readonly>
                            </div>
                            </div>
                        </div>
                      <div class="form-group">
                        <label for="">Nomor HP</label>
                        <div class="row mx-n1">
                          <div class="col px-1">
                            <input v-model="nomorHp" id="nomor_hp" type="text" class="form-control numeric" placeholder="Contoh: 081765765243">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button v-on:click="struk(1,0)" class="btn btn-success" >Kirim WA</button>
                      <button v-on:click="struk(1,1)" class="btn btn-warning" >Cetak & Kirim WA</button>
                      <button v-on:click="struk(0,1)" class="btn btn-accent" >Cetak</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
        <PopupPay 
            :total="splitTotal"
            :currentRound="currentRound" 
            :transId="tmpId"
            :pajak="splitPajak"
            :service="splitServiceCharge"
            :tunai="splitTarikTunai"
            :discount="discount"
            :defaultPay="defaultPay"
            :voucher="voucherValue"
            :sub_total="splitSubTotal"
            :lossBill="lossBill"
            :nomorHp="nomorHp"
            :memberId="memberId"
            :splitPay="splitPay"
            @onPay="payAction"
        />
        <PopupDiskon
        :total="splitTotal" 
        @saveDiskon="addDiscount"
        />
    </div>
</template>
<script lang="js" >
    import {onMounted, ref} from 'vue';
    import axios from 'axios';
    import{useRouter} from 'vue-router';
    import Nav from '@/components/secure/dashboard/Nav.vue';
    import PopupPay from '@/components/secure/dashboard/PopupPay.vue';
    import '@formatjs/intl-numberformat';
    import { v4 as uuidv4 } from 'uuid';
    import PopupDiskon from '@/components/secure/dashboard/PopupDiskon.vue';

    export default {
        name: "Dashboard",
        components: {
            Nav,
            PopupPay,
            PopupDiskon
        },
         data(){
            return{
                splitId : '',
                splitNotaNumber: "",
                splitSubTotal: 0,
                splitPajak: 0,
                splitServiceCharge: 0,
                splitTarikTunai: 0,
                splitTotal: 0,
                currentPajak: 0,
                currentService: 0,
                currentRound: 'ya',
                stockMenuList: [],
                cart:[],
                splitCart:[],
                uuidChildNota: this.$route.params.transactionId,
                allNotaListMenu: [],
                notaListMenu: [],
                splitListMenu: [],
                showListMenu: [],
                // subTotalNotaListMenu: 0,
                // TotalNotaListMenu: 0,
                // pajakNotaListMenu: 0,
                // ServiceNotaListMenu: 0,
                // ServiceNotaListMenuPrice: 0,
                // TarikTunaiNotaListMenu: 0,
                notaDetailList: {
                    tmp_transaction_table_list: 0,
                    tmp_transaction_note_number: 0,
                    tmp_transaction_customer_name: 0,
                    tmp_transaction_by: "",
                    tmp_transaction_table_name_list: "",
                    service_charge: 0,
                    pajak: 0,
                    m_t_t_name: "",
                    pembulatan: '',
                },
                notaDetailListChild: {
                    tmp_transaction_table_list: 0,
                    tmp_transaction_note_number: 0,
                    tmp_transaction_customer_name: 0,
                    tmp_transaction_by: ""
                },
                // notaListMenuChild: [],
                // subTotalNotaListMenuChild: 0,
                // TotalNotaListMenuChild: 0,
                // pajakNotaListMenuChild: 0,
                // ServiceNotaListMenuChild: 0,
                // ServiceNotaListMenuPriceChild: 0,
                // TarikTunaiNotaListMenuChild: 0,
                defaultPay: 'all',
                voucherValue: 0,
                discount: 0,
                tmpId: this.$route.params.transactionId,
                lossBill: 'hide',
                nomorHp: '',
                memberId: '',
                memberName: '',
                kodeTransaksi: '',
                // repeatStruk: 1,
                typeTransaksiId: 1,
                splitPay: 'yes',
                waroengInfo : {},
                currencyFormat : 'id-ID',
                currencyPrefix : 'IDR',
            }
        },
        mounted() {
            axios.get('m-waroeng/waroeng-info')
            .then(resp => {
                this.waroengInfo = resp.data
                if(this.waroengInfo.currency == 'RM'){
                this.currencyFormat = 'ms-MY'
                this.currencyPrefix = 'MYR'
                }
            }).catch(err => console.log(err))

            this.splitId = uuidv4();
            
            axios.get('/m-menu-jenis?tmp_id='+this.$route.params.transactionId).then(res => {
                
                this.stockMenuList = res.data
                this.loadNotaDetail();
            }).catch ((err) => {
                console.log(err);
                this.$router.push('/dashboard');
            })
            
            // window.setTimeout( "loadingComponentOrder()" ,750);
            document.getElementById("listTableNavigation").classList.add("active");
        },
        methods: {
            currency(price,prefix){
                const nominal = parseFloat(price);
                const format = this.currencyFormat
                const pref = this.currencyPrefix
                const prefView = (prefix != '') ? this.waroengInfo.currency : '';

                const rupiah = new Intl.NumberFormat(format, {
                    style: 'decimal', //decimal,currency,percent
                    currency: pref,
                }).format(nominal);
                
                return prefView+' '+rupiah;
            },
            searchMember(){
                const member = this.memberId;
                
                if(member){
                    const clearMmber = member.replace ( /[^0-9.]/g, '' );
                    if(clearMmber.split('.').length == 1){
                        if(clearMmber.length == 5){
                            const userId = parseInt(clearMmber)
                            if(userId > 0){
                                axios.get('/users/'+userId)
                                .then(resp => {
                                    console.log(resp)
                                    if (resp.status == 200) {
                                        this.memberName = resp.data.data.name
                                    }else{
                                        this.memberName = 'ID Tidak Valid'
                                    }
                                })
                                .catch(err => console.log(err))
                            }else{
                                this.memberName = 'ID Tidak Valid'
                            }
                        }else{
                            this.memberName = 'ID Tidak Valid'
                        }
                    }
                }
            },
            loadNotaDetail(idTrans = this.$route.params.transactionId){
                axios.get('/tmp-transaction/'+idTrans).then(res => {
                    if(res.data == null || res.data.data.length == 0){
                        this.$router.push({name: 'dashboardPage'});
                        return false
                    }
                    let cekMemberId = 2
                    if(res.data.data.tmp_transaction_member_id){
                        cekMemberId = res.data.data.tmp_transaction_member_id.split('.')
                        this.searchMember()
                        // console.log('cek no hap: '+cekMemberId.length)
                        if (cekMemberId.length == 1) {
                            if(res.data.data.tmp_transaction_member_id.length >= 10){
                                this.nomorHp = res.data.data.tmp_transaction_member_id
                                this.memberId = res.data.data.tmp_transaction_member_id
                            }else if(res.data.data.tmp_transaction_member_id.length == 5){
                                this.nomorHp = ''
                                this.memberId = res.data.data.tmp_transaction_member_id
                            }else{
                                this.nomorHp = ''
                                this.memberId = ''
                            }
                        }else{
                            this.nomorHp = ''
                            this.memberId = res.data.data.tmp_transaction_member_id
                        }
                    }
                    this.notaDetailList = {
                        tmp_transaction_table_list: res.data.data.tmp_transaction_table_list,
                        tmp_transaction_note_number: res.data.data.tmp_transaction_note_number,
                        tmp_transaction_customer_name: res.data.data.tmp_transaction_customer_name,
                        tmp_transaction_by: res.data.data.name,
                        tmp_transaction_table_name_list: res.data.data.tmp_transaction_table_name_list,
                        pajak: res.data.data.pajak,
                        service_charge: res.data.data.service_charge,
                        m_t_t_name: res.data.data.m_t_t_name,
                        pembulatan: res.data.data.pembulatan
                    }
                    this.splitNotaNumber = res.data.data.tmp_transaction_note_number+"-"+res.data.data.new_split;
                    this.currentPajak = res.data.data.pajak
                    this.currentService = res.data.data.service_charge
                    this.currentRound = res.data.data.pembulatan;
                    // this.TarikTunaiNotaListMenu = parseFloat(res.data.data.tmp_transaction_detail_tarik_tunai)
                    // this.ServiceNotaListMenu = parseFloat(res.data.data.tmp_transaction_detail_service_charge)
                    this.loadMenuNota();
                }).catch ((err) => {
                    alert('Some Error');
                    this.$router.push('/dashboard');
                })
            },
            // loadNotaDetailChild(idMenuJenis = this.$route.params.transactionId){
            //     axios.get('/m-transaction-table/'+idMenuJenis).then(res => {
            //         if(res.data == null || res.data.data.length == 0){
            //             this.$router.push({name: 'dashboardPage'});
            //             return false
            //         }
            //         this.notaDetailListChild = {
            //             tmp_transaction_table_list: res.data.data.tmp_transaction_table_list,
            //             tmp_transaction_note_number: res.data.data.tmp_transaction_note_number,
            //             tmp_transaction_customer_name: res.data.data.tmp_transaction_customer_name,
            //             tmp_transaction_by: res.data.data.name
            //         }
            //         this.TarikTunaiNotaListMenuChild = parseFloat(res.data.data.tmp_transaction_detail_tarik_tunai)
            //         this.ServiceNotaListMenuChild = parseFloat(res.data.data.tmp_transaction_detail_service_charge)
            //     }).catch ((err) => {
            //         alert('Some Error');
            //         this.$router.push({name: 'dashboardPage'});
            //     })
            // },
            // loadChildListNota(idTrans = this.$route.params.transactionId){
            //     axios.get('/m-transaction-table/list-child-menu/'+idTrans).then(res => {
            //         if(res.data == null || res.data.length == 0){
            //             this.$router.push({name: 'dashboardPage'});
            //             return false
            //         }
            //         this.uuidChildNota = res.data.m_transaction_table_id
            //         this.loadNotaDetailChild(res.data.m_transaction_table_id)
            //         this.loadMenuNotaChild(res.data.m_transaction_table_id)
            //     }).catch ((err) => {
            //         alert('Some Error');
            //         this.$router.push({name: 'dashboardPage'});
            //     })
            // },
            // backToMenu(){
            //     this.$router.push({name: 'dashboardPage'});
            // },
            // saveTransaction(idMenuJenis = this.$route.params.transactionId){
            //     if(idMenuJenis == this.$route.params.transactionId){
            //         const ServiceNotaListMenu = this.ServiceNotaListMenu
            //         const TarikTunaiNotaListMenu = this.TarikTunaiNotaListMenu
            //         axios.put('/m-transaction-table/'+idMenuJenis, {
            //             tmp_transaction_detail_service_charge: ServiceNotaListMenu,
            //             tmp_transaction_detail_tarik_tunai: TarikTunaiNotaListMenu
            //         }).then(res => {
            //             this.$router.push({name: 'dashboardPage'});
            //         }).catch ((err) => {
            //             console.log(err);
            //         })
            //     }else{
            //         const ServiceNotaListMenu = this.ServiceNotaListMenuChild
            //         const TarikTunaiNotaListMenu = this.TarikTunaiNotaListMenuChild
            //         axios.put('/m-transaction-table/'+idMenuJenis, {
            //             tmp_transaction_detail_service_charge: ServiceNotaListMenu,
            //             tmp_transaction_detail_tarik_tunai: TarikTunaiNotaListMenu
            //         }).then(res => {
            //             this.$router.push({name: 'dashboardPage'});
            //         }).catch ((err) => {
            //             console.log(err);
            //         })
            //     }
            // },
            loadMenuNota(idMenuJenis = this.$route.params.transactionId){
                axios.get('/transaction-detail?tmp_transaction_detail_tmp_transaction_id='+idMenuJenis).then(res => {
                    this.notaListMenu = res.data
                    this.allNotaListMenu = res.data
                    this.updateAllCart()

                    // this.subTotalNotaListMenu = 0
                    // this.TotalNotaListMenu = 0
                    // this.pajakNotaListMenu = 0
                    // for(const keyData in res.data){
                    //     this.pajakNotaListMenu += res.data[keyData].tmp_transaction_detail_price * res.data[keyData].tmp_transaction_detail_qty
                    //     this.TotalNotaListMenu += res.data[keyData].tmp_transaction_detail_price * res.data[keyData].tmp_transaction_detail_qty
                    //     this.subTotalNotaListMenu += res.data[keyData].tmp_transaction_detail_price * res.data[keyData].tmp_transaction_detail_qty
                    // }
                    // // this.pajakNotaListMenu = this.pajakNotaListMenu / 100 * 10;
                    // // this.TotalNotaListMenu += this.pajakNotaListMenu
                    // // this.TotalNotaListMenu += this.subTotalNotaListMenu / 100 * this.ServiceNotaListMenu;
                    // // this.ServiceNotaListMenuPrice = this.subTotalNotaListMenu / 100 * this.ServiceNotaListMenu;
                    // // this.TotalNotaListMenu += this.TarikTunaiNotaListMenu;
                }).catch ((err) => {
                    console.log(err);
                })
            },
            // loadMenuNotaChild(idMenuJenis = this.uuidChildNota){
            //     axios.get('/m-transaction-meja?tmp_transaction_detail_tmp_transaction_id='+idMenuJenis).then(res => {
            //         console.log(res.data)
            //         this.notaListMenuChild = res.data
            //         this.subTotalNotaListMenuChild = 0
            //         this.TotalNotaListMenuChild = 0
            //         this.pajakNotaListMenuChild = 0
            //         for(const keyData in res.data){
            //             this.pajakNotaListMenuChild += res.data[keyData].tmp_transaction_detail_price * res.data[keyData].tmp_transaction_detail_qty
            //             this.TotalNotaListMenuChild += res.data[keyData].tmp_transaction_detail_price * res.data[keyData].tmp_transaction_detail_qty
            //             this.subTotalNotaListMenuChild += res.data[keyData].tmp_transaction_detail_price * res.data[keyData].tmp_transaction_detail_qty
            //         }
            //         this.pajakNotaListMenuChild = this.pajakNotaListMenuChild / 100 * 10;
            //         this.TotalNotaListMenuChild += this.pajakNotaListMenuChild
            //         this.TotalNotaListMenuChild += this.subTotalNotaListMenuChild / 100 * this.ServiceNotaListMenuChild;
            //         this.ServiceNotaListMenuPriceChild = this.subTotalNotaListMenuChild / 100 * this.ServiceNotaListMenuChild;
            //         this.TotalNotaListMenuChild += this.TarikTunaiNotaListMenuChild;
            //     }).catch ((err) => {
            //         console.log(err);
            //     })
            // },
            // setNullPajakChild(){
            //     const pajakPriceNowChild = this.pajakNotaListMenuChild;
            //     this.pajakNotaListMenuChild = 0;
            //     this.TotalNotaListMenuChild -= pajakPriceNowChild;
            // },
            // setServiceCharge(){
            //     this.ServiceNotaListMenu
            //     const promptServiceCharge = prompt("Masukan Persentase Service Charge", this.ServiceNotaListMenu);
            //     if (promptServiceCharge != null && parseFloat(promptServiceCharge)) {
            //         this.ServiceNotaListMenu = parseFloat(promptServiceCharge)
            //         this.ServiceNotaListMenuPrice = this.subTotalNotaListMenu / 100 * this.ServiceNotaListMenu;
            //     }else{
            //         this.ServiceNotaListMenu = parseFloat(0)
            //         this.ServiceNotaListMenuPrice = this.subTotalNotaListMenu / 100 * this.ServiceNotaListMenu;
            //     }
            //     this.TotalNotaListMenu = 0;
            //     this.TotalNotaListMenu += this.subTotalNotaListMenu;
            //     this.TotalNotaListMenu += this.pajakNotaListMenu;
            //     this.TotalNotaListMenu += this.subTotalNotaListMenu / 100 * this.ServiceNotaListMenu;
            //     this.TotalNotaListMenu += this.TarikTunaiNotaListMenu;
            // },
            // setServiceChargeChild(){
            //     this.ServiceNotaListMenuChild
            //     const promptServiceChargeChild = prompt("Masukan Persentase Service Charge", this.ServiceNotaListMenuChild);
            //     if (promptServiceChargeChild != null && parseFloat(promptServiceChargeChild)) {
            //         this.ServiceNotaListMenuChild = parseFloat(promptServiceChargeChild)
            //         this.ServiceNotaListMenuPriceChild = this.subTotalNotaListMenuChild / 100 * this.ServiceNotaListMenuChild;
            //     }else{
            //         this.ServiceNotaListMenuChild = parseFloat(0)
            //         this.ServiceNotaListMenuPriceChild = this.subTotalNotaListMenuChild / 100 * this.ServiceNotaListMenuChild;
            //     }
            //     this.TotalNotaListMenuChild = 0;
            //     this.TotalNotaListMenuChild += this.subTotalNotaListMenuChild;
            //     this.TotalNotaListMenuChild += this.pajakNotaListMenuChild;
            //     this.TotalNotaListMenuChild += this.subTotalNotaListMenuChild / 100 * this.ServiceNotaListMenuChild;
            //     this.TotalNotaListMenuChild += this.TarikTunaiNotaListMenuChild;
            // },
            // setTarikTunai(){
            //     this.TarikTunaiNotaListMenu
            //     const promptTarikTunai = prompt("Masukan Jumlah Nominal Tarik Tunai", this.TarikTunaiNotaListMenu);
            //     if (promptTarikTunai != null && parseFloat(promptTarikTunai)) {
            //         this.TarikTunaiNotaListMenu = parseFloat(promptTarikTunai)
            //     }else{
            //         this.TarikTunaiNotaListMenu = parseFloat(0)
            //     }
            //     this.TotalNotaListMenu = 0;
            //     this.TotalNotaListMenu += this.subTotalNotaListMenu;
            //     this.TotalNotaListMenu += this.pajakNotaListMenu;
            //     this.TotalNotaListMenu += this.subTotalNotaListMenu / 100 * this.ServiceNotaListMenu;
            //     this.ServiceNotaListMenuPrice = this.subTotalNotaListMenu / 100 * this.ServiceNotaListMenu;
            //     this.TotalNotaListMenu += this.TarikTunaiNotaListMenu;
            // },
            setTarikTunaiChild(){
                const InputTarikTunai = document.getElementById("input_tarik_tunai_split").value
                console.log("Tarik: "+InputTarikTunai);
                this.splitTarikTunai
                if (InputTarikTunai != null && parseFloat(InputTarikTunai)) {
                    this.splitTarikTunai = parseFloat(InputTarikTunai)
                }else{
                    this.splitTarikTunai = parseFloat(0)
                }
                this.countSplitPrice()
                // this.TotalNotaListMenuChild = 0;
                // this.TotalNotaListMenuChild += this.subTotalNotaListMenuChild;
                // this.TotalNotaListMenuChild += this.pajakNotaListMenuChild;
                // this.TotalNotaListMenuChild += this.subTotalNotaListMenuChild / 100 * this.ServiceNotaListMenuChild;
                // this.ServiceNotaListMenuPriceChild = this.subTotalNotaListMenuChild / 100 * this.ServiceNotaListMenuChild;
                // this.TotalNotaListMenuChild += this.TarikTunaiNotaListMenuChild;
      document.getElementById("close-modal-tunai-split").click()

            },
            // deleteTransaction(idMenuJenis = this.$route.params.transactionId){
            //     axios.delete('/m-transaction-table/'+idMenuJenis).then(res => {
            //         this.$router.push({name: 'dashboardPage'});
            //     }).catch ((err) => {
            //         this.$router.push({name: 'dashboardPage'});
            //     })
            // },
            // splitMenuOrder(idMenuJenis = this.$route.params.transactionId){
            //     axios.post('/m-transaction-table/split-menu-order', {transactionId: idMenuJenis}).then(res => {
            //         this.$router.push({name: 'splitMenuOrder', params: { transactionId: idMenuJenis}});
            //     }).catch ((err) => {
            //         console.log(err);
            //     })
            // },
            // splitMenu(uuidFirst, uuidGoTo, uuidMenu){
            //     axios.post('/m-transaction-meja/split-menu', {
            //             uuidFirst: uuidFirst, 
            //             uuidGoTo: uuidGoTo,
            //             uuidMenu: uuidMenu
            //         }).then(res => {
            //             this.loadMenuNota();
            //             this.loadMenuNotaChild();
            //             console.log(res)
            //     }).catch ((err) => {
            //         console.log(err);
            //     })
            // },
            addToSplitMenu(menu){

                const oldCart = [...this.cart];
                const newCart = [];
                const menuJenisOnCart = {
                    id: "",
                    name: "",
                    items: []
                }
                const newMenu = JSON.parse(JSON.stringify(menu));

                // reduce from old cart
                for (let i = 0; i < oldCart.length; i++) {
                    const menuCart = JSON.parse(JSON.stringify(oldCart[i]));
                    if (menuCart.id == menu.m_produk_m_jenis_produk_id) {
                        menuJenisOnCart.id = menuCart.id;
                        menuJenisOnCart.name = menuCart.name;
                        const newItems = []
                        for (let y = 0; y < menuCart.items.length; y++) {
                            const oldMenu = JSON.parse(JSON.stringify(menuCart.items[y]));
                            if (oldMenu.tmp_transaction_detail_id == menu.tmp_transaction_detail_id) {
                                oldMenu.tmp_transaction_detail_qty-=1;
                            }
                            if (oldMenu.tmp_transaction_detail_qty>0) {
                                newItems.push(oldMenu);
                            }
                        }
                        if (newItems.length>0) {
                            newCart.push({
                                id: menuCart.id,
                                name: menuCart.name,
                                items: newItems
                            });
                        }

                    } else {
                        newCart.push(menuCart);
                    } 
                }
                this.cart = newCart;
                const newNotaList = [];

                for (let index = 0; index < this.cart.length; index++) {
                    const e = JSON.parse(JSON.stringify(this.cart[index]));
                    const temp = JSON.parse(JSON.stringify(e.items))
                    for (let i = 0; i < temp.length; i++) {
                        const y = temp[i];
                        newNotaList.push(y)
                        
                    }
                }    

                this.notaListMenu = newNotaList;

                const newSplitMenu = [...this.splitListMenu];
                
                if (newSplitMenu.length===0) {
                    const addNewMenu = JSON.parse(JSON.stringify(menu));

                    addNewMenu.tmp_transaction_detail_qty=1;
                    newSplitMenu.push(addNewMenu);
                    this.splitListMenu = [...newSplitMenu];
                } else {
                    const tempSplitMenu = [...this.splitListMenu]
                    let found = false;
                    for (let index = 0; index < newSplitMenu.length; index++) {
                        const e = newSplitMenu[index];

                        const oldMenu = JSON.parse(JSON.stringify(e));
                        
                        if(oldMenu.tmp_transaction_detail_id==newMenu.tmp_transaction_detail_id){
                            found = true;
                            oldMenu.tmp_transaction_detail_qty+=1;
                            
                            tempSplitMenu[index].tmp_transaction_detail_qty = oldMenu.tmp_transaction_detail_qty;
                        } 
                        
                    }
                    if (!found) {
                        newMenu.tmp_transaction_detail_qty=1;
                        tempSplitMenu.push(newMenu)
                    }
                    this.splitListMenu = [...tempSplitMenu];
                }
                
                this.updateSplitCart()
                this.countSplitPrice()
            },
            removeFromSplitMenu(menu){

                const oldCart = [...this.splitCart];
                const newCart = [];
                const menuJenisOnCart = {
                    id: "",
                    name: "",
                    items: []
                }
                const newMenu = JSON.parse(JSON.stringify(menu));

                // reduce from old split cart
                for (let i = 0; i < oldCart.length; i++) {
                    const menuCart = JSON.parse(JSON.stringify(oldCart[i]));
                    if (menuCart.id == menu.m_produk_m_jenis_produk_id) {
                        menuJenisOnCart.id = menuCart.id;
                        menuJenisOnCart.name = menuCart.name;
                        const newItems = []
                        for (let y = 0; y < menuCart.items.length; y++) {
                            const oldMenu = JSON.parse(JSON.stringify(menuCart.items[y]));
                            if (oldMenu.tmp_transaction_detail_id == menu.tmp_transaction_detail_id) {
                                oldMenu.tmp_transaction_detail_qty-=1;
                            }
                            if (oldMenu.tmp_transaction_detail_qty>0) {
                                newItems.push(oldMenu);
                            }
                        }
                        if (newItems.length>0) {
                            newCart.push({
                                id: menuCart.id,
                                name: menuCart.name,
                                items: newItems
                            });
                        }

                    } else {
                        newCart.push(menuCart);
                    } 
                }
                this.splitCart = newCart;
                const newNotaList = [];

                for (let index = 0; index < this.splitCart.length; index++) {
                    const e = JSON.parse(JSON.stringify(this.splitCart[index]));
                    const temp = JSON.parse(JSON.stringify(e.items))
                    for (let i = 0; i < temp.length; i++) {
                        const y = temp[i];
                        newNotaList.push(y)
                        
                    }
                }    

                this.splitListMenu = newNotaList;

                const newNotaMenu = [...this.notaListMenu];
                
                if (newNotaMenu.length===0) {
                    const addNewMenu = JSON.parse(JSON.stringify(menu));
                    addNewMenu.tmp_transaction_detail_qty=1;
                    newNotaMenu.push(addNewMenu);   
                    this.notaListMenu = [...newNotaMenu];
                } else {
                    const tempMenu = [...this.notaListMenu]
                    let found = false;
                    for (let index = 0; index < newNotaMenu.length; index++) {
                        const e = newNotaMenu[index];

                        const oldMenu = JSON.parse(JSON.stringify(e));
                        
                        if(oldMenu.tmp_transaction_detail_id==newMenu.tmp_transaction_detail_id){
                            found = true;
                            oldMenu.tmp_transaction_detail_qty+=1;
                            tempMenu[index].tmp_transaction_detail_qty = oldMenu.tmp_transaction_detail_qty;
                        } 
                        
                    }
                    if (!found) {
                        newMenu.tmp_transaction_detail_qty=1;
                        tempMenu.push(newMenu)
                    }
                    this.notaListMenu = [...tempMenu];
                }
                
                this.updateAllCart()
                this.countSplitPrice()
            },
            addDiscount(value){
                if (value.discountType == 'voucher') {
                    this.voucherValue = value.discountNominal
                    this.countSplitPrice()
                }else{
                    this.discount = value.discountNominal
                    this.countSplitPrice()
                }
            },
            removeDiskon(){
                this.discount = 0;
                this.countSplitPrice()
            },
            removeVoucher(){
                this.voucherValue = 0;
                this.countSplitPrice()
            },
            updateAllCart(){
                this.cart = []
                const notaList = this.notaListMenu
                if (notaList.length>0) {
                    
                    const tempMenuJenis = []

                    notaList.forEach(function(e) {
                        if (tempMenuJenis.indexOf(e.m_produk_m_jenis_produk_id) === -1) {
                            tempMenuJenis.push(e.m_produk_m_jenis_produk_id)
                        }
                    });

                    const menuList = this.stockMenuList
                    const tempCart = []
                    tempMenuJenis.forEach(function(e) {
                        const tempMenuList = []
                        let name
                        menuList.forEach(function (item) {
                            
                            if (item.m_jenis_produk_id == e) {
                                name = item.m_jenis_produk_nama
                            }
                        });
                        
                        notaList.forEach(function (item) {
                            if (e == item.m_produk_m_jenis_produk_id) {
                                tempMenuList.push(item)
                            }                                
                        });

                        const menuOnCart = {
                            id: e,
                            name: name,
                            items: tempMenuList
                        }
                        tempCart.push(menuOnCart)  
                    });

                    this.cart = tempCart;
                    
                }
            },
            updateSplitCart(){
                this.splitCart = []
                const notaList = this.splitListMenu
                if (notaList.length>0) {
                    
                    const tempMenuJenis = []

                    notaList.forEach(function(e) {
                        if (tempMenuJenis.indexOf(e.m_produk_m_jenis_produk_id) === -1) {
                            tempMenuJenis.push(e.m_produk_m_jenis_produk_id)
                        }
                    });

                    const menuList = this.stockMenuList
                    const tempCart = []
                    tempMenuJenis.forEach(function(e) {
                        const tempMenuList = []
                        let name
                        menuList.forEach(function (item) {
                            
                            if (item.m_jenis_produk_id == e) {
                                name = item.m_jenis_produk_nama
                            }
                        });
                        
                        notaList.forEach(function (item) {
                            if (e == item.m_produk_m_jenis_produk_id) {
                                tempMenuList.push(item)
                            }                                
                        });

                        const menuOnCart = {
                            id: e,
                            name: name,
                            items: tempMenuList
                        }
                        tempCart.push(menuOnCart)  
                    });

                    this.splitCart = tempCart;
                    
                }
            },
            backPage(){
                this.$router.push({ name: 'orderMenu', params: { transactionId: this.$route.params.transactionId} });
            },
            cancelSplit(){
                const router = this.$router;
                if (this.splitListMenu.length==0) {
                    this.$router.push({ name: 'orderMenu', params: { transactionId: this.$route.params.transactionId} });
                    // this.$router.go(-1)
                } else {
                    const back = this.backPage
                    this.$swal.fire({
                        title:"Konfirmasi", 
                        text:"Yakin gajadi split nota", 
                        icon:"warning",
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Iya',
                        cancelButtonText: 'Tidak'
                    }).then(function (success) {
                        if (success.isConfirmed) {
                            back()
                            // router.go(-1)
                        }
                    })
                }
            },
            // deleteAllSplit(){
            //     this.notaListMenu = this.allNotaListMenu
            //     this.splitListMenu = []

            //     this.updateAllCart()
            //     this.updateSplitCart()
            // },
            countSplitPrice(){
                let total = 0
                let pajak = 0
                let sc = 0
                const currentSc = this.currentService
                const currentTax = this.currentPajak
                
                for (let i = 0; i < this.splitListMenu.length; i++) {
                    const splitItem = JSON.parse(JSON.stringify(this.splitListMenu[i]));
                    if (splitItem.tmp_transaction_detail_status==0) {
                        total += splitItem.tmp_transaction_detail_price * splitItem.tmp_transaction_detail_qty
                        if (splitItem.tmp_transaction_detail_tax_status) {
                            pajak += splitItem.tmp_transaction_detail_price * splitItem.tmp_transaction_detail_qty * currentTax
                        }

                        if (splitItem.tmp_transaction_detail_service_charge_status) {
                            sc += splitItem.tmp_transaction_detail_price * splitItem.tmp_transaction_detail_qty * currentSc
                        }
                    }
                }
                this.splitSubTotal = total;
                this.splitPajak = pajak
                this.splitServiceCharge = sc
                // this.splitTarikTunai = 0
                this.splitTotal = this.splitSubTotal + this.splitPajak + this.splitServiceCharge +this.splitTarikTunai - this.discount - this.voucherValue
                
            },
            setNullPajak(){
                this.currentPajak = 0
                this.countSplitPrice()
            },
            setPajak(){
                this.currentPajak = this.notaDetailList.pajak
                this.countSplitPrice()
            },
            setNullService(){
                this.currentService = 0
                this.countSplitPrice()
            },
            setService(){
                this.currentService = this.notaDetailList.service_charge
                this.countSplitPrice()
            },
            strukCetak(repeat){
                const rekapTransId = this.kodeTransaksi
                // const repeat = this.repeatStruk
                this.$swal.fire({
                    // title: 'Mencetak!',
                    html: 'Mencetak Struk',
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: () => {
                    this.$swal.showLoading()
                    // console.log(resp);
                    axios.get(`/cashier/print-transaksi/${rekapTransId}/${repeat}`).then(resp => {
                        // console.log(resp.data)
                        if(resp.data.error == 0){
                            if(resp.data.struct != ''){
                                location.href = resp.data.struct
                            }
                        }
                    }).catch ((err) => {
                        if (err.response) {
                        console.log(err.response.data)
                        }
                    })
                    }
                }).then((result) => {
                    /* Read more about handling dismissals below */
                    if (result.dismiss === this.$swal.DismissReason.timer) {
                    console.log('printing success')
                    }
                    document.getElementById('close-modal-struk').click()
                    this.$router.go(0)
                    // this.$router.push({ name: 'dashboardPage' })
                })
            },
            cronJobWa(target,transId){
                axios.get(`/cashier/cronjob-wa/${target}/${transId}`).then(resp => {
                    console.log(resp)
                }).catch ((err) => {
                    if (err.response) {
                    console.log(err.response.data)
                    }
                });
            },
            sendWa(target,transId){
                axios.get(`/sendStruct/${target}/${transId}`).then(resp => {
                    console.log(resp)
                }).catch ((err) => {
                    if (err.response) {
                    console.log(err.response.data)
                    }
                });
            },
            struk(wa,print){
                const rekapTransId = this.kodeTransaksi
                const hpbos = document.getElementById('nomor_hp').value
                const member = document.getElementById('member_id').value;

                if(hpbos && hpbos.split('.').length == 1){
                    if (hpbos.length < 10) {
                        this.$swal.fire({
                            title:"Warning!", 
                            text:"Nomor WA Tidak Sesuai", 
                            icon:"warning",
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok'
                        })
                        return false
                    }
                }

                if(member){
                    if(member.split('.').length == 1){
                        let alert = 0
                        if(member.length == 5){
                            alert = 0
                        }else{
                            if (member.length < 10) {
                                alert = 1
                            }
                        }
                        if (alert == 1) {
                            this.$swal.fire({
                                title:"Warning!", 
                                text:"NIK/ID Member Tidak Sesuai", 
                                icon:"warning",
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'Ok'
                            })
                            return false
                        }
                    }
                }

                const formData = new FormData()
                formData.append('r_t_id', rekapTransId)
                formData.append('nomor_wa', hpbos)
                formData.append('member_id', member)
                axios.post('/transaksi/save-member', formData).then(res => {
                    console.log(res);
                }).catch ((err) => {
                    console.log(err.response.data);
                })

                if (wa == 1) {
                    if (hpbos && hpbos.split('.').length == 1) {

                        this.$swal.fire({
                            // title: 'Mencetak!',
                            html: 'Mengirim Pesan Ke '+hpbos,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: () => {
                            this.$swal.showLoading()
                            // console.log(resp);
                            this.cronJobWa(hpbos,rekapTransId)
                            }
                        }).then((result)=>{
                            if (print == 1) {
                                this.strukCetak(1)

                                // if (this.typeTransaksiId > 2) {
                                //     this.strukCetak(1)
                                // }else{
                                //     this.strukCetak(2)
                                // }
                            }else{
                                document.getElementById('close-modal-struk').click()
                                // this.$router.push({ name: 'dashboardPage' })
                                this.$router.go(0)
                            }
                        })
                    }
                }else{
                    if (this.typeTransaksiId > 2) {
                        this.strukCetak(1)
                    }else{
                        this.strukCetak(2)
                    }
                }
            },
            payAction(value){
                let freeExchange = 0
                if (value.freeExchange!="") {
                    freeExchange = value.freeExchange
                }
                let nomorWa = 0
                let memberId = ''
                if (value.paymentMethod == 1) {
                    nomorWa = value.nomorWa
                    memberId = value.memberId
                }        
                let cetakStruk = 0
                if (value.cetakStruk != 0) {
                    cetakStruk = value.cetakStruk
                }

                const formData = new FormData()

                formData.append('tmp_transaction_id', this.$route.params.transactionId)
                formData.append('payment_method', value.paymentMethod)
                formData.append('sub_total', this.splitSubTotal)
                formData.append('total', this.splitTotal)
                formData.append('nominal_uang', value.payNominal)
                formData.append('exchange', value.exchange)
                formData.append('round', value.exchangeRounded)
                formData.append('free_exchange', freeExchange)
                formData.append('global_discount', this.discount)
                formData.append('nominal_voucher', this.voucherValue)
                formData.append('nominal_pajak', this.splitPajak)
                formData.append('nominal_sc', this.splitServiceCharge)
                formData.append('nominal_tariktunai', this.splitTarikTunai)
                formData.append('nota_number',this.splitNotaNumber),
                formData.append('split_menu',JSON.stringify(this.splitListMenu))
                formData.append('nomor_wa', nomorWa)
                formData.append('member_id', memberId)
                formData.append('cetak_struk', cetakStruk)
                formData.append('selisih', 0)
                formData.append('splitId', this.splitId)
                
                const router = this.$router;

                axios.post('/recap-transactions', formData).then(res => {
                    console.log(res);
                    if(res.data.meta.error == true){
                        this.$swal.fire({
                            title:"Terjadi Kesalahan!", 
                            text:res.data.meta.message, 
                            icon:"warning",
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok'
                        })
                        .then(function (success) {
                            if (success.isConfirmed) {
                            document.getElementById('close-modal-exchange').click()
                            }
                        }) 
                    }
                    if(res.status==201){
                        // document.getElementById("close-modal-exchange").click()
                        // router.go(-1)
                        
                        this.updateAllCart()
                        const rekapTransId = res.data.data.rekap_id
                        this.kodeTransaksi = rekapTransId
                        this.typeTransaksiId = res.data.data.m_t_t_id
                        
                        if (value.paymentMethod > 1) {
                            document.getElementById('closeChooseEdc').click()
                            document.getElementById('show-modal-struk').click()
                        }

                        if (value.paymentMethod == 1) {
                            // this.repeatStruk = 2
                            if (nomorWa != 0) {
                                // this.repeatStruk = 1
                                this.$swal.fire({
                                    // title: 'Mencetak!',
                                    html: 'Mengirim Pesan Ke '+nomorWa,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: () => {
                                    this.$swal.showLoading()
                                    this.cronJobWa(nomorWa,rekapTransId)
                                    }
                                })
                            }
                            if (value.cetakStruk == 1) {
                                if (nomorWa != '') {
                                    this.strukCetak(1)
                                }else{
                                    this.strukCetak(2)
                                }
                            }
                        }

                        // document.getElementById('close-modal-exchange').click()
                        
                        
                        // this.$swal.fire({
                        //     // title: 'Mencetak!',
                        //     html: 'Mencetak Struk',
                        //     timer: 2000,
                        //     timerProgressBar: true,
                        //     didOpen: () => {
                        //     this.$swal.showLoading()
                        //     axios.get(`/cashier/print-transaksi/${rekapTransId}/${repeat}`).then(resp => {
                        //         console.log(resp.data)
                        //         if(resp.data != ''){
                        //             location.href = resp.data
                        //         }
                        //         document.getElementById('close-modal-exchange').click()
                        //         document.getElementById('closeChooseEdc').click()
                        //         // this.$router.push({name: 'splitMenuOrder', params: { transactionId: this.$route.params.transactionId}});
                        //         router.go(0)
                        //     }).catch ((err) => {
                        //         if (err.response) {
                        //         console.log(err.response.data)
                        //         }
                        //     })
                        //     }
                        // }).then((result) => {
                        //     /* Read more about handling dismissals below */
                        //     if (result.dismiss === this.$swal.DismissReason.timer) {
                        //     console.log('printing success')
                        //     }
                        // })

                    }
                    
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            }
            
        },
        setup() {
            const user = ref(null);
            const router = useRouter();

            onMounted( async () => {
                try {
                    const response = await axios.get('/user');
                    user.value = response.data.data;
                } catch (e) {
                    await router.push({ name: 'loginPage' });
                }
            });

            return {
                user,
            }

        },
    }
</script>
