<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-accent" id="navbar">
        <div class="container-fluid">
            <!-- <router-link  class="navbar-brand d-flex align-items-center" :to="{ name: 'odcr' }">
                <img :src="urlImage" alt="" height="30" class="mr-3">
                CR55
            </router-link > -->
            <div class="navbar-brand d-flex align-items-center" :to="{ name: 'odcr' }">
                <img :src="urlImage" alt="" height="30" class="mr-3">
                <span>
                CR55 <br>
                <h6>v4.2403.02</h6>
                </span>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto nav-primary-menu">
                    <li class="nav-item" id="listTableNavigation"><router-link class="nav-link" :to="{ name: 'dashboardPage'}">Daftar Transaksi</router-link></li>
                    <li v-if="userRole != 'opp'" class="nav-item" id="onlineOrderNavigation"><router-link class="nav-link" :to="{ name: 'onlineOrderPage'}">OJOL</router-link></li>
                    <li v-if="userRole != 'wbd'" class="nav-item" id="onlineOrderQrNavigation">
                      <router-link class="nav-link" :to="{ name: 'onlineOrderQrPage'}">
                        Online Order
                        <span v-if="countOrder > 0" class="badge rounded-pill badge-notification bg-success">{{ countOrder }}</span>
                      </router-link>
                    </li>
                    <li v-if="userRole != 'opp'" class="nav-item"><a class="nav-link" href="javascript:void(0)" data-toggle="buka-laci" 
                      @click="showOpenDrawer">Buka Laci</a></li>
                    <li v-if="userRole != 'opp'" class="nav-item dropdown" id="mutationOrderNavigation">
                        <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown">Kas Kecil</a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <router-link class="dropdown-item mb-1 py-2" :to="{ name: 'mutationPage'}">Kas Kecil</router-link>
                          <a href="" class="dropdown-item mb-1 py-2" @click.prevent="uangTipsClicked">Uang Tips</a>
                        </div>
                    </li>
                    <li v-if="userRole != 'opp'" class="nav-item dropdown" id="historyNavigation">
                        <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown">History</a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <!-- <a href="javascript:void(0)" class="dropdown-item" @click.prevent="onShowModalTransaction">Cari Transaksi</a> -->
                          <a href="#modal-search-history" class="dropdown-item mb-1 py-2" 
                                data-toggle="modal"
                                data-target="#modal-search-history"
                                data-dismiss="modal">
                              Cari
                            </a>
                          <router-link class="dropdown-item mb-1 py-2" :to="{ name: 'historyPage'}">Transaksi</router-link>
                        </div>
                    </li>
                    <!-- <li class="nav-item" id="stockNavigation"><router-link class="nav-link" :to="{ name: 'stockPage'}">Stock</router-link></li> -->
                </ul>
                <div class="time-box ml-auto text-white d-flex align-items-center">
                    <i class="fa fa-clock-o mr-3"></i>
                    <span id="jam"> {{ dateTime.hours }}</span>:<span id="menit">{{ dateTime.minutes }}</span>:<span id="detik">{{ dateTime.seconds }}</span>
                    
                </div>
                
                <ul class="navbar-nav ml-4">
                    <li class="nav-item dropdown">
                        <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown"><span v-if="namaWaroeng != ''">{{ capitalFirst(namaWaroeng) }}<br></span> {{ username }}</a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <!-- <router-link class="dropdown-item" :to="{ name: 'dashboardPage'}">Daftar Meja</router-link>
                            <router-link class="dropdown-item" :to="{ name: 'UpdateData'}">UpdateData</router-link>
                            <a href="javascript:void(0)" class="dropdown-item">Refresh CR55</a>
                            <div class="dropdown-divider"></div> -->
                            <a v-if="userRole != 'opp'" href="" class="dropdown-item mb-1 py-2" @click.prevent="showModal">Saldo Awal</a>
                            <a v-if="userRole != 'opp'" href="#modal-input-cash" class="dropdown-item mb-1 py-2" 
                                data-toggle="modal"
                                data-target="#modal-input-cash"
                                data-dismiss="modal">
                              Tutup Saldo
                            </a>
                            <!-- <router-link class="dropdown-item mb-1 py-2" :to="{ name: 'Shift' }">Ganti Shift</router-link> -->
                            <div class="dropdown-divider"></div>
                            <a href="#modal-printer" class="dropdown-item mb-1 py-2" 
                                data-toggle="modal"
                                data-target="#modal-printer"
                                data-dismiss="modal">
                              Printer Setting
                            </a>
                            <a href="#modal-approvel" class="dropdown-item mb-1 py-2" 
                                data-toggle="modal"
                                data-target="#modal-approvel"
                                data-dismiss="modal">
                              Role Setting
                            </a>
                            <a href="#modal-watest" class="dropdown-item mb-1 py-2" 
                                data-toggle="modal"
                                data-target="#modal-watest"
                                data-dismiss="modal">
                              Test Whatsapp
                            </a>
                            <a v-if="userRole != 'opp'" href="javascript:void(0)" @click="removeTemp" class="dropdown-item mb-1 py-2">Refresh CR55</a>
                            <a href="javascript:void(0)" class="dropdown-item mb-1 py-2">Bantuan</a>
                            <div class="dropdown-divider"></div>
                            <a href="javascript:void(0)" id="logout_btn" class="dropdown-item mb-1 py-2" @click="logout">Log Out</a>
                        </div>
                    </li>
                </ul>

            </div>
        </div>

    </nav>

    <ModalTransaction @on-hide="onModalTransactionHide" :showModal="showModalTransaction" />
    <Drawer @on-hide="onDrawerHide" @on-submit="onDrawerSubmit" :showModal="showDrawer" />
    <InputCash></InputCash>
    <SearchHistory></SearchHistory>
    <PrinterSetting></PrinterSetting>
    <ApprovelAkses></ApprovelAkses>
    <WaTest></WaTest>
</template>

<script lang="js">
import { ref, onMounted } from 'vue';
import moment from 'moment-timezone';
import { useRouter } from 'vue-router';
import { mapActions } from 'vuex';
import ModalTransaction from '@/components/secure/history/ModalTransaction.vue';
import Drawer from '@/components/secure/dashboard/Drawer.vue';
import InputCash from '@/components/secure/dashboard/InputCash.vue';
import SearchHistory from '../history/SearchHistory.vue';
import axios from 'axios';
import PrinterSetting from './PrinterSetting.vue';
import ApprovelAkses from './ApprovelAkses.vue';
import WaTest from '@/components/secure/dashboard/WaTest.vue';

const date = new Date();

export default {
  name: 'Nav',
  components: {
    ModalTransaction,
    Drawer,
    InputCash,
    SearchHistory,
    PrinterSetting,
    ApprovelAkses,
    WaTest
},
  data() {
    return {
      dateTime: {
        hours: moment().format('H'),
        minutes: moment().format('mm'),
        seconds: moment().format('ss'),
      },
      timer: null,
      namaWaroeng: '',
      username: '',
      userRole: 'kasir',
      countOrder: 0,
      notifAudio: '/assets/ringtone/bell.mp3'
    }
  },
  setup() {
    const router = useRouter();
    const urlImage = "/assets/images/logo.jpg";
    const logout = async () => {
      localStorage.setItem('token', null);
      localStorage.clear();
      router.push({ name: 'loginPage' });
    };

    const showModal = () => {
      router.push({ name: 'Modal' });
    };

    const showModalTransaction = ref(false)
    const onModalTransactionHide = () => showModalTransaction.value = false
    const onShowModalTransaction = () => showModalTransaction.value = true

    const showDrawer = ref(false)
    const onDrawerHide = () => showDrawer.value = false
    const showOpenDrawer = () => showDrawer.value = true
    const onDrawerSubmit = () => {
      showDrawer.value = false
      // console.log('open drawer')
    }
    return {
      logout,
      urlImage,
      showModal,
      showModalTransaction,
      onModalTransactionHide,
      onShowModalTransaction,
      showDrawer,
      onDrawerHide,
      onDrawerSubmit,
      showOpenDrawer
    }
  },
  mounted() {
    this.username = localStorage.getItem('user_name').toUpperCase();
    this.userRole = localStorage.getItem('user_role');
    // console.log(this.userRole)
    // window.setTimeout( "waktu()" ,1000);
    this.infoWaroeng();
    this.modalCek();
    // this.aksesRole();
    
    if(this.userRole != 'wbd'){
      this.notifOrder();
      // setInterval(() => {
      //   this.notifOrder();
      // }, 5000);
    }
  },
  methods: {
    ...mapActions('modal', [
      'showModal',
      'hideModal'
    ]),
    // aksesRole(){
    //   axios.get('/akses-role')
    //   .then(resp => {
    //     if (resp.status == 200) {
    //       const parsed = JSON.stringify(resp.data);
    //       localStorage.setItem('role_akses', parsed);
    //     }
    //   })
    //   .catch(err => console.log(err))
      
    //   // const callAkses = JSON.parse(localStorage.role_akses)
    //   // console.log(callAkses)
    // },
    playSound(){
      const audio = new Audio(this.notifAudio);
      audio.play();
    },
    removeTemp(){
      this.$swal.fire({
        // title: 'Mencetak!',
        html: 'Please wait ..',
        timer: 3000,
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })
      axios.get('/transaksi/clearTmp')
      .then(resp => {
        console.log(resp.data)
        // this.$router.push({name: 'dashboardPage'});
        // this.$router.push('/dashboard');
        window.location.replace("./dashboard");
        // this.$router.go(0)
      })
      .catch(err => {
        console.log(err)
      })
    },
    notifOrder(){
      axios.get('/tmp-online/notif')
      .then(resp => {
        // console.log("notif: ",resp)
        if(resp){
          this.countOrder = resp.data.count
          if(resp.data.sound < 2 || resp.data.tmpId != ''){
            this.playSound()
          }
          if (resp.data.tmpId != '') {
            this.updateNotifOrder(resp.data.tmpId)
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
    },
    updateNotifOrder(id){
      axios.get('/tmp-online/off-notif/'+id)
      .then(resp => {
        // console.log("notif: ",resp)
      })
      .catch(err => {
        console.log(err)
      })
    },
    capitalFirst(text) {
      // ubah kalimat menjadi array of words
      const words = text.split(' ')
      // ubah setiap kata menjadi kapital
      const capitalizedWords = words.map(word => word[0]?.toUpperCase() + word.slice(1) || '')
      // gabungkan kembali menjadi kalimat
      return capitalizedWords.join(' ')
    },
    setDateTime() {
      const date = new Date()
      this.dateTime = {
        hours: moment().format('H'),
        minutes: moment().format('mm'),
        seconds: moment().format('ss'),
      }
    },
    uangTipsClicked() {
      this.$store.dispatch('uang_tips/setEditStatus', false);
      this.$router.push({ name: 'UangTips' });
    },
    modalCek(){
      const router = useRouter();
      axios.get('/cek-modal')
      .then(resp => {
        if (resp.data == 0 && this.userRole != 'opp') {
          router.push({ name: 'Modal' })
        } 
      })
      .catch(err => {
        // console.log("Modal error: ",err)
        if (401 === err.response.status) {
          localStorage.setItem('token', null);
          localStorage.clear();
          router.push({ name: 'loginPage' });
        } else {
            // return Promise.reject(err);
          // alert("Connection unstable!")
          this.$swal.fire({
              title:"Oops!", 
              text:"Connection unstable.", 
              icon:"warning",
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
          })
        }
        
        // localStorage.setItem('token', null);
        // localStorage.clear();
        // router.push({ name: 'loginPage' });
      })
    },
    infoWaroeng(){
      axios.get('/info-waroeng')
      .then(resp => {
        this.namaWaroeng = resp.data.m_w_nama
      })
      .catch(err => console.log(err))
    }
  },
  beforeMount() {
    this.timer = setInterval(this.setDateTime, 1000)
  },
  beforeUnmount() {
    clearInterval(this.timer)
  }
}
</script>
