<template>
    <div>
        <Nav></Nav>
        <section class="py-4 section-app" style="background: #FFF8DC">
            <div class="container-fluid">
                <div class="d-flex" style="min-height:calc(100vh - 300px);">
                    <div class="row h-100 w-100 m-0">
                        <div class="col-lg-6 px-0">
                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="card bg-transparent border-0">
                                        <div class="card-header bg-transparent font-weight-bold border-0" style="font-size:12px">{{leftName}}</div>
                                        <div v-if="leftQueue.length==0" class="card-body border-bottom">
                                            Belum ada pesanan
                                        </div>
                                        <div v-else class="row mx-1">
                                            <template v-for="(queue, index) in leftQueue">
                                                <div v-if="index<2" class="col-lg-6 px-1 mb-3" :key="queue.id">
                                                    <div  class="card bg-accent text-white">
                                                        <div class="card-header bg-transparent border-0 font-weight-bold text-center text-uppercase" style="font-size:18px">{{queue.m_menu_nama}}</div>
                                                        <ul class="list-group">
                                                            <li class="list-group-item p-0" style="font-size:10px;">
                                                                <table v-for=" (menu, index) in queue.m_dish_status" :key="index" width="100%" class="bg-accent">
                                                                    <tr style="border-bottom: 1px solid #aaa;">
                                                                        <td rowspan="2" class="text-center font-weight-bold" width="20" style="fontSize: 14px">{{menu.total}}</td>
                                                                        <td rowspan="2" class="text-center text-dark" style="background: #FFF8DC;color:#333; fontSize: 16px">{{menu.customMenu}}</td>
                                                                        <td class="text-center font-weight-bold" width="10" style="border-bottom:1px solid #fff1; fontSize: 14px">{{menu.tableNames}}</td>
                                                                    </tr>
                                                                    <tr style="border-bottom: 1px solid #aaa;">
                                                                        <td class="text-center px-1" style="fontSize: 14px">{{menu.time}}</td>
                                                                    </tr>
                                                                </table>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div v-else class="col-lg-4 px-1 mb-3" :key="queue.id">
                                                    <div class="card bg-dark text-white">
                                                        <div class="card-header bg-transparent border-0 font-weight-bold text-center text-uppercase" style="font-size:18px">{{queue.m_menu_nama}}</div>
                                                        <ul class="list-group">
                                                            <li class="list-group-item p-0" style="font-size:10px;">
                                                                <table v-for=" (menu, index) in queue.m_dish_status" :key="index" width="100%" class="bg-dark">
                                                                    <tr style="border-bottom: 1px solid #aaa;">
                                                                        <td rowspan="2" class="text-center" width="20">{{menu.total}}</td>
                                                                        <td rowspan="2" class="text-center text-dark" style="background: #FFF8DC;color:#333;"><h6>{{menu.customMenu}}</h6></td>
                                                                        <td class="text-center font-weight-bold" width="10" style="border-bottom:1px solid #fff1;">{{menu.tableNames}}</td>
                                                                    </tr>
                                                                    <tr style="border-bottom: 1px solid #aaa;">
                                                                        <td class="text-center" style="">{{menu.time}}</td>
                                                                    </tr>
                                                                </table>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="card bg-transparent border-0">
                                        <div class="card-header bg-transparent font-weight-bold border-0" style="font-size:12px">Tambahan</div>
                                        <div v-if="leftTambahan.length==0" class="card-body border-bottom">Belum ada pesanan</div>
                                        <div v-else class="row mx-1">
                                            <div v-for="(tambahan) in leftTambahan" :key="tambahan.id" class="col-lg-12 px-1 mb-3">
                                                <div class="card bg-dark text-white">
                                                    <div class="card-header bg-transparent border-0 font-weight-bold text-center text-uppercase" style="font-size:18px">{{tambahan.m_menu_nama}}</div>
                                                    <ul class="list-group">
                                                        <li class="list-group-item p-0" style="font-size:10px;">
                                                            <table v-for=" (menu, index) in tambahan.m_dish_status" :key="index" width="100%" class="bg-dark">
                                                                <tr style="border-bottom: 1px solid #aaa;">
                                                                    <td rowspan="2" class="text-center font-weight-bold" width="20" style="fontSize: 14px">{{menu.total}}</td>
                                                                    <td rowspan="2" class="text-center text-dark" style="background: #FFF8DC;color:#333; fontSize: 16px">{{menu.customMenu}}</td>
                                                                    <td class="text-center font-weight-bold" width="10" style="border-bottom:1px solid #fff1; fontSize: 14px">{{menu.tableNames}}</td>
                                                                </tr>
                                                                <tr style="border-bottom: 1px solid #aaa;">
                                                                    <td class="text-center px-1" style="fontSize: 14px">{{menu.time}}</td>
                                                                </tr>
                                                            </table>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-6 px-0">
                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="card bg-transparent border-0">
                                        <div class="card-header bg-transparent font-weight-bold border-0" style="font-size:12px">{{rightName}}</div>
                                        <div v-if="rightQueue.length==0" class="card-body border-bottom">
                                            Belum ada pesanan
                                        </div>
                                        <div v-else class="row mx-1">
                                            <template v-for="(queue, index) in rightQueue">
                                                <div v-if="index<2" class="col-lg-6 px-1 mb-3" :key="queue.id">
                                                    <div  class="card bg-accent text-white">
                                                        <div class="card-header bg-transparent border-0 font-weight-bold text-center text-uppercase" style="font-size:18px">{{queue.m_menu_nama}}</div>
                                                        <ul class="list-group">
                                                            <li class="list-group-item p-0" style="font-size:10px;">
                                                                <table v-for=" (menu, index) in queue.m_dish_status" :key="index" width="100%" class="bg-accent">
                                                                    <tr style="border-bottom: 1px solid #aaa;">
                                                                        <td rowspan="2" class="text-center font-weight-bold" width="20" style="fontSize: 14px">{{menu.total}}</td>
                                                                        <td rowspan="2" class="text-center text-dark" style="background: #FFF8DC;color:#333; fontSize: 16px">{{menu.customMenu}}</td>
                                                                        <td class="text-center font-weight-bold" width="10" style="border-bottom:1px solid #fff1; fontSize: 14px">{{menu.tableNames}}</td>
                                                                    </tr>
                                                                    <tr style="border-bottom: 1px solid #aaa;">
                                                                        <td class="text-center px-1" style="fontSize: 14px">{{menu.time}}</td>
                                                                    </tr>
                                                                </table>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div v-else class="col-lg-4 px-1 mb-3" :key="queue.id">
                                                    <div class="card bg-dark text-white">
                                                        <div class="card-header bg-transparent border-0 font-weight-bold text-center text-uppercase" style="font-size:18px">{{queue.m_menu_nama}}</div>
                                                        <ul class="list-group">
                                                            <li class="list-group-item p-0" style="font-size:10px;">
                                                                <table v-for=" (menu, index) in queue.m_dish_status" :key="index" width="100%" class="bg-dark">
                                                                    <tr style="border-bottom: 1px solid #aaa;">
                                                                        <td rowspan="2" class="text-center" width="20">{{menu.total}}</td>
                                                                        <td rowspan="2" class="text-center text-dark" style="background: #FFF8DC;color:#333;"><h6>{{menu.customMenu}}</h6></td>
                                                                        <td class="text-center font-weight-bold" width="10" style="border-bottom:1px solid #fff1;">{{menu.tableNames}}</td>
                                                                    </tr>
                                                                    <tr style="border-bottom: 1px solid #aaa;">
                                                                        <td class="text-center" style="">{{menu.time}}</td>
                                                                    </tr>
                                                                </table>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </template>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="card bg-transparent border-0">
                                        <div class="card-header bg-transparent font-weight-bold border-0" style="font-size:12px">Tambahan</div>
                                        <div v-if="rightTambahan.length==0" class="card-body border-bottom">Belum ada pesanan</div>
                                        <div v-else class="row mx-1">
                                            <div v-for="(tambahan) in rightTambahan" :key="tambahan.id" class="col-lg-12 px-1 mb-3">
                                                <div class="card bg-dark text-white">
                                                    <div class="card-header bg-transparent border-0 font-weight-bold text-center text-uppercase" style="font-size:18px">{{tambahan.m_menu_nama}}</div>
                                                    <ul class="list-group">
                                                        <li class="list-group-item p-0" style="font-size:10px;">
                                                            <table v-for=" (menu, index) in tambahan.m_dish_status" :key="index" width="100%" class="bg-dark">
                                                                <tr style="border-bottom: 1px solid #aaa;">
                                                                    <td rowspan="2" class="text-center font-weight-bold" width="20" style="fontSize: 14px">{{menu.total}}</td>
                                                                    <td rowspan="2" class="text-center text-dark" style="background: #FFF8DC;color:#333; fontSize: 16px">{{menu.customMenu}}</td>
                                                                    <td class="text-center font-weight-bold" width="10" style="border-bottom:1px solid #fff1; fontSize: 14px">{{menu.tableNames}}</td>
                                                                </tr>
                                                                <tr style="border-bottom: 1px solid #aaa;">
                                                                    <td class="text-center px-1" style="fontSize: 14px">{{menu.time}}</td>
                                                                </tr>
                                                            </table>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                </div>

                <div class="card mt-3">
                    <div class="card-header bg-accent text-white">Menu Yang Di Cancel</div>
                    <div class="card-body">
                        <div class="row">
                            <div v-for="cancel in cancelled" :key="cancel.id" class="col-lg-2 mb-1">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="font-weight-bold">{{cancel.m_menu_nama}}</div>
                                        <div>{{cancel.m_transaction_table_note_number}}</div>
                                        <div>{{cancel.updated_at}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>

    </div>
        
</template>
<script lang="js" >
import { onMounted, ref } from 'vue';
import axios from 'axios';
import{useRouter} from 'vue-router';
import Nav from '@/components/secure/odcr/Nav.vue'
import moment from 'moment'
import { AUTO_REFRESH_DURATION, sleep } from '@/mixins/app'
import { mapGetters } from 'vuex'

export default {
    name: "Layar",
    components: {
        Nav
    },
    data(){
        return{
            leftQueue:[],
            rightQueue:[],
            leftTambahan:[],
            rightTambahan:[],
            cancelled:[],
            tableNames: [],
            plotNames: [],
            leftName:'',
            rightName:''
        }
    },
    computed: {
      ...mapGetters({
        autoRefresh: 'production/autoRefresh'
      })
    },
    mounted() {
      const loadTableName = this.loadTableName;
      const autoRefresh = this.autoRefresh;
      const loadData = this.loadData;
      const enableAutoRefresh = this.enableAutoRefresh;

      this.loadData().then(_loadDataResp => {
        (function loop(fn, enableAutoRefresh) {
          setTimeout(() => {
            enableAutoRefresh();
            const startTime = new Date().getTime()
      
            fn().then(resp => {
              const endTime = new Date().getTime()
              const duration = endTime - startTime
              let extra = 0

              if (duration < AUTO_REFRESH_DURATION) {
                sleep(AUTO_REFRESH_DURATION - duration).then(_sleepResp => {
                  extra = new Date().getTime()
                  console.log('Duration req: ' + (extra - startTime))
                  loop(fn, enableAutoRefresh)
                })
              } else {
                loop(fn, enableAutoRefresh)
              }
            })

          }, AUTO_REFRESH_DURATION)
        })(loadData, enableAutoRefresh);
      })
    },
    watch: {
        '$route.params.layarId': function (id) {
            this.leftQueue=[],
            this.rightQueue=[],
            this.leftTambahan=[],
            this.rightTambahan=[],
            this.cancelled=[],
            this.tableNames= [],
            this.plotNames= [],
            this.leftName='',
            this.rightName=''
            this.loadTableName()
        }
    },
    methods: {
      enableAutoRefresh() {
        this.$store.dispatch('enableAutoRefresh')
      },
      loadData() {
        window.setTimeout("loadingComponentOrder()", 750)
        document.getElementById("layarNavigation").classList.add("active")
        return this.loadTableName()
      },
      loadTableName(){
        return axios.get('/m-transaction-table/table-name')
        .then(res => {
          this.tableNames = res.data.data                    
          return this.loadPlotName()

        }).catch ((err) => {
          console.log(err.response.data);
          return new Promise.reject(err)
        })
      },
      loadPlotName(){
        return axios.get('/m-layar-produksi/plot-name').then(res => {
          this.plotNames = res.data.data.name                  
          return this.loadLayar();

        }).catch ((err) => {
          console.log(err.response.data);
          return new Promise.reject(err)
        })
      },
        loadLayar(){
            return axios.get('/m-layar-produksi/detail-screen/'+this.$route.params.layarId).then(res => {
                                    
                // console.log(res)
                const allPlotName = this.plotNames

                const leftPlots = []
                const leftPlotArray = JSON.parse(res.data.data.left.m_plotting_type_plot_produksi_id)

                leftPlotArray.forEach(function (plot) {
                    leftPlots.push(allPlotName[plot])
                });

                this.leftName = leftPlots.join(', ')

                const rightPlots = []
                const rightPlotArray = JSON.parse(res.data.data.right.m_plotting_type_plot_produksi_id)

                rightPlotArray.forEach(function (plot) {
                    rightPlots.push(allPlotName[plot])
                });

                this.rightName = rightPlots.join(', ')

                const leftQueue = res.data.data.left_queue
                const rightQueue = res.data.data.right_queue
                const leftAdditional = res.data.data.left_additional
                const rightAdditional = res.data.data.right_additional
                const cancelled = res.data.data.cancelled
                
                this.leftQueue = this.setPlotting(leftQueue)
                this.rightQueue = this.setPlotting(rightQueue)
                this.leftTambahan = this.setPlotting(leftAdditional)
                this.rightTambahan = this.setPlotting(rightAdditional)
                res.data.data.cancelled.forEach(function (cancel) {
                    cancel.updated_at = moment(cancel.updated_at).format('HH:mm:ss')
                });

                this.cancelled = res.data.data.cancelled

                // console.log(cancelled);
              return res.data.data 
                
            }).catch ((err) => {
                console.log(err.response.data);
                return new Promise.reject(err)
            })
        },  
        setPlotting(arrayPlot){
            const newQueue = []
            const allTableName = this.tableNames
            arrayPlot.forEach(function (queue) {
                const newMDishStatus = []
                queue.m_dish_status.forEach(function(menu) {
                    if (menu.menu_detail) {
                        const tableIds = JSON.parse(menu.menu_detail.m_transaction_table_table_list)
                        const leftQueueTable = []
                        if (tableIds) {
                            tableIds.forEach(function(id) {
                                leftQueueTable.push(allTableName[id])
                            });    
                        } else {
                            leftQueueTable.push(menu.menu_detail.name)
                        }
                        
                        menu.tableNames = leftQueueTable.join(', ')                            
                        menu.time = menu.menu_detail.m_transaction_table_order_time
                        menu.customMenu = menu.menu_detail.m_transaction_menu_custom_menu
                        menu.updatedAt = moment(menu.updated_at).format('HH:mm:ss')
                        newMDishStatus.push(menu)    
                    }
                    

                });

                queue.m_dish_status = newMDishStatus
                
                newQueue.push(queue)
            })

            console.log(newQueue);
            

            return newQueue
        }
    },
    setup() {
        const user = ref(null);
        const router = useRouter();

        onMounted( async () => {
            try {
                const response = await axios.get('/user');
                user.value = response.data.data;
            } catch (e) {
                await router.push({ name: 'loginPage' });
            }
        });

        return {
            user,
        }

    },
}
</script>
