<template>
    <div>
        <Nav></Nav>

        <section class="py-4 section-app">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header font-weight-bold">
                        <div class="row align-items-center">
                            <div class="col-auto"><button v-on:click="setupPopup('grab')" class="btn btn-action" data-toggle="modal" data-target="#modal-input-nota"><i class="feather-plus"></i> ORDER</button></div>
                            <div class="col-auto"><img :src="grab" alt="" height="20"></div>
                        </div>
                    </div>
                    <div class="card-body bg-light" style="min-height: calc((100vh - 300px) / 3);">
                        <div class="row mx-n2 row-meja">
                            <div class="col-sm-3 col-md-2 col-lg-2 col-xl-1 mb-3 px-2" v-on:click="toOrder(order.tmp_transaction_id, 'grab')" v-for="order in grabOrder" :key="order.tmp_transaction_id">
                                <a href="#"  class="card card-meja h-100 text-decoration-none">
                                    <div class="card-body align-self-center justify-content-center text-center"><br/> {{order.tmp_transaction_note_number}}</div>
                                    <div class="card-footer">{{order.tmp_transaction_customer_name}}</div>
                                    <div class="content-center text-center">{{ getDuration(order.tmp_transaction_order_time) }}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-header font-weight-bold">
                        <div class="row align-items-center">
                            <div class="col-auto"><button v-on:click="setupPopup('grabmart')" class="btn btn-action" data-toggle="modal" data-target="#modal-input-nota"><i class="feather-plus"></i> ORDER</button></div>
                            <div class="col-auto"><img :src="grabmart" alt="" height="20"></div>
                        </div>
                    </div>
                    <div class="card-body bg-light" style="min-height: calc((100vh - 300px) / 3);">
                        <div class="row mx-n2 row-meja">
                            <div class="col mb-3 px-2" v-on:click="toOrder(order.tmp_transaction_id, 'grabmart')" v-for="order in grabMartOrder" :key="order.tmp_transaction_id">
                                <a href="#"  class="card card-meja h-100 text-decoration-none">
                                    <div class="card-body align-self-center justify-content-center text-center"><br/> {{order.tmp_transaction_note_number}}</div>
                                    <div class="card-footer">{{order.tmp_transaction_customer_name}}</div>
                                    <div class="content-center text-center">{{ getDuration(order.tmp_transaction_order_time) }}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-header font-weight-bold">
                        <div class="row align-items-center">
                            <div class="col-auto"><button v-on:click="setupPopup('gojek')" class="btn btn-action" data-toggle="modal" data-target="#modal-input-nota"><i class="feather-plus"></i> ORDER</button></div>
                            <div class="col-auto"><img :src="gojek" alt="" height="20"></div>
                        </div>
                    </div>
                    <div class="card-body bg-light" style="min-height: calc((100vh - 300px) / 3);">
                        <div class="row mx-n2 row-meja">
                            <div class="col mb-3 px-2" v-on:click="toOrder(order.tmp_transaction_id, 'gojek')" v-for="order in gojekOrder" :key="order.tmp_transaction_id">
                                <a href="#"  class="card card-meja h-100 text-decoration-none">
                                    <div class="card-body align-self-center justify-content-center text-center"><br/> {{order.tmp_transaction_note_number}}</div>
                                    <div class="card-footer">{{order.tmp_transaction_customer_name}}</div>
                                    <div class="content-center text-center">{{ getDuration(order.tmp_transaction_order_time) }}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-header font-weight-bold">
                        <div class="row align-items-center">
                            <div class="col-auto"><button v-on:click="setupPopup('shopeefood')" class="btn btn-action" data-toggle="modal" data-target="#modal-input-nota"><i class="feather-plus"></i> ORDER</button></div>
                            <div class="col-auto"><img :src="shopee" alt="" height="30"></div>
                        </div>
                    </div>
                    <div class="card-body bg-light" style="min-height: calc((100vh - 300px) / 3);">
                        <div class="row mx-n2 row-meja">
                            <div class="col mb-3 px-2" v-on:click="toOrder(order.tmp_transaction_id, 'maxim')" v-for="order in shopeeOrder" :key="order.tmp_transaction_id">
                                <a href="#"  class="card card-meja h-100 text-decoration-none">
                                    <div class="card-body align-self-center justify-content-center text-center"><br/> {{order.tmp_transaction_note_number}}</div>
                                    <div class="card-footer">{{order.tmp_transaction_customer_name}}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-header font-weight-bold">
                        <div class="row align-items-center">
                            <div class="col-auto"><button v-on:click="setupPopup('maxim')" class="btn btn-action" data-toggle="modal" data-target="#modal-input-nota"><i class="feather-plus"></i> ORDER</button></div>
                            <div class="col-auto"><img :src="maxim" alt="" height="30"></div>
                        </div>
                    </div>
                    <div class="card-body bg-light" style="min-height: calc((100vh - 300px) / 3);">
                        <div class="row mx-n2 row-meja">
                            <div class="col mb-3 px-2" v-on:click="toOrder(order.tmp_transaction_id, 'maxim')" v-for="order in maximOrder" :key="order.tmp_transaction_id">
                                <a href="#"  class="card card-meja h-100 text-decoration-none">
                                    <div class="card-body align-self-center justify-content-center text-center"><br/> {{order.tmp_transaction_note_number}}</div>
                                    <div class="card-footer">{{order.tmp_transaction_customer_name}}</div>
                                    <div class="content-center text-center">{{ getDuration(order.tmp_transaction_order_time) }}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Modal Input Nota by staff-->
        <div class="modal fade" id="modal-input-nota">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Input Info Nota</h5>
                        <button id="closeModalInformation" v-on:click="loadTransactions()" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="form" id="transactionTableSubmitData" @submit.prevent="transactionTableSubmit" method="post">
                        <div class="modal-body">
                            <div class="mb-4"><img :src="selectedTypeLogo" alt="" height="20"></div>
                            <div class="row">
                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <input id="table-type-transaction" name="tmp_transaction_m_t_t_name" type="hidden"/>
                                        <label class="col-form-label col px-1" for="">
                                            Nomor Nota
                                        </label>
                                        <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">{{ prefixNota }}</span>
                                        </div>
                                        <input id="tmp_transaction_note_number" name="tmp_transaction_note_number" type="text" class="form-control keyboard" aria-label="nota" aria-describedby="basic-addon1" required minlength="3">
                                        </div>
                                        <!-- <input id="tmp_transaction_note_number" name="tmp_transaction_note_number" type="text" class="form-control keyboard" autocomplete="off" required minlength="4"> -->
                                    </div>
                                    
                                    <div class="form-group">
                                        <label for="">Nama Bigboss</label>
                                        <input type="text" class="form-control keyboard"  name="tmp_transaction_customer_name" autocomplete="off" required>
                                    </div>
                                    
                                </div>

                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <!-- <div class="form-group">
                                        <label class="col-form-label col px-1" for="tableSelectedMerge">Staff</label>
                                        <select name="tmp_transaction_created_by" id="tableStaff" class="form-control select2" required>
                                        </select>
                                    </div> -->
                                    <div class="row mx-n2">
                                        <div class="col-5 px-2">
                                            <div class="form-group">
                                                <label class="col-form-label col px-1" for="">Jam</label>
                                                <input id="input_time" type="time" class="form-control timepicki" name="tmp_transaction_order_time" required>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" v-on:click="loadTransactions()" class="btn btn-light" data-dismiss="modal">Batal</button>
                            <button type="submit" class="btn btn-accent" >Proses</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="js">
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
// import { resetOjolStatus } from '@/mixins/app'
import Nav from '@/components/secure/dashboard/Nav.vue'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const gojek = '/assets/images/ojol/gojek.png'
const grab = '/assets/images/ojol/grab.png'
const maxim = '/assets/images/ojol/maxim.png'
const shopee = '/assets/images/ojol/shopee-food.png'
const grabmart = '/assets/images/ojol/grab-mart.png'

export default {
  name: 'OnlineOrder',
  components: {
    Nav,
  },
  data() {
    return {
      selectedTypeLogo: {},
      listTransactions: [],
      allStaff: [],
      grabOrder: [],
      grabMartOrder: [],
      gojekOrder: [],
      maximOrder: [],
      shopeeOrder: [],
      prefixNota:''
    }
  },
  mounted() {
    document.getElementById("onlineOrderNavigation").classList.add("active");
    window.setTimeout( "loadingComponentOrder()" ,750);

    this.loadTransactions()
    // this.$store.dispatch('order/setVendorName', '')
    // this.$store.dispatch('order/setIsOnlineOrder', false)
  },
  methods: {
    loadTransactions() {
      axios.get('/tmp-transaction?ojol=true').then(res => {
        const grabs = []
        const grabmarts = []
        const gojeks = []
        const maxims = []
        const shopee = []

        res.data.forEach(function(e)  {
            if (e.transaction_name == 'grab') {
                grabs.push(e)
            } else if (e.transaction_name == 'gojek') {
                gojeks.push(e)
            } else if (e.transaction_name == 'maxim') {
                maxims.push(e)
            } else if (e.transaction_name == 'shopeefood') {
                shopee.push(e)
            }else if (e.transaction_name == 'grabmart') {
                grabmarts.push(e)
            }
        });

        this.grabOrder = grabs
        this.grabMartOrder = grabmarts
        this.gojekOrder = gojeks
        this.maximOrder = maxims
        this.shopeeOrder = shopee

        // this.loadStaff()
      }).catch ((err) => {
        console.log(err)
      })
    },
    // loadStaff() {
    //   axios.get('/users').then(res => {                    
    //       this.allStaff = res.data
    //   }).catch ((err) => {
    //       console.log(err);
    //   })
    // },
    setupPopup(type) {
        let now = moment().format('HH:mm:ss')
        now = now.replaceAll(':', '')
        switch (type) {
            case 'gojek':
                this.selectedTypeLogo = gojek;
                this.prefixNota = 'G-';
                break;
            case 'grab':
                this.selectedTypeLogo = grab;
                this.prefixNota = 'GF-';
                break;
            case 'maxim':
                this.selectedTypeLogo = maxim;
                this.prefixNota = 'MX-';
                break;
            case 'shopeefood':
              this.selectedTypeLogo = shopee;
              this.prefixNota = 'SF-';
              break;
            case 'grabmart':
              this.selectedTypeLogo = grabmart;
              this.prefixNota = 'GM-';
              break;
            default:
                break;
        }   

        // const tableStaff = document.getElementById('tableStaff')
        // const staffs = this.allStaff

        // let staffHtml = "<option disabled selected>Pilih Karyawan</option>"
        // staffs.forEach(function(e) {
        //     const html = "<option value="+e.id+">"+e.name.toUpperCase()+"</option>"
        //     staffHtml+=html
        // });

        // tableStaff.innerHTML = staffHtml 

        document.getElementById('table-type-transaction').value = type
        // document.getElementById('tmp_transaction_note_number').value = this.prefixNota

        const tanggal = new Date();
        
        const jam = ('0'+tanggal.getHours()).substr(-2);
        const menit = ('0'+tanggal.getMinutes()).substr(-2);

        document.getElementById("input_time").defaultValue = jam+":"+menit;
        
    },
    transactionTableSubmit: function(e) {
        e.preventDefault()
        const prefix = this.prefixNota
        const formIdTransaction = document.getElementById('transactionTableSubmitData')
        const formSubmitTableTransaction = new FormData(formIdTransaction)
        const type = formSubmitTableTransaction.get('tmp_transaction_m_t_t_name')
        const notaNumber = formSubmitTableTransaction.get('tmp_transaction_note_number')
        formSubmitTableTransaction.delete('tmp_transaction_note_number')
        formSubmitTableTransaction.append('tmp_transaction_note_number', prefix+notaNumber)
    //   let vendorName = ''
      
      // formSubmitTableTransaction.append('tmp_transaction_table_list[]', "")
    //   const notaNumber = formSubmitTableTransaction.get('tmp_transaction_note_number')
    //   if (type == 'Grab') {
        // formSubmitTableTransaction.delete('tmp_transaction_note_number')
        // formSubmitTableTransaction.append('tmp_transaction_note_number', 'GF-'+notaNumber)
        // vendorName = 'grab'
      
    //   } else if (type == 'Gojek') {
        // formSubmitTableTransaction.delete('tmp_transaction_note_number')
        // formSubmitTableTransaction.append('tmp_transaction_note_number', 'G-'+notaNumber)
        // vendorName = 'gojek'
      
    //   } else if (type == 'Maxim') {
        // formSubmitTableTransaction.delete('tmp_transaction_note_number')
        // formSubmitTableTransaction.append('tmp_transaction_note_number', 'M-'+notaNumber)
        // vendorName = 'maxim'
      
    //   } else if (type == 'Shopee Food') {
        // formSubmitTableTransaction.delete('tmp_transaction_note_number')
        // formSubmitTableTransaction.append('tmp_transaction_note_number', 'SF-'+notaNumber)
        // vendorName = 'shopee'
    //   }else if (type == 'Shopee Food') {
        // formSubmitTableTransaction.delete('tmp_transaction_note_number')
        // formSubmitTableTransaction.append('tmp_transaction_note_number', 'SF-'+notaNumber)
    //     vendorName = 'shopee'
    //   }


    //   for (const pair of formSubmitTableTransaction.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]); 
    //   }

      axios.post('/tmp-transaction', formSubmitTableTransaction)
      .then(res => {
        if (res.data.meta.code == 200) {
          formIdTransaction.reset();
          document.getElementById('closeModalInformation').click();
          this.$router.push({ name: 'orderMenu', params: { transactionId: res.data.data.tmp_transaction_id} })
        //   this.$store.dispatch('order/setVendorName', type)
        //   this.$store.dispatch('order/setIsOnlineOrder', true)

        } else {
          this.$swal.fire({
            text: res.data.meta.message, 
            icon:"warning",
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Iya'
          })
        }
      }).catch ((err) => {
          console.log(err.response.data);
      })
    },
    toOrder(id, name) {
      this.$router.push({ name: 'orderMenu', params: { transactionId: id} })
    //   this.$store.dispatch('order/setVendorName', name)
    //   this.$store.dispatch('order/setIsOnlineOrder', true)
    },
    getDuration(time){
      const nowDate = moment().format('YYYY-MM-DD').toString()
      const nowDateTime = moment().format('YYYY-MM-DD HH:mm:ss').toString()

      const start = moment(nowDate+" "+time)
      const end = moment(nowDateTime);
      
      const diff = end.diff(start,"minutes");
      let dur = moment.duration(diff, "minutes").abs().format("h [h] m [m]"); //"2 hrs, 3 mins"
      if (diff < 60){
        dur = moment.duration(diff, "minutes").abs().format("m [mins]");
      }

      return dur
    },
  },
  setup() {
    const user = ref(null);
    const router = useRouter();
    const gojek = '/assets/images/ojol/gojek.png'
    const grab = '/assets/images/ojol/grab.png'
    const maxim = '/assets/images/ojol/maxim.png'
    const shopee = '/assets/images/ojol/shopee-food.png'
    const grabmart = '/assets/images/ojol/grab-mart.png'
    
    // resetOjolStatus()
    onMounted( async () => {
      try {
        const response = await axios.get('/user');
        user.value = response.data.data;
      } catch (e) {
        await router.push({ name: 'loginPage' });
      }
    });

    return {
      user,
      gojek,
      grab,
      maxim,
      shopee,
      grabmart
    }
  },
}
</script>
