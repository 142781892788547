
// TODO: Tambah pagination
//
import { defineComponent, ref, onMounted, computed, reactive } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'RecapFreeReturn',
  setup() {
    const store = useStore()
    const freeReturn = ref([] as any)
    const isEmpty = ref(true)
    const total = computed(() => {
      if (freeReturn.value.length == 0) {
        return 0
      }

      return freeReturn.value.reduce((acc: number, val: any) => {
        acc += (val.nominal_free_kembalian + val.nominal_free_kembalian_void)
        return acc
      }, 0)
    })

    onMounted(() => {
      axios.get('cashier/free-data').then(resp => {
        store.dispatch('setFreeReturn', resp.data)
        freeReturn.value = resp.data.result
        if (resp.data.error == 0) {
          isEmpty.value = false
        }
      })
      .catch(err => console.log)
    })

    const subTotal = (x: number, y: number) => x + y

    return {
      freeReturn,
      isEmpty,
      subTotal,
      total
    }
  }
})
