<template>
    <div>
        <Nav></Nav>

        <section class="py-4 section-app">
            <div class="container-fluid">
                <div class="row mx-n2" >
                    
                    <div class="col-lg-6 px-2">
                        <div class="card mt-2">
                            <div class="table-responsive" style="max-height: calc(100vh - 182px); height: 100vh">
                                <table class="table table-menu m-0">
                                    <thead class="bg-dark text-white">
                                        <tr>
                                            <th>No</th>
                                            <th class="text-center">Waktu</th>
                                            <th class="text-center">Nomor Nota</th>
                                            <th class="text-center">Big Boss</th>
                                            <th class="text-center">Total</th>
                                            <th width="120" class="text-center">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        <tr :class="getRowClass(history.m_transaction_table_id)" 
                                          v-for="(history, index) in histories" :key="index">
                                            <td>{{ index + 1 }}</td>
                                            <td>
                                                {{history.r_t_jam}}
                                            </td>
                                            <td class="text-center">
                                                {{history.r_t_nota_code}}
                                            </td>
                                            <td class="text-center">
                                                {{history.r_t_bigboss}}
                                            </td>
                                            <td class="text-center">
                                                {{currency(history.r_t_nominal_total_bayar,'')}}
                                            </td>
                                            <td class="text-center">
                                                <button class="btn btn-primary font-weight-bold btn-sm" type="button" v-on:click="loadHistoryDetail(history.r_t_id)">Lihat Detail</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    
                    <div class="col-lg-6 mt-4 mt-lg-0 px-2">
                      <div class="card mt-2" v-if="!historyDetail">
                        <div class="table-responsive" style="max-height: calc(100vh - 182px); height: 100vh">
                          <div class="px-5 py-5">Pilih transaksi detil!</div>
                        </div>
                      </div>

                        <div v-if="historyDetail" class="card bg-light">
                            <div class="card-body p-3">
                                <div class="row mx-n2">
                                    <div class="col-lg-6 px-2">
                                        <table width="100%">
                                            <tr>
                                                <td>Nomor Nota</td>
                                                <td>:</td>
                                                <td><strong>{{ historyDetail.transaction.r_t_nota_code }}</strong></td>
                                            </tr>
                                            <tr>
                                                <td width="100">Nama Bigboss</td>
                                                <td width="10">:</td>
                                                <td><strong>{{ historyDetail.transaction.r_t_bigboss }}</strong></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-lg-6 px-2">
                                        <table width="100%">
                                            
                                            <tr>
                                                <td>Tanggal</td>
                                                <td>:</td>
                                                <td><strong>{{ historyDetail.transaction.r_t_tanggal }}</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Jam</td>
                                                <td>:</td>
                                                <td><strong>{{ historyDetail.transaction.r_t_jam }}</strong></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="historyDetail" class="card mt-2">
                            <div class="table-responsive" style="max-height: calc(100vh - 350px); height: 100vh">
                                <table class="table table-menu m-0">
                                    <thead class="bg-dark text-white">
                                        <tr>
                                            <th>No</th>
                                            <th>Nama Menu</th>
                                            <th class="text-center">Qty</th>
                                            <th class="text-center">Harga</th>
                                            <th class="text-center">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="menuJenis in historyDetail.menus" :key="menuJenis.id">
                                        
                                        <tr>
                                            <td colspan="7" class="bg-accent text-white">{{menuJenis.name}}</td>
                                        </tr>
                                        <tr v-for="(listMenus, index) in menuJenis.items" :key="listMenus.r_t_detail_id" >
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ listMenus.m_produk_nama }}</td>
                                            <td class="text-center">
                                              
                                              {{ listMenus.r_t_detail_qty }}
                                              <template v-if="listMenus.r_t_detail_qty_qr > 0">
                                                <sup v-if="listMenus.r_t_detail_qty_qr > listMenus.r_t_detail_qty" class="text-danger">-{{ listMenus.r_t_detail_qty_qr-listMenus.r_t_detail_qty }}</sup>
                                              </template>
                                              <template v-if="listMenus.r_t_detail_qty_qr > 0">
                                              <sup v-if="listMenus.r_t_detail_qty_qr < listMenus.r_t_detail_qty" class="text-success">+{{ listMenus.r_t_detail_qty-listMenus.r_t_detail_qty_qr }}</sup>
                                              </template>
                                            </td>
                                            <td class="text-center">{{ currency(listMenus.r_t_detail_price,'') }}</td>
                                            <td class="text-center">{{ currency(listMenus.r_t_detail_nominal,'') }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div v-if="historyDetail" class="card mt-2">
                            <table class="table table-menu table-condensed m-0">
                                <tr>
                                    <td class="text-right"><strong>Total</strong></td>
                                    <td width="5" class="px-0"><strong>:</strong></td>
                                    <td width="10"><strong>Rp</strong></td>
                                    <td class="text-right text-danger" width="150">{{ currency(historyDetail.transaction.r_t_nominal,'') }}</td>
                                    <td width="10"></td>
                                </tr>
                                
                                <tr>
                                    <td class="text-right"><strong>Pajak Resto</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>Rp</strong></td>
                                    <td class="text-right text-danger" width="150">{{ currency(historyDetail.transaction.r_t_nominal_pajak,'') }}</td>
                                </tr>

                                <tr v-if="historyDetail.transaction.r_t_nominal_sc > 0">
                                    <td class="text-right"><strong>Service Charge</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>Rp</strong></td>
                                    <td class="text-right text-danger" width="150">{{ currency(historyDetail.transaction.r_t_nominal_sc,'') }}</td>
                                </tr>
                                <tr v-if="historyDetail.transaction.r_t_nominal_diskon > 0">
                                    <td class="text-right"><strong>Diskon</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>Rp</strong></td>
                                    <td class="text-right text-danger" width="150">{{ currency(historyDetail.transaction.r_t_nominal_diskon,'') }}</td>
                                </tr>
                                <tr v-if="historyDetail.transaction.r_t_nominal_voucher > 0">
                                    <td class="text-right"><strong>Voucher</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>Rp</strong></td>
                                    <td class="text-right text-danger" width="150">{{ currency(historyDetail.transaction.r_t_nominal_voucher,'') }}</td>
                                </tr>

                                <tr>
                                    <td class="text-right"><strong>Total Bayar</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>Rp</strong></td>
                                    <td class="text-right text-danger" width="150">{{ currency(historyDetail.transaction.r_t_nominal_total_bayar,'') }}</td>
                                </tr>
                                <tr v-if="historyDetail.transaction.r_t_nominal_pembulatan > 0">
                                    <td class="text-right"><strong>Pembulatan</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>Rp</strong></td>
                                    <td class="text-right text-danger" width="150">{{ currency(historyDetail.transaction.r_t_nominal_pembulatan,'') }}</td>
                                    <td class="p-0 px-2"></td>
                                </tr>
                                <tr v-if="historyDetail.transaction.r_t_nominal_free_kembalian > 0">
                                    <td class="text-right"><strong>Free Kembalian</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>Rp</strong></td>
                                    <td class="text-right text-danger" width="150">{{ currency(historyDetail.transaction.r_t_nominal_free_kembalian,'') }}</td>
                                    <td class="p-0 px-2"></td>
                                </tr>
                            </table>
                        </div>

                        <div v-if="historyDetail" class="card mt-2">
                            <div class="card-body p-2">
                                <div class="row mx-n1 justify-content-center">
                                    <div class="col-auto px-1 mb-1">
                                      <button class="btn btn-primary font-weight-bold btn-sm"
                                        @click="printStruk(historyDetail.transaction.r_t_id)" type="button" >
                                        Print Ulang
                                      </button>
                                    </div>
                                    <div class="col-auto px-1 mb-1">
                                      <button class="btn btn-success font-weight-bold btn-sm"
                                        @click="kirimUlang()" type="button" >
                                        Kirim Ulang
                                      </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </section>
        <section>
          <button id="show-modal-struk-digital" data-toggle="modal" data-target="#modal-struk-digital"/>
          <div class="modal fade" id="modal-struk-digital">
              <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                      <div class="modal-header bg-accent text-white">
                          <h6 class="modal-title">Kirim Struk Digital</h6>
                          <button id="close-modal-struk-digital" type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                              <span class="sr-only">Close</span>
                          </button>
                      </div>
                      <div class="modal-body">
                          
                          <div class="form-group">
                              <label for="">Nomor Whatsapp</label>
                              <div class="row mx-n1">
                                  <div class="col px-1">
                                      <input v-model="nomorwa" type="text" class="form-control numeric">
                                  </div>
                                  
                                      <div class="col-auto px-1">
                                          
                                          <button v-on:click="sendStruct()" class="btn btn-primary btn-confirm-cash">Kirim</button>
                                      </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </div>
</template>

<script lang="js" >
import { onMounted, ref, onErrorCaptured } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import Nav from '@/components/secure/dashboard/Nav.vue'
import moment from 'moment'
import '@formatjs/intl-numberformat';

export default {
  name: "History",
  components: {
      Nav
  },
  data() {
    return{
      menuJenis: [],
      histories: [],
      historyDetail: null,
      selectedTransId: '',
      nomorwa: '',
    }
  },
  mounted() {
    document.getElementById("historyNavigation").classList.add("active");
    this.load()
  },
  methods: {
    kirimUlang(){
      this.nomorwa = ''
      document.getElementById('show-modal-struk-digital').click()
    },
    cronjobWa(target, transId){
      axios.get(`/cashier/cronjob-wa/${target}/${transId}`).then(resp => {
          console.log(resp)
        }).catch ((err) => {
          if (err.response) {
            console.log(err.response.data)
          }
        });
    },
    sendStruct(){
      const wa = this.nomorwa.replace ( /[^0-9]/g, '' )
      const idTrans = this.selectedTransId
      if (wa != ''){
        this.$swal.fire({
          // title: 'Mencetak!',
          html: 'Mengirim Pesan Ke '+wa,
          timer: 3000,
          timerProgressBar: true,
          didOpen: () => {
            this.$swal.showLoading()
            this.cronjobWa(wa,idTrans)
          }
        }).then((result)=>{
          document.getElementById('close-modal-struk-digital').click()
        })
      }
    },
    printStruk(id){
      this.$swal.fire({
        // title: 'Mencetak!',
        html: 'Mencetak Struk',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
          axios.get(`/cashier/print-transaksi/${id}/1`).then(resp => {
            console.log(resp.data)
            if(resp.data.error == 0){
              if(resp.data.struct != ''){
                location.href = resp.data.struct
              }
            }
          }).catch ((err) => {
            if (err.response) {
              console.log(err.response.data)
            }
          })
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === this.$swal.DismissReason.timer) {
          console.log('printing success')
        }
      })
    },
    getRowClass(id) {
      let classVal = 'py-1'
      if (this.selectedTransId === id) {
        classVal = 'py-1 table-active'
      }
      return classVal
    },
    currency(price,prefix){
        const nominal = parseFloat(price);
        const rupiah = new Intl.NumberFormat("id-ID", {
            style: 'decimal', //decimal,currency,percent
            currency: 'IDR',
        }).format(nominal);
        return prefix+' '+rupiah;
    },
    load() {
      axios.get('/m-menu-jenis').then(res => {
        this.menuJenis = res.data
        this.loadHistory()
      }).catch ((err) => {
        console.log(err);
      })
    },
    loadHistory(){
      axios.get('/transaksi/history').then(res => {
        res.data.data.forEach(function (history)  {
          history.time = moment(new Date(history.r_t_jam)).format('HH:mm')
        });
        this.histories = res.data.data

      }).catch ((err) => {
          console.log(err);
      })
    },
    loadHistoryDetail(id) {
      this.selectedTransId = id
      axios.get('/transaksi/history-detail/'+id)
      .then(res => {
        const historyDetail = res.data.data
        const tempMenuJenis = []

        historyDetail.menus.forEach(function(e) {
          if (tempMenuJenis.indexOf(e.m_produk_m_jenis_produk_id) === -1) {
            tempMenuJenis.push(e.m_produk_m_jenis_produk_id)
          }
        });

        const menuJenis = this.menuJenis
        const menus = historyDetail.menus
        const tempCart = []
        tempMenuJenis.forEach(function(e) {
          const tempMenuList = []
          let name
          menuJenis.forEach(function (item) {
            if (item.m_jenis_produk_id == e) {
              name = item.m_jenis_produk_nama
            }
          });
            
          menus.forEach(function (item) {
            if (e == item.m_produk_m_jenis_produk_id) {
              tempMenuList.push(item)
            }                                
          });

          const menuOnCart = {
            id: e,
            name: name,
            items: tempMenuList
          }
          tempCart.push(menuOnCart)  
        });
        historyDetail.transaction.r_t_tanggal = moment(new Date(historyDetail.transaction.r_t_tanggal)).format('DD/MM/YYYY')
        historyDetail.menus = tempCart
        this.historyDetail = historyDetail
        if (historyDetail.transaction.r_t_member_id && historyDetail.transaction.r_t_member_id.split('.').length == 1) {
          this.nomorwa = historyDetail.transaction.r_t_member_id
        }

      }).catch (err => {
        console.log(err);
      })
    }
  },
  setup() {
    const user = ref(null);
    const router = useRouter();
    const error = ref(null)

    onErrorCaptured(e => {
      error.value = e
      return true
    })

    onMounted( async () => {
      try {
          const response = await axios.get('/user');
          user.value = response.data.data;
      } catch (e) {
          await router.push({ name: 'loginPage' });
      }
    });

    return {
      user,
      error
    }
  }
}
</script>
