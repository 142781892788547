import axios from 'axios'
import { Payment, SpendingResult, Promo } from './shift_type'

export const useShift = () => {
  const token = localStorage.getItem('token') || ''

  const getModal = async (): Promise<object> => {
    const resp = await axios.get('/m-modal/get-modal')
    let result = {}
    result = resp.data

    return result
  }

  const getSpending = async (): Promise<SpendingResult> => {
    const resp = await axios.get('/cashier/spending')
    const result: SpendingResult = {
      spending: resp.data.spending,
      total: parseFloat(resp.data.total)
    }

    return result
  }

  const getPayment = async (): Promise<Payment> => {
    const resp = await axios.get('/cashier/payment')
    const result: Payment = {
      paymentDetail: resp.data.paymentDetail,
      cashTotal: parseFloat(resp.data.cashTotal),
      nonTunaiTotal: parseFloat(resp.data.nonTunaiTotal),
      edcTotal: parseFloat(resp.data.edcTotal)
    }

    return result
  }

  const getPromo = async (): Promise<Promo> => {
    const resp = await axios.get('/cashier/promo')
    const promo = resp.data
    const result: Promo = {
      gratis: promo.gratis,
      voucher: promo.voucher,
      diskon: promo.diskon,
      pembulatan: promo.pembulatan,
      free_kembalian: promo.free_kembalian,
      free_kembalian_void: promo.free_kembalian_void,
      nominal: promo.nominal,
      pajak: promo.pajak,
      service: promo.service,
      void_total: promo.void_total,
      diskon_val: promo.diskon_val,
      tarik_tunai: promo.tarik_tunai,
      total_lost_bills: promo.total_lost_bills,
      total_uang_tips: promo.total_uang_tips,
      total_warranty: promo.total_warranty
    }

    return result
  }

  return {
    getModal,
    getSpending,
    getPayment,
    getPromo
  }
}
