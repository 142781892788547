const state = () => ({
  voucherModal: false,
  inputVoucherModal: false,
  voucherType: 0,
  voucherCode: '',
  voucherValue: 0,
  // nilai diskon dalam rupiah
  discount: 0,
  payTotal: 0
})

const getters = {
  voucherModal: (state: any, getters: any, rootState: any) => {
    return state.voucherModal
  },
  inputVoucherModal: (state: any, getters: any, rootState: any) => {
    return state.inputVoucherModal
  },
  voucherType: (state: any, getters: any, rootState: any) => {
    return state.voucherType
  },
  voucherCode: (state: any, getters: any, rootState: any) => {
    return state.voucherCode
  },
  voucherValue: (state: any, getters: any, rootState: any) => {
    return state.voucherValue
  },
  discount: (state: any, getters: any, rootState: any) => {
    return state.discount
  },
  payTotal: (state: any, getters: any, rootState: any) => {
    return state.payTotal
  }
}

const actions = {
  async showVoucherModal({ commit, state }: any) {
    commit('setVoucherModal', true)
  },
  async hideVoucherModal({ commit, state }: any) {
    commit('setVoucherModal', false)
  },
  async showInputVoucherModal({ commit, state }: any) {
    commit('setInputVoucherModal', true)
  },
  async hideInputVoucherModal({ commit, state }: any) {
    commit('setInputVoucherModal', false)
  },
  async setVoucherType({ commit, state }: any, data: number) {
    commit('setVoucherType', data)
  },
  async setVoucherCode({ commit, state }: any, data: string) {
    commit('setVoucherCode', data)
  },
  async setVoucherValue({ commit, state }: any, data: number) {
    commit('setVoucherValue', data)
  },
  async setDiscount({ commit, state }: any, data: number) {
    commit('setDiscount', data)
  },
  async setPayTotal({ commit, state }: any, data: number) {
    commit('setPayTotal', data)
  }
}

const mutations = {
  setVoucherModal(state: any, value: boolean) {
    state.voucherModal = value
  },
  setInputVoucherModal(state: any, value: boolean) {
    state.inputVoucherModal = value
  },
  setVoucherType(state: any, value: number) {
    state.voucherType = value
  },
  setVoucherCode(state: any, value: string) {
    state.voucherCode = value
  },
  setVoucherValue(state: any, value: number) {
    state.voucherValue = value
  },
  setDiscount(state: any, value: number) {
    state.discount = value
  },
  setPayTotal(state: any, value: number) {
    state.payTotal = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
