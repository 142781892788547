<template>
    <div>
        <Nav></Nav>

        <section class="py-4 section-app">
            <div class="container-fluid">
                <div class="row mx-n2">
                    <div class="col-lg-9 order-1 order-lg-0 px-2">
                        <div v-if="userRole != 'wbd'" class="card">
                            <div class="card-header font-weight-bold bg-accent text-white">MEJA</div>
                            <div class="card-body bg-light">
                                <template v-if="loadingMeja && !autoRefresh">
                                    <img :src="loadingGif" alt="" height="100" width="100" class="mr-3 mb-3"/>
                                </template>
                                <template v-else>
                                    <div class="row mx-n2 row-meja">
                                        <div class="col-sm-3 col-md-2 col-lg-2 col-xl-1 mb-3 px-2" v-for="mejaIn in listMeja" :key="mejaIn.m_meja_id">
                                            <template v-if="transactionTable[mejaIn.m_meja_id]">
                                                <a v-if="transactionTable[mejaIn.m_meja_id].dish_count!=0" href="javascript:void()" v-on:click="setupIdTransaction(transactionTable[mejaIn.m_meja_id].tmp_transaction_id, mejaIn.m_meja_id)" class="card card-meja h-100 text-decoration-none" data-toggle="modal" data-target="#modal-update-nota"  data-backdrop="static">
                                                    <div class="card-body align-self-center justify-content-center text-center"><span class="font-weight-bold text-warning" style="font-size: 13px;">{{mejaIn.m_meja_nama}}</span> <br/> {{transactionTable[mejaIn.m_meja_id].tmp_transaction_note_number}}</div>
                                                    <div class="card-footer font-weight-bold">{{transactionTable[mejaIn.m_meja_id].tmp_transaction_customer_name}}</div>
                                                    <div class="content-center text-center">{{ getDuration(transactionTable[mejaIn.m_meja_id].tmp_transaction_order_time) }}</div>
                                                </a>
                                                <a v-else href="javascript:void()" v-on:click="setupIdTransaction(transactionTable[mejaIn.m_meja_id].tmp_transaction_id, mejaIn.m_meja_id)" class="card card-meja draft h-100 text-decoration-none" data-toggle="modal" data-target="#modal-update-nota"  data-backdrop="static">
                                                    <div class="card-body align-self-center justify-content-center text-center"><span class="font-weight-bold text-warning" style="font-size: 13px;">{{mejaIn.m_meja_nama}}</span> <br/> {{transactionTable[mejaIn.m_meja_id].tmp_transaction_note_number}}</div>
                                                    <div class="card-footer  font-weight-bold">{{transactionTable[mejaIn.m_meja_id].tmp_transaction_customer_name}}</div>
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a href="javascript:void()" v-on:click="tableNota(mejaIn.m_meja_id)" class="card card-menu table h-100 text-decoration-none" data-toggle="modal" data-target="#modal-input-nota" data-backdrop="static">
                                                    <div class="card-body align-self-center justify-content-center text-center"><span class="font-weight-bold text-warning" style="font-size: 13px;">{{mejaIn.m_meja_nama}}</span><br/>&nbsp;</div>
                                                    <div class="card-footer">&nbsp;</div>
                                                </a>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="card mt-3">
                            <div class="card-header font-weight-bold bg-accent text-white">
                                <div class="row align-items-center">
                                    <div class="col-auto">BUNGKUS</div>
                                </div>
                            </div>
                            <div class="card-body bg-light">
                                <div class="row mx-n2 row-meja">
                                    <div class="col-sm-3 col-md-2 col-lg-2 col-xl-1 mb-3 px-2" v-for="mejaIn in listBungkus" :key="mejaIn.m_meja_id">
                                            <template v-if="transactionTable[mejaIn.m_meja_id]">
                                                <a href="javascript:void()" v-on:click="toOrder(transactionTable[mejaIn.m_meja_id].tmp_transaction_id)" class="card card-meja h-100 text-decoration-none" data-toggle="modal" data-target="#modal-update-nota"  data-backdrop="static">
                                                    <div class="card-body align-self-center justify-content-center text-center"><span class="font-weight-bold text-warning" style="font-size: 13px;">{{mejaIn.m_meja_nama}}</span> <br/> {{transactionTable[mejaIn.m_meja_id].tmp_transaction_note_number}}</div>
                                                    <div class="card-footer">{{transactionTable[mejaIn.m_meja_id].tmp_transaction_customer_name}}</div>
                                                    <div class="content-center text-center">{{ getDuration(transactionTable[mejaIn.m_meja_id].tmp_transaction_order_time) }}</div>
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a href="javascript:void()" v-on:click="tableNotaBungkus(mejaIn.m_meja_id)" class="card card-menu table h-100 text-decoration-none" data-toggle="modal" data-target="#modal-input-nota-bungkus" data-backdrop="static">
                                                    <div class="card-body align-self-center justify-content-center text-center"><span class="font-weight-bold text-warning" style="font-size: 13px;">{{mejaIn.m_meja_nama}}</span><br/>&nbsp;</div>
                                                    <div class="card-footer">&nbsp;</div>
                                                </a>
                                            </template>
                                        </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-lg-3 order-2 mb-4 mb-lg-0 px-2">
                      <div class="card h-100">
                        <div class="card-header font-weight-bold">SS Express</div>
                        <div class="card-body" v-if="loadingMeja && !autoRefresh">
                          <img :src="loadingGif" alt="" height="100" width="100" class="mr-3 mb-3"/>
                        </div>
                        <div class="card-body" v-if="!loadingMeja">
                          <div class="row">
                            <div v-for="item in expressTable" :key="'meja-' + item.m_meja_id" 
                                  class="col-sm-3 col-md-3 col-lg-12 col-xl-12 mb-1 px-1">
                                <a href="javascript:void(0)" :class="expressLinkClass(item.m_meja_id)"
                                @click.prevent="showExpressModal(item)">
                                  <i class="feather-chevrons-right mr-2"></i>{{ item.m_meja_nama }}
                                </a>
                            </div>
                            <!-- <div class="col-sm-3 col-md-3 col-lg-12 col-xl-12 mb-1 px-1">
                                <a href="waroengss://print?image=http://training.api.sipedas.wss/struct/struk-ihsanuddin57.jpeg">
                                  <i class="feather-chevrons-right mr-2"></i>Coba Print
                                </a>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>

                </div>

            </div>
        </section>

        <!-- Modal Input Nota-->
        <div class="modal fade" id="modal-input-nota">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Input Info Nota Dine In</h5>
                        <button id="closeModalInformation" v-on:click="loadTable" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="form" id="transactionTableSubmitData" @submit.prevent="transactionTableSubmit" method="post">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <input id="table-type-transaction" value="dine in" name="tmp_transaction_m_t_t_name" type="hidden"/>
                                        <label class="col-form-label col px-1" >Nomor Nota
                                        <input name="tmp_transaction_note_number" type="text" 
                                          class="form-control numeric" autocomplete="off"
                                          tabindex="1"
                                          required
                                          minlength="4">
                                        </label>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label >Nama Bigboss
                                        <input type="text" class="form-control"  
                                          name="tmp_transaction_customer_name" autocomplete="off" 
                                          required 
                                          minlength="3"
                                          tabindex="2">
                                        </label>
                                    </div>
                                    <div class="row mx-n2">
                                        <div class="col-5 px-2">
                                            <div class="form-group">
                                                <label for="">Jam Order</label>
                                                <input id="input_time" class="form-control timepicki" name="tmp_transaction_order_time" 
                                                tabindex="4" required>
                                            </div>
                                        </div>
                                        <div class="col-5 px-2">
                                            <div class="form-group">
                                                <label for="">Jam Menu Lengkap</label>
                                                <input id="input_time_menu" class="form-control timepicki" name="tmp_transaction_kitchen_done_time" 
                                                tabindex="4">
                                                <span>*Optional</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="tableSelected">Meja</label>
                                        <select name="tmp_transaction_table_list[]" id="tableSelected" 
                                          class="form-control" readonly required tabindex="3">
                                            <option
                                                v-for="mejaIn in listMeja" :key="mejaIn.m_meja_id" 
                                                :value="mejaIn.m_meja_id"
                                            >
                                                {{mejaIn.m_meja_nama}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="tableSelectedMerge">Gabungkan Meja</label>
                                        <select name="tmp_transaction_table_list[]" id="tableSelectedMerge" class="form-control select2" multiple>
                                            <template v-for="mejaIn in listMeja">
                                                <option v-if="!transactionTable[mejaIn.m_meja_id]" :key="mejaIn.m_meja_id" :value="mejaIn.m_meja_id" >
                                                    {{mejaIn.m_meja_nama}}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="member">ID Member / NIK</label>
                                        <input type="text" class="form-control keyboard"  
                                          name="tmp_transaction_member_id" autocomplete="off" minlength="5">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" v-on:click="loadTable" class="btn btn-light" data-dismiss="modal" tabindex="5">Batal</button>
                            <button type="submit" class="btn btn-accent" tabindex="6">Proses</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modal-input-nota-bungkus">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Input Info Nota Bungkus</h5>
                        <button id="closeModalInformationBungkus" v-on:click="loadTable" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="form" id="transactionTableSubmitDataBungkus" @submit.prevent="transactionTableSubmitBungkus" method="post">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <input value="take away" name="tmp_transaction_m_t_t_name" type="hidden"/>
                                        <label class="col-form-label col px-1" for="">Nomor Nota</label>
                                        <input name="tmp_transaction_note_number" type="text" 
                                              class="form-control numeric" 
                                              minlength="4"
                                              required
                                              tabindex="1" autocomplete="off" ref="nota_number">
                                    </div>
                                    
                                    <div class="form-group">
                                        <label for="">Nama Bigboss</label>
                                        <input type="text" class="form-control"  name="tmp_transaction_customer_name" autocomplete="off" required minlength="3" tabindex="2" >
                                    </div>
                                    <div class="row mx-n2">
                                        <div class="col-5 px-2">
                                            <div class="form-group">
                                                <label for="">Jam Order</label>
                                                <input id="input_time_bungkus" class="form-control timepicki" name="tmp_transaction_order_time" required tabindex="4" >
                                            </div>
                                        </div>
                                        <div class="col-5 px-2">
                                            <div class="form-group">
                                                <label for="">Jam Menu Lengkap</label>
                                                <input id="input_time_menu_bungkus" class="form-control timepicki" name="tmp_transaction_kitchen_done_time" 
                                                tabindex="4">
                                                <span>*Optional</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="tableSelected">Meja</label>
                                        <select name="tmp_transaction_table_list[]" id="tableSelectedBungkus" class="form-control" readonly required tabindex="5" >
                                            <option
                                                v-for="mejaIn in listBungkus" :key="mejaIn.m_meja_id" 
                                                :value="mejaIn.m_meja_id"
                                            >
                                                {{mejaIn.m_meja_nama}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="member">ID Member / NIK</label>
                                        <input type="text" class="form-control keyboard"  
                                          name="tmp_transaction_member_id" autocomplete="off" minlength="5">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" v-on:click="loadTable" class="btn btn-light" data-dismiss="modal" tabindex="7" >Batal</button>
                            <button type="submit" class="btn btn-accent" tabindex="6" >Proses</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal Update Nota-->
        <div class="modal fade" id="modal-update-nota">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Info :</h5>
                        <button id="closeModalInformationUpdate" v-on:click="loadTable" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <button v-on:click="transactionNota(idTransaction)"  class="btn btn-primary btn-lg form-control">Lihat Transaksi</button>
                                </div>
                                <div class="col-md-6"> 
                                    <button v-on:click="updateNota(idTransaction)" class="btn btn-warning btn-lg form-control" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#modal-update-transaction-nota" data-backdrop="static" >Ubah Informasi Nota</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Updated Nota-->
        <div class="modal fade" id="modal-update-transaction-nota">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Update Info Nota</h5>
                        <button id="closeModalInformationUpdateForm" v-on:click="loadTable" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="form" id="transactionTableUpdateData" @submit.prevent="transactionTableUpdate" method="post">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <label for="">Nomor Nota</label>
                                        <input type="hidden" name="_method" value="PUT">
                                        <input type="hidden" name="tmp_transaction_id" id="update_tmp_transaction_id" required readonly>
                                        <input type="text" class="form-control numeric" name="tmp_transaction_note_number" 
                                          id="update_tmp_transaction_note_number" required readonly
                                          tabindex="1" minlength="4" ref="nota_number">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Nama Bigboss</label>
                                        <input type="text" class="form-control"  name="tmp_transaction_customer_name" 
                                          id="update_tmp_transaction_customer_name" required
                                          tabindex="2" minlength="3">
                                    </div>
                                    <div class="row mx-n2">
                                        <div class="col-5 px-2">
                                            <div class="form-group">
                                                <label for="">Jam Order</label>
                                                <input class="form-control timepicki" 
                                                  name="tmp_transaction_order_time" id="update_tmp_transaction_order_time" required
                                                  tabindex="4">
                                            </div>
                                        </div>
                                        <div class="col-5 px-2">
                                          <div class="form-group">
                                              <label for="">Jam Menu Lengkap</label>
                                              <input id="update_tmp_transaction_kitchen_done_time" class="form-control timepicki" name="tmp_transaction_kitchen_done_time" 
                                              tabindex="4">
                                              <span>*Optional</span>
                                          </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="update_tableSelected">Meja</label>
                                        <select name="tmp_transaction_table_list[]" id="update_tableSelected" class="form-control option" required>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="tableSelectedMerge">Gabungkan Meja</label>
                                        <select name="tmp_transaction_table_list[]" id="update_tableSelectedMerge" 
                                          class="form-control select2" multiple tabindex="3">
                                            <template 
                                                v-for="mejaIn in listMeja" 
                                            >
                                                <option v-if="true" :key="mejaIn.m_meja_id" :value="mejaIn.m_meja_id" >
                                                    {{mejaIn.m_meja_nama}}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="member">ID Member / NIK</label>
                                        <input type="text" class="form-control keyboard"
                                        id="update_tmp_transaction_member_id"  
                                          name="tmp_transaction_member_id" autocomplete="off" minlength="5">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" v-on:click="loadTable" class="btn btn-light" 
                              data-dismiss="modal" tabindex="5">Batal</button>
                            <button type="submit" class="btn btn-accent" tabindex="6">Proses</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>

    <Modal title="Anda Yakin?" :bodyText="cmBodyText" modalSize="medium" 
      @on-confirm="createExpressOrder"
      @on-hide="onExpressModalHide" 
      :modalShow="isExpressModalVisible" />
</template>

<script lang="js" >
import {onMounted, ref} from 'vue';
// import axios from '@/mixins/axios'
import axios from 'axios'
import { useRouter } from 'vue-router';
import { mapGetters } from 'vuex'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);
import Nav from '@/components/secure/dashboard/Nav.vue'
import Modal from '@/components/secure/Modal.vue'
import { resetOjolStatus, loadData, sleep, AUTO_REFRESH_DURATION } from '@/mixins/app'

const api = () => {
  const client = axios.create({ baseURL: process.env.VUE_APP_URL })
  const token = localStorage.getItem('token')
  if (token) {
    client.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    client.defaults.headers.common['Authorization'] = null
  }

  return client
}

export default {
  name: 'Dashboard',
  components: {
    Nav,
    Modal
  },
  data() {
    return{
      listMeja: [],
      listBungkus: [],
      transactionTable: [],
      idTransaction: 0,
      selectedTable: "",
      transactionTableIds: [],
      loadingMeja: false,
      // Konten confirm modal
      cmBodyText: 'Membuat transaksi',
      // Id table express order
      expressTableId: '',
      expressTable: [],
      transTableData: [],
      isExpressModalVisible: false,
      userRole: 'kasir',
    }
  },
  computed: {
    ...mapGetters({
      autoRefresh: 'dashboard/autoRefresh'
    })    
  },
  mounted() {
    // this.getDuration()
    const loadTable = this.loadTable
    // const clearStructs = this.clearStructs
    const loadActiveUserId = this.loadActiveUserId
    const getUser = this.getUser;
    const enableAutoRefresh = this.enableAutoRefresh;
    const isAutoRefreshActive = this.autoRefresh;

    this.userRole = localStorage.getItem('user_role');

    if(localStorage.getItem('user_role') == 'kasir'){
      this.clearTemp()
    }
    const loadData = this.loadData
    this.loadData().then(_resp => {

      // (function loop(fn, autoRefresh) {
      //   autoRefresh();
      //   setTimeout(function () {
      //     const startTime = new Date().getTime()
          
      //     fn().then(resp => {
      //       const endTime = new Date().getTime()
      //       const duration = endTime - startTime
      //       let extra = 0

      //       if (duration < AUTO_REFRESH_DURATION) {
      //         sleep(AUTO_REFRESH_DURATION - duration).then(_sleepResp => {
      //           extra = new Date().getTime()
      //           console.log('Duration req: ' + (extra - startTime))
      //           loop(fn, autoRefresh)
      //         })
      //       } else {
      //         loop(fn, autoRefresh)
      //       }
      //     })
      //   }, AUTO_REFRESH_DURATION)
      // })(loadData, enableAutoRefresh);

    })
    .catch(err => console.log(err))
  },
  methods: {
    loadData() {
      document.getElementById("tableSelected").style.pointerEvents = 'none';
      window.setTimeout( "loadingComponentOrder()" ,750);
      document.getElementById("listTableNavigation").classList.add("active");
      
      // const promises = [this.loadTable(), this.clearStructs()]
      const promises = [this.loadTable()]
      if (!localStorage.getItem('user_id') || localStorage.getItem('user_id') == null) {
        promises.push(this.loadActiveUserId(localStorage.getItem('user_id')))
        promises.push(this.getUser())
      }

      return Promise.all(promises)
    },
    enableAutoRefresh() {
      this.$store.dispatch('dashboard/enableAutoRefresh')
    },
    // clearStructs() {
    //   api().get('/recap-transactions/clear-structs')
    //   .then(resp => {
    //     return resp.data
    //   })
    //   .catch(err => console.log(err))
    // },
    getDuration(time){
      const nowDate = moment().format('YYYY-MM-DD').toString()
      const nowDateTime = moment().format('YYYY-MM-DD HH:mm:ss').toString()

      const start = moment(nowDate+" "+time)
      const end = moment(nowDateTime);
      
      const diff = end.diff(start,"minutes");
      
      let dur = moment.duration(diff, "minutes").abs().format("h [h] m [m]"); //"2 hrs, 3 mins"
      if (diff < 60){
        dur = moment.duration(diff, "minutes").abs().format("m [mins]");
      }
      
      return dur
    },
    clearTemp(){
      axios.get('/transaksi/clearTmp')
      .then(resp => {
        // console.log(resp.data)
      })
      .catch(err => {
        console.log(err)
      })
    },
    loadTable(){
      this.loadingMeja = false
      api().get('/m-meja').then(res => {
        this.loadingMeja = false
        const expressTable = res.data.filter(item => item.m_meja_type === 'express')
        this.expressTable = expressTable
        const tables = res.data.filter(item => item.m_meja_type !== 'express')
        const newMeja = []
        const newBungkus = []
        const mejaList = []

        for (const list in tables) {
          if (tables[list].m_meja_type == 'bungkus') {
            newBungkus.push(tables[list])
          } else {
            newMeja.push(tables[list])
          }
          mejaList.push(tables[list].m_meja_id)
        }

        for (const table of expressTable) {
          mejaList.push(table.m_meja_id)
        }

        this.listMeja = newMeja
        this.listBungkus = newBungkus
        this.loadTransactionTable(JSON.stringify(mejaList))
      }).catch ((err) => {
        console.log(err)
      })
    },
    loadTransactionTable(listMeja){
      api().get('/tmp-transaction?json_m_transaction_table_table_list='+listMeja)
      .then(res => {
        this.transactionTable = res.data //respon dari rest api dimasukan ke users
        const transTables = []
        for (const [_key, val] of Object.entries(res.data)) {
          transTables.push(val)
        }
        this.transTableData = transTables
      }).catch ((err) => {
        console.log(err)
      })
    },
    tableNota(tableId){
      api().get('/tmp-transaction/cek-meja/'+tableId).then(res => {
        if (res.data == 0) {
          this.$refs.nota_number.focus();
      
          const element = document.getElementById('tableSelected');
          this.selectedTable = tableId;
          element.value = tableId;
          const tableMerge = document.getElementById('tableSelectedMerge');
          const tempMeja = this.listMeja;

          document.getElementById('table-type-transaction').value = 'dine in'

          const tanggal = new Date();
          
          const jam = ('0'+tanggal.getHours()).substr(-2);
          const menit = ('0'+tanggal.getMinutes()).substr(-2);

          document.getElementById("input_time").defaultValue = jam+":"+menit;
          document.getElementById("input_time_menu").defaultValue = '';

          let resultHtml
          const tempSelectedTable = this.selectedTable
          const transactionTable = this.transactionTable;
          
          tempMeja.forEach(function(element) {

              const e = JSON.parse(JSON.stringify(element))
              
              let disabled
              if (e.m_meja_id == tableId) {
                  disabled = "disabled"
                  
              } else disabled = ""

              const html = "<option value="+e.m_meja_id+" "+disabled+">"+e.m_meja_nama+"</option>"
              if(!transactionTable[e.m_meja_id]) resultHtml+=html
          });

          tableMerge.innerHTML = resultHtml;
        }else{
          const refresh = this.loadData
          this.$swal.fire({
            title:"Oops!", 
            text:"Meja sudah digunakan", 
            icon:"warning",
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then(function (success) {
            if (success.isConfirmed) {
              refresh()
            }
          }) 
        }
      }).catch ((err) => {
        console.log(err);
      })
        
    },
    tableNotaBungkus(tableId){
      api().get('/tmp-transaction/cek-meja/'+tableId).then(res => {
        if (res.data == 0) {
          const element = document.getElementById('tableSelectedBungkus');
          this.selectedTable = tableId;
          element.value = tableId;
          const tempMeja = this.listBungkus;
          const tanggal = new Date();
          const jam = ('0'+tanggal.getHours()).substr(-2);
          const menit = ('0'+tanggal.getMinutes()).substr(-2);

          document.getElementById("input_time_bungkus").defaultValue = jam+":"+menit;
          document.getElementById("input_time_menu_bungkus").defaultValue = '';
        }else{
          const refresh = this.loadData
          this.$swal.fire({
            title:"Oops!", 
            text:"Meja sudah digunakan", 
            icon:"warning",
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then(function (success) {
            if (success.isConfirmed) {
              refresh()
            }
          }) 
        }
      }).catch ((err) => {
        console.log(err);
      })
    },
    transactionTableSubmit: function(e) {
      e.preventDefault();
      const formIdTransaction = document.getElementById('transactionTableSubmitData');
      const formSubmitTableTransaction = new FormData(formIdTransaction);

      api().post('/tmp-transaction', formSubmitTableTransaction).then(res => {
        if (res.data.meta.code==200) {
          formIdTransaction.reset();
          document.getElementById('closeModalInformation').click();
          this.$router.push({ name: 'orderMenu', params: { 
            transactionId: res.data.data.tmp_transaction_id
          }})
        } else if(res.data.meta.code==204) {
          this.$swal.fire({
            text:res.data.meta.message, 
            icon:"warning",
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Iya'
          })
        }

      }).catch ((err) => {
        console.log(err.response.data);
      })
    },
    transactionTableSubmitBungkus: function(e){
      e.preventDefault();
      const formIdTransaction = document.getElementById('transactionTableSubmitDataBungkus');
      const formSubmitTableTransaction = new FormData(formIdTransaction);
      api().post('/tmp-transaction', formSubmitTableTransaction).then(res => {
        if (res.data.meta.code==200) {
          formIdTransaction.reset();
          document.getElementById('closeModalInformationBungkus').click();
          this.$router.push({ name: 'orderMenu', params: { transactionId: res.data.data.tmp_transaction_id} });
        } else if(res.data.meta.code==204) {
          this.$swal.fire({
            text:res.data.meta.message, 
            icon:"warning",
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Iya'
          })
        }
          
      }).catch ((err) => {
        console.log(err.response.data);
      })
    },
    updateNota(transactionId){
      api().get('/tmp-transaction/'+transactionId).then(res => {
        document.getElementById("update_tmp_transaction_note_number").value = res.data.data.tmp_transaction_note_number
        document.getElementById("update_tmp_transaction_customer_name").value = res.data.data.tmp_transaction_customer_name
        document.getElementById("update_tmp_transaction_order_time").value = res.data.data.tmp_transaction_order_time
        document.getElementById("update_tmp_transaction_kitchen_done_time").value = res.data.data.tmp_transaction_kitchen_done_time
        document.getElementById("update_tmp_transaction_id").value = res.data.data.tmp_transaction_id
        document.getElementById("update_tmp_transaction_member_id").value = res.data.data.tmp_transaction_member_id
        const tableMerge = document.getElementById('update_tableSelectedMerge');
        const tableSelected = document.getElementById('update_tableSelected');
        const tableArray = res.data.data.tmp_transaction_table_list.replace(/ /g,"").split(',');
        const tempMeja = this.listMeja;

        let resultHtml
        let resultHtmlSelectedTable
        const tempSelectedTable = this.selectedTable
        const transactionTable = this.transactionTable;
        tempMeja.forEach(function(e) {
          let selected
          let selectedBool = false
          let selectedPrimaryTable = ""

          if (tableArray.indexOf(e.m_meja_id.toString())>=0 && e.m_meja_id != tempSelectedTable) {
            selected = "selected"
            selectedBool = true
          } else {
            selected = ""
          }

          let disabled
          if (e.m_meja_id == tempSelectedTable) {
            disabled = "disabled"
            selectedPrimaryTable = "selected"
          } else {
            disabled = ""
            selectedPrimaryTable = ""
          }

          const html = "<option value="+e.m_meja_id+" "+selected+" "+disabled+">"+e.m_meja_nama+"</option>"
          const htmlSelected = "<option value="+e.m_meja_id+" "+selectedPrimaryTable+">"+e.m_meja_nama+"</option>"
          
          if(!transactionTable[e.m_meja_id] || selectedBool){
            resultHtml+=html
          }

          if (!transactionTable[e.m_meja_id] || e.m_meja_id == tempSelectedTable) {
            resultHtmlSelectedTable+=htmlSelected
          }
        });

        tableMerge.innerHTML = resultHtml;
        tableSelected.innerHTML = resultHtmlSelectedTable;
          
      }).catch ((err) => {
        console.log(err);
      })
    },
    transactionNota(transactionId){
        api().get('/tmp-transaction/cek/'+transactionId).then(res => {
          document.getElementById('closeModalInformationUpdate').click();
          if (res.data == 1) {
            this.$router.push({ name: 'orderMenu', params: { transactionId: transactionId} });
          }else{
            const refresh = this.loadData
            this.$swal.fire({
              title:"Maaf!", 
              text:"Transaksi tidak ditemukan", 
              icon:"warning",
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            }).then(function (success) {
              if (success.isConfirmed) {
                refresh()
              }
            }) 
          }
        }).catch ((err) => {
          console.log(err);
        })
    },
    setupIdTransaction(idTransaction, tableId){
        this.idTransaction = idTransaction;
        this.selectedTable = tableId;
        const element = document.getElementById('update_tableSelected');
        element.value = tableId;
        const tableMerge = document.getElementById('update_tableSelectedMerge');

    },
    transactionTableUpdate: function(e){
      e.preventDefault();
      const formIdTransaction = document.getElementById('transactionTableUpdateData');
      const idTransactionNota = document.getElementById('update_tmp_transaction_id');
      const formSubmitTableTransaction = new FormData(formIdTransaction);
      api().post('/tmp-transaction/'+idTransactionNota.value, formSubmitTableTransaction).then(res => {
        formIdTransaction.reset();
        document.getElementById('closeModalInformationUpdateForm').click();
      }).catch ((err) => {
        console.log(err);
      })
    },
    toOrder(id){
      this.$router.push({ name: 'orderMenu', params: { transactionId: id} });    
    },
    // Simpan id user yg aktif saat ini(sedang login).
    loadActiveUserId(userId) {
      this.$store.dispatch('user/setCurrentUserId', userId);
    },
    // Get user yg aktif
    getUser() {
      api().get('/user').then(resp => {                    
        this.$store.dispatch('user/setCurrentUser', resp.data);

      }).catch ((err) => {
        console.log(err);
      })
    },
    showExpressModal(table) {
      // TODO: jika express saat ini sudah ada bawa ke halaman order
      const transaction = this.transTableData
      .filter(val => val.tmp_transaction_m_meja_id === table.m_meja_id)

      if (transaction.length > 0) {
        const transObj = { ...transaction[0] }
        
        api().get('/tmp-transaction/cek/'+transObj.tmp_transaction_id).then(res => {
          document.getElementById('closeModalInformationUpdate').click();
          if (res.data == 1) {
            this.$router.push({ name: 'orderMenu', params: { 
              transactionId: transObj.tmp_transaction_id
            }})
          }else{
            const refresh = this.loadData
            this.$swal.fire({
              title:"Maaf!", 
              text:"Transaksi tidak ditemukan", 
              icon:"warning",
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            }).then(function (success) {
              if (success.isConfirmed) {
                refresh()
              }
            }) 
          }
        }).catch ((err) => {
          console.log(err);
        })
        
      } else {
        api().get('/tmp-transaction/cek-meja/'+table.m_meja_id).then(res => {
          if (res.data == 0) {
            this.expressTableId = table.m_meja_id
            this.cmBodyText = `Membuat transaksi ${table.m_meja_nama}`
            this.isExpressModalVisible = true
          }else{
            const refresh = this.loadData
            this.$swal.fire({
              title:"Oops!", 
              text:"Meja sudah digunakan kasir lain", 
              icon:"warning",
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            }).then(function (success) {
              if (success.isConfirmed) {
                refresh()
              }
            }) 
          }
        }).catch ((err) => {
          console.log(err);
        })
      }
    },
    createExpressOrder() {
      const postData = new FormData()
      let now = moment().format('HH:mm:ss')
      now = now.replaceAll(':', '')
      postData.append('tmp_transaction_note_number', `SS-${now}`)
      postData.append('tmp_transaction_customer_name', `SS-${now}`)
      postData.append('tmp_transaction_order_time', moment().format('HH:mm:ss'))
      postData.append('tmp_transaction_table_list[]', `${this.expressTableId}`)
      postData.append('tmp_transaction_m_t_t_name', 'take away')

      api().post('/tmp-transaction', postData)
      .then(res => {
        if (res.data.meta.code == 200) {
          this.isExpressModalVisible = false
          this.$router.push({ name: 'orderMenu', params: { 
            transactionId: res.data.data.tmp_transaction_id
          }})    
        } 
        
        // else if(res.data.meta.code==204) {
        //     this.$swal.fire({
        //         text:res.data.meta.message, 
        //         icon:"warning",
        //         showCancelButton: false,
        //         confirmButtonColor: '#3085d6',
        //         confirmButtonText: 'Iya'
        //     })
        // }
      }).catch ((err) => {
        console.log(err.response.data)
      })
    },
    onExpressModalHide() {
      this.isExpressModalVisible = false
    },
    hasTransaction(tableId) {
      const transaction = Object.entries(this.transactionTable);
      return transaction.some(val => val[0] == tableId);
    },
    expressLinkClass(tableId) {
      if (this.hasTransaction(tableId)) {
        return 'btn btn-light btn-block py-4 font-weight-bold card-express'
      }
      return 'btn btn-light btn-block py-4'
    }
  },
  setup() {
    const user = ref(null);
    const router = useRouter();
    const loadingGif = "/assets/images/loading.gif";

    const userId = localStorage.getItem('user_id') == null ? '' : localStorage.getItem('user_id')
    loadData(userId)
    resetOjolStatus()

    onMounted( async () => {
      document.body.style.backgroundColor = "#FFFFFF";
      try {
        const response = await api().get('/user');
        user.value = response.data.data;
      } catch (e) {
        await router.push({ name: 'loginPage' });
      }
    });

    return {
      user,
      loadingGif
    }
  },
  // beforeRouteEnter(to, from, next) {

  //   next(vm => {
  //     api().get('/warung')
  //     .then(resp => {
  //       vm.$store.dispatch('warung/setWarung', resp.data.data[0])
  //       vm.$store.dispatch('warung/setArea', resp.data.data[0].m_area)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })

  //     const userId = localStorage.getItem('user_id')
  //     api().get('/karyawan/by-user-id/' + userId)
  //     .then(resp => {
  //       if (parseInt(resp.data.error) == 0) {
  //         vm.$store.dispatch('user/setEmployee', resp.data.result)
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  //   })
  // }
}
</script>
