
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'VoucherModal',
  props: ['modalShow'],
  emits: ['on-hide', 'on-voucher'],
  setup(props: any, context: any) {
    const modalVisible = computed(() => props.modalShow)
    const hideModal = () => {
      context.emit('on-hide')
    }

    const onVoucherClick = () => {
      hideModal()
      
      context.emit('on-voucher')
    }

    return {
      modalVisible,
      onVoucherClick,
      hideModal
    }
  }
})
