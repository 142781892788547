import axios from "axios"

const state = () => ({
  isOnlineOrder: false,
  // Nama ojol
  vendorName: ''
})

const getters = {
  isOnlineOrder: (state: any, getters: any, rootState: any) => {
    return state.isOnlineOrder
  },
  vendorName: (state: any, getters: any, rootState: any) => {
    return state.vendorName
  }
};

const actions = {
  async setIsOnlineOrder({ commit, state }: any, value: boolean) {
    commit('setIsOnlineOrder', value)
  },
  async setVendorName({ commit, state }: any, name: string) {
    commit('setVendorName', name)
  },
  async setTransactionType({ commit, state }: any, id: string) {
    axios.get('type-transactions/all')
    .then(resp => {
      const result = resp.data.data
      console.log('--setTransactionType--')
      console.log(result)
      let transactionName = ''
      result.forEach((val: any) => {
        if (val.id == id) {
          transactionName = val.name
        }
      })

      commit('setVendorName', transactionName)
      commit('setIsOnlineOrder', true)
    })
    .catch(err => {
      console.log('Error setOnlineOrderStatus: ')
      console.log(err)
    })
  }
};

const mutations = {
  setVendorName(state: any, name: string) {
    state.vendorName = name
  },
  setIsOnlineOrder(state: any, value: boolean) {
    state.isOnlineOrder = value
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
