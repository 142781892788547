<template>
    <div>
        <Nav></Nav>

        <section class="py-4 section-app">
            <div class="container-fluid">
                <div class="row mx-n2">
                    <div class="col-lg-3 mb-4 mb-lg-0 px-2">
                        <div class="card">
                            <div class="table-responsive bg-accent">
                                <div class="card-header font-weight-bold bg-accent text-light">STAF PELAYANAN</div>
                            </div>
                            <div class="tab-content" style="max-height: calc(100vh - 136.5px); height: 100vh">
                                <div class="tab-pane active">
                                    <div class="tab-content">
                                        <div class="tab-pane active p-3" id="minuman-semua" style="max-height: calc(100vh - 136.5px);overflow: auto;">
                                            <div class="row">
                                                <a v-on:click="tableNotaByStaff(staff.id)" data-toggle="modal" data-target="#modal-input-nota-by-staff" data-backdrop="static" href="javascript:void(0)" v-for="staff in allStaff" :key="staff.id" class="btn btn-success btn-block py- mx-2">{{ staff.name.toUpperCase() }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 order-1 order-lg-0 px-2">
                        <div class="card">
                            <div class="card-header font-weight-bold bg-accent text-white">MEJA</div>
                            <div class="card-body bg-light">
                                <template v-if="loadingMeja && !autoRefresh">
                                    <img :src="loadingGif" alt="" height="100" width="100" class="mr-3 mb-3"/>
                                </template>
                                <template v-else>
                                    <div class="row mx-n2 row-meja">
                                        <div class="col mb-3 px-2" v-for="mejaIn in listMeja" :key="mejaIn.m_meja_id">
                                            <template v-if="transactionTable[mejaIn.m_meja_id]">
                                                <a v-if="transactionTable[mejaIn.m_meja_id].dish_count!=0" href="javascript:void()" v-on:click="setupIdTransaction(transactionTable[mejaIn.m_meja_id].m_transaction_table_id, mejaIn.m_meja_id)" class="card card-meja h-100 text-decoration-none" data-toggle="modal" data-target="#modal-update-nota"  data-backdrop="static">
                                                    <div class="card-body align-self-center justify-content-center text-center">{{mejaIn.m_meja_nama}} <br/> {{transactionTable[mejaIn.m_meja_id].m_transaction_table_note_number}}</div>
                                                    <div class="card-footer font-weight-bold">{{transactionTable[mejaIn.m_meja_id].m_transaction_table_customer_name}}</div>
                                                </a>
                                                <a v-else href="javascript:void()" v-on:click="setupIdTransaction(transactionTable[mejaIn.m_meja_id].m_transaction_table_id, mejaIn.m_meja_id)" class="card card-meja draft h-100 text-decoration-none" data-toggle="modal" data-target="#modal-update-nota"  data-backdrop="static">
                                                    <div class="card-body align-self-center justify-content-center text-center">{{mejaIn.m_meja_nama}} <br/> {{transactionTable[mejaIn.m_meja_id].m_transaction_table_note_number}}</div>
                                                    <div class="card-footer  font-weight-bold">{{transactionTable[mejaIn.m_meja_id].m_transaction_table_customer_name}}</div>
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a href="javascript:void()" v-on:click="tableNota(mejaIn.m_meja_id)" class="card card-menu table h-100 text-decoration-none" data-toggle="modal" data-target="#modal-input-nota" data-backdrop="static">
                                                    <div class="card-body align-self-center justify-content-center text-center">{{mejaIn.m_meja_nama}}<br/>&nbsp;</div>
                                                    <div class="card-footer">&nbsp;</div>
                                                </a>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="card mt-3">
                            <div class="card-header font-weight-bold bg-accent text-white">
                                <div class="row align-items-center">
                                    <div class="col-auto">BUNGKUS</div>
                                </div>
                            </div>
                            <div class="card-body bg-light">
                                <div class="row mx-n2 row-meja">
                                    <div class="col mb-3 px-2" v-for="mejaIn in listBungkus" :key="mejaIn.m_meja_id">
                                        <template v-if="transactionTable[mejaIn.m_meja_id]">
                                            <a href="javascript:void()" v-on:click="toOrder(transactionTable[mejaIn.m_meja_id].m_transaction_table_id)" class="card card-meja h-100 text-decoration-none" data-toggle="modal" data-target="#modal-update-nota"  data-backdrop="static">
                                                <div class="card-body align-self-center justify-content-center text-center">{{mejaIn.m_meja_nama}} <br/> {{transactionTable[mejaIn.m_meja_id].m_transaction_table_note_number}}</div>
                                                <div class="card-footer">{{transactionTable[mejaIn.m_meja_id].m_transaction_table_by_name.toUpperCase()}}</div>
                                            </a>
                                        </template>
                                        <template v-else>
                                            <a href="javascript:void()" v-on:click="tableNotaBungkus(mejaIn.m_meja_id)" class="card card-menu table h-100 text-decoration-none" data-toggle="modal" data-target="#modal-input-nota-bungkus" data-backdrop="static">
                                                <div class="card-body align-self-center justify-content-center text-center">{{mejaIn.m_meja_nama}}<br/>&nbsp;</div>
                                                <div class="card-footer">&nbsp;</div>
                                            </a>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>

        <!-- Modal Input Nota-->
        <div class="modal fade" id="modal-input-nota">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Input Info Nota</h5>
                        <button id="closeModalInformation" v-on:click="loadTable" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="form" id="transactionTableSubmitData" @submit.prevent="transactionTableSubmit" method="post">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <input id="table-type-transaction" name="m_transaction_table_type_transaction" type="hidden"/>
                                        <label class="col-form-label col px-1" for="">Nomor Nota</label>
                                        <input name="m_transaction_table_note_number" type="text" class="form-control numeric" autocomplete="off" required>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label for="">Nama Bigboss</label>
                                        <input type="text" class="form-control"  name="m_transaction_table_customer_name" autocomplete="off" required>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label for="tableSelectedMerge">Staf</label>
                                        <select name="m_transaction_table_by" id="tableStaffBy" class="form-control select2" required>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <label class="col-form-label col px-1"  for="tableSelected">Meja</label>
                                        <select name="m_transaction_table_table_list[]" id="tableSelected" class="form-control" readonly required>
                                            <option
                                                v-for="mejaIn in listMeja" :key="mejaIn.m_meja_id" 
                                                :value="mejaIn.m_meja_id"
                                            >
                                                {{mejaIn.m_meja_nama}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="tableSelectedMerge">Gabungkan Meja</label>
                                        <select name="m_transaction_table_table_list[]" id="tableSelectedMerge" class="form-control select2" multiple>
                                            <template v-for="mejaIn in listMeja">
                                                <option v-if="!transactionTable[mejaIn.m_meja_id]" :key="mejaIn.m_meja_id" :value="mejaIn.m_meja_id" >
                                                    {{mejaIn.m_meja_nama}}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <div class="row mx-n2">
                                        <div class="col-5 px-2">
                                            <div class="form-group">
                                                <label for="">Jam</label>
                                                <input id="input_time" class="form-control timepicki" name="m_transaction_table_order_time" required>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" v-on:click="loadTable" class="btn btn-light" data-dismiss="modal">Batal</button>
                            <button type="submit" class="btn btn-accent" >Proses</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modal-input-nota-bungkus">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Input Info Nota</h5>
                        <button id="closeModalInformationBungkus" v-on:click="loadTable" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="form" id="transactionTableSubmitDataBungkus" @submit.prevent="transactionTableSubmitBungkus" method="post">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <input id="table-type-transaction-bungkus" name="m_transaction_table_type_transaction" type="hidden" value="Take Away"/>
                                        <label class="col-form-label col px-1" for="">Nomor Nota</label>
                                        <input name="m_transaction_table_note_number" type="text" class="form-control numeric" autocomplete="off" required>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label for="">Nama Bigboss</label>
                                        <input type="text" class="form-control"  name="m_transaction_table_customer_name" autocomplete="off" required>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label for="tableSelectedMerge">Staff</label>
                                        <select name="m_transaction_table_by" id="tableStaffByBungkus" class="form-control select2" required>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <label class="col-form-label col px-1"  for="tableSelected">Meja</label>
                                        <select name="m_transaction_table_table_list[]" id="tableSelectedBungkus" class="form-control" readonly required>
                                            <option
                                                v-for="mejaIn in listBungkus" :key="mejaIn.m_meja_id" 
                                                :value="mejaIn.m_meja_id"
                                            >
                                                {{mejaIn.m_meja_nama}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="row mx-n2">
                                        <div class="col-5 px-2">
                                            <div class="form-group">
                                                <label for="">Jam</label>
                                                <input id="input_time_bungkus" class="form-control timepicki" name="m_transaction_table_order_time" required>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" v-on:click="loadTable" class="btn btn-light" data-dismiss="modal">Batal</button>
                            <button type="submit" class="btn btn-accent" >Proses</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal Input Nota by staff-->
        <div class="modal fade" id="modal-input-nota-by-staff">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Input Info Nota</h5>
                        <button id="closeModalInformationByStaff" v-on:click="loadTable" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="form" id="transactionTableSubmitDataByStaff" @submit.prevent="transactionTableSubmitDataByStaff" method="post">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <input id="table-type-transaction-by-staff" name="m_transaction_table_type_transaction" type="hidden"/>
                                        <label class="col-form-label col px-1" for="">Nomor Nota</label>
                                        <input name="m_transaction_table_note_number" type="text" class="form-control numeric" autocomplete="off" required>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label for="">Nama Bigboss</label>
                                        <input type="text" class="form-control"  name="m_transaction_table_customer_name" autocomplete="off" required>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label for="tableSelectedMerge">Staff</label>
                                        <select name="m_transaction_table_by" id="tableStaffByStaff" class="form-control select2" readonly required >
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <label class="col-form-label col px-1" for="tableSelectedByStaff">Meja</label>
                                        <select name="m_transaction_table_table_list[]" id="tableSelectedByStaff" class="form-control select2" multiple required>
                                        </select>
                                    </div>
                                    <div class="row mx-n2">
                                        <div class="col-5 px-2">
                                            <div class="form-group">
                                                <label for="">Jam</label>
                                                <input id="input_time_by_staff" class="form-control timepicki" name="m_transaction_table_order_time" required>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" v-on:click="loadTable" class="btn btn-light" data-dismiss="modal">Batal</button>
                            <button type="submit" class="btn btn-accent" >Proses</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal Update Nota-->
        <div class="modal fade" id="modal-update-nota">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Info :</h5>
                        <button id="closeModalInformationUpdate" v-on:click="loadTable" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <button v-on:click="transactionNota(idTransaction)"  class="btn btn-primary btn-lg form-control">Lihat Transaksi</button>
                                </div>
                                <div class="col-md-6"> 
                                    <button v-on:click="updateNota(idTransaction)" class="btn btn-warning btn-lg form-control" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#modal-update-transaction-nota" data-backdrop="static" >Ubah Informasi Nota</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Updated Nota-->
        <div class="modal fade" id="modal-update-transaction-nota">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Update Info Nota</h5>
                        <button id="closeModalInformationUpdateForm" v-on:click="loadTable" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="form" id="transactionTableUpdateData" @submit.prevent="transactionTableUpdate" method="post">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <label for="">Nomor Nota</label>
                                        <input type="hidden" name="_method" value="PUT">
                                        <input type="hidden" name="m_transaction_table_id" id="update_m_transaction_table_id" required readonly>
                                        <input type="text" class="form-control" name="m_transaction_table_note_number" id="update_m_transaction_table_note_number" required readonly>
                                    </div>
                                    <div class="form-group">
                                        <label for="">Nama Bigboss</label>
                                        <input type="text" class="form-control"  name="m_transaction_table_customer_name" id="update_m_transaction_table_customer_name" required>
                                    </div>
                                    <div class="form-group">
                                        <label for="update_staff_name">Karyawan</label>
                                        <input type="text" class="form-control"  id="update_staff_name" required readonly>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="update_tableSelected">Meja</label>
                                        <select name="m_transaction_table_table_list[]" id="update_tableSelected" class="form-control option" required>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="tableSelectedMerge">Gabungkan Meja</label>
                                        <select name="m_transaction_table_table_list[]" id="update_tableSelectedMerge" class="form-control select2" multiple>
                                            <template 
                                                v-for="mejaIn in listMeja" 
                                            >
                                                <option v-if="true" :key="mejaIn.m_meja_id" :value="mejaIn.m_meja_id" >
                                                    {{mejaIn.m_meja_nama}}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <div class="row mx-n2">
                                        <div class="col-5 px-2">
                                            <div class="form-group">
                                                <label for="">Jam</label>
                                                <input class="form-control timepicki" name="m_transaction_table_order_time" id="update_m_transaction_table_order_time" required>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" v-on:click="loadTable" class="btn btn-light" data-dismiss="modal">Batal</button>
                            <button type="submit" class="btn btn-accent" >Proses</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="js" >
import {onMounted, ref} from 'vue';
import axios from 'axios';
import{useRouter} from 'vue-router';
import Nav from '@/components/secure/odcr/Nav.vue'
import { AUTO_REFRESH_DURATION, sleep } from '@/mixins/app'
import { mapGetters } from 'vuex'

export default {
    name: "Odcr",
    components: {
        Nav,
    },
      data(){
        return{
            listMeja: [],
            listBungkus: [],
            transactionTable: [],
            allStaff: [],
            idTransaction: 0,
            selectedTable: "",
            transactionTableIds: [],
            loadingMeja: false,
            selectedTableByStaff: ""
        }
    },
    computed: {
      ...mapGetters([
        'autoRefresh'
      ])
    },
    mounted() {
      const loadTable = this.loadTable;
      const enableAutoRefresh = this.enableAutoRefresh;
      const autoRefresh = this.autoRefresh;
      const loadData = this.loadData;

      this.loadData().then(_loadDataResp => {
        (function loop(fn, autoRefresh) {
          setTimeout(function() {
            autoRefresh();
            const startTime = new Date().getTime()

            fn().then(resp => {
              const endTime = new Date().getTime()
              const duration = endTime - startTime
              let extra = 0
              if (duration < AUTO_REFRESH_DURATION) {
                sleep(AUTO_REFRESH_DURATION - duration).then(_resp => {
                  extra = new Date().getTime()
                  loop(fn, autoRefresh)
                })
              } else {
                loop(fn, autoRefresh)
              }
            })
          }, AUTO_REFRESH_DURATION)
        })(loadData, enableAutoRefresh);
      })
    },
    methods: {
      loadData() {
        document.getElementById("tableSelected").style.pointerEvents = 'none';
        window.setTimeout( "loadingComponent()" ,750);
        document.getElementById("odcrNavigation").classList.add("active");
        return this.loadTable()
      },
      enableAutoRefresh() {
        this.$store.dispatch('enableAutoRefresh')
      },
      disableAutoRefresh() {
        this.$store.dispatch('disableAutoRefresh')
      },
      loadTable() {
        this.loadingMeja = true
        return axios.get('/m-meja').then(res => {                    
          this.loadingMeja = false
          
          const newMeja = []
          const newBungkus = []

          const mejaList = [];
          for(const list in res.data){
              if (res.data[list].m_meja_jenis.m_meja_jenis_nama=='Bungkus') {
                  newBungkus.push(res.data[list])
              } else {
                  newMeja.push(res.data[list])
              }
              mejaList.push(res.data[list].m_meja_id)
          }
          this.listMeja = newMeja
          this.listBungkus = newBungkus

          return Promise.all([ 
            this.loadStaff(),
            this.loadTransactionTable(JSON.stringify(mejaList))
          ])

        }).catch((err) => {
          console.log(err);
          return Promise.reject(err)
        })
      },
      loadStaff() {
        return axios.get('/users').then(res => {                    
          console.log(res);
          this.allStaff = res.data
          return res.data
        }).catch ((err) => {
          console.log(err);
          return Promise.reject(err)
        })
      },
      loadTransactionTable(listMeja){
        return axios.get('/tmp-transaction?json_m_transaction_table_table_list='+listMeja)
        .then(res => {
          this.transactionTable = res.data //respon dari rest api dimasukan ke users
          return res.data
        })
        .catch ((err) => {
          console.log(err)
          return Promise.reject(err)
        })
      },
        tableNota(tableId){
            const element = document.getElementById('tableSelected');
            this.selectedTable = tableId;
            element.value = tableId;
            const tableMerge = document.getElementById('tableSelectedMerge');
            const tempMeja = this.listMeja;

            document.getElementById('table-type-transaction').value = 'Dine In'
    
            const tanggal = new Date();
            
            const jam = ('0'+tanggal.getHours()).substr(-2);
            const menit = ('0'+tanggal.getMinutes()).substr(-2);

            document.getElementById("input_time").defaultValue = jam+":"+menit;

            let resultHtml
            const tempSelectedTable = this.selectedTable
            const transactionTable = this.transactionTable;
            
            tempMeja.forEach(function(element) {

                const e = JSON.parse(JSON.stringify(element))
                
                let disabled
                if (e.m_meja_id == tableId) {
                    disabled = "disabled"
                    
                } else disabled = ""

                const html = "<option value="+e.m_meja_id+" "+disabled+">"+e.m_meja_nama+"</option>"
                if(!transactionTable[e.m_meja_id]) resultHtml+=html
            });

            tableMerge.innerHTML = resultHtml;

            this.setupStaffInputNota()
        },
        tableNotaBungkus(tableId){
            const element = document.getElementById('tableSelectedBungkus');
            this.selectedTable = tableId;
            element.value = tableId;
            const tempMeja = this.listBungkus;

            document.getElementById('table-type-transaction-bungkus').value = 'Take Away'
    
            const tanggal = new Date();
            
            const jam = ('0'+tanggal.getHours()).substr(-2);
            const menit = ('0'+tanggal.getMinutes()).substr(-2);

            document.getElementById("input_time_bungkus").defaultValue = jam+":"+menit;
            
            this.setupStaffInputNota()
        },
        tableNotaByStaff(staffId){
            const tableElement = document.getElementById('tableSelectedByStaff');
            const tempMeja = this.listMeja;

            document.getElementById('table-type-transaction-by-staff').value = 'Dine In'
    
            const tanggal = new Date();
            
            const jam = ('0'+tanggal.getHours()).substr(-2);
            const menit = ('0'+tanggal.getMinutes()).substr(-2);

            document.getElementById("input_time_by_staff").defaultValue = jam+":"+menit;

            let resultHtml = ""
            const tempSelectedTable = ""
            const transactionTable = this.transactionTable;
            
            tempMeja.forEach(function(element) {

                const e = JSON.parse(JSON.stringify(element))
                
                const html = "<option value="+e.m_meja_id+" key="+e.m_meja_id+">"+e.m_meja_nama+"</option>"
                if(!transactionTable[e.m_meja_id]) resultHtml+=html
            });

            tableElement.innerHTML = resultHtml;

            this.setupStaffInputNotaByStaff(staffId)
        },
        transactionTableSubmit: function(e){
            e.preventDefault();
            const formIdTransaction = document.getElementById('transactionTableSubmitData');
            const formSubmitTableTransaction = new FormData(formIdTransaction);
            axios.post('/tmp-transaction', formSubmitTableTransaction).then(res => {
                console.log(res)
                if (res.status==200) {
                    formIdTransaction.reset();
                    document.getElementById('closeModalInformation').click();
                    this.$router.push({ name: 'kitchenOrder', params: { transactionId: res.data.data.m_transaction_table_id} });    
                } else {
                    this.$swal.fire({
                        text:"Nomor Nota sudah ada", 
                        icon:"warning",
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Iya'
                    })
                }
                
            }).catch ((err) => {
                console.log(err.response.data);
            })
        },
        transactionTableSubmitBungkus: function(e){
            e.preventDefault();
            const formIdTransaction = document.getElementById('transactionTableSubmitDataBungkus');
            const formSubmitTableTransaction = new FormData(formIdTransaction);
            axios.post('/tmp-transaction', formSubmitTableTransaction).then(res => {
                console.log(res)
                if (res.status==200) {
                    formIdTransaction.reset();
                    document.getElementById('closeModalInformationBungkus').click();
                    this.$router.push({ name: 'kitchenOrder', params: { transactionId: res.data.data.m_transaction_table_id} });    
                } else {
                    this.$swal.fire({
                        text:"Nomor Nota sudah ada", 
                        icon:"warning",
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Iya'
                    })
                }
                
            }).catch ((err) => {
                console.log(err.response.data);
            })
        },
        transactionTableSubmitDataByStaff: function(e){
            e.preventDefault();
            const formIdTransaction = document.getElementById('transactionTableSubmitDataByStaff');
            const formSubmitTableTransaction = new FormData(formIdTransaction);
            axios.post('/tmp-transaction', formSubmitTableTransaction).then(res => {
                console.log(res)
                if (res.data.meta.code==200) {
                    formIdTransaction.reset();
                    document.getElementById('closeModalInformationByStaff').click();
                    this.$router.push({ name: 'kitchenOrder', params: { transactionId: res.data.data.m_transaction_table_id} });    
                } else if(res.data.meta.code==204) {
                    this.$swal.fire({
                        text:res.data.meta.message, 
                        icon:"warning",
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Iya'
                    })
                }
                
            }).catch ((err) => {
                console.log(err.response.data);
            })
        },
        updateNota(transactionId){
            axios.get('/tmp-transaction/'+transactionId).then(res => {
                
                document.getElementById("update_m_transaction_table_note_number").value = res.data.data.m_transaction_table_note_number
                document.getElementById("update_staff_name").value = res.data.data.user_input.name.toUpperCase()
                document.getElementById("update_m_transaction_table_customer_name").value = res.data.data.m_transaction_table_customer_name
                document.getElementById("update_m_transaction_table_order_time").value = res.data.data.m_transaction_table_order_time
                document.getElementById("update_m_transaction_table_id").value = res.data.data.m_transaction_table_id
                const tableMerge = document.getElementById('update_tableSelectedMerge');
                const tableSelected = document.getElementById('update_tableSelected');
                const tableArray = res.data.data.m_transaction_table_table_list.replace(/ /g,"").split(',');

                const tempMeja = this.listMeja;

                let resultHtml
                let resultHtmlSelectedTable
                const tempSelectedTable = this.selectedTable
                const transactionTable = this.transactionTable;

                tempMeja.forEach(function(e) {

                    let selected
                    let selectedBool = false
                    let selectedPrimaryTable = ""

                    if (tableArray.indexOf(e.m_meja_id)>=0 && e.m_meja_id != tempSelectedTable) {
                        selected = "selected"
                        selectedBool = true
                    } else selected = ""

                    let disabled
                    if (e.m_meja_id == tempSelectedTable) {
                        disabled = "disabled"
                        selectedPrimaryTable = "selected"
                    } else {
                        disabled = ""
                        selectedPrimaryTable = ""
                    }
                    // console.log();
                    

                    const html = "<option value="+e.m_meja_id+" "+selected+" "+disabled+">"+e.m_meja_nama+"</option>"
                    const htmlSelected = "<option value="+e.m_meja_id+" "+selectedPrimaryTable+">"+e.m_meja_nama+"</option>"

                    if(!transactionTable[e.m_meja_id] || selectedBool){
                        resultHtml+=html
                    }

                    if (!transactionTable[e.m_meja_id] || e.m_meja_id == tempSelectedTable) {
                        resultHtmlSelectedTable+=htmlSelected
                    }
                });

                tableMerge.innerHTML = resultHtml;
                tableSelected.innerHTML = resultHtmlSelectedTable;
                
            }).catch ((err) => {
                console.log(err);
            })
        },
        transactionNota(transactionId){
            document.getElementById('closeModalInformationUpdate').click();
            this.$router.push({ name: 'kitchenOrder', params: { transactionId: transactionId} });
        },
        setupIdTransaction(idTransaction, tableId){
            this.idTransaction = idTransaction;
            this.selectedTable = tableId;
            const element = document.getElementById('update_tableSelected');
            element.value = tableId;
            const tableMerge = document.getElementById('update_tableSelectedMerge');

        },
        transactionTableUpdate: function(e){
            e.preventDefault();
            const formIdTransaction = document.getElementById('transactionTableUpdateData');
            const idTransactionNota = document.getElementById('update_m_transaction_table_id');
            const formSubmitTableTransaction = new FormData(formIdTransaction);
            axios.post('/tmp-transaction/'+idTransactionNota.value, formSubmitTableTransaction).then(res => {
                console.log(res)
                formIdTransaction.reset();
                document.getElementById('closeModalInformationUpdateForm').click();
            }).catch ((err) => {
                console.log(err);
            })
        },
        setupTableMergeByStaff(event){
            console.log(event.target.value);
            
        },
        setupStaffInputNota(){
            const tableStaff = document.getElementById('tableStaffBy')
            const tableStaffBungkus = document.getElementById('tableStaffByBungkus')
            const staffs = this.allStaff

            let staffHtml = "<option disabled selected value= >Pilih Karyawan</option>"

            staffs.forEach(function(e) {

                const html = "<option value="+e.id+">"+e.name.toUpperCase()+"</option>"
                staffHtml+=html
            });

            tableStaff.innerHTML = staffHtml
            tableStaffBungkus.innerHTML = staffHtml

        },
        setupStaffInputNotaByStaff(staffId){
            const tableStaff = document.getElementById('tableStaffByStaff')
            const staffs = this.allStaff

            let staffHtml

            staffs.forEach(function(e) {
                if (e.id == staffId) {
                    const html = "<option value="+e.id+" selected>"+e.name.toUpperCase()+"</option>"
                    staffHtml+=html    
                } else {
                    const html = "<option value="+e.id+">"+e.name.toUpperCase()+"</option>"
                    staffHtml+=html    
                }
                
            });

            tableStaff.innerHTML = staffHtml

        },
        toOrder(id){
            this.$router.push({ name: 'kitchenOrder', params: { transactionId: id} })
        }
    },
    setup() {
        const user = ref(null);
        const router = useRouter();
        const loadingGif = "/assets/images/loading.gif";


        onMounted( async () => {
            try {
                const response = await axios.get('/user');
                user.value = response.data.data;
            } catch (e) {
                await router.push({ name: 'loginPage' });
            }
        });

        return {
            user,
            loadingGif
        }

    },
}
</script>
