
import { onMounted, ref, defineComponent, computed } from 'vue'
import axios from 'axios'
import { useRouter, useRoute } from 'vue-router'
import moment from 'moment'
import order from '@/mixins/order'
import { useHistory } from '@/mixins/history'
import Nav from '@/components/secure/dashboard/Nav.vue'
import HistoryDetail from '@/components/secure/history/HistoryDetail.vue'

export default defineComponent({
  name: 'HistoryByDate',
  components: {
    Nav,
    HistoryDetail,
  },
  setup() {
    const user = ref(null)
    const router = useRouter()
    const route = useRoute()
    const { downloadStructFile } = order()
    const menuJenis = ref([])
    const histories = ref([] as any)
    const historyDetail = ref({} as any)
    const selectedTransId = ref('')
    const transactions = ref({} as any)
    const showDetail = ref(false)
    const getRowClass = (id: string) => {
      let classVal = 'py-1'
      if (selectedTransId.value === id) {
        classVal = 'py-1 table-active'
      }
      return classVal
    }
    
    const historyEmpty = computed(() => {
      return histories.value.length == 0 ? true : false
    })
    const { loadHistory, loadHistoryDetail } = useHistory()
    const showHistoryDetail = computed(() => showDetail.value)

    const load = async (notaNumber: string, transactionDate: string) => {
      try {
        const resp = await axios.get('/m-menu-jenis')
        console.log('jenis menu')
        console.log(resp.data)

        menuJenis.value = resp.data
        const transDate = moment(transactionDate).format('YYYY-MM-DD')
        const historyResp = await loadHistory(notaNumber, transDate)
        histories.value = historyResp
      
      } catch (err) {
        console.log(err)
      }
    }

    onMounted( async () => {
      try {
        const response = await axios.get('/user')
        user.value = response.data.data

        const nota = route.params.notaNumber.toString()
        const transDate = route.params.transactionDate.toString()
        await load(nota, transDate)

      } catch (e) {
        await router.push({ name: 'loginPage' })
      }
    })

    const onLoadHistoryDetailClick = async (id: string) => {
      const resp = await loadHistoryDetail(id)
      historyDetail.value = resp
      showDetail.value = true
    }

    return {
      user,
      downloadStructFile,
      menuJenis,
      histories,
      historyDetail,
      selectedTransId,
      transactions,
      historyEmpty,
      getRowClass,
      showHistoryDetail,
      onLoadHistoryDetailClick
    }
  }
  // beforeRouteEnter(to, from, next) {
  //   const nota = to.params.notaNumber.toString()
  //   const transDate = to.params.transactionDate.toString()

  //   next((vm: any) => {
  //     axios.get('/m-menu-jenis').then(res => {
  //       vm.menuJenis = res.data
  //       const tsDate = moment(transDate).format('YYYY-MM-DD')
  //       vm.loadHistory(nota, tsDate)

  //     }).catch ((err) => {
  //       console.log(err)
  //     })
  //   });
  // }
  // beforeRouteUpdate(to, from, next) {
  //   const nota = to.params.notaNumber.toString()
  //   const transDate = to.params.transactionDate.toString()

  //   this.load(nota, transDate)
  //   next()
  // }
})
