
import { defineComponent, ref, onMounted } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'
import { useShift } from '@/mixins/shift'
import { Payment, SpendingResult, Promo } from '@/mixins/shift_type'
import Nav from '@/components/secure/dashboard/Nav.vue';
import RecapFreeReturn from '@/components/secure/odcr/shift/RecapFreeReturn.vue';
import RecapCancelledMenu from '@/components/secure/odcr/shift/RecapCancelledMenu.vue';
import RecapMoneyTips from '@/components/secure/odcr/shift/RecapMoneyTips.vue';
import RecapSalesMenu from '@/components/secure/odcr/shift/RecapSalesMenu.vue';

export default defineComponent({
  name: 'Shift',
  components: {
    Nav,
    RecapFreeReturn,
    RecapCancelledMenu,
    RecapMoneyTips,
    RecapSalesMenu,
  },
  setup() {
    const store = useStore()
    const remainingModal = ref(0)
    const subTotalMenu = ref(0)
    const payTotal = ref(0)
    const taxPlusService = ref(0)
    const salesPluxTax = ref(0)
    const payDiffCheck = ref(0)
    const realMoneyTotal = ref(0)
    const { getModal, getSpending, getPayment, getPromo } = useShift()
    const realMoney = ref(0)

    onMounted(async () => {
      const modal: any = await getModal()
      store.dispatch('shift/setModal', modal)

      const spending: SpendingResult = await getSpending()
      store.dispatch('shift/setSpendings',  spending)
      remainingModal.value = parseFloat(modal.m_modal_nominal) + spending.total
      
      const payment: Payment = await getPayment()
      store.dispatch('shift/setPayment', payment)

      const promo: Promo = await getPromo()
      store.dispatch('shift/setPromo', promo)

      payTotal.value = payment.cashTotal + payment.cashTotal
      taxPlusService.value = promo.pajak + promo.service
      realMoneyTotal.value = remainingModal.value + payment.cashTotal
    })
    
    // http://cr55.local/kasir/getmodal -> m-modal/get-modal
    // http://cr55.local/kasir/batal_menu
    // http://cr55.local/kasir/uang_tips -> recap-uang-tips/today
    // http://cr55.local/fetch/SyncList
    // http://cr55.local/kasir/getdata/garansi
    // http://cr55.local/kasir/getdata/rekapbatal
    // http://cr55.local/kasir/getdata/uangtips
    // http://cr55.local/kasir/getdata/free
    // http://cr55.local/kasir/pengeluaran
    // http://cr55.local/kasir/penjualan
    // http://cr55.local/kasir/pembayaran
    // http://cr55.local/kasir/promodll
    // cashier/canceled-menu
    // cashier/uang-tips
    // cashier/warranty-data
    // cashier/canceled-recap-data
    // cashier/uang-tips-data
    // cashier/free-data
    // cashier/spending
    // cashier/payment
    // cashier/promo

    const changeShift = () => {
      const params = {
        command: '',
        nama_kasir: '',
        nama_karyawan: '',
        date: '',
        pembayaran: {
          total_tunai: 0
        },
        promo_dll: {
          free_kembalian_void: 0,
          free_kembalian: 0,
          nominal: 0,
          tarik_tunai: 0,
          lost_bill: 0,
          pembulatan: 0,
          gratis: 0,
          voucher: 0,
          diskon: 0,
          garansi: 0,
          void: 0,
          uang_tips: 0
        },
        modal: {
          modal_nomimal: 0
        },
        modal_sisa: {},
        pengeluaran: {
          pengguna: '',
          nominal: 0,
          total: 0
        },
        penjualan: {
          total_penjualan_regular: 0,
          total_penjualan: 0,
          pajak_penjualan_regular: 0,
          layanan_penjualan_regular: 0,
          total_penjualan_ojol: 0,
          pajak_penjualan_ojol: 0,
          total_penjualan_ojol_regular: 0,
          layanan_penjualan_ojol: 0,
          total_sharing_ojol: 0,
          kategori_regular: {
            jumlah_nota: 0,
            nominal_plus_pajak: 0,
            nominal: 0,
            type: '',
            pajak: 0,
            service: 0
          },
          kategori_ojol: {
            type: '',
            jumlah_nota: 0,
            nominal: 0,
            nominal_plus_pajak: 0,
            sharing_profit: 0,
            sharing_ojol: 0,
            pajak: 0
          },
          menu: {
            jumlah_nota: 0,
            nominal: 0,
            pajak: 0,
            nominal_pajak: 0
          },
          non_menu: {
            jumlah_nota: 0,
            nominal: 0,
            pajak: 0,
            nominal_pajak: 0
          },
          detail_bayar: {
            total_edc: 0,
            total_non_tunai: 0
          }
        }
      }
    }

    return {
      remainingModal,
      subTotalMenu,
      payTotal,
      taxPlusService,
      salesPluxTax,
      payDiffCheck,
      realMoneyTotal,
      realMoney
    }
  }
})
