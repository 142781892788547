
import { useStore } from 'vuex';
import { computed, defineComponent, ref } from 'vue';
// import { resetOjolStatus, loadData } from '@/mixins/app'
import Drawer from '@/components/secure/dashboard/Drawer.vue';

export default defineComponent({
  name: 'App',
  components: {
    Drawer
  },
  setup() {
    const store = useStore();

    const modalVisible = computed(() => store.state['modal'].modalVisible);
    const modalClass = computed(() => {
      const visible = store.state['modal'].modalVisible;
      if (visible) {
        return 'modal-backdrop fade show';
      }
      return 'modal-backdrop fade';
    });

    // const userId = localStorage.getItem('user_id') == null ? '' : localStorage.getItem('user_id')
    // loadData(userId)
    // resetOjolStatus()

    const token = computed(() => {
      return store.state['user'].token
    })

    return {
      modalVisible,
      modalClass,
      token,
    }
  }
});
