
// FIXME: pada saat klik update tetapi user tidak mengklik tombol update lalu
//        user mengklik link delete data di form tidak tereset
import { useStore } from 'vuex'
import { computed, defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'
import UangTipsForm from './UangTipsForm.vue'
import UangTipsEdit from './UangTipsEdit.vue'
import UangTipsView from './UangTipsView.vue'
import Nav from '@/components/secure/dashboard/Nav.vue'
import Modal from '@/components/secure/Modal.vue'

export default defineComponent({
  name: 'UangTips',
  components: {
    Nav,
    UangTipsView,
    UangTipsForm,
    Modal,
    UangTipsEdit
  },
  setup() {
    const store = useStore()
    const modalShow = ref(false)
    const isEdit = computed(() => store.state['uang_tips'].isEdit)
    const onHide = () => {
      modalShow.value = false
    }
    const uangTipsId = ref('')
    const deleteUangTips = () => {
      onHide()

      axios.delete(`/recap-uang-tips/${uangTipsId.value}`)
      .then(resp => {
        store.dispatch('uang_tips/setReloadData', true)
      })
      .catch(err => {
        console.log(err)
      })
    }

    const resetEdit = () => {
      store.dispatch('uang_tips/setEditStatus', false)
    }

    onMounted(async () => {
      store.dispatch('uang_tips/setEditStatus', false)
    })

    const onDelete = (id: string) => {
      uangTipsId.value = id
      modalShow.value = true
    }

    return {
      isEdit,
      deleteUangTips,
      resetEdit,
      onHide,
      modalShow,
      onDelete
    }
  },
  beforeRouteUpdate(to, from) {
    this.resetEdit()
  }
});
