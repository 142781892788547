
import axios from 'axios';
import { ref, onMounted, defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import Nav from '@/components/secure/dashboard/Nav.vue';
import Datepicker from 'vue3-datepicker';
import { format, compareAsc, parse, parseISO} from 'date-fns';
import { useRouter } from 'vue-router';


export default defineComponent({
  name: 'Modal',
  components: {
    Nav,
    // Datepicker
  },
  setup(props, { emit }) {
    const store = useStore()

    const modal = ref({})
    const modalData = ref({} as any)
    const cashierName = ref('')
    // const cashierName = computed({
    //   get: () => {
    //     const user = store.state['user'].currentUser
    //     console.log(store)
    //     if (user != null) {
    //       return user.name
    //     }
    //     return ''
    //   },
    //   set: (value) => value
    // })
    const modalDate = ref(new Date())
    const modalId = ref(0)
    const pecahan = ref([] as any)
    // const jmlKasir = ref(0)
    // const jmlPelayanan = ref(0)
    // const jmlProduksiMasak = ref(0)
    // const jmlProduksiNonMasak = ref(0)
    const cashierSesi = ref(0)
    const totalUangModal = computed(() => {
      let res = 0
      for (const val of pecahan.value) {
        res += (val.qty * val.tipe_urut)
      }

      return res
    })
    const submitTxt = ref('Simpan')
    const waroengList = ref([] as any)
    const waroengId = ref(0);
    const waroengNama = ref('');
    const saveDisable = ref(false);
    onMounted(() => {
      axios.get('m-waroeng/waroeng-user')
      .then(resp => {
        // console.log(resp)
        waroengList.value = resp.data
      }).catch(err => console.log(err))

      axios.get('/m-modal/get-modal')
      .then(resp => {
        modal.value = resp.data
        resp.data.modal_tipe.forEach((val: any) => {
          pecahan.value.push({
            detail_id: val.rekap_modal_detail_id, qty: val.rekap_modal_detail_qty,
            tipe_id: val.m_modal_tipe_id,
            name: val.m_modal_tipe_nama, tipe_urut: val.m_modal_tipe_urut
          })
        })
        // jmlKasir.value = resp.data.jml_kasir
        // jmlPelayanan.value = resp.data.jml_pelayanan
        // jmlProduksiMasak.value = resp.data.jml_produksi_masak
        // jmlProduksiNonMasak.value = resp.data.jml_produksi_non_masak
        cashierSesi.value = resp.data.sesi
        cashierName.value = resp.data.kasirName
        // modalDate.value = moment(parseISO(resp.data.date)).format('YYYY-MM-DD HH:mm:ss')
        // modalDate.value = parse(resp.data.date, 'yyyy-mm-dd', new Date()).toString()
        // modalDate.value = parseISO(resp.data.date)
        modalDate.value = resp.data.date
        modalData.value = resp.data.modal
        modalId.value = resp.data.modal_id
        waroengId.value = resp.data.waroeng_id
        waroengNama.value = resp.data.waroeng_nama
        // console.log(parseISO(resp.data.date))
        // console.log("Now Date: "+new Date())
      })
      .catch(err => console.log(err))
    })
    
    const submit = () => {
      submitTxt.value = 'Menyimpan..'
      saveDisable.value = true
      const params = {
        rekap_modal_id: modalId.value,
        rekap_modal_sesi: cashierSesi.value,
        rekap_modal_tanggal: modalDate.value,
        rekap_modal_nominal: totalUangModal.value,
        rekap_modal_m_w_id: waroengId.value,
        // rekap_modal_status: '0',
        modal_detail: pecahan.value,
        // jml_kasir: jmlKasir.value,
        // jml_pelayanan: jmlPelayanan.value,
        // jml_produksi_masak: jmlProduksiMasak.value,
        // jml_produksi_non_masak: jmlProduksiNonMasak.value
      }

      axios.post('/m-modal/save-modal', params)
      .then(resp => {
        console.log(resp)
        if (resp.data.error == 1) {
          alert(resp.data.msg)
          // localStorage.setItem('token', '');
          // localStorage.clear();
          // location.replace('/login')
        }
        if (resp.data.error == 0) {
          axios.get('/open-laci/saldo-awal')
          .then(resp => {
              if(resp.data.struct != ''){
                  location.href = resp.data.struct
              }
              window.location.replace("./dashboard");
          }).catch(err => console.log(err))
        }
        submitTxt.value = 'Simpan'
      })
      .catch(err => {
        console.warn(err)
        submitTxt.value = 'Simpan'
      })
    }

    return {
      modalId,
      modal,
      totalUangModal,
      cashierName,
      modalDate,
      pecahan,
      // jmlKasir,
      // jmlPelayanan,
      // jmlProduksiMasak,
      // jmlProduksiNonMasak,
      cashierSesi,
      submitTxt,
      submit,
      waroengId,
      waroengNama,
      waroengList,
      saveDisable
    }
  },
  methods:{ 
    updateTmpUser(){
      axios.get('cashier/tmp-user-waroeng/'+this.waroengId)
        .then(resp => {
            // console.log(resp)
            this.$router.go(0)
        }).catch(err => console.log(err))
    }
  //   async formatDate(date: any){
  //        if (date) {
  //          return moment(String(date)).format('YYYY-MM-DD')
  //         }
  //     },
   },
})
