
import { ref, onMounted, watch, computed, defineComponent, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { tipsMoneyReason } from './uang_tips'

export default defineComponent({
  name: 'UangTipsForm',
  setup(props, context) {
    const cashierId = ref('')
    const cashierName = ref('')
    const description = ref('')
    const amountDebit = ref(0)
    const users = ref([])
    const btnSaveText = ref('Update')
    const router = useRouter()
    const store = useStore()
    const isEdit = computed(() => store.state['uang_tips'].isEdit)
    const tipsData = computed(() => store.state['uang_tips'].uangTips)

    watch(tipsData, (old, newd) => {
      const data = store.state['uang_tips'].uangTips
      cashierId.value = data.id_kasir
      cashierName.value = data.nama_kasir
      description.value = data.keterangan
      amountDebit.value = data.amount_debit
    })

    const uangTipsId = computed(() => store.state['uang_tips'].currentId)
    watch(uangTipsId, (newId, oldId) => {
      axios.get('/recap-uang-tips/' + newId)
      .then(resp => {
        store.dispatch('uang_tips/setUangTips', resp.data)
        description.value = resp.data.keterangan
        amountDebit.value = resp.data.amount_debit
      })
      .catch(err => console.log(err))
    })

    onMounted(async () => {
      const data = store.state['uang_tips'].uangTips
      cashierId.value = data.id_kasir
      cashierName.value = data.nama_kasir
      description.value = data.keterangan
      amountDebit.value = data.amount_debit

      try {
        const resp = await axios.get('/users')
        users.value = resp.data

      } catch(error) {
        await router.push({ name: 'loginPage' }) 
      }
    })

    const resetForm = () => {
      cashierId.value = ''
      cashierName.value = ''
      amountDebit.value = 0
      description.value = ''
    }

    const saveUangTips = () => {
      const data = store.state['uang_tips'].uangTips
      const params = { id_kasir: cashierId.value, keterangan: description.value, 
        amount_debit: amountDebit.value,
        m_karyawan_id: data.m_karyawan_id,
        m_karyawan_nama: data.m_karyawan_nama,
        id_waroeng: data.m_w_id,
        nama_waroeng: data.m_w_nama,
        nama_area: data.m_area_nama,
        nama_kasir: cashierName.value
      }

      btnSaveText.value = 'Menyimpan..'
      axios.patch('/recap-uang-tips/' + data.id, params)
      .then(resp => {
        btnSaveText.value = 'Update'
        store.dispatch('uang_tips/setReloadData', true)
        store.dispatch('uang_tips/setEditStatus', false)
        resetForm()
      })
      .catch(err => {
        console.log(err)
        btnSaveText.value = 'Update'
      })
    }
    
    const btnBatalClicked = () => {
      store.dispatch('uang_tips/setEditStatus', false)
    }

    // Event saat drop daftar user diubah
    const updateCashierName = (obj: any) => {
      const selIndex = obj.target.selectedIndex
      let name = ''
      for( const v of obj.target) {
        if (v.selected) {
          name = v.innerText
        }
      }

      cashierName.value = name
    }

    const { listReason } = tipsMoneyReason()

    return {
      tipsData,
      uangTipsId,
      cashierId,
      cashierName,
      description,
      amountDebit,
      users,
      isEdit,
      listReason,
      btnSaveText,
      saveUangTips,
      btnBatalClicked,
      updateCashierName
    }
  },
  beforeRouteEnter(to: any, from: any, next: any) {
    next()
  }
});
