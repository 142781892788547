
import { defineComponent, ref, onMounted, computed, Ref, reactive } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import { tipsMoneyReason } from '@/components/secure/mutation/uang_tips'
import Modal from '@/components/secure/Modal.vue'
import DetailMoneyTips from './DetailMoneyTips.vue'

interface MoneyTipsResponse {
  error: number;
  result: any[];
}

interface MoneyTips {
  id: string;
  description: string;
  value: number;
}

export default defineComponent({
  name: 'RecapMoneyTips',
  components: { Modal, DetailMoneyTips },
  setup() {
    const store = useStore()
    const moneyTips: Ref<MoneyTipsResponse> = ref({} as any)
    const isEmpty = ref(true)
    const { listReason } = tipsMoneyReason()
    const listMoneyTips: Ref<Array<MoneyTips>> = ref([])
    const modalContent = ref('Data tidak ditemukan')
    const modalShow = ref(false)
    const showDetailModal = ref(false)
    const details = ref([] as any)

    for (const val of listReason.value) {
      listMoneyTips.value.push({ id: '', description: val, value: 0 })
    }

    onMounted(() => {
      axios.get('cashier/uang-tips-data').then(resp => {
        store.dispatch('setMoneyTips', resp.data)
        moneyTips.value = resp.data
        if (resp.data.error == 0) {
          isEmpty.value = false
        }
      })
      .catch(err => console.log)
    })

    const onClick = (item: any) => {
      if (item.value < 1) {
        modalShow.value = true
        return false
      }

      showDetailModal.value = true
      const tips = moneyTips.value.result
      details.value = tips.filter(value => value.keterangan == item.description)
    }

    const hideModal = () => modalShow.value = false
    const onHideDetailModal = () => showDetailModal.value = false

    return {
      moneyTips,
      isEmpty,
      onClick,
      listMoneyTips,
      modalContent,
      modalShow,
      hideModal,
      showDetailModal,
      onHideDetailModal,
      details
    }
  }
})
