<template>
    <div>
        <Nav></Nav>

        <section class="py-4 section-app">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header font-weight-bold">
                        <div class="row align-items-center">
                            <div class="col-auto">DINE IN</div>
                            <div class="col-auto"><img :src="dinein" alt="" height="20"></div>
                        </div>
                    </div>
                    <div class="card-body bg-light" style="min-height: calc((100vh - 300px) / 3);">
                        <div class="row mx-n2 row-meja">
                            <div class="col-sm-3 col-md-2 col-lg-2 col-xl-1 mb-3 px-2" v-on:click="toOrder(order.tmp_online_id,order.tmp_online_bigboss_name,order.tmp_online_table_name)" v-for="order in dineInOrder" :key="order.tmp_online_id">
                                <a href="#"  class="card card-meja h-100 text-decoration-none">
                                    <div class="card-body align-self-center justify-content-center text-center">
                                        <span class="font-weight-bold text-warning" style="font-size: 14px;">{{order.tmp_online_table_name}}</span>
                                        <br/> 
                                        <br/> 
                                        {{order.tmp_online_code}}
                                    </div>
                                    <div class="card-footer">{{order.tmp_online_bigboss_name}}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-header font-weight-bold">
                        <div class="row align-items-center">
                            <div class="col-auto">TAKE AWAY</div>
                            <div class="col-auto"><img :src="takeaway" alt="" height="20"></div>
                        </div>
                    </div>
                    <div class="card-body bg-light" style="min-height: calc((100vh - 300px) / 3);">
                        <div class="row mx-n2 row-meja">
                            <div class="col-sm-3 col-md-2 col-lg-2 col-xl-1 mb-3 px-2" v-on:click="toOrder(order.tmp_online_id, order.tmp_online_bigboss_name, order.tmp_online_table_name)" v-for="order in takeawayOrder" :key="order.tmp_online_id">
                                <a href="#"  class="card card-meja h-100 text-decoration-none">
                                    <div class="card-body align-self-center justify-content-center text-center">
                                        <span class="font-weight-bold text-warning" style="font-size: 14px;">{{order.tmp_online_table_name}}</span>
                                        <br/> <br/> {{order.tmp_online_code}}
                                    </div>
                                    <div class="card-footer">{{order.tmp_online_bigboss_name}}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <button id="show-modal-terima-pesanan" data-toggle="modal" data-backdrop="static" data-target="#modal-terima-pesanan"/>
            <div class="modal fade" id="modal-terima-pesanan">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Konfirmasi Pesanan</h6>
                        <button type="button" id="close-terima-pesanan" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body py-4">
                        <div class="row flex-nowrap mx-n1">
                            <div class="col-4 px-1">
                                <button class="btn btn-danger btn-block py-3" 
                                v-on:click="confirmHapus()"
                                data-dismiss="modal">HAPUS</button>
                            </div>
                            <div class="col-4 px-1">
                                <button v-on:click="cetakPesanan()" class="btn btn-primary btn-block py-3" dismiss="modal">CETAK</button>
                            </div>
                            <div class="col-4 px-1">
                                <button class="btn btn-success btn-block py-3" id="chooseEdc" data-toggle="modal" data-target="#modal-bayar-edc" data-dismiss="modal" v-on:click="confirmPesanan()">TERIMA</button>
                            </div>
                            
                            <!-- <div class="col-4 px-1">
                                <button class="btn btn-warning btn-block py-3" data-toggle="modal" data-target="#modal-bayar-multipayment" data-dismiss="modal">Multipayment</button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button id="show-modal-pindah-meja" data-toggle="modal" data-backdrop="static" data-target="#modal-pindah-meja"/>
            <div class="modal fade" id="modal-pindah-meja">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Pindah Meja</h6>
                        <button type="button" id="close-pindah-meja" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="pilih_meja">Pilih Meja</label>
                            <Multiselect
                                v-model="mejaId"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="this.listMeja"
                                />
                            <!-- <select class="form-control option"
                                v-model="mejaId">
                                <template v-for="mejaIn in listMeja">
                                    <option v-if="true" :key="mejaIn.m_meja_id" :value="mejaIn.m_meja_id" >
                                        {{mejaIn.m_meja_nama}}
                                    </option>
                                </template>
                            </select> -->
                        </div>
                        <div class="modal-footer">
                        <button v-on:click="terimaPesanan()" class="btn btn-accent" :disabled='(mejaId > 0) ? false: true'>Simpan</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import Nav from '@/components/secure/dashboard/Nav.vue';
import Multiselect from '@vueform/multiselect';

export default {
  name: 'OnlineOrder',
  components: {
    Nav,
    Multiselect
  },
  data() {
    return {
      dineInOrder: [],
      takeawayOrder: [],
      tmpId: '',
      bigBoss: '',
      mejaBoss: '',
      mejaId: 0,
      listMeja: [],
      timerReload: null
    }
  },
  mounted() {
    document.getElementById("onlineOrderQrNavigation").classList.add("active");
    window.setTimeout( "loadingComponentOrder()" ,750);

    this.loadTransactions()
    // this.getListMeja()
    this.timerReload = setInterval(() => {
        this.loadTransactions()
    }, 5000);
  },
  beforeUnmount () {
    clearInterval(this.timerReload)
},
  methods: {
    loadTransactions() {
      axios.get('/tmp-online/list').then(res => {
        const dinein = []
        const takeaway = []
        res.data.forEach(function(e)  {
            if (e.tmp_online_m_t_t_id == 1) {
                dinein.push(e)
            } else if (e.tmp_online_m_t_t_id == 2) {
                takeaway.push(e)
            }
        });

        this.dineInOrder = dinein
        this.takeawayOrder = takeaway

      }).catch ((err) => {
        console.log(err)
      })
    },
    getListMeja(){
        axios.get('/meja/meja-kosong').then(res => {
            // this.listMeja = res.data
            this.listMeja = [];
            res.data.forEach(element => {
                this.listMeja.push({
                            value: element.m_meja_id,
                            label: element.m_meja_nama
                        });
            });
        }).catch ((err) => {
            if (err.response) {
            console.log(err.response.data)
            }
        })
    },
    replace(val){
        return val.replace(/[^a-zA-Z0-9]/g, '');
    },
    showWarning(msg){
        this.$swal.fire({
            title:"Oops!", 
            text:msg, 
            icon:"warning",
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
        })
    },
    cekPesanan(){
        axios.get('/tmp-online/cek-nota/'+this.tmpId).then(res => {
            if (res.data.status > 0) {
                this.showWarning("Pesanan Meja "+this.mejaBoss+" Sudah Ada!")
            }
        }).catch ((err) => {
            if (err.response) {
            console.log(err.response.data)
            }
        })
    },
    confirmHapus(){
        const transId = this.tmpId
        this.$swal.fire({
            title: 'Anda Yakin?',
            html: 'Pesanan yang dihapus, tidak bisa dibatalkan.',
            icon: 'warning',
            showCancelButton: 'true',
            cancelButtonText: 'Tidak',
            reverseButtons: true,
            confirmButtonText: 'Ya'
        }).then(function (success) {
            if (success.isConfirmed) {
                axios.get('/tmp-online/delete/'+transId).then(res => {
                    this.loadTransactions()
                }).catch ((err) => {
                    if (err.response) {
                    console.log(err.response.data)
                    }
                })
            }
        })
    },
    cetakPesanan(){
        const transId = this.tmpId
        this.cekPesanan()
        this.$swal.fire({
        // title: 'Mencetak!',
        html: 'Mencetak Pesanan',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
          axios.get('/cashier/print-bill-online/'+transId).then(res => {
            if(res.data.struct != ''){
                location.href = res.data.struct
            }
          }).catch ((err) => {
            if (err.response) {
              console.log(err.response.data)
            }
          })
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === this.$swal.DismissReason.timer) {
          console.log('bill success')
        }
      })
    },
    terimaPesanan(){
        const transId = this.tmpId
        const name = this.bigBoss
        const redirect = this.toDetail
        const warning = this.showWarning

        const formData = new FormData()
        formData.append('tmp_online_id',this.tmpId)
        formData.append('pindah_meja',this.mejaId)

        this.$swal.fire({
            title: 'Anda Yakin?',
            html: 'Menerima pesanan '+name,
            icon: 'warning',
            showCancelButton: 'true',
            cancelButtonText: 'Tidak',
            reverseButtons: true,
            confirmButtonText: 'Ya'
        }).then(function (success) {
            if (success.isConfirmed) {
                axios.post('/tmp-online/terima', formData).then(res => {
                    // this.loadTransactions()
                    // console.log(res)
                    document.getElementById("close-pindah-meja").click() 

                    if(res.data.meta.code == 200){
                        redirect(res.data.data.transactionId)
                    }
                    
                }).catch ((err) => {
                    if (err.response) {
                        console.log(err.response.data)
                        warning(err.response.data.meta.message)
                    }
                })
            }
        })
    },
    toDetail(id){
        this.$router.push({ name: 'orderMenu', params: { transactionId: id} });    
    },
    confirmPesanan(){
        const oldMeja = this.mejaBoss
        const approve = this.terimaPesanan
        const daftarMeja = this.getListMeja
        axios.get('/tmp-online/cek-nota/'+this.tmpId).then(res => {
            if (res.data.status > 0) {
                this.$swal.fire({
                    title: 'Warning!',
                    html: 'Terdapat pesanan di meja '+oldMeja,
                    icon: 'warning',
                    showCancelButton: 'true',
                    cancelButtonText: 'Batal',
                    reverseButtons: true,
                    confirmButtonText: 'Tambahkan',
                    showDenyButton: true,
                    denyButtonText: 'Pindah Meja'
                }).then(function (success) {
                    // console.log(success)
                    if (success.isConfirmed) {
                        approve()
                    }else if (success.isDenied){
                        daftarMeja()
                        document.getElementById("show-modal-pindah-meja").click() 
                    }
                })

            }else{
                this.terimaPesanan()
            }
        }).catch ((err) => {
            if (err.response) {
            console.log(err.response.data)
            }
        })
    },
    toOrder(id, bos, meja) {
        this.tmpId = id
        this.bigBoss = bos
        this.mejaBoss = meja
        this.mejaId = 0
        document.getElementById("show-modal-terima-pesanan").click()
    }
  },
  setup() {
    const user = ref(null);
    const router = useRouter();
    const dinein = '/assets/images/online/dinner.png'
    const takeaway = '/assets/images/online/take-away.png'
    
    // resetOjolStatus()
    onMounted( async () => {
      try {
        const response = await axios.get('/user');
        user.value = response.data.data;
      } catch (e) {
        await router.push({ name: 'loginPage' });
      }
    });

    return {
      user,
      dinein,
      takeaway
    }
  },
  beforeRouteLeave(to, from, next){
    clearInterval(this.timerReload)
    next()
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>