import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-4 section-app" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row mx-n2" }
const _hoisted_4 = { class: "col-lg-4 px-2" }
const _hoisted_5 = { class: "col-lg-8 px-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav = _resolveComponent("Nav")!
  const _component_UangTipsEdit = _resolveComponent("UangTipsEdit")!
  const _component_UangTipsForm = _resolveComponent("UangTipsForm")!
  const _component_UangTipsView = _resolveComponent("UangTipsView")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_Nav),
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.isEdit)
                ? (_openBlock(), _createBlock(_component_UangTipsEdit, { key: 0 }))
                : _createCommentVNode("", true),
              (!_ctx.isEdit)
                ? (_openBlock(), _createBlock(_component_UangTipsForm, { key: 1 }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_UangTipsView, { onOnDelete: _ctx.onDelete }, null, 8, ["onOnDelete"])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Modal, {
      onOnConfirm: _ctx.deleteUangTips,
      onOnHide: _ctx.onHide,
      modalShow: _ctx.modalShow,
      title: "Delete Uang Tips",
      bodyText: "Anda yakin akan menghapus data ini?",
      modalSize: "medium"
    }, null, 8, ["onOnConfirm", "onOnHide", "modalShow"])
  ], 64))
}