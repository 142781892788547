<template>
    <section>
        <button id="show-modal-approvel" data-toggle="modal" data-target="#modal-approvel"/>
        <div class="modal fade" id="modal-approvel">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Akses Atasan Dibutuhkan</h6>
                        <button id="close-modal-approvel" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="approvel_email">Email</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <input id="approvel_email" v-model="approvelEmail" type="text" class="form-control keyboard" autocomplete="false">
                                </div>
                                
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="approvel_password">Password</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <input id="approvel_password" v-model="approvelPassword" type="password" class="form-control keyboard" autocomplete="false">
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-on:click="confirmApprovel()" class="btn btn-accent" >Masuk</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <button id="show-modal-approvel-akses" data-toggle="modal" data-target="#modal-approvel-akses"/>
        <div class="modal fade" id="modal-approvel-akses">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Role Setting</h6>
                        <button id="close-modal-approvel-akses" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <form
                    class="form"
                    id="formUpdateRole"
                    @submit.prevent="updateRole('formUpdateRole')"
                    method="post"
                >
                        <input
                            type="hidden"
                            name="approvel_id"
                            :value="approvelId"
                            />
                    <div class="modal-body">
                        <div class="form-group">
                            <div
                            v-for="(role, index) in cashierRole"
                            :key="index"
                            class="form-group row align-items-center"
                            >
                            <label for="" class="col-5 font-weight-bold">{{ strToLabel(role.m_kasir_akses_fitur) }}</label>
                            <div class="col-3">
                                <input
                                type="hidden"
                                name="m_kasir_akses_id[]"
                                :value="role.m_kasir_akses_id"
                                />
                                <div class="custom-control custom-switch">
                                    <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    :name="'m_kasir_akses_temp_role['+role.m_kasir_akses_id+']'"
                                    :id="index"
                                    :checked="role.m_kasir_akses_temp_role == 'allow' ? true : false"
                                    value="1"
                                    />
                                    <label
                                    class="custom-control-label"
                                    :for="index"
                                    ></label>
                                </div>
                                <!-- <select class="form-control option"
                                name="m_kasir_akses_temp_role[]"
                                :class="[role.m_kasir_akses_temp_role == 'deny' ? 'text-danger' : 'text-success']"                
                                :value="role.m_kasir_akses_temp_role">
                                    <option class="text-success" value="allow">OPEN</option>
                                    <option class="text-danger" value="deny">CLOSE</option>
                                </select> -->
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            data-dismiss="modal">
                            Close
                        </button>
                        <button type="submit" class="btn btn-accent">
                            Simpan
                        </button>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </section>
    
</template>

<script lang="js">
import axios from 'axios';

export default {
    name: "Dashboard",
    data(){
        return{
            waroengId:'',
            approvelEmail: '',
            approvelPassword: '',
            approvelId: 0,
            cashierRole: {},
        }
    },
    mounted() {
        // window.setTimeout( "loadingComponent()" ,750);
        this.setupRole()
        this.infoWaroeng()
    },
    
    methods: {
        strToLabel(str){
            const label = str.split('_').join(' ');
            return label.toUpperCase();
        },
        confirmApprovel(){
            // const emailval = document.getElementById("approvel_email").value
            // const passwordval = document.getElementById("approvel_password").value
            axios.post('/approve', {
                waroeng: this.waroengId,
                email: this.approvelEmail,
                password: this.approvelPassword
            }).then(resp => {
                console.log(resp)
                if (resp.data.meta.code == 401) {
                    this.$swal.fire({
                        title:"Akses DiTolak!", 
                        text:resp.data.meta.message, 
                        icon:"warning",
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    }) 
                }else{
                    this.setupRole()
                    this.approvelId = resp.data.data.id;
                    this.approvelEmail = "";
                    this.approvelPassword = "";
                    document.getElementById("close-modal-approvel").click();
                    document.getElementById("show-modal-approvel-akses").click();
                }
            });
        },
        setupRole(){
            // const callAkses = JSON.parse(localStorage.role_akses)
            // this.cashierRole = callAkses
            axios.get('/get-all-role')
            .then(resp => {
                if (resp.status == 200) {
                    this.cashierRole = resp.data
                }
            })
            .catch(err => console.log(err))
        },
        updateRole(idForm){
            const formId = document.getElementById(idForm);
            const formData = new FormData(formId);
            
            axios.post('/role-kasir/update',formData).then(res => {
                this.setupRole()
                document.getElementById("close-modal-approvel-akses").click()

            }).catch ((err) => {
                console.log(err);
            })
        },
        infoWaroeng(){
            axios.get('/info-waroeng')
            .then(resp => {
                this.waroengId = resp.data.m_w_id
            })
            .catch(err => console.log(err))
        }
    }
}
</script>
