<template>
    <section>
        <div class="modal fade" id="modal-search-history">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Cari Transaksi</h6>
                        <button id="close-modal-search-history" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        
                        <div class="form-group">
                            <label for="">Nomor Transaksi</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <input id="input_nomor_nota" v-model="nomorNota" type="text" class="form-control keyboard">
                                </div>
                            </div>
                            <label for="">Tanggal Transaksi</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <datepicker v-model="transactionDate" type="text" class="form-control datepicker" />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-accent" v-on:click="confirmSearch()">Cari</button>
                    </div>
                </div>
            </div>
        </div>

        
        
    </section>
    
</template>

<script lang="js">
import axios from 'axios';
// import { useStore } from 'vuex'
// import { useRouter, useRoute } from 'vue-router';
import '@formatjs/intl-numberformat';
import { formatMoney } from '@/mixins/app';
import { format, compareAsc, parse, parseISO} from 'date-fns';

import Datepicker from 'vue3-datepicker';


    export default {
        name: "SearchHistory",
        components: {
            Datepicker
        },
        props: [
        
        ],
        data(){
            return{
                nomorNota: '',
                transactionDate: new Date()
            }
        },
        mounted() {
            window.setTimeout( "loadingComponentOrder()" ,750);
        },
        
        methods: {
            confirmSearch(){
                const nota = document.getElementById("input_nomor_nota").value;
                if(nota && this.transactionDate){
                    axios.get(`search-transactions/${nota}/${this.transactionDate}`).then(resp =>{
                        console.log(resp.data);
                        if (resp.data.error == 0) {
                            document.getElementById("close-modal-search-history").click()
                            const transId = resp.data.transId
                            this.$router.push({ name: 'Refund', params: { rekapTransaksiId: transId } });

                        }else{
                            this.$swal.fire({
                                title: 'Info!',
                                html: 'Transaksi tidak ditemukan',
                                icon: 'info'
                            })
                        }
                        

                    })
                    
                

                }else{
                    this.$swal.fire({
                        title: 'Peringatan!',
                        html: 'Nomor Transaksi dan Tanggal harus diisi',
                        icon: 'warning'
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>