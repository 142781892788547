import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import moment from "moment-timezone";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

moment.tz.setDefault("Asia/Jakarta");
axios.defaults.baseURL = process.env.VUE_APP_URL;
const token = localStorage.getItem("token");
axios.defaults.headers["Authorization"] = `Bearer ${token}`;
// axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
const app = createApp(App);

app.use(VueSweetalert2);
app.use(store).use(router).mount("#app");
