const state = () => ({
  counter: 0,
});

const getters = {
  counter: (state: any, getters: any, rootState: any) => {
    return state.counter;
  }
};

const actions = {
  async incCounter({ commit, state }: any) {
    commit('incCounter');
  },
  async decCounter({ commit, state }: any) {
    commit('decCounter');
  }
};

const mutations = {
  incCounter(state: any) {
    state.counter += 1;
  },
  decCounter(state: any) {
    state.counter -= 1;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}