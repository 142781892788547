<template>
    <div>
        <Nav></Nav>

        <section class="py-4 section-app">
            <div class="container-fluid">
                <div class="card h-100">
                    <div class="card-header bg-accent text-white font-weight-bold">Form Stok Menu</div>
                    <div class="table-responsive bg-accent">
                        <ul class="nav nav-menu flex-nowrap text-nowrap">
                            <li v-on:click="loadMenuList(stockMeja.m_menu_jenis_id)" v-for="stockMeja in stockMenuList" :key="stockMeja.m_menu_jenis_id" class="nav-item"><a href="#" :id="stockMeja.m_menu_jenis_id" class="nav-link " data-toggle="tab" :data-target="stockMeja.m_menu_jenis_id">{{stockMeja.m_menu_jenis_nama}}</a></li>
                        </ul>
                    </div>
                    <div class="card-body p-3">
                        <div class="row mx-n2">
                            <div v-for="rowStock in stockList" :key="rowStock.m_menu_id" class="col-lg-2 px-2 mb-3">
                                <div class="card">
                                    <div class="card-body p-3">
                                        <div class="form-group mb-0">
                                            <label for=""><small>{{ rowStock.m_menu_nama }}</small></label>
                                            <input type="text" class="form-control" :value="rowStock.m_stock_stock_awal">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button class="btn btn-primary" :style="showButtonSave" >Simpan</button>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script lang="js" >
    import {onMounted, ref} from 'vue';
    import axios from 'axios';
    import{useRouter} from 'vue-router';
    import Nav from '@/components/secure/dashboard/Nav.vue'

    export default {
        name: "Dashboard",
        components: {
            Nav,
        },
        data(){
            return{
                stockMenuList: [],
                stockList: [],
                showButtonSave: "display:none"
            }
        },
        mounted() {
            this.load();
            document.getElementById("stockNavigation").classList.add("active");
        },
        methods: {
            load(){
                axios.get('/m-menu-jenis').then(res => {
                    this.stockMenuList = res.data
                    this.loadMenuList(res.data[0].m_menu_jenis_id)
                }).catch ((err) => {
                    console.log(err);
                })
            },
            loadMenuList(idMenuJenis){
                axios.get('/m-menu/indexStock?m_menu_m_menu_jenis_id='+idMenuJenis).then(res => {
                    this.stockList = res.data
                    if(res.data.length > 0){
                        this.showButtonSave = ""
                    }else{
                        this.showButtonSave = "display:none"
                    }
                }).catch ((err) => {
                    console.log(err);
                })
            }
        },
        setup() {
            const user = ref(null);
            const router = useRouter();

            onMounted( async () => {
                try {
                    const response = await axios.get('/user');
                    user.value = response.data.data;
                } catch (e) {
                    await router.push({ name: 'loginPage' });
                }
            });

            return {
                user,
            }

        },
    }
</script>
