const state = () => ({
  warung: null,
  area: null
})

const getters = {
  warung: (state: any, getters: any, rootState: any) => {
    return state.warung
  },
  area: (state: any, getters: any, rootState: any) => {
    return state.area
  }
};

const actions = {
  async setWarung({ commit, state }: any, data: any) {
    commit('setWarung', data);
  },
  async clearWarung({ commit, state }: any) {
    commit('clearWarung');
  },
  async setArea({ commit, state }: any, data: any) {
    commit('setArea', data);
  },
  async clearArea({ commit, state }: any) {
    commit('clearArea');
  }
};

const mutations = {
  setWarung(state: any, value: any) {
    state.warung = value;
  },
  clearWarung(state: any) {
    state.warung = null;
  },
  setArea(state: any, value: any) {
    state.area = value;
  },
  clearArea(state: any) {
    state.area = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
