
import { defineComponent, computed, ref } from 'vue'
import InputVoucherModal from './InputVoucherModal.vue'
import VoucherModal from './VoucherModal.vue'

export default defineComponent({
  name: 'Voucher',
  components: {
    VoucherModal,
    InputVoucherModal
  },
  emits: [
    'on-hide'
  ],
  props: ['modalShow'],
  setup(props: any, context: any) {
    const voucherModalShow = computed(() => props.modalShow)
    const inputVoucherModalShow = ref(false)
    const onVoucher = () => inputVoucherModalShow.value = true
    const onInputVoucherHide = () => inputVoucherModalShow.value = false
    const onVoucherHide = () => context.emit('on-hide')
    const modalVisible = computed(() => props.modalShow || inputVoucherModalShow.value)

    return {
      voucherModalShow,
      inputVoucherModalShow,
      onVoucher,
      onVoucherHide,
      onInputVoucherHide,
      modalVisible
    }
  }
})
