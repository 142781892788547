
import { defineComponent, ref, computed, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import { formatMoney } from '@/mixins/app'

export default defineComponent({
  name: 'RecapSalesMenu',
  setup() {
    const store = useStore()
    const modal = computed(() => store.state['shift'].modal.modal)
    const modalNominal = computed(() => {
      if (modal.value == null) {
        return 0
      }
      return modal.value.m_modal_nominal
    })
    const spendings = computed(() => store.state['shift'].spendings)
    const promo = computed(() => store.state['shift'].promo)
    const payments = computed(() => store.state['shift'].payment)
    const hideModalNominal = computed(() => {
      if (store.state['shift'].modal === null) {
        return true
      }
      return false
    })
    const remainingModal = computed(() => modalNominal.value + spendings.value.total)
    const cashTotal = computed(() => payments.value.cashTotal)
    let sales = reactive({} as any)
    let dineInSales = reactive({})
    let takeAwaySales = reactive({})
    let ojolSales = reactive({} as any)
    let menuSales = reactive({})
    let nonMenuSales = reactive({})

    const canceledRecap = ref({})
    onMounted(() => {
      axios.get('cashier/sales').then(resp => {
        store.dispatch('shifts/setSales', resp.data)

        sales = resp.data
        menuSales = resp.data.menu_sales
        nonMenuSales = resp.data.non_menu_sales

        const regSalesErr = resp.data.reg_category_sales.error
        if (regSalesErr == 1) {
          dineInSales = resp.data.reg_category_sales.items
          takeAwaySales = resp.data.reg_category_sales.items
        } else if (regSalesErr == 0) {
          const initialData = {
            reg_sales_total: 0,
            sales_total: 0,
            count_nota: 0,
            reg_sales_tax: 0,
            reg_sales_service: 0
          }

          dineInSales = resp.data.reg_category_sales.items.reduce((acc: any, val: any) => {
            const transactionName = val.transaction_name.toLowerCase()
            if (transactionName == 'dine in') {
              acc = {
                ...acc, 
                reg_sales_total: acc.reg_sales_total + val.reg_sales_total,
                sales_total: acc.sales_total + val.sales_total,
                count_nota: acc.count_nota + val.count_nota,
                reg_sales_tax: acc.reg_sales_tax + val.reg_sales_tax,
                reg_sales_service: acc.reg_sales_service + val.reg_sales_service
              }
            }
            return acc
          }, initialData)
          
          takeAwaySales = resp.data.reg_category_sales.items.reduce((acc: any, val: any) => {
            const transactionName = val.transaction_name.toLowerCase()
            if (transactionName == 'take away') {
              acc = {
                ...acc, 
                reg_sales_total: acc.reg_sales_total + val.reg_sales_total,
                sales_total: acc.sales_total + val.sales_total,
                count_nota: acc.count_nota + val.count_nota,
                reg_sales_tax: acc.reg_sales_tax + val.reg_sales_tax,
                reg_sales_service: acc.reg_sales_service + val.reg_sales_service
              }
            }
            return acc
          }, initialData)
        }

        const ojolInitialData = {
          reg_sales_total: 0,
          sales_total: 0,
          count_nota: 0,
          reg_sales_tax: 0,
          reg_sales_service: 0,
          sharing_profit: 0
        }
        const ojolSalesErr = resp.data.ojol_category_sales.error
        if (ojolSalesErr == 0) {
          ojolSales = resp.data.ojol_category_sales.items.reduce((acc: any, val: any) => {
            acc = {
              ...acc, 
              reg_sales_total: acc.reg_sales_total + val.reg_ojol_sales_total,
              sales_total: acc.sales_total + val.ojol_sales_total,
              count_nota: acc.count_nota + val.count_nota,
              reg_sales_tax: acc.reg_sales_tax + val.ojol_sales_tax,
              reg_sales_service: acc.reg_sales_service + val.ojol_sales_service,
              sharing_profit: acc.sharing_profit + val.ojol_sharing_total
            }
            return acc
          }, ojolInitialData)
        } else {
          ojolSales = ojolInitialData
        }
        
        console.log(resp.data)
      }).catch(err => console.log)
    })

    // Total penjualan regular
    const regSalesTotal = computed(() => {
      if (!Object.prototype.hasOwnProperty.call(sales, 'reg_category_sales')) {
        return 0
      }

      const err = parseInt(sales.reg_category_sales.error)
      if (err === 1 || sales.reg_category_sales.items.length == 0) {
        return 0
      }

      return sales.reg_category_sales.items.reduce((acc: number, val: any) => acc + val.reg_sales_total, 0)
    })

    // Total pajak + service charge
    const taxSrvChargeTotal = computed(() => {
      if (!Object.prototype.hasOwnProperty.call(sales, 'reg_category_sales')) {
        return 0
      }
      
      const err = parseInt(sales.reg_category_sales.error)
      if (err === 1 || sales.reg_category_sales.items.length == 0) {
        return 0
      }
      return sales.reg_category_sales.items.reduce((acc: number, val: any) => acc + val.reg_sales_tax + val.reg_sales_service, 0)
    })

    // Total penjualan
    const salesTotal = computed(() => {
      if (!Object.prototype.hasOwnProperty.call(sales, 'reg_category_sales')) {
        return 0
      }
      
      const err = parseInt(sales.reg_category_sales.error)
      if (err === 1 || sales.reg_category_sales.items.length == 0) {
        return 0
      }

      return sales.reg_category_sales.items.reduce((acc: number, val: any) => {
        acc + val.reg_sales_total + val.reg_sales_tax + val.reg_sales_service
      }, 0)
    })

    // Total penjualan regular dan ojol
    const regOjolTotal = computed(() => {
      let res = 0
      if (salesTotal.value && ojolSales.reg_sales_total) {
        res += salesTotal.value + ojolSales.reg_sales_total
      }
      return res
    })

    // Total pajak regular dan ojol
    const regOjolTaxTotal = computed(() => {
      let res = 0
      if (taxSrvChargeTotal.value && ojolSales.reg_sales_tax) {
        res += taxSrvChargeTotal.value + ojolSales.reg_sales_tax
      }
      return res 
    })

    // Total penjualan regular + ojol plus pajak
    const regOjolTotalPlusTax = computed(() => {
      let res = 0
      if (regOjolTotal.value && regOjolTaxTotal.value) {
        res += regOjolTotal.value + regOjolTaxTotal.value
      }
      return res 
    })

    // Total penjualan ojol
    const ojolSalesTotal = computed(() => {
      let res = 0
      if (regOjolTotal.value && regOjolTaxTotal.value) {
        res += regOjolTotal.value + regOjolTaxTotal.value
      }
      return res
    })

    // Pajak pendapatan
    const incomeTax = computed(() => {
      let res = 0
      if (promo.value) {
        res += parseFloat(promo.value.pajak) + parseFloat(promo.value.service) + parseFloat(promo.value.nominal)
      }
      return res
    })

    // Pajak plus layanan
    const taxAndService = computed(() => {
      let res = 0
      if (promo.value.pajak) {
        res += promo.value.pajak
      }

      if (promo.value.service) {
        res += promo.value.service
      }
      return res
    })

    // Penjualan plus pajak
    const salePlusTax = computed(() => {
      let res = 0
      if (taxAndService.value) {
        res += taxAndService.value
      }

      if (promo.value.nominal) {
        res += parseFloat(promo.value.nominal)
      }
      return res
    })

    // Total bayar
    const paymentTotal = computed(() => {
      let res = 0
      if (payments.value.cashTotal) {
        res += payments.value.cashTotal
      }

      if (payments.value.nonTunaiTotal) {
        res += payments.value.nonTunaiTotal
      }
      return res
    })

    // Cek pendapatan dan pembayaran
    const incomeAndPaymentCheck = computed(() => {
      let res = 0
      if (!salePlusTax.value) {
        return res
      }

      res = salePlusTax.value
      if (paymentTotal.value) {
        res -= paymentTotal.value 
      }

      if (promo.value.free_kembalian) {
        res -= parseFloat(promo.value.free_kembalian)
      }

      if (promo.value.free_kembalian_void) {
        res -= parseFloat(promo.value.free_kembalian_void)
      }
      return res
    })

    // Total uang riil
    const realMoneyTotal = computed(() => {
      let res = 0
      if (remainingModal.value) {
        res += remainingModal.value
      }

      if (payments.value.cashTotal) {
        res += payments.value.cashTotal
      }
      return res
    })

    return {
      modal,
      modalNominal,
      remainingModal,
      spendings,
      promo,
      hideModalNominal,
      cashTotal,
      payments,
      canceledRecap,
      sales,
      dineInSales,
      takeAwaySales,
      menuSales,
      nonMenuSales,
      ojolSales,
      regSalesTotal,
      taxSrvChargeTotal,
      salesTotal,
      regOjolTotal,
      regOjolTaxTotal,
      regOjolTotalPlusTax,
      ojolSalesTotal,
      incomeTax, // Pendapatan + pajak,
      incomeAndPaymentCheck,
      realMoneyTotal,
      paymentTotal,
      formatMoney
    }
  }
})
