
import { defineComponent, onMounted, ref, toRefs } from 'vue'
import axios from 'axios'

export default defineComponent({
  name: 'RecapCancelledMenu',
  setup() {
    const cancelledRecap = ref([]) as any
    const warranty = ref([]) as any
    const mutModalEmpty = ref(false)

    onMounted(() => {
      axios.get('cashier/canceled-recap-data').then(resp => {
        cancelledRecap.value = resp.data
        if (resp.data.length < 1) {
          mutModalEmpty.value = true
        }
      })
      .catch(err => console.log)

      axios.get('cashier/warranty-data').then(resp => {
        console.log(resp)
      })
      .catch(err => console.log)
    })

    const onClick = (data: any) => console.log(toRefs(data))
    return {
      cancelledRecap,
      warranty,
      onClick,
      mutModalEmpty
    }
  }
})
