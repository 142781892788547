/**
 * Index Vuex
 * 
 * @author Agus Susilo<smartgdi@gmail.com>
 */

import { createStore, createLogger } from 'vuex'
import demo from './modules/demo'
import drawer from './modules/drawer'
import modal from './modules/modal'
import history from './modules/history'
import user from './modules/user'
import nav from './modules/nav'
import warung from './modules/warung'
import confirm_modal from './modules/confirm_modal'
import uang_tips from './modules/uang_tips'
import order from './modules/order'
import voucher from './modules/voucher'
import table from './modules/table'
import shift from './modules/shift'
import mutation from './modules/mutation'
import dashboard from './modules/dashboard'
import production from './modules/production'

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  plugins: process.env.NODE_ENV === 'development' ? [createLogger()] : [],
  modules: {
    demo,
    modal,
    history,
    drawer,
    user,
    nav,
    warung,
    confirm_modal,
    uang_tips,
    order,
    voucher,
    table,
    shift,
    mutation,
    dashboard,
    production
  },
  state() {
    return {
      autoRefresh: false
    }
  },
  mutations: {
    setAutoRefresh(state: any, value: boolean) {
      state.autoRefresh = value
    }
  },
  getters: {
    autoRefresh: (state: any, getters: any, rootState: any) => state.autoRefresh
  },
  actions: {
    async enableAutoRefresh({ commit, state }: any) {
      commit('setAutoRefresh', true)
    },
    async disableAutoRefresh({ commit, state }: any) {
      commit('setAutoRefresh', false)
    }
  },
  devtools: true
})
