<template>
    <div>
        <Nav></Nav>
        <section class="py-4 section-app">
            <div class="container-fluid">
                <div class="row mx-n2 mb-2">
                    <div class="col-lg-12 px-2">
                        <div class="card bg-light">
                            <div class="card-body p-3">
                                <div class="row mx-n2">
                                    <div class="col-lg-6 px-2">
                                        <table width="100%">
                                            <tr>
                                                <td width="150">Meja Lengkap</td>
                                                <td width="10">:</td>
                                                <td>
                                                    <a data-toggle="modal" data-target="#modal-table-menu" href="javascript:void(0)" v-on:click="getMenuByTransaction(nota.m_transaction_table_id)" v-for="nota in transactionTableComplete" :key="nota.m_transaction_table_id" class="badge badge-success py-2 px-4 my-1 mx-1">
                                                        <span v-if="nota.m_transaction_table_table_name_list!=''"> {{nota.m_transaction_table_table_name_list}}</span>
                                                        <span v-else>{{nota.transaction_name}} ({{nota.m_transaction_table_customer_name}})</span>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Urutan Meja</td>
                                                <td>:</td>
                                                <td>
                                                    <a data-toggle="modal" data-target="#modal-table-menu" href="javascript:void(0)" v-on:click="getMenuByTransaction(nota.m_transaction_table_id)" v-for="nota in transactionTableNotComplete" :key="nota.m_transaction_table_id" class="badge badge-danger py-2 px-4 my-1 mx-1">
                                                        <span v-if="nota.m_transaction_table_table_name_list!=''"> {{nota.m_transaction_table_table_name_list}}</span>
                                                        <span v-else>{{nota.transaction_name}} ({{nota.m_transaction_table_customer_name}})</span>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Urutan Bungkus</td>
                                                <td>:</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Tambahan</td>
                                                <td>:</td>
                                                <td>
                                                    <a data-toggle="modal" data-target="#modal-table-menu" href="javascript:void(0)" v-on:click="getMenuByTransaction(nota.m_transaction_table_id)" v-for="nota in transactionTableAdditional" :key="nota.m_transaction_table_id" class="badge badge-danger py-2 px-4 my-1 mx-1">
                                                        <span v-if="nota.m_transaction_table_table_name_list!=''"> {{nota.m_transaction_table_table_name_list}}</span>
                                                        <span v-else>{{nota.transaction_name}} ({{nota.m_transaction_table_customer_name}})</span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="table-responsive bg-accent">
                        <ul class="nav nav-menu flex-nowrap text-nowrap" ref="menuList">
                            <li v-on:click="loadAllMenu()" class="nav-item"><a href="#" class="nav-link active" data-toggle="tab">Semua</a></li>
                            <li :id="menuJenis.m_menu_jenis_id" v-on:click="loadKategoriList(menuJenis)" v-for="menuJenis in allMenuJenis" :key="menuJenis.m_menu_jenis_id" class="nav-item">
                                <a href="#" class="nav-link" data-toggle="tab" :data-target="menuJenis.m_menu_jenis_id">
                                    {{menuJenis.m_menu_jenis_nama}}
                                    <span v-if="sumDishByMenuJenis[menuJenis.m_menu_jenis_id]" class="badge badge-danger py-1 px-2 ml-1">{{sumDishByMenuJenis[menuJenis.m_menu_jenis_id].total}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-content mb-2" style="max-height: calc(100vh - 450px); height: 100vh">
                        <div class="tab-pane active pb-2" id="minuman">
                            <template v-if="menuCategory.length>0">
                                <div class="table-responsive border-bottom">
                                    <ul class="nav nav-submenu flex-nowrap text-nowrap">
                                        <li v-on:click="loadMenuList(selectedMenuJenisId)" class="nav-item"><a href="#" class="nav-link active" data-toggle="tab" data-target="#minuman-semua">Semua</a></li>
                                        <li v-on:click="loadMenuByKategori(kategori.m_kategori_id)" :id="kategori.m_kategori_id" v-for="kategori in menuCategory" :key="kategori.m_kategori_id" class="nav-item">
                                            <a href="#" class="nav-link" data-toggle="tab" :data-target="kategori.m_kategori_id">
                                                {{kategori.m_kategori_nama}}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                            <div class="tab-content">
                                <div class="tab-pane active p-3" style="max-height: calc(100vh - 450px);overflow: auto;">
                                    <div class="row row-menu-list">
                                        <div v-for="menu in allMenu" :key="menu.m_menu_id" class="col">
                                            <a v-if="sumDishByMenu[menu.m_menu_id]" v-on:click="loadDishTableByMenu(menu.m_menu_id)" href="javascript:void(0)" class="card card-menu" data-toggle="modal" data-target="#modal-daftar-pesanan">
                                                <div class="card-body">{{ menu.m_menu_nama }}</div>
                                            </a>
                                            <a v-else class="card card-menu disabled" data-toggle="modal">
                                                <div class="card-body">{{ menu.m_menu_nama }}</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-3">
                    <div class="card-header bg-accent text-white">Menu Yang Di Cancel</div>
                    <div class="card-body">
                        <div class="row">
                            <div v-for="cancel in dishCancelled" :key="cancel.id" class="col-lg-2 mb-1">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="font-weight-bold">{{cancel.m_menu_nama}}</div>
                                        <div>{{cancel.m_transaction_table_note_number}}</div>
                                        <div>{{cancel.date}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>

        <div class="modal fade" id="modal-daftar-pesanan">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Daftar Pesanan : {{selectedMenuPopup.m_menu_nama}}</h5>
                        <button type="button" class="close text-white" id="close-daftar-pesanan" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="card">
                            <div class="card-header bg-transparent font-weight-bold">MEJA</div>
                            <div class="card-body">
                                <div class="row">
                                    <div v-for="table in selectedDishTable" :key="table.m_dish_status_id" class="col-lg-3 mb-2">
                                        <a v-on:click="setDishReady(table.m_dish_status_id, table.m_dish_status_menu_id, table.m_transaction_table_id)" href="javascript:void(0)" class="card h-100 bg-success">
                                            <div class="card-header font-weight-bold bg-transparent text-center text-white">
                                                {{table.m_transaction_table_table_name_list}}
                                            </div>
                                            <div class="card-body text-center text-white">
                                                {{table.custom_menu}}
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mt-2">
                            <div class="card-header bg-transparent font-weight-bold">BUNGKUS</div>
                            <div class="card-body">
                                <div class="row">
                                    <div v-for="table in selectedDishWrap" :key="table.m_dish_status_id" class="col-lg-3 mb-2">
                                        <a v-on:click="setDishReady(table.m_dish_status_id, table.m_dish_status_menu_id, table.m_transaction_table_id)" href="javascript:void(0)" class="card h-100 bg-success">
                                            <div class="card-header font-weight-bold bg-transparent text-center text-white">
                                                {{table.name}}<br/>
                                                {{table.m_transaction_table_customer_name}}
                                            </div>
                                            <div class="card-body text-center text-white">
                                                {{table.custom_menu}}
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modal-edit-menu">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Edit Menu</h5>
                        <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="">Qty</label>
                            <input type="text" class="form-control number">
                        </div>
                        <div class="form-group">
                            <label for="">Keterangan</label>
                            <textarea name="" id="" cols="30" rows="5" class="form-control"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Batal</button>
                        <button type="button" class="btn btn-accent" data-dismiss="modal">Simpan</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Table Menu -->
        <div class="modal fade" id="modal-table-menu">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Daftar Pesanan</h5>
                        <button type="button" class="close text-white" id="close-warning" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        
                        <div v-for="(menuJenis, index) in tableMenu" class="card mb-2" :key="index">
                            <div class="card-header bg-accent text-white font-weight-bold" >{{menuJenis.name}}</div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr class="border-bottom mb-1">
                                            <th style="width: 10%">No</th>
                                            <th style="width: 70%">Nama Menu</th>
                                            <th style="width: 20%" class="text-right"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(menu, index) in menuJenis.items" :key="index">
                                            <td style="width: 10%">{{index+1}}</td>
                                            <td style="width: 70%" class="text-wrap">{{menu.m_menu_nama}}</td>
                                            <td style="width: 20%" class="text-right">
                                                <a v-if="menu.is_ready==1" v-on:click="setDishNotReady(menu.id, menu.m_dish_status_menu_id, menu.m_dish_status_table_id)" href="#" data-toggle="modal" data-target="#modal-kembalikan-menu"><i class="fa fa-check-circle fa-2x text-success"></i></a>
                                                <a v-else v-on:click="setDishReady(menu.id, menu.m_dish_status_menu_id, menu.m_dish_status_table_id)" href="#" data-toggle="modal" data-target="#modal-kembalikan-menu"><i class="fa fa-times-circle fa-2x text-danger"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
        
</template>
<script lang="js" >
    import {onMounted, ref} from 'vue';
    import axios from 'axios';
    import{useRouter} from 'vue-router';
    import Nav from '@/components/secure/odcr/Nav.vue'
    import moment from 'moment'

    export default {
        name: "HalTata",
        components: {
            Nav
        },
        data(){
            return{
                transactionTableNotComplete: [],
                transactionTableComplete: [],
                transactionTableAdditional: [],
                allMenu: [],
                menuCategory: [],
                allMenuJenis: [],
                selectedMenuJenisId: {
                    m_menu_jenis_id: ''
                },
                sumDishByMenu: [],
                sumDishByMenuJenis: [],
                dishCancelled: [],
                selectedDishTable: [],
                selectedDishWrap: [],
                selectedMenuPopup: {},
                tableMenu: []
            }
        },
        mounted() {
            
            this.load();
            window.setTimeout( "loadingComponentOrder()" ,750);
            document.getElementById("halTataNavigation").classList.add("active");
        },
        watch: {
            '$route.params.type': function (id) {
                this.transactionTableNotComplete=[],
                this.transactionTableComplete=[],
                this.transactionTableAdditional=[],
                this.allMenu=[],
                this.menuCategory=[],
                this.allMenuJenis= [],
                this.sumDishByMenu= [],
                this.sumDishByMenuJenis= [],
                this.dishCancelled= [],
                this.selectedDishTable= [],
                this.selectedDishWrap= [],
                this.selectedMenuPopup= {},
                this.tableMenu= []
                this.selectedMenuPopup={},
                this.load()
            }
        },
        methods: {
            load(){
                axios.get('/m-transaction-table/ready-status?type='+this.$route.params.type).then(res => {

                    this.transactionTableComplete = res.data.data.completed
                    this.transactionTableNotComplete = res.data.data.notCompleted
                    this.transactionTableAdditional = res.data.data.additional

                    this.loadMenuJenis()
                    this.loadDishStatus()
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            loadReadyStatus(){
                axios.get('/m-transaction-table/ready-status?type='+this.$route.params.type).then(res => {

                    console.log(res);
                    

                    this.transactionTableComplete = res.data.data.completed
                    this.transactionTableNotComplete = res.data.data.notCompleted
                    this.transactionTableAdditional = res.data.data.additional
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            loadDishStatus(){
                axios.get('/m-dish-status/available?type='+this.$route.params.type).then(res => {

                    console.log(res);
                    

                    if (res.data.meta.code==200) {
                        this.sumDishByMenu = res.data.data.by_menu
                        this.sumDishByMenuJenis = res.data.data.by_menu_jenis
                        this.dishCancelled = res.data.data.cancelled
                    }

                    this.dishCancelled.forEach(element => {
                        element.date = moment(element.updated_at).format('DD-MM-YYYY, HH:mm:ss')
                    });

                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            loadMenuJenis(){
                axios.get('/m-menu-jenis?m_menu_jenis_odcr55=makan').then(res => {
                    
                    this.allMenuJenis = res.data
                    this.loadAllMenu();
                }).catch ((err) => {
                    console.log(err);
                })
            },
            loadAllMenu(){
                axios.get('/mmenu').then(res => {
                    
                    this.menuCategory = []
                    this.allMenu = res.data
                    
                }).catch ((err) => {
                    console.log(err);
                })
            },
            loadKategoriList(menuJenis){
                axios.get('/m-kategori?m_kategori_m_menu_jenis_id='+menuJenis.m_menu_jenis_id).then(res => {
                    
                    const temp = []
                    
                    if (res.data.length>0) {
                        
                        res.data.forEach(element => {
                            
                            const e = JSON.parse(JSON.stringify(element));
                            temp.push({
                                m_kategori_id : e.m_kategori_id,
                                m_kategori_nama : e.m_kategori_nama,
                                m_kategori_m_menu_jenis_id: e.m_kategori_m_menu_jenis_id
                            })
                        });

                    }
                    this.selectedMenuJenisId.m_menu_jenis_id = menuJenis.m_menu_jenis_id
                    this.loadMenuList(menuJenis)
                    this.menuCategory = temp
                    
                }).catch ((err) => {
                    console.log(err);
                })
            },
            loadMenuList(menuJenis){
                axios.get('/mmenu?m_menu_m_menu_jenis_id='+menuJenis.m_menu_jenis_id).then(res => {
                    
                    this.allMenu = res.data
                    
                }).catch ((err) => {
                    console.log(err);
                })
            },
            loadMenuByKategori(idKategori){
                axios.get('/m-menu/byKategori/'+idKategori).then(res => {
                    
                    this.allMenu = res.data
                    
                }).catch ((err) => {
                    console.log(err);
                })
            },
            loadDishTableByMenu(menu = this.selectedMenuPopup){
                axios.get('/m-dish-status/table-by-menu/'+menu).then(res => {
                    
                    this.selectedMenuPopup = menu
                    console.log(menu);
                    

                    const newTable = []
                    const newWrap = []

                    res.data.data.forEach(function(dish) {
                        if (dish.name=='Dine In') {
                            newTable.push(dish)
                        } else {
                            newWrap.push(dish)
                        }
                    });

                    this.selectedDishTable = newTable
                    this.selectedDishWrap = newWrap

                    console.log(res.data.data);

                    if (res.data.data.length==0) {
                        document.getElementById('close-daftar-pesanan').click()
                    }
                    
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            setDishReady(id, menuId, tableId){

                console.log(menuId);
                
                axios.get('/m-dish-status/set-ready/'+id).then(res => {
                    
                    if(res.data.meta.code == 200){
                        this.loadDishTableByMenu(menuId)
                        this.loadDishStatus()
                        this.getMenuByTransaction(tableId)
                        this.loadReadyStatus()
                    }
                    
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            setDishNotReady(id, menuId, tableId){
                axios.get('/m-dish-status/set-not-ready/'+id).then(res => {
                    
                    if(res.data.meta.code == 200){
                        this.loadDishTableByMenu(menuId)
                        this.loadDishStatus()
                        this.getMenuByTransaction(tableId)
                        this.loadReadyStatus()

                    }
                    
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            getMenuByTransaction(id){
                axios.get('/m-dish-status/by-transaction/'+id).then(res => {
                    
                    const menuJenis = this.allMenuJenis
                    const newTableMenu = []

                    if(res.data.meta.code == 200){

                        console.log(res.data.data);
                        
                        menuJenis.forEach(function (jenis) {
                            const newItems = []
                            res.data.data.forEach(function(menu) {
                                
                                if (menu.m_dish_status_menu_jenis_id == jenis.m_menu_jenis_id) {
                                    newItems.push(menu)
                                }
                            });
                            if (newItems.length>0) {
                                newTableMenu.push({
                                    name: jenis.m_menu_jenis_nama,
                                    items: newItems
                                })
                            }
                        });

                        this.tableMenu = newTableMenu
                    }
                    
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            }
        },
        setup() {
            const user = ref(null);
            const router = useRouter();

            onMounted( async () => {
                try {
                    const response = await axios.get('/user');
                    user.value = response.data.data;
                } catch (e) {
                    await router.push({ name: 'loginPage' });
                }
            });

            return {
                user,
            }

        },
    }
</script>
