<template>
    <div>
        <Nav></Nav>

        <section class="py-4 section-app">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header font-weight-bold">
                        <div class="row align-items-center">
                            <div class="col-auto"><button v-on:click="setupPopup('Grab')" class="btn btn-action" data-toggle="modal" data-target="#modal-input-nota"><i class="feather-plus"></i> ORDER</button></div>
                            <div class="col-auto"><img :src="grab" alt="" height="20"></div>
                        </div>
                    </div>
                    <div class="card-body bg-light" style="min-height: calc((100vh - 300px) / 3);">
                        <div class="row mx-n2 row-meja">
                            <div class="col mb-3 px-2" v-on:click="toOrder(order.m_transaction_table_id)" v-for="order in grabOrder" :key="order.m_transaction_table_id">
                                <a href="javascript:void()"  class="card card-meja h-100 text-decoration-none">
                                    <div class="card-body align-self-center justify-content-center text-center"><br/> {{order.m_transaction_table_note_number}}</div>
                                    <div class="card-footer">{{order.m_transaction_table_customer_name}}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-header font-weight-bold">
                        <div class="row align-items-center">
                            <div class="col-auto"><button v-on:click="setupPopup('Gojek')" class="btn btn-action" data-toggle="modal" data-target="#modal-input-nota"><i class="feather-plus"></i> ORDER</button></div>
                            <div class="col-auto"><img :src="gojek" alt="" height="20"></div>
                        </div>
                    </div>
                    <div class="card-body bg-light" style="min-height: calc((100vh - 300px) / 3);">
                        <div class="row mx-n2 row-meja">
                            <div class="col mb-3 px-2" v-on:click="toOrder(order.m_transaction_table_id)" v-for="order in gojekOrder" :key="order.m_transaction_table_id">
                                <a href="javascript:void()"  class="card card-meja h-100 text-decoration-none">
                                    <div class="card-body align-self-center justify-content-center text-center"><br/> {{order.m_transaction_table_note_number}}</div>
                                    <div class="card-footer">{{order.m_transaction_table_customer_name}}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-header font-weight-bold">
                        <div class="row align-items-center">
                            <div class="col-auto"><button v-on:click="setupPopup('Maxim')" class="btn btn-action" data-toggle="modal" data-target="#modal-input-nota"><i class="feather-plus"></i> ORDER</button></div>
                            <div class="col-auto"><img :src="maxim" alt="" height="30"></div>
                        </div>
                    </div>
                    <div class="card-body bg-light" style="min-height: calc((100vh - 300px) / 3);">
                        <div class="row mx-n2 row-meja">
                            <div class="col mb-3 px-2" v-on:click="toOrder(order.m_transaction_table_id)" v-for="order in maximOrder" :key="order.m_transaction_table_id">
                                <a href="javascript:void()"  class="card card-meja h-100 text-decoration-none">
                                    <div class="card-body align-self-center justify-content-center text-center"><br/> {{order.m_transaction_table_note_number}}</div>
                                    <div class="card-footer">{{order.m_transaction_table_customer_name}}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Modal Input Nota by staff-->
        <div class="modal fade" id="modal-input-nota">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Input Info Nota</h5>
                        <button id="closeModalInformation" v-on:click="loadTransactions()" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="form" id="transactionTableSubmitData" @submit.prevent="transactionTableSubmit" method="post">
                        <div class="modal-body">
                            <div class="mb-4"><img :src="selectedTypeLogo" alt="" height="20"></div>
                            <div class="row">
                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <input id="table-type-transaction" name="m_transaction_table_type_transaction" type="hidden"/>
                                        <label class="col-form-label col px-1" for="">
                                            Nomor Nota
                                        </label>
                                        <input type="text" class="form-control numeric" name="m_transaction_table_note_number" autocomplete="off" required>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label for="">Nama Bigboss</label>
                                        <input type="text" class="form-control"  name="m_transaction_table_customer_name" autocomplete="off" required>
                                    </div>
                                    
                                </div>

                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <label class="col-form-label col px-1" for="tableSelectedMerge">Staff</label>
                                        <select name="m_transaction_table_by" id="tableStaff" class="form-control select2" required>
                                        </select>
                                    </div>
                                    <div class="row mx-n2">
                                        <div class="col-5 px-2">
                                            <div class="form-group">
                                                <label class="col-form-label col px-1" for="">Jam</label>
                                                <input id="input_time" type="time" class="form-control timepicki" name="m_transaction_table_order_time" required>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" v-on:click="loadTransactions()" class="btn btn-light" data-dismiss="modal">Batal</button>
                            <button type="submit" class="btn btn-accent" >Proses</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="js" >
    import {onMounted, ref} from 'vue';
    import axios from 'axios';
    import{useRouter} from 'vue-router';
    import Nav from '@/components/secure/odcr/Nav.vue'
    const gojek = '/assets/images/ojol/gojek.png'
    const grab = '/assets/images/ojol/grab.png'
    const maxim = '/assets/images/ojol/maxim.png'


    export default {
        name: "OdcrOnline",
        components: {
            Nav,
        },
        data(){
             return {
                selectedTypeLogo: {},
                listTransactions: [],
                allStaff: [],
                grabOrder: [],
                gojekOrder: [],
                maximOrder: []
             }
        },
        mounted() {
            document.getElementById("odcrOnlineNNavigation").classList.add("active");
            window.setTimeout( "loadingComponent()" ,750);

            this.loadTransactions()
        },
        methods: {
            loadTransactions(){
                axios.get('/tmp-transaction?ojol=true').then(res => {
                    console.log(res);
                    const grabs = []
                    const gojeks = []
                    const maxims = []

                    res.data.forEach(function(e)  {
                        if (e.transaction_name == 'Grab') {
                            grabs.push(e)
                        } else if (e.transaction_name == 'Gojek') {
                            gojeks.push(e)
                        } else if (e.transaction_name == 'Maxim') {
                            maxims.push(e)
                        }
                    });

                    this.grabOrder = grabs
                    this.gojekOrder = gojeks
                    this.maximOrder = maxims

                    this.loadStaff()
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            loadStaff(){
                axios.get('/users').then(res => {                    
                    console.log(res);
                    this.allStaff = res.data
                }).catch ((err) => {
                    console.log(err);
                })
            },
            setupPopup(type){
                switch (type) {
                    case 'Gojek':
                        this.selectedTypeLogo = gojek
                        break;
                    case 'Grab':
                            this.selectedTypeLogo = grab
                            break;
                    case 'Maxim':
                            this.selectedTypeLogo = maxim
                            break;
                    
                    default:
                        break;
                }   

                const tableStaff = document.getElementById('tableStaff')
                const staffs = this.allStaff

                let staffHtml = "<option disabled selected>Pilih Karyawan</option>"
                staffs.forEach(function(e) {
                    const html = "<option value="+e.id+">"+e.name.toUpperCase()+"</option>"
                    staffHtml+=html
                });

                tableStaff.innerHTML = staffHtml 

                document.getElementById('table-type-transaction').value = type
        
                const tanggal = new Date();
                
                const jam = ('0'+tanggal.getHours()).substr(-2);
                const menit = ('0'+tanggal.getMinutes()).substr(-2);

                document.getElementById("input_time").defaultValue = jam+":"+menit;
                
            },
            transactionTableSubmit: function(e){
                e.preventDefault();
                const formIdTransaction = document.getElementById('transactionTableSubmitData');
                const formSubmitTableTransaction = new FormData(formIdTransaction);
                const type = formSubmitTableTransaction.get('m_transaction_table_type_transaction')
                // formSubmitTableTransaction.append('m_transaction_table_table_list[]', "")
                if (type=='Grab') {
                    const notaNumber = formSubmitTableTransaction.get('m_transaction_table_note_number')
                    formSubmitTableTransaction.delete('m_transaction_table_note_number')
                    formSubmitTableTransaction.append('m_transaction_table_note_number', 'GF-'+notaNumber)
                } else if (type=='Gojek') {
                    const notaNumber = formSubmitTableTransaction.get('m_transaction_table_note_number')
                    formSubmitTableTransaction.delete('m_transaction_table_note_number')
                    formSubmitTableTransaction.append('m_transaction_table_note_number', 'G-'+notaNumber)
                }

                for (const pair of formSubmitTableTransaction.entries()) {
                   console.log(pair[0]+ ', ' + pair[1]); 
                }

                axios.post('/tmp-transaction', formSubmitTableTransaction).then(res => {
                    console.log(res)
                    if (res.data.meta.code==200) {
                        
                        
                        formIdTransaction.reset();
                        document.getElementById('closeModalInformation').click();
                        this.$router.push({ name: 'kitchenOrder', params: { transactionId: res.data.data.m_transaction_table_id} });    
                    } else {
                        this.$swal.fire({
                            text: res.data.meta.message, 
                            icon:"warning",
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Iya'
                        })
                    }
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            toOrder(id){
                this.$router.push({ name: 'kitchenOrder', params: { transactionId: id} })
            }
        },
        setup() {
            const user = ref(null);
            const router = useRouter();
            const gojek = '/assets/images/ojol/gojek.png'
            const grab = '/assets/images/ojol/grab.png'
            const maxim = '/assets/images/ojol/maxim.png'

            onMounted( async () => {
                try {
                    const response = await axios.get('/user');
                    user.value = response.data.data;
                } catch (e) {
                    await router.push({ name: 'loginPage' });
                }
            });

            return {
                user,
                gojek,
                grab,
                maxim
            }

        },
    }
</script>
