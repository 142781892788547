<template>
    <section>
        <div class="modal fade" id="modal-input-cash">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Tutup Saldo</h6>
                        <button id="close-modal-cash" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        
                        <div class="form-group">
                            <label for="">Masukkan Jumlah Uang di Laci</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <!-- <input id="input_cash_real" type="number" class="form-control numeric" min="1000"> -->
                                    <!-- <input v-model.lazy="inputNominal" v-bind="config" class="form-control" /> -->
                                    <money3 v-model.number="inputNominal" v-bind="config" class="form-control"></money3>
                                </div>
                                
                                    <div class="col-auto px-1">
                                        
                                        <button v-on:click="confirmCash()" class="btn btn-primary btn-confirm-cash">Simpan</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        
    </section>
    
</template>

<script lang="js">
import {roundTo, roundToUp, roundToDown} from 'round-to';
import axios from 'axios';
// import { useStore } from 'vuex'
// import { useRouter, useRoute } from 'vue-router';
import '@formatjs/intl-numberformat';
import { formatMoney } from '@/mixins/app';
import { Money3Component } from 'v-money3';

    export default {
        name: "InputCash",
        components: { money3: Money3Component },
        data(){
            return{
                inputNominal: 0,
                config: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'Rp ',
                    suffix: '',
                    precision: 0,
                    masked: false
                },
                waroengInfo : {},
                currencyFormat : 'id-ID',
                currencyPrefix : 'IDR',
            }
        },
        mounted() {
            // window.setTimeout( "loadingComponentOrder()" ,750);
            axios.get('m-waroeng/waroeng-info')
            .then(resp => {
                this.waroengInfo = resp.data
                if(resp.data.currency == 'RM'){
                    this.currencyFormat = 'ms-MY'
                    this.currencyPrefix = 'MYR'

                    this.config.decimal = '.'
                    this.config.thousands = ','
                    this.config.prefix = 'RM '
                    this.config.precision = 2
                }
            }).catch(err => console.log(err))
        },
        methods: {
            closeModal(modalId){
                this.$router.push({name: 'Modal'});
                this.$swal.fire({
                    title: 'Cetak Ulang?',
                    html: 'Pastikan Laporan Sudah Tercetak',
                    icon: 'info',
                    showCancelButton: 'true',
                    cancelButtonText: 'Tidak',
                    reverseButtons: true,
                    confirmButtonText: 'Ya'
                    }).then(function (success) {
                        if (success.isConfirmed) {
                            axios.get(`/cashier/print-tarikan/${modalId}`).then(resp => {
                                // console.log(resp.data)
                                if(resp.data.struct != ''){
                                    location.href = resp.data.struct
                                }
                                document.getElementById("logout_btn").click()
                            })
                        }else{
                            document.getElementById("logout_btn").click()
                        }
                    })
            },
            showWarning(msg){
                this.$swal.fire({
                    title:"Oops!", 
                    text:msg, 
                    icon:"warning",
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            },
            confirmCash(){
                // const cash = parseInt(document.getElementById("input_cash_real").value);
                const cash = this.inputNominal
                const redirect = this.closeModal
                const warning = this.showWarning
                // console.log('Cash: '+ this.inputNominal)
                if(cash > 0){
                    this.$swal.fire({
                    title: 'Anda Yakin?',
                    html: 'Saldo akhir senilai: '+formatMoney(cash),
                    icon: 'info',
                    showCancelButton: 'true',
                    cancelButtonText: 'Tidak',
                    reverseButtons: true,
                    confirmButtonText: 'Ya'
                    }).then(function (success) {
                        if (success.isConfirmed) {
                            const formData = new FormData()
                            formData.append('nominal_cash', cash)
                            
                            axios.post('/m-modal/close-modal', formData).then(res => {
                                // console.log(res.data);
                                if (res.data.error == 0) {
                                    const modalId = res.data.rekap_modal_id
                                   
                                    axios.get(`/cashier/print-tarikan/${modalId}`).then(resp => {
                                        // console.log(resp.data)
                                        if(resp.data.struct != ''){
                                            location.href = resp.data.struct
                                        }
                                        document.getElementById("close-modal-cash").click()
                                        redirect(modalId)
                                    })
                                }else{
                                    warning(res.data.msg)
                                }

                            }).catch ((err) => {
                                if (err.response) {
                                console.log(err.response.data)
                                }
                            })
                        }
                    })
                

                }else{
                    this.$swal.fire({
                        title: 'Transaksi ditolak!',
                        html: 'Nominal Uang Tidak Boleh Kosong',
                        icon: 'error'
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>