
import { defineComponent, computed } from 'vue'
import Modal from '@/components/secure/Modal.vue'

export default defineComponent({
  name: 'DetailMoneyTips',
  props: {
    modalShow: {
      type: Boolean
    },
    tips: {
      type: Array
    },
    modalSize: {
      type: String
    }
  },
  emits: ['on-hide'],
  components: { Modal },
  setup(props, context) {
    const tipsData = computed(() => props.tips)
    const isEmpty = computed(() => props.tips?.length == 0)
    const onClick = () => {
      context.emit('on-hide')
    }

    return {
      onClick,
      tipsData,
      isEmpty
    }
  }
})
