
import { computed, ref } from 'vue'
import axios from 'axios';

export default {
  name: 'Drawer',
  emits: ['on-hide', 'on-submit'],
  props: ['showModal'],
  setup(props: any, context: any) {
    const modalVisible = computed(() => props.showModal)
    const reason = ref('');
    const modalClass = computed(() => {
      if (props.showModal) {
        return 'modal fade show';
      }
      return 'modal fade';
    });
    const listReason = ref([
      { value: 'tukaruang', text: 'Tukar Uang' },
      { value: 'tidakbuka', text: 'Laci tidak terbuka saat Transaksi' },
      { value: 'closingsaldo', text: 'Tutup Saldo' }
    ])

    const modalStyle = computed(() => {
      if (props.showModal) {
        return 'display: block;';
      }
      return 'display: none;';
    });

    const hideModal = () => {
      context.emit('on-hide')
    }

    const submitForm = () => {
      if(reason.value != ''){
        const params = {
          reason: reason.value,
        }

        axios.post('/cashier/buka-laci', params)
        .then(resp => {
          if (resp.data.error == 0) {
            if(resp.data.struct != ''){
                location.href = resp.data.struct
            }
            context.emit('on-submit') //close popup
          }
        })
        .catch(err => {
          console.warn(err)
        })
      }
    }

    return {
      reason,
      modalVisible,
      modalClass,
      modalStyle,
      listReason,
      hideModal,
      submitForm
    }
  }
}
