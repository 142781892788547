<template>
    <section>
        <div class="modal fade" id="modal-watest">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Test Struk Digital</h6>
                        <button id="close-modal-watest" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        
                        <div class="form-group">
                            <label for="">Masukkan Nomor Whatsapp</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <input v-model="nomorwa" type="text" class="form-control numeric">
                                </div>
                                
                                    <div class="col-auto px-1">
                                        
                                        <button v-on:click="testStruct()" class="btn btn-primary btn-confirm-cash">Kirim</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        
    </section>
    
</template>

<script lang="js">
import axios from 'axios';

    export default {
        name: "InputCash",
        data(){
            return{
                nomorwa: '',
                
            }
        },
        mounted() {
            // window.setTimeout( "loadingComponentOrder()" ,750);
        },
        methods: {
            testWa(){
                axios.get(`/cashier/test-wa/${this.nomorwa}`).then(resp => {
                    console.log(resp)
                }).catch ((err) => {
                    if (err.response) {
                    console.log(err.response.data)
                    }
                });
            },
            testStruct(){
                const hpbos = this.nomorwa.replace ( /[^0-9]/g, '' );
                console.log(hpbos)
                if (hpbos != '') {
                    this.$swal.fire({
                    // title: 'Mencetak!',
                    html: 'Mengirim Pesan Ke '+hpbos,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: () => {
                        this.$swal.showLoading()
                        this.testWa()
                        }
                    }).then((result)=>{
                        document.getElementById('close-modal-watest').click()
                        this.nomorwa = ''
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>