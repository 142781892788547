<template>
    <div class="login-app mt-5">
        <div class="d-flex justify-content-center mt-lg-5">
            <div class="col-lg-4">
                <div class="logo mb-4 text-center"><img src="assets/images/logo.jpg" alt="" width="100"></div>
                <div class="card border-0">
                    <div class="card-body p-4 p-lg-5">
                        <h2 class="mb-5 text-center">Sign in</h2>
                        <form class="form" @submit.prevent="submit">
                            <div class="form-group">
                                <div class="input-icon">
                                    <div class="icon-left"><i class="feather-user"></i></div>
                                    <input type="text" id="email_login" class="form-control form-control-large keyboard" placeholder="user@email.com" required v-model="email">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-icon">
                                    <div class="icon-left"><i class="feather-lock"></i></div>
                                    <input type="password" id="password_login" class="form-control form-control-large keyboard" placeholder="Password" required v-model="password">
                                </div>
                            </div>
                            <!-- <input
      :value="input"
      class="input"
      @input="onInputChange"
      placeholder="Tap on the virtual keyboard to start"
    > -->
    
                            <div class="form-group">
                                <div class="togglebutton">
                                    <label>
                                        <input type="checkbox" checked="">
                                        <span class="toggle"></span>
                                        Ingat saya
                                    </label>
                                </div>
                            </div>
                            <div class="form-group text-center mt-4">
                                <button class="btn btn-accent btn-block py-3" type="submit">Masuk</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="input"/> -->
</template>

<script>
import { ref } from 'vue';
import axios from 'axios'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import SimpleKeyboard from "./SimpleKeyboard";

export default {
  name: "Login",
  // components: {
  //   SimpleKeyboard
  // },
  data: () => ({
    input: ""
  }),
  methods: {
    onChange(input) {
      this.input = input;
    },
    onKeyPress(button) {
      console.log("button", button);
    },
    onInputChange(input) {
      this.input = input.target.value;
    }
  },
  setup() {
    const email = ref('');
    const password = ref('');
    const router = useRouter();
    const store = useStore();

    const submit = async () => {
        const emailval = document.getElementById("email_login").value
        const passwordval = document.getElementById("password_login").value
      const response = await axios.post('/login', {
        email: email.value,
        password: password.value
        // email: emailval,
        // password: passwordval
      });

      if (response.data.meta.error == true) {
        alert(response.data.meta.message)
        return false
      }

      store.dispatch('user/setToken', response.data.data.token)
      store.dispatch('user/setUserId', response.data.data.id)
      
      localStorage.setItem('token', response.data.data.token);
      localStorage.setItem('user_id', response.data.data.id);
      localStorage.setItem('user_name', response.data.data.name);
      localStorage.setItem('user_role', response.data.data.role);
    //   location.reload()
      if (response.data.data.role == 'kasir' || response.data.data.role == 'wbd') {
        location.replace('/modal')
      } else {
        location.replace('/online-order')
      }
    //   router.push({ name: 'dashboardPage' })
    };
    return {
        email,
        password,
        submit,
    }
  },
  mounted() {
    document.body.style.backgroundColor = "#633130";
    // window.setTimeout( "loadingComponentOrder()" ,750); 
  }
}
</script>