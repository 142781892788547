<template>
    <div>
        <Nav></Nav>

        <section class="py-4 section-app">
            <div class="container-fluid">
                <div class="row mx-n2" >
                    <div class="col-lg-5 px-2">
                    <div class="row mx-n1">
                        <div class="col-4 px-1">
                            <div class="card bg-accent text-white h-100">
                                <div class="card-body p-3 h-100 d-flex align-items-center justify-content-center text-center">
                                    <h4 v-if="notaDetailList.m_transaction_table_table_name_list!=''" class="font-weight-bold m-0">Meja {{notaDetailList.m_transaction_table_table_name_list}}</h4>
                                    <img v-else-if="notaDetailList.name=='Grab'" :src="grab" height="60" class="p-2"/>
                                    <img v-else-if="notaDetailList.name=='Gojek'" :src="gojek" height="60" class="p-2"/>
                                    <img v-else-if="notaDetailList.name=='Maxim'" :src="maxim" height="60" class="p-2"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-8 px-1">
                            <div class="card bg-accent text-white h-100">
                                <div class="card-body p-3">
                                    <table width="100%">
                                        <tr>
                                            <td width="20"><i class="fa fa-user"></i></td>
                                            <td>NAMA BIG BOSS</td>
                                            <td width="5">:</td>
                                            <td class="font-weight-bold">{{notaDetailList.m_transaction_table_customer_name}}</td>
                                        </tr>
                                        <tr>
                                            <td><i class="fa fa-file-text-o"></i></td>
                                            <td>NOMER NOTA</td>
                                            <td>:</td>
                                            <td>{{notaDetailList.m_transaction_table_note_number}}</td>
                                        </tr>
                                        <tr>
                                            <td><i class="fa fa-file-text-o"></i></td>
                                            <td>PENERIMA ORDER</td>
                                            <td>:</td>
                                            <td>{{notaDetailList.m_transaction_table_by}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-3">
                        <div class="card-header bg-accent text-white font-weight-bold">Daftar Pesanan</div>
                        <div class="card-body p-2 bg-light" style="height: calc(100vh - 300px);overflow: auto;">
                            <div class="card">
                                <div class="card-header bg-transparent font-weight-bold"><i class="fa fa-check"></i> SUDAH DIORDER</div>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr class="border-bottom">
                                                <th>No</th>
                                                <th width="300" class="text-left">Nama Menu</th>
                                                <th class="text-center">Qty</th>
                                                <th class="text-center" width="60">G</th>
                                                <th class="text-center" width="60">C</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div v-for="(menuJenis, index) in productionMenu" class="card mb-2" :key="index">
                                    <div class="card-header bg-accent text-white font-weight-bold w-50" >{{menuJenis.name}}</div>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <tbody>
                                                <tr v-for="(menu, index) in menuJenis.items" :key="index">
                                                    <td class="text-left">{{index+1}}</td>
                                                    <td width="300" class="text-left">
                                                        {{menu.m_menu_nama}}
                                                        <template v-if="menu.m_transaction_menu_reasone_approve">
                                                            <br/>
                                                            <span v-if="menu.m_transaction_menu_status == 3" class="text-danger">{{menu.m_transaction_menu_reasone_approve}}</span>
                                                            <span v-if="menu.m_transaction_menu_status == 2" class="text-danger">Batal - {{menu.m_transaction_menu_reasone_approve}}</span>
                                                            <span v-if="menu.m_transaction_menu_status == 4" class="text-danger">Gratis - {{menu.m_transaction_menu_reasone_approve}}</span>
                                                        </template>
                                                    </td>
                                                    <td class="text-center">{{menu.m_transaction_menu_qty}}</td>
                                                    <td class="text-center" width="60">
                                                        <a v-if="menu.m_transaction_menu_status==0" v-on:click="garansiMenu(menu.m_transaction_menu_menu_id, menu.m_transaction_menu_table_id, menu.m_transaction_menu_id), setPembatalanMenu()" href="javascript:void(0)" class="text-accent text-decoration-none h5" data-toggle="modal" data-target="#modal-cancel-menu"><i class="fa fa-undo"></i></a>
                                                    </td>
                                                    <td class="text-center" width="60"><a v-if="menu.m_transaction_menu_status!=2" v-on:click="loadCancelMenu(menu)" href="javascript:void(0)" class="text-accent text-decoration-none h5" data-toggle="modal" data-target="#modal-cancel-dish"><i class="fa fa-trash-o"></i></a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="card mt-2">
                                <div class="card-header bg-transparent font-weight-bold"><i class="fa fa-times"></i> BELUM DIORDER</div>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr class="border-bottom">
                                                <th>No</th>
                                                <th>Nama Menu</th>
                                                <th class="text-left" >Qty</th>
                                                <th class="text-center" width="30">Custom</th>
                                                <th class="text-center" width="30">Hapus</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(menu, index) in notaListMenuNotProduction" :key="menu.m_transaction_menu_id" >
                                                <td>{{index+1}}.</td>
                                                <td>
                                                    {{menu.m_menu_nama}}
                                                    <span v-if="menu.m_transaction_menu_custom_menu">
                                                        ({{menu.m_transaction_menu_custom_menu}})
                                                    </span>
                                                </td>
                                                <td class="text-center">
                                                    <div style="display: flex;" class="input-qty">
                                                        <button style="width: 20px; height: 20px; border:1px solid #ddd; font-size:10px;" v-on:click="selectedReduceMenu=menu" data-toggle="modal" data-target="#modal-reduce-menu" class="btn-minus" type="button"><i class="fa fa-minus"></i></button>
                                                        <div style="width: 20px; height: 20px; line-height: 20px; text-align: center;" class="value">{{ menu.m_transaction_menu_qty }}</div>
                                                        <button style="width: 20px; height: 20px; border:1px solid #ddd; font-size:10px;" v-on:click="addMenutoNota(menu.m_transaction_menu_id,null, menu.m_transaction_menu_table_id)" class="btn-minus" type="button"><i class="fa fa-plus"></i></button>
                                                    </div>
                                                </td>
                                                <td class="p-0 text-center"><a v-on:click="loadListMenu(menu)" href="javascript:void(0)" data-toggle="modal" data-target="#modal-custom" class="text-accent text-decoration-none h5"><i class="fa fa-edit"></i></a></td>
                                                <td class="p-0 text-center"><a v-on:click="reduceMenutoNota(menu.m_transaction_menu_id, menu.m_transaction_menu_table_id)" href="javascript:void(0)" class="text-accent text-decoration-none h5"><i class="fa fa-trash-o"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div class="card-footer">
                            <div class="row mx-n1">
                                <div class="col-auto px-1">
                                    <button class="btn btn-danger"><i class="fa fa-trash-o"></i></button>
                                </div>
                                <div class="col-auto px-1 ml-auto">
                                    <button v-on:click="this.$router.go(-1)" class="btn btn-primary">SIMPAN PESANAN</button>
                                </div>
                                <div class="col-auto px-1">
                                    <button class="btn btn-warning" type="button" v-on:click="setWarningMenu()" data-toggle="modal" data-target="#modal-order-produksi">ORDER KE PRODUKSI</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                    <div class="col-lg-7 mt-4 mt-lg-0 px-2">
                        <div class="card">
                            <div class="table-responsive bg-accent">
                                <ul class="nav nav-menu flex-nowrap text-nowrap" ref="menuList">
                                    <li v-on:click="loadAllMenu()" class="nav-item"><a href="#" class="nav-link active" data-toggle="tab">Semua</a></li>
                                    <li :id="stockMeja.m_menu_jenis_id" v-on:click="loadKategoriList(stockMeja)" v-for="stockMeja in stockMenuList" :key="stockMeja.m_menu_jenis_id" class="nav-item"><a href="#" class="nav-link" data-toggle="tab" :data-target="stockMeja.m_menu_jenis_id">{{stockMeja.m_menu_jenis_nama}}</a></li>
                                </ul>
                            </div>
                            <div class="tab-content" style="max-height: calc(100vh - 136.5px); height: 100vh">
                                <div class="tab-pane active" id="minuman">
                                    <template v-if="stockMenuKategoriList.length>0">
                                        <div class="table-responsive border-bottom">
                                            <ul class="nav nav-submenu flex-nowrap text-nowrap">
                                                <li v-on:click="loadMenuList(selectedMenuJenisId)" class="nav-item"><a href="#" class="nav-link active" data-toggle="tab" data-target="#minuman-semua">Semua</a></li>
                                                <li v-on:click="loadMenuByKategori(kategori.m_kategori_id)" :id="kategori.m_kategori_id" v-for="kategori in stockMenuKategoriList" :key="kategori.m_kategori_id" class="nav-item"><a href="#" class="nav-link" data-toggle="tab" :data-target="kategori.m_kategori_id">{{kategori.m_kategori_nama}}</a></li>
                                            </ul>
                                        </div>
                                    </template>
                                    <div class="tab-content">
                                        <div class="tab-pane active p-3" id="minuman-semua" style="max-height: calc(100vh - 175px);overflow: auto;">
                                            <div class="row row-menu-list">
                                                <div v-for="rowStock in stockList" :key="rowStock.m_menu_id" class="col">
                                                    <a href="javascript:void(0)" v-on:click="addMenutoNota(null,rowStock.m_menu_id, $route.params.transactionId)" class="card card-menu" data-toggle="modal">
                                                        <div class="card-body font-weight-bold">{{ rowStock.m_menu_nama }}</div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>

        <div class="modal fade" id="modal-edit-menu">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">Edit Menu</h6>
                        <button id="close-edit-modal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <form class="form" id="transactionEditMenu" @submit.prevent="transactionEditMenu('transactionEditMenu')" method="post">
                        <input type="hidden" class="" value="editMenu" name="m_transaction_menu_type" required>
                        <input type="hidden" class="inputMenuId" name="m_transaction_menu_menu_id" required>
                        <input type="hidden" class="inputNotaId" name="m_transaction_menu_table_id" required>
                        <div class="modal-body">
                            <div class="form-group row align-items-center">
                                <label class="col-form-label col">Pajak</label>
                                <div class="col-auto">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" name="m_transaction_menu_tax" value="1" id="pajak_edit_menu_modal">
                                        <label class="custom-control-label" for="pajak_edit_menu_modal"></label>
                                    </div>
                                </div>  
                            </div>
                            
                            <div class="form-group row align-items-center">
                                <label class="col-form-label col">Service Charge</label>
                                <div class="col-auto">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" name="m_transaction_menu_service_charge" value="1" id="service_charge_edit_menu_modal">
                                        <label class="custom-control-label" for="service_charge_edit_menu_modal"></label>
                                    </div>
                                </div>  
                            </div>
                            <div class="form-group row align-items-center mx-n1">
                                <label class="col-form-label col px-1">Diskon</label>
                                <div class="col-auto px-1">
                                    <select name="" id="" class="form-control">
                                        <option value="">%</option>
                                        <option value="">Nominal</option>
                                        <option value="">Qty</option>
                                    </select>
                                </div>
                                <div class="col-auto px-1">
                                    <input name="m_transaction_menu_discount" type="text" class="form-control numeric ui-keyboard-input ui-widget-content ui-corner-all ui-keyboard-input-current">
                                </div>  
                            </div>
                            <div class="form-group row align-items-center mx-n1">
                                <label class="col-form-label col px-1">Harga</label>
                                <div class="col-auto px-1">
                                    <input name="m_transaction_menu_price" type="text" class="form-control numeric ui-keyboard-input ui-widget-content ui-corner-all ui-keyboard-input-current">
                                </div>  
                            </div>
                            <div class="form-group">
                                <label>Custom Menu</label>
                                <textarea name="m_transaction_menu_custom_menu" id="m_transaction_menu_custom_menu" cols="30" rows="5" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal Custom -->
        <div class="modal fade" id="modal-custom">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Custom Menu</h5>
                        <button id="close-modal-custom" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="form" id="form-custom-menu" @submit.prevent="updateMenus()" method="post">
                        <div class="modal-body">
                            <div v-for="(menu, index) in customListMenu" :key="index" class="form-group row align-items-center">
                                <label for="" class="col-4">{{menu.m_menu_nama}}</label>
                                <div class="col-8">
                                    <input type="hidden" name="m_transaction_menu_id_custom_menu[]"  :value="menu.m_transaction_menu_id">
                                    <input type="text" name="m_transaction_menu_custom_menu[]" class="form-control" placeholder="Custom" :value="menu.m_transaction_menu_custom_menu">
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light" data-dismiss="modal">Batal</button>
                            <button type="submit" class="btn btn-action">Simpan</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modal-cancel-menu">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">Garansi</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul class="nav nav-tabs">
                            <!-- <li class="nav-item"><a href="javascript:void(0)" id="menu-tab-pembatalan" v-on:click="setPembatalanMenu()" class="nav-link active" data-toggle="tab" data-target="#tab-pembatalan">Pembatalan</a></li> -->
                            <li class="nav-item"><a href="javascript:void(0)" id="menu-tab-garansi" v-on:click="setGaransiMenu()" class="nav-link active" data-toggle="tab" data-target="#tab-garansi">Garansi</a></li>
                        </ul>
                        <div class="tab-content p-3">
                            <div class="tab-pane active" id="tab-pembatalan">
                                <form class="form" id="transactionPembatalanMenu" @submit.prevent="transactionGaransiMenu('transactionPembatalanMenu')" method="post">
                                    <div class="form-group">
                                        <label for="">Quantity</label>
                                        <input type="hidden" class="inputMenuId" name="m_transaction_menu_menu_id" required>
                                        <input type="hidden" class="inputNotaId" name="m_transaction_menu_table_id" required>
                                        <input type="hidden" class="transactionMenuId" name="m_transaction_menu_id" required>
                                        <input type="hidden" value="2" name="status-menu" required>
                                        <input type="text" name="qty-approve" class="form-control numeric pembatalan-menu-tab" required>
                                    </div>
                                    <div class="form-group">
                                        <label for="">Alasan Pembatalan</label>
                                        <textarea name="reason-approve" cols="30" rows="5" class="form-control pembatalan-menu-tab" required></textarea>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary">Save changes</button>
                                    </div>
                                </form>
                            </div>
                            <div class="tab-pane" id="tab-garansi">
                                <form class="form" id="transactionGaransiMenu" @submit.prevent="transactionGaransiMenu('transactionGaransiMenu')" method="post">
                                    <div class="form-group">
                                        <input type="hidden" class="inputMenuId" name="m_transaction_menu_menu_id">
                                        <input type="hidden" class="inputNotaId" name="m_transaction_menu_table_id">
                                        <input type="hidden" class="transactionMenuId" name="m_transaction_menu_id" required>
                                        <label for="">Aksi</label>
                                        <select name="status-menu" class="form-control garansi-menu-tab" required>
                                            <option value="3">Ganti Baru</option>
                                            <option value="4">Gratis</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="">Quantity</label>
                                        <input name="qty-approve" type="text" class="form-control numeric garansi-menu-tab" required>
                                    </div>
                                    <div class="form-group">
                                        <label for="">Alasan Garansi</label>
                                        <textarea name="reason-approve" cols="30" rows="5" class="form-control garansi-menu-tab" required></textarea>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" id="closeModalGaransi" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary">Save changes</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Cancel -->
        <div class="modal fade" id="modal-cancel-dish">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Cancel Menu</h5>
                        <button type="button" id="close-modal-cancel-dish" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="form" id="form-cancel-dish" @submit.prevent="cancelDishes()" method="post">
                        <div class="modal-body">
                            <div v-for="(menu, index) in cancelListMenu" :key="index" class="form-check mb-3 mt-1">
                                <input class="form-check-input" type="checkbox" :id="menu.id" :value="menu.id" name="m_dish_status_id[]">
                                <label class="form-check-label" :for="menu.id">
                                    {{menu.m_menu_nama}}
                                    <span v-if="menu.custom_menu">
                                        ( {{menu.custom_menu}} )
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light" data-dismiss="modal">Batal</button>
                            <button type="submit" class="btn btn-danger">Hapus</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal Order Produksi -->
        <div class="modal fade" id="modal-order-produksi">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Pastikan lagi pesanan <strong class="text-danger">Sudah Benar!</strong></h5>
                        <button type="button" class="close text-white" id="close-warning" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        
                        <div v-for="(menuJenis, index) in warningMenu" class="card mb-2" :key="index">
                            <div class="card-header bg-accent text-white font-weight-bold" >{{menuJenis.name}}</div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr class="border-bottom">
                                            <th>No</th>
                                            <th>Nama Menu</th>
                                            <th class="text-right">Qty</th>
                                            <th>Custom</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(menu, index) in menuJenis.items" :key="index">
                                            <td>{{index+1}}</td>
                                            <td>{{menu.m_menu_nama}}</td>
                                            <td class="text-right">{{menu.m_transaction_menu_qty}}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Batal</button>
                        <button v-on:click="setToProduction()" type="button" class="btn btn-action">Order ke Produksi</button>
                    </div>
                </div>
            </div>
        </div>

        <PopupReduceMenu 
            :menu="selectedReduceMenu"
            @onReduce="reduceMenuMultiple"
        />
        
    </div>
</template>
<script lang="js" >
    import {onMounted, ref} from 'vue';
    import axios from 'axios';
    import{useRouter} from 'vue-router';
    import Nav from '@/components/secure/odcr/Nav.vue'
    import PopupPay from '@/components/secure/dashboard/PopupPay.vue'
    import PopupReduceMenu from '@/components/secure/dashboard/PopupReduceMenu.vue'

    const gojek = '/assets/images/ojol/gojek.png'
    const grab = '/assets/images/ojol/grab.png'
    const maxim = '/assets/images/ojol/maxim.png'

    export default {
        name: "KitchenOrder",
        components: {
            Nav,
            PopupReduceMenu
        },
        data(){
            return{
                stockMenuList: [],
                stockMenuKategoriList: [],
                stockList: [],
                notaListMenu: [],
                notaListMenuNotProduction: [],
                newListMenu: [],
                selectedMenuJenisId: {
                    m_menu_jenis_id: ''
                },
                inputMenuId: "",
                inputNotaId: "",
                notaDetailList: {
                    m_transaction_table_table_list: 0,
                    m_transaction_table_note_number: 0,
                    m_transaction_table_customer_name: 0,
                    m_transaction_table_by: "",
                    m_transaction_table_table_name_list: "",
                    service_charge: 0,
                    pajak: 0
                },
                warningMenu: [],
                productionMenu: [],
                customListMenu: [],
                cancelListMenu: [],
                selectedReduceMenu: {}
            }
        },
        mounted() {
            
            this.load();
            window.setTimeout( "loadingComponentOrder()" ,750);
            document.getElementById("odcrNavigation").classList.add("active");
        },
        methods: {
            load(){
                axios.get('/m-menu-jenis').then(res => {
                    
                    this.stockMenuList = res.data
                                        
                    this.loadAllMenu();
                    this.loadNotaDetail();
                }).catch ((err) => {
                    console.log(err);
                })
            },
            loadNotaDetail(idMenuJenis = this.$route.params.transactionId){
                axios.get('/tmp-transaction/'+idMenuJenis).then(res => {

                    console.log(res);
                    
                    if(res.data == null || res.data.data.m_transaction_table_status == 2){
                        this.$router.push({name: 'odcr'});
                        return false
                    }

                    this.notaDetailList = {
                        m_transaction_table_table_list: res.data.data.m_transaction_table_table_list,
                        m_transaction_table_note_number: res.data.data.m_transaction_table_note_number,
                        m_transaction_table_customer_name: res.data.data.m_transaction_table_customer_name,
                        m_transaction_table_by: res.data.data.user_input.name,
                        m_transaction_table_table_name_list: res.data.data.m_transaction_table_table_name_list,
                        pajak: res.data.data.pajak,
                        service_charge: res.data.data.service_charge,
                        name: res.data.data.name
                    }
                    this.currentPajak = res.data.data.pajak;
                    this.currentService = res.data.data.service_charge;
                    this.TarikTunaiNotaListMenu = parseInt(res.data.data.m_transaction_menu_tarik_tunai)
                    this.ServiceNotaListMenu = parseInt(res.data.data.m_transaction_menu_service_charge)
                    this.loadMenuNota();
                }).catch ((err) => {
                    alert('Some Error');
                    this.$router.push({name: 'odcr'});
                })
            },
            loadAllMenu(){
                axios.get('/mmenu').then(res => {
                    
                    this.stockMenuKategoriList = []
                    this.stockList = res.data
                    console.log(res);
                    
                    if(res.data.length > 0){
                        this.showButtonSave = ""
                    }else{
                        this.showButtonSave = "display:none"
                    }
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            loadMenuList(menuJenis){
                axios.get('/mmenu?m_menu_m_menu_jenis_id='+menuJenis.m_menu_jenis_id).then(res => {
                    
                    this.stockList = res.data
                    
                    if(res.data.length > 0){
                        this.showButtonSave = ""
                    }else{
                        this.showButtonSave = "display:none"
                    }
                }).catch ((err) => {
                    console.log(err);
                })
            },
            loadMenuByKategori(idKategori){
                axios.get('/m-menu/byKategori/'+idKategori).then(res => {
                    console.log(res);
                    
                    this.stockList = res.data
                    
                    if(res.data.length > 0){
                        this.showButtonSave = ""
                    }else{
                        this.showButtonSave = "display:none"
                    }
                }).catch ((err) => {
                    console.log(err);
                })
            },
            loadKategoriList(menuJenis){
                axios.get('/m-kategori?m_kategori_m_menu_jenis_id='+menuJenis.m_menu_jenis_id).then(res => {
                    
                    const temp = []
                    
                    if (res.data.length>0) {
                        
                        res.data.forEach(element => {
                            
                            const e = JSON.parse(JSON.stringify(element));
                            temp.push({
                                m_kategori_id : e.m_kategori_id,
                                m_kategori_nama : e.m_kategori_nama,
                                m_kategori_m_menu_jenis_id: e.m_kategori_m_menu_jenis_id
                            })
                        });

                    }
                    this.selectedMenuJenisId.m_menu_jenis_id = menuJenis.m_menu_jenis_id
                    this.loadMenuList(menuJenis)
                    this.stockMenuKategoriList = temp
                    
                }).catch ((err) => {
                    console.log(err);
                })
            },
            backToMenu(){
                this.$router.push({name: 'odcr'});
            },
            saveTransaction(idMenuJenis = this.$route.params.transactionId){
                axios.put('/tmp-transaction/'+idMenuJenis, {
                    m_transaction_menu_service_charge: this.ServiceNotaListMenu,
                    m_transaction_menu_tarik_tunai: this.TarikTunaiNotaListMenu
                }).then(res => {
                    this.$router.push({name: 'odcr'});
                }).catch ((err) => {
                    console.log(err);
                })
            },
            addMenutoNota(transactionMenuId, menuId, notaId){
                axios.post('/m-transaction-meja/add-menu', {
                    m_transaction_menu_id: transactionMenuId,
                    m_transaction_menu_menu_id: menuId,
                    m_transaction_menu_table_id: notaId,
                    m_transaction_menu_production_status: 0
                }).then(res => {
                    
                    this.loadMenuNota(notaId)
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            reduceMenutoNota(menuId, notaId){
                axios.post('/m-transaction-meja/reduce-menu', {
                    m_transaction_menu_id: menuId
                }).then(res => {
                    this.loadMenuNota(notaId)
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            loadMenuNota(idMenuJenis = this.$route.params.transactionId){
                axios.get('/transaction-detail?m_transaction_menu_table_id='+idMenuJenis).then(res => {
                    
                    this.cart = []
                    this.notaListMenu = []
                    this.notaListMenuNotProduction = []

                    console.log(res.data);
                    
                    
                    res.data.forEach(menu => {
                        const addMenu = JSON.parse(JSON.stringify(menu))
                        if (addMenu.m_transaction_menu_production_status == 0) {
                            this.notaListMenuNotProduction.push(addMenu)
                        } else if (addMenu.m_transaction_menu_production_status == 1){
                            this.notaListMenu.push(addMenu)
                        }
                    });
                    
                    if (this.notaListMenu.length>0) {
                        
                        const allMenu = this.notaListMenu
                        const allJenis = this.stockMenuList
                        const newProductionMenu = []

                        allJenis.forEach(function (jenis) {
                            const newItems = []
                            allMenu.forEach(function(menu) {
                                
                                if (menu.m_menu_m_menu_jenis_id == jenis.m_menu_jenis_id) {
                                    newItems.push(menu)
                                }
                            });
                            if (newItems.length>0) {
                                newProductionMenu.push({
                                    name: jenis.m_menu_jenis_nama,
                                    items: newItems
                                })
                            }
                        });

                        this.productionMenu = newProductionMenu
                        
                    }
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            addToNewMenu(menu){
                
                const temp = [...this.newListMenu]
                let newMenu = []
                if (temp.length==0) {
                    const addMenu = JSON.parse(JSON.stringify(menu))
                    addMenu.qty = 1
                    newMenu.push(addMenu)
                } else {
                    let isNew = true
                    temp.forEach(function(element) {
                        const oldMenu = JSON.parse(JSON.stringify(element))
                        if (oldMenu.m_menu_id == menu.m_menu_id) {
                            isNew = false
                        }
                    });
                    if (isNew) {
                        newMenu = temp
                        const addMenu = JSON.parse(JSON.stringify(menu))
                        addMenu.qty = 1
                        newMenu.push(addMenu)
                    } else {
                        temp.forEach(function(element) {
                            const oldMenu = JSON.parse(JSON.stringify(element))
                            if (oldMenu.m_menu_id == menu.m_menu_id) {
                                oldMenu.qty+=1
                            }
                            newMenu.push(oldMenu)
                        });
                    }
                }

                this.newListMenu = newMenu
            },
            removeFromNewMenu(menu){
                const temp = [...this.newListMenu]
                const newMenu = []
                temp.forEach(function(element) {
                    const oldMenu = JSON.parse(JSON.stringify(element))
                    if (oldMenu.m_menu_id == menu.m_menu_id) {
                        oldMenu.qty-=1
                    }
                    if(oldMenu.qty>0)newMenu.push(oldMenu)
                });
                this.newListMenu = newMenu
            },
            setWarningMenu(){
                const allMenu = this.notaListMenuNotProduction
                const allJenis = this.stockMenuList
                const newWarningMenu = []

                allJenis.forEach(function (jenis) {
                    const newItems = []
                    allMenu.forEach(function(menu) {
                        
                        if (menu.m_menu_m_menu_jenis_id == jenis.m_menu_jenis_id) {
                            newItems.push(menu)
                        }
                    });
                    if (newItems.length>0) {
                        newWarningMenu.push({
                            name: jenis.m_menu_jenis_nama,
                            items: newItems
                        })
                    }
                });

                this.warningMenu = newWarningMenu
            },
            addMultipleMenu(){
                const formData = new FormData()
                formData.append('m_transaction_table_id',this.$route.params.transactionId)
                formData.append('menu',JSON.stringify(this.newListMenu))
                const router = this.$router;
                
                axios.post('/m-transaction-meja/add-multiple-menu', formData).then(res => {
                    console.log(res.data);
                    
                    if(res.status==201){
                        document.getElementById("close-warning").click()
                        router.go(-1)
                    }
                    
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            garansiMenu(menuId, notaId, menutTransactionId){
                const inputMenuIdClass = document.getElementsByClassName("inputMenuId");
                for (let i = 0; i < inputMenuIdClass.length; i++) {
                    inputMenuIdClass[i].value = menuId
                }
                const inputNotaIdClass = document.getElementsByClassName("inputNotaId");
                for (let i = 0; i < inputNotaIdClass.length; i++) {
                    inputNotaIdClass[i].value = notaId
                }
                const transactionMenuClass = document.getElementsByClassName("transactionMenuId");
                for (let i = 0; i < transactionMenuClass.length; i++) {
                    transactionMenuClass[i].value = menutTransactionId
                }
                axios.get('/m-transaction-meja/'+menutTransactionId).then(res => {
                    console.log(res.data)
                }).catch ((err) => {
                    console.log(err);
                })
            },
            setPembatalanMenu(){
                const elementsGaransi = document.getElementsByClassName("garansi-menu-tab");
                for (let i = 0; i < elementsGaransi.length; i++) {
                    elementsGaransi[i].value = ""
                }
            },
            setGaransiMenu(){
                // garansi-menu-tab
                const elementsPembatalan = document.getElementsByClassName("pembatalan-menu-tab");
                for (let i = 0; i < elementsPembatalan.length; i++) {
                    elementsPembatalan[i].value = ""
                }
            },
            transactionGaransiMenu: function(idForm){
                const formIdTransaction = document.getElementById(idForm);
                const formSubmitTableTransaction = new FormData(formIdTransaction);
                for (const pair of formSubmitTableTransaction.entries()) {
                    console.log(pair[0]+ ', ' + pair[1]); 
                }
                axios.post('/m-transaction-meja/changeMenuDetail', formSubmitTableTransaction).then(res => {
                    formIdTransaction.reset();
                    this.loadMenuNota();
                    document.getElementById('closeModalGaransi').click();
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            setToProduction: function(){
                axios.get('/m-transaction-meja/set-production/'+this.$route.params.transactionId).then(res => {
                    console.log(res);
                    if (res.status == 202) {
                        document.getElementById("close-warning").click()
                        this.$router.go(-1)

                    }
                    
                }).catch ((err) => {
                    console.log(err.response.data);
                })
            },
            loadListMenu(menu){
                axios.post('/m-transaction-menu/list-menu', {
                    m_transaction_menu_menu_id: menu.m_transaction_menu_menu_id,
                    m_transaction_menu_table_id: menu.m_transaction_menu_table_id
                }).then(res => {
                    
                    const newListMenu = []
                    if (res.data.meta.code ==200) {
                        res.data.data.forEach(function(menu) {
                            for (let i = 0; i < menu.m_transaction_menu_qty; i++) {
                                newListMenu.push(menu)
                            }
                        });
                    }

                    this.customListMenu = newListMenu
                    window.setTimeout( "loadingComponentOrder()" ,750);

                })

            },
            loadCancelMenu(menu){
                axios.post('/m-dish-status/list', {
                    m_transaction_menu_menu_id: menu.m_transaction_menu_id,
                    m_transaction_menu_table_id: menu.m_transaction_menu_table_id
                }).then(res => {
                    
                    console.log(res);
                    this.cancelListMenu = res.data.data
                    
                }).catch(err => {
                    console.log(err.response.data);
                })

            },
            updateMenus(){
                const formId = document.getElementById('form-custom-menu');
                const formSubmit = new FormData(formId);
                for (const pair of formSubmit.entries()) {
                    if (pair[1] == '') {
                        pair[1] = '[delete]'
                    }
                    console.log(pair[0]+' '+pair[1]);
                    
                }
                
                axios.post('/m-transaction-menu/update-menus', formSubmit).then(res => {
                    
                    console.log(res);
                    if (res.data.meta.code==200) {
                        document.getElementById('close-modal-custom').click()
                        this.loadMenuNota()
                    }
                    
                }).catch(err => {
                    console.log(err.response.data);
                    
                })

            },
            cancelDishes(){
                const formId = document.getElementById('form-cancel-dish');
                const formSubmit = new FormData(formId);
                for (const pair of formSubmit.entries()) {
                    console.log(pair[0]+' '+pair[1]);
                    
                }
                
                axios.post('/m-dish-status/cancel-dishes', formSubmit).then(res => {
                    
                    console.log(res);
                    if (res.data.meta.code==200) {
                        document.getElementById('modal-cancel-dish').click()
                        this.loadMenuNota()
                    }
                    
                }).catch(err => {
                    console.log(err.response.data);
                    
                })

            },
            reduceMenuMultiple(value){
            
                const formData = new FormData()
                formData.append('m_transaction_menu_id', value.transactionMenuId)
                formData.append('qty',value.qty)
                formData.append('reason',value.reason)
                
                axios.post('/m-transaction-meja/reduce-menu-multiple', formData).then(res => {
                    console.log(res);
                    
                    if(res.status==200){
                        
                        this.loadMenuNota()
                    }
                    
                }).catch ((err) => {
                    console.log(err);
                })
                
            }
        },
        setup() {
            const user = ref(null);
            const router = useRouter();
            const gojek = '/assets/images/ojol/gojek.png'
            const grab = '/assets/images/ojol/grab.png'
            const maxim = '/assets/images/ojol/maxim.png'

            onMounted( async () => {
                try {
                    const response = await axios.get('/user');
                    user.value = response.data.data;
                } catch (e) {
                    await router.push({ name: 'loginPage' });
                }
            });

            return {
                user,
                gojek,
                grab,
                maxim
            }

        },
    }
</script>
