/**
 * File komposisi untuk komponen `Order`
 * 
 * @author Agus Susilo<smartgdi@gmail.com>
 */

import axios from 'axios'
import { useStore } from 'vuex'

export default () => {
  const downloadStructFile = (fileName: string) => {
    axios.get(`/recap-transactions/download-struct/${fileName}`, {'responseType': 'blob'})
      .then((resp) => {
        const fileUrl = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
        const fileLink = document.createElement('a');

        fileLink.href = fileUrl;
        fileLink.setAttribute('download', 'struct-' + Date.now() +'.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
  }

  const setOnlineOrderStatus = (to: any) => {
    const store = useStore()
    const transId = to.params.transactionId
    if (transId == '') {
      return
    }

    axios.get('type-transactions/all')
    .then(resp => {
      const result = resp.data.data
      let transactionName = ''
      result.forEach((val: any) => {
        if (val.id == transId) {
          transactionName = val.name
        }
      })

      store.dispatch('order/setVendorName', transactionName)
      store.dispatch('order/setIsOnlineOrder', true)
    })
    .catch(err => {
      console.log('Error setOnlineOrderStatus: ')
      console.log(err)
    })
  }

  return {
    downloadStructFile,
    setOnlineOrderStatus
  }
}
