const state = () => ({
  modalVisible: false,
});

const getters = {
  modalVisible: (state: any, getters: any, rootState: any) => {
    return state.modalVisible;
  }
};

const actions = {
  async showModal({ commit, state }: any) {
    commit('setModal', true);
  },
  async hideModal({ commit, state }: any) {
    commit('setModal', false);
  }
};

const mutations = {
  setModal(state: any, value: boolean) {
    state.modalVisible = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
