const state = () => ({
  users: [],
  currentUserId: '',
  currentUser: null,
  employee: null,
  token: '',
  userId: 0,
});

const getters = {
  users: (state: any, getters: any, rootState: any) => {
    return state.users
  },
  currentUserId: (state: any, getters: any, rootState: any) => {
    return state.currentUserId
  },
  currentUser: (state: any, getters: any, rootState: any) => {
    return state.currentUser
  },
  employee: (state: any, getters: any, rootState: any) => {
    return state.employee
  },
  token: (state: any, getters: any, rootState: any) => {
    return state.token
  },
  userId: (state: any, getters: any, rootState: any) => {
    return state.userId
  }
};

const actions = {
  async setUsers({ commit, state }: any, users: any) {
    commit('setUsers', users)
  },
  async setCurrentUserId({ commit, state }: any, userId: string) {
    commit('setCurrentUserId', userId)
  },
  async setCurrentUser({ commit, state }: any, user: any) {
    commit('setCurrentUser', user)
  },
  async setEmployee({ commit, state }: any, data: any) {
    commit('setEmployee', data)
  },
  async setToken({ commit, state }: any, token: string) {
    commit('setToken', token)
  },
  async setUserId({ commit, state }: any, id: number) {
    commit('setUserId', id)
  }
};

const mutations = {
  setUsers(state: any, value: any) {
    state.users = value
  },
  setCurrentUserId(state: any, id: string) {
    state.currentUserId = id
  },
  setCurrentUser(state: any, user: any) {
    state.currentUser = user
  },
  setEmployee(state: any, data: any) {
    state.employee = data
  },
  setToken(state: any, token: string) {
    state.token = token
  },
  setUserId(state: any, id: number) {
    state.userId = id
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
