<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-dark bg-accent" id="navbar">
            <div class="container-fluid">
                <router-link  class="navbar-brand d-flex align-items-center" :to="{ name: 'dashboardPage' }">
                    <img :src="urlImage" alt="" height="30" class="mr-3">
                    CR55
                </router-link >
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto nav-primary-menu">
                        <li class="nav-item" id="odcrNavigation"><router-link class="nav-link" :to="{ name: 'odcr'}">Hal Order</router-link></li>
                        <li class="nav-item" id="odcrOnlineNNavigation"><router-link class="nav-link" :to="{ name: 'odcrOnline'}">Hal Ojol</router-link></li>
                        <li class="nav-item" id="pesanAktifNavigation"><router-link  class="nav-link" :to="{ name: 'pesanAktif'}">Pesan Aktif</router-link></li>
                        <li class="nav-item" id="halTataNavigation"><a href="#" class="nav-link" data-toggle="modal" data-target="#modal-tata">Hal Tata</a></li>
                        <li class="nav-item" id="ambilNavigation"><router-link class="nav-link" :to="{ name: 'ambilLanding'}">Layar Ambil</router-link></li>
                        <li class="nav-item" id="stockNavigation"><router-link class="nav-link" :to="{ name: 'stockPage'}">Gagal Proses</router-link></li>
                        <li class="nav-item" id="stockNavigation"><router-link class="nav-link" :to="{ name: 'stockPage'}">Menu Habis</router-link></li>
                        <li class="nav-item" id="stockNavigation"><router-link class="nav-link" :to="{ name: 'stockPage'}">Bungkusan</router-link></li>
                        <li class="nav-item" id="layarNavigation"><a href="#" v-on:click="loadLayar()" class="nav-link" data-toggle="modal" data-target="#modal-layar">Produksi</a></li>
                        <li class="nav-item" id="plottingNavigation"><router-link class="nav-link" :to="{ name: 'plotting'}">Plotting</router-link></li>
                    </ul>
                    <div class="time-box ml-auto text-white d-flex align-items-center">
                        <i class="fa fa-clock-o mr-3"></i>
                        <span id="jam"> {{ dateTime.hours }}</span>:<span id="menit">{{ dateTime.minutes }}</span>:<span id="detik">{{ dateTime.seconds }}</span>
                    </div>
                    <ul class="navbar-nav ml-4">
                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown">WSS Palagan</a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a href="modal-kasir.html" class="dropdown-item">Modal</a>
                                <a href="javascript:void(0)" class="dropdown-item">Ganti Shift</a>
                                <a href="javascript:void(0)" class="dropdown-item">Bantuan</a>
                                <div class="dropdown-divider"></div>
                                <a href="javascript:void(0)" class="dropdown-item" @click="logout">Log Out</a>
                            </div>
                        </li>
                    </ul>

                </div>
            </div>

        </nav>

        <div class="modal fade" id="modal-layar">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Pilih Layar Produksi</h5>
                        <button id="closeModalLayar" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <button v-for="layar in allLayar" :key="layar.id" v-on:click="goToLayar(layar.id)" type="button" class="btn btn-light mb-3" data-dismiss="modal" style="width:100%">Layar {{layar.layar_name}}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modal-tata">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Pilih Tipe Halaman Tata</h5>
                        <button id="closeModalLayar" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <button v-on:click="goToTata('dine_in')" type="button" class="btn btn-light mb-3" data-dismiss="modal" style="width:100%">Dine In</button>
                        <button v-on:click="goToTata('take_away')" type="button" class="btn btn-light mb-3" data-dismiss="modal" style="width:100%">Take Away</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import moment from 'moment-timezone';
import {useRouter} from 'vue-router';
import axios from 'axios';

const date = new Date();

export default {
    name: "Nav",
    setup() {
        const router = useRouter();
        // const jam = moment().format('H');
        // const menit = moment().format('mm');
        // const detik = moment().format('ss');
        const urlImage = "/assets/images/logo.jpg";
        const logout = async () => {
          localStorage.setItem('token', null);
          localStorage.clear();
          router.push({ name: 'loginPage' });
        };


        return {
          logout,
          urlImage
        }
    },
    data() {
      return {
        allLayar: [],
        dateTime: {
          hours: moment().format('H'),
          minutes: moment().format('mm'),
          seconds: moment().format('ss'),
        },
        timer: null
      }
    },
    mounted() {
      // window.setTimeout( "waktu()" ,1000);
    },
    methods: {
      loadLayar() {
          axios.get('/m-layar-produksi/all').then(res => {
              this.allLayar = res.data.data
              
              
          }).catch ((err) => {
              console.log(err.response.data);
          })
      },
      goToLayar(id){
          this.$router.push({ name: 'layarProduksi', params: { layarId: id} });
      },
      goToTata(type){
          this.$router.push({ name: 'halTata', params: { type: type} });
      },
      setDateTime() {
        const date = new Date()
        this.dateTime = {
          hours: moment().format('H'),
          minutes: moment().format('mm'),
          seconds: moment().format('ss'),
        }
      }
    },
  beforeMount() {
    this.timer = setInterval(this.setDateTime, 1000)
  },
  beforeUnmount() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>

</style>