import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import moment from 'moment'

export const useHistory = () => {
  const route = useRoute()
  const menuJenis = ref([])
  const histories = ref([])
  const historyDetail = ref({} as any)
  const selectedTransId = ref('')
  const transactions = ref({} as any)
  const showDetail = ref(false)

  const getPrice = (qty: number, total: any) => {
    return parseFloat(total) / qty
  }

  const loadHistory = async (notaNumber: string, transactionDate: string): Promise<Array<any>> => {
    try {
      const resp = await axios.get('/m-transaction-table/history-by-date', {
        params: { created_at: transactionDate, nota_number: notaNumber } 
      })

      resp.data.data.forEach((history: any) => {
        history.time = moment(history.updated_at).format('HH:mm')
      })
      return resp.data.data

    } catch (err) {
      console.log(err)
    }

    return []
  }

  const getHistoryDetail = (id: string): Promise<any> => {
    return axios.get(`/m-transaction-table/history-detail/${id}`)
    .then(res => {
      return res.data.data
    })
  }

  const collectTypeMenuId = (menus: any[]): number[] => {
    const typeMenuId: any = []
    for (const menu of menus) {
      if (typeMenuId.indexOf(menu.m_menu_m_menu_jenis_id) === -1) {
        typeMenuId.push(menu.m_menu_m_menu_jenis_id)
      }
    }

    return typeMenuId
  }

  const findTypeMenusByName = (typeMenuId: number, typeMenus: any[]): any => {
    const res: any = {} 
    const filteredMenus = typeMenus.filter(typeMenu => typeMenu.m_menu_jenis_id == typeMenuId)
    if (filteredMenus.length > 0) {
      return filteredMenus[0]
    }

    return res
  }

  const createMenusCart = (menus: any[], typeMenuIds: any[], typeMenus: any[]): any[] => {
    const carts: any = []
    for (const typeMenuId of typeMenuIds) {
      const typeMenuNameObj = findTypeMenusByName(typeMenuId, typeMenus)
      const menuList = menus.reduce<any[]>((acc, item) => {
        if (item.m_menu_m_menu_jenis_id == typeMenuId) {
          acc.push(item)
        }
        return acc
      }, [])

      carts.push({ id: typeMenuId, name: typeMenuNameObj.m_menu_jenis_nama, items: menuList })
    }

    return carts
  }

  const loadHistoryDetail = (id: string): object => {
    return axios.get('/m-transaction-table/history-detail/'+id).then(res => {
      const tempMenuJenis: any = []

      const data = res.data.data
      data.menus.forEach(function(e: any) {
        if (tempMenuJenis.indexOf(e.m_menu_m_menu_jenis_id) === -1) {
          tempMenuJenis.push(e.m_menu_m_menu_jenis_id)
        }
      })

      const menus = data.menus
      const tempCart: any = []
      tempMenuJenis.forEach(function(e: any) {
        const tempMenuList: any = []
        let name = ''
        menuJenis.value.forEach(function (item: any) {
          if (item.m_menu_jenis_id == e) {
            name = item.m_menu_jenis_nama
          }
        })
          
        menus.forEach(function (item: any) {
          if (e == item.m_menu_m_menu_jenis_id) {
            tempMenuList.push(item)
          }                                
        })

        const menuOnCart = {
          id: e,
          name: name,
          items: tempMenuList
        }
        tempCart.push(menuOnCart)
      })

      data.menus = tempCart
      return data
    }).catch (err => {
      console.log(err)
    })
  }

  return {
    menuJenis,
    histories,
    historyDetail,
    selectedTransId,
    transactions,
    getPrice,
    loadHistory,
    loadHistoryDetail,
    showDetail
  }
}
