<template>
    <div>
        <Nav></Nav>

        <section class="py-4 section-app">
            <div class="container-fluid">
                <div class="layout-laya d-flex" style="height:calc(100vh - 100px);">
                    <div class="row h-100 w-100">
                        <div class="col-lg-6">
                            <a href="javascript:void(0)" v-on:click="goToAmbilPage('minum')" class="card card-menu-ambil">
                                <div class="card-body text-center">
                                    <div class="icon mb-4"><img :src="drink" alt="" width="150"></div>
                                    <h4>MINUMAN</h4>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-6">
                            <a href="javascript:void(0)" v-on:click="goToAmbilPage('makan')" class="card card-menu-ambil">
                                <div class="card-body text-center">
                                    <div class="icon mb-4"><img :src="food" alt="" width="150"></div>
                                    <h4>MAKANAN</h4>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </section>
    </div>
</template>
<script lang="js" >
    import {onMounted, ref} from 'vue';
    import axios from 'axios';
    import{useRouter} from 'vue-router';
    import Nav from '@/components/secure/odcr/Nav.vue'

    export default {
        name: "AmbilLanding",
        components: {
            Nav
        },
        data(){
            return{
                
            }
        },
        mounted() {
            
            window.setTimeout( "loadingComponentOrder()" ,750);
            document.getElementById("ambilNavigation").classList.add("active");
        },
        methods: {
            goToAmbilPage(type){
                this.$router.push({ name: 'halAmbil', params: { type: type} });
            }
        },
        setup() {
            const user = ref(null);
            const router = useRouter();
            const food = "/assets/images/food.svg";
            const drink = "/assets/images/drink.svg";

            onMounted( async () => {
                try {
                    const response = await axios.get('/user');
                    user.value = response.data.data;
                } catch (e) {
                    await router.push({ name: 'loginPage' });
                }
            });

            return {
                user,
                food,
                drink
            }

        },
    }
</script>
