<template>
    <div>
        <Nav></Nav>

        <section class="py-4 section-app">
            <div class="container-fluid">
                <div class="row mx-n2">
                    <div class="col-lg-6 px-2">
                        <div class="card bg-light">
                            <div class="card-body p-3">
                                <div class="row mx-n2">
                                    <div class="col-lg-6 px-2">
                                        <table width="100%">
                                            <tr>
                                                <td width="100">Transaksi</td>
                                                <td width="10">:</td>
                                                <td><strong>{{ notaDetailList.transaksi_tipe }}</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Nomor Nota</td>
                                                <td>:</td>
                                                <td><strong>{{ notaDetailList.transaksi_nota_code }}</strong></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-lg-6 px-2">
                                        <table width="100%">
                                            <tr>
                                                <td width="100">Nama Bigboss</td>
                                                <td width="10">:</td>
                                                <td><strong>{{ notaDetailList.transaksi_bigboss }}</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Kasir</td>
                                                <td>:</td>
                                                <td><strong>{{ notaDetailList.transaksi_kasir }}</strong></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mt-2">
                            <div class="table-responsive" style="max-height: calc(100vh - 175.5px); height: 100vh">
                                <table class="table table-menu m-0 font-summary">
                                    <thead class="bg-dark text-white">
                                        <tr>
                                            <th>#</th>
                                            <th>Nama Menu</th>
                                            <th class="text-center" style="padding-left: 0px">Qty</th>
                                            <th class="text-right">Harga</th>
                                            <th class="text-right">Total</th>
                                            <th class="text-right">Refund</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="menuJenis in cart" :key="menuJenis.id">
                                        
                                        <tr>
                                            <td colspan="6" class="bg-accent text-white">{{menuJenis.name}}</td>
                                        </tr>
                                        <tr v-for="(listMenus, index) in menuJenis.items" :key="listMenus.r_t_detail_id" >
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ listMenus.m_produk_nama }}
                                            </td>
                                            <td class="text-center">{{ listMenus.r_t_detail_qty }}</td>
                                            <td class="text-right text-nowrap">{{ currency(parseFloat(listMenus.r_t_detail_price ),'') }}</td>
                                            <td class="text-right text-nowrap">{{ currency(listMenus.r_t_detail_price*listMenus.r_t_detail_qty,'') }}</td>
                                            <td  class="text-nowrap text-right" style="padding-right: 10px">
                                                <a v-on:click="addToRefundMenu(listMenus)" href="javascript:void(0)" class="text-accent text-decoration-none h6" data-toggle="modal" data-target="#modal-edit-menu"><i class="fa fa-arrow-right"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- <div class="card mt-2">
                            <table class="table table-menu table-condensed m-0">
                                <tr>
                                    <td class="text-right"><strong>Sub Total</strong></td>
                                    <td width="5" class="px-0"><strong>:</strong></td>
                                    <td width="10"><strong>Rp</strong></td>
                                    <td class="text-right text-danger" width="150">{{ subTotalNotaListMenu }}</td>
                                    <td width="10"></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><strong>Pajak Resto (10%)</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>Rp</strong></td>
                                    <td class="text-right text-danger">{{ pajakNotaListMenu }}</td>
                                    <td class="p-0 px-2"><a href="javascript:void(0)" v-on:click="setNullPajak()" class="text-accent text-decoration-none h5"><i class="fa fa-trash-o"></i></a></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><strong>Service Charge ({{ ServiceNotaListMenu }}%)</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>Rp</strong></td>
                                    <td class="text-right text-danger">{{ ServiceNotaListMenuPrice }}</td>
                                    <td class="p-0 px-2"><a href="javascript:void(0)" v-on:click="setServiceCharge()" class="text-primary text-decoration-none h5"><i class="fa fa-plus-circle"></i></a></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><strong>Tarik Tunai</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>Rp</strong></td>
                                    <td class="text-right text-danger">{{ TarikTunaiNotaListMenu }}</td>
                                    <td class="p-0 px-2"><a href="javascript:void(0)" v-on:click="setTarikTunai()" class="text-primary text-decoration-none h5"><i class="fa fa-plus-circle"></i></a></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><strong>Total Bayar</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>Rp</strong></td>
                                    <td class="text-right text-danger">{{ TotalNotaListMenu }}</td>
                                    <td class="p-0 px-2"></td>
                                </tr>
                            </table>
                        </div>

                       -->

                    </div>
                    <div class="col-lg-6 mt-4 mt-lg-0 px-2">
                        
                        <div class="card bg-light">
                            <div class="card-body p-3">
                                <div class="row mx-n2">
                                    <div class="col-lg-6 px-2">
                                        <table width="100%">
                                            <tr>
                                                <td width="100">Transaksi</td>
                                                <td width="10">:</td>
                                                <td><strong>{{ notaDetailList.transaksi_tipe }}</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Nomor Nota</td>
                                                <td>:</td>
                                                <td><strong>{{ notaDetailList.transaksi_nota_code }}</strong></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-lg-6 px-2">
                                        <table width="100%">
                                            <tr>
                                                <td width="100">Nama Bigboss</td>
                                                <td width="10">:</td>
                                                <td><strong>{{ notaDetailList.transaksi_bigboss }}</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Kasir</td>
                                                <td>:</td>
                                                <td><strong>{{ notaDetailList.transaksi_kasir }}</strong></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mt-2">
                            <div class="table-responsive" style="max-height: calc(100vh - 350px);  height: 100vh">
                                <table class="table table-menu m-0 font-summary">
                                    <thead class="bg-dark text-white">
                                        <tr>
                                            <th class="text-left">Refund</th>
                                            <th>#</th>
                                            <th>Nama Menu</th>
                                            <th class="text-right">Qty</th>
                                            <th class="text-right">Harga</th>
                                            <th class="text-right">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="menuJenis in refundCart" :key="menuJenis.id">
                                        
                                        <tr>
                                            <td colspan="6" class="bg-accent text-white">{{menuJenis.name}}</td>
                                        </tr>
                                        <tr v-for="(listMenus, index) in menuJenis.items" :key="listMenus.r_t_detail_id" >
                                            <td class="text-nowrap text-left" style="padding-left: 10px">
                                                <a v-on:click="removeFromSplitMenu(listMenus)" href="javascript:void(0)" class="text-accent text-decoration-none h6" data-toggle="modal" data-target="#modal-edit-menu"><i class="fa fa-arrow-left"></i></a>
                                            </td>
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ listMenus.m_produk_nama }}
                                            </td>
                                            <td class="text-center">{{ listMenus.r_t_detail_qty }}</td>
                                            <td class="text-right text-nowrap">{{ currency(parseFloat(listMenus.r_t_detail_price),'') }}</td>
                                            <td class="text-right text-nowrap">{{ currency(listMenus.r_t_detail_price * listMenus.r_t_detail_qty,'') }}</td>
                                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="card mt-2">
                            <table class="table table-menu table-condensed m-0 font-summary">
                                <tr>
                                    <td class="text-right"><strong>Sub Total</strong></td>
                                    <td width="5" class="px-0"><strong>:</strong></td>
                                    <td width="10"><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger" width="150">{{ currency(refundSubTotal,'') }}</td>
                                    <td width="10"></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><strong>Pajak Resto ({{this.currentPajak * 100}}%)</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger">{{ currency(refundPajak,'') }}</td>
                                    <template v-if="notaDetailList.pajak>0">
                                        <template v-if="currentPajak>0">
                                            <td class="p-0 px-2"><a href="javascript:void(0)" v-on:click="setNullPajak()" class="text-accent text-decoration-none h5"><i class="fa fa-trash-o"></i></a></td>
                                        </template>
                                        <template v-else>
                                            <td class="p-0 px-2"><a href="javascript:void(0)" v-on:click="setPajak()" class="text-primary text-decoration-none h5"><i class="fa fa-plus-circle"></i></a></td>
                                        </template>
                                    </template>
                                </tr>
                                <tr>
                                    <td class="text-right"><strong>Service Charge ({{ this.currentService * 100 }}%)</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger">{{ currency(refundServiceCharge,'') }}</td>
                                    <template v-if="notaDetailList.service_charge>0">
                                        <template v-if="currentService>0">
                                            <td class="p-0 px-2"><a href="javascript:void(0)" v-on:click="setNullService()" class="text-accent text-decoration-none h5"><i class="fa fa-trash-o"></i></a></td>
                                        </template>
                                        <template v-else>
                                            <td class="p-0 px-2"><a href="javascript:void(0)" v-on:click="setService()" class="text-primary text-decoration-none h5"><i class="fa fa-plus-circle"></i></a></td>
                                        </template>
                                    </template>
                                </tr>
                                <!-- <tr>
                                    <td class="text-right"><strong>Tarik Tunai</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger">{{ splitTarikTunai }}</td>
                                    <td class="p-0 px-2">
                                        <a href="#modal-input-tunai-split"
                                            data-toggle="modal"
                                            data-target="#modal-input-tunai-split"
                                            data-dismiss="modal"
                                            class="text-primary text-decoration-none h5"
                                        ><i class="fa fa-plus-circle"></i
                                        ></a>
                                    </td>
                                </tr> -->
                                <tr>
                                    <td class="text-right"><strong>Total Refund</strong></td>
                                    <td class="px-0"><strong>:</strong></td>
                                    <td><strong>{{this.waroengInfo.currency}}</strong></td>
                                    <td class="text-right text-danger"><h5>{{ currency(refundTotal,'') }}</h5></td>
                                    <td class="p-0 px-2"></td>
                                </tr>
                            </table>
                        </div>

                        <div class="card mt-2">
                            <div class="card-body p-2">
                                <div class="row mx-n1 justify-content-center">
                                    <div class="col-auto px-1 mb-1">
                                        <button class="btn btn-danger font-weight-bold btn-sm" type="button" v-on:click="cancelSplit()">BATAL</button>
                                    </div>
                                    <div class="col-auto px-1 mb-1">
                                        <!-- <button v-if="refundTotal > 0 && refund_counter <= 0" class="btn btn-success font-weight-bold btn-sm" type="button" data-toggle="modal" data-target="#modal-refund" data-dismiss="modal" data-backdrop="static">REFUND</button>
                                        <button v-if="refundTotal <= 0 || refund_counter > 0" class="btn btn-success font-weight-bold btn-sm disabled" type="button">REFUND</button> -->
                                        <button :disabled="refundTotal <= 0 || refund_counter > 0 ? true : false" class="btn btn-success font-weight-bold btn-sm" type="button" v-on:click="refund()">REFUND</button>
                                    </div>
                                </div>
                                <div v-if="refund_counter > 0" class="alert alert-warning" role="alert" >
                                    Pengembalian Dana Hanya Boleh 1x
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
        </section>
        <section>
            <button id="show-modal-refund" type="button" data-toggle="modal" data-target="#modal-refund" data-dismiss="modal" data-backdrop="static"/>
        <div class="modal fade" id="modal-refund">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Pengembalian Dana (Refund)</h6>
                        <!-- <button id="close-modal-refund" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button> -->
                    </div>
                    <div class="modal-body py-4">
                        <div class="form-group text-right">
                            <h2 class="m-0 text-accent">{{currency(refundTotal+exchangeRounded,'')}}</h2>
                            <h6 v-if="exchangeRounded > 0" class="m-0 text-accent" style="color: red;">+ {{currency(exchangeRounded,'')}}</h6>
                        </div>
                        <hr/>
                        <div class="form-group">
                            <label for="">Free Kembalian</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <!-- <input id="free_kembalian_refund" v-model="freeExchange" type="text" class="form-control numeric"> -->
                                    <money3 v-model.number="freeExchange" v-bind="config" class="form-control"></money3>
                                </div>
                                <!-- <div class="col-auto px-1">
                                    <button v-on:click="payAction()" class="btn btn-success btn-confirm-cash">Cetak</button>
                                </div> -->
                            </div>
                        </div>
                        <hr/>
                        <div class="form-group">
                            <label for="">Nomor HP</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <input id="nomor_hp_bos" v-model="nomorHp" type="number" class="form-control numeric" placeholder="Contoh: 081765765243" >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="close-modal-refund" type="button" class="btn btn-danger" data-dismiss="modal" aria-label="Close" :disabled="buttonCloseStruk" v-on:click="backHome()">
                            Selesai
                        </button>
                      <button v-on:click="strukCash(1,0)" class="btn btn-success" :disabled="buttonStruk">Kirim WA</button>
                      <button v-on:click="strukCash(1,1)" class="btn btn-warning" :disabled="buttonStruk">Cetak & Kirim WA</button>
                      <button v-on:click="strukCash(0,1)" class="btn btn-accent" :disabled="buttonStruk">Cetak</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script lang="js" >
import {onMounted, ref} from 'vue';
import axios from 'axios';
import{useRouter} from 'vue-router';
import Nav from '@/components/secure/dashboard/Nav.vue';
import PopupPay from '@/components/secure/dashboard/PopupPay.vue';
import '@formatjs/intl-numberformat';
import {roundTo, roundToUp, roundToDown} from 'round-to';
import { Money3Component } from 'v-money3';

export default {
    name: "Dashboard",
    components: {
        Nav,
        money3: Money3Component
    },
    data(){
        return{
            refundSubTotal: 0,
            refundPajak: 0,
            refundServiceCharge: 0,
            splitTarikTunai: 0,
            refundTotal: 0,
            currentPajak: 0,
            currentService: 0,
            currentRound: 'ya',
            exchangeRounded: 0,
            freeExchange: 0,
            taxStatus: 0,
            scStatus: 0,
            stockMenuList: [],
            cart:[],
            refundCart:[],
            uuidChildNota: this.$route.params.rekapTransaksiId,
            allNotaListMenu: [],
            notaListMenu: [],
            refundListMenu: [],
            refund_counter: 0,
            // showListMenu: [],
            // subTotalNotaListMenu: 0,
            // TotalNotaListMenu: 0,
            // pajakNotaListMenu: 0,
            // ServiceNotaListMenu: 0,
            // ServiceNotaListMenuPrice: 0,
            // TarikTunaiNotaListMenu: 0,
            notaDetailList: {
                transaksi_tipe: '',
                transaksi_nota_code: 0,
                transaksi_bigboss: 0,
                transaksi_kasir: "",
                service_charge: 0,
                pajak: 0,
                pembulatan: '',
            },
            // notaDetailListChild: {
            //     transaksi_tipe: '',
            //     transaksi_nota_code: 0,
            //     transaksi_bigboss: 0,
            //     transaksi_kasir: ""
            // },
            // notaListMenuChild: [],
            // subTotalNotaListMenuChild: 0,
            // TotalNotaListMenuChild: 0,
            // pajakNotaListMenuChild: 0,
            // ServiceNotaListMenuChild: 0,
            // ServiceNotaListMenuPriceChild: 0,
            // TarikTunaiNotaListMenuChild: 0,
            defaultPay: 'all',
            voucherValue: 0,
            discount: 0,
            tmpId: this.$route.params.rekapTransaksiId,
            lossBill: 'hide',
            cashierRole: {},
            config: {
                decimal: ',',
                thousands: '.',
                prefix: 'Rp ',
                suffix: '',
                precision: 0,
                masked: false
            },
            nomorHp: '',
            buttonStruk: false,
            buttonCloseStruk: true,
            kirimWa: 0,
            cetakStruk: 0,
            waroengInfo : {},
            currencyFormat : 'id-ID',
            currencyPrefix : 'IDR',
        }
    },
    mounted() {
        axios.get('m-waroeng/waroeng-info')
        .then(resp => {
            this.waroengInfo = resp.data
            if(this.waroengInfo.currency == 'RM'){
                this.currencyFormat = 'ms-MY'
                this.currencyPrefix = 'MYR'

                this.config.decimal = '.'
                this.config.thousands = ','
                this.config.prefix = 'RM '
                this.config.precision = 2
            }
        }).catch(err => console.log(err))

        axios.get('/m-menu-jenis').then(res => {
            this.stockMenuList = res.data
            this.loadNotaDetail();
        }).catch ((err) => {
            console.log(err);
        })
        window.setTimeout( "loadingComponentOrder()" ,750);
        document.getElementById("listTableNavigation").classList.add("active");
        // this.setupRole()
    },
    methods: {
        setupRole(){
            // const callAkses = JSON.parse(localStorage.role_akses)
            // this.cashierRole = callAkses
            axios.get('/akses-role')
            .then(resp => {
                if (resp.status == 200) {
                this.cashierRole = resp.data
                }
            })
            .catch(err => console.log(err))
        },
        currency(price,prefix){
                const nominal = parseFloat(price);
                const format = this.currencyFormat
                const pref = this.currencyPrefix
                const prefView = (prefix != '') ? this.waroengInfo.currency : '';

                const rupiah = new Intl.NumberFormat(format, {
                    style: 'currency', //decimal,currency,percent
                    currency: pref,
                }).format(nominal);
                
                return prefView+' '+rupiah;
            },
        loadNotaDetail(idTrans = this.$route.params.rekapTransaksiId){
            axios.get('/recap-transactions/'+idTrans).then(res => {
                if(res.data == null || res.data.data.length == 0){
                    this.$router.push({name: 'dashboardPage'});
                    return false
                }
                this.notaDetailList = {
                    transaksi_tipe: res.data.data.m_t_t_name,
                    transaksi_nota_code: res.data.data.r_t_nota_code,
                    transaksi_bigboss: res.data.data.r_t_bigboss,
                    transaksi_kasir: res.data.data.name,
                    pajak: res.data.data.r_t_tax_percent,
                    service_charge: res.data.data.r_t_sc_percent,
                    pembulatan: res.data.data.pembulatan,
                }
                this.currentPajak = res.data.data.r_t_tax_percent
                this.currentService = res.data.data.r_t_sc_percent
                this.currentRound = res.data.data.pembulatan;
                this.refund_counter = res.data.data.refundcounter;
                this.taxStatus = res.data.data.r_t_nominal_pajak
                this.scStatus = res.data.data.r_t_nominal_sc
                this.loadMenuNota();
            }).catch ((err) => {
                alert('Some Error');
                this.$router.push({name: 'dashboardPage'});
            })
        },
        
        loadMenuNota(idTrans = this.$route.params.rekapTransaksiId){
            axios.get('/rekap-transaksi-detail/detailtransaksi/'+idTrans).then(res => {
                // console.log(res.data);
                this.notaListMenu = res.data
                this.allNotaListMenu = res.data
                this.updateAllCart()

                
            }).catch ((err) => {
                console.log(err);
            })
        },
        addToRefundMenu(menu){

            const oldCart = [...this.cart];
            const newCart = [];
            const menuJenisOnCart = {
                id: "",
                name: "",
                items: []
            }
            const newMenu = JSON.parse(JSON.stringify(menu));

            // reduce from old cart
            for (let i = 0; i < oldCart.length; i++) {
                const menuCart = JSON.parse(JSON.stringify(oldCart[i]));
                if (menuCart.id == menu.m_produk_m_jenis_produk_id) {
                    menuJenisOnCart.id = menuCart.id;
                    menuJenisOnCart.name = menuCart.name;
                    const newItems = []
                    for (let y = 0; y < menuCart.items.length; y++) {
                        const oldMenu = JSON.parse(JSON.stringify(menuCart.items[y]));
                        if (oldMenu.r_t_detail_id == menu.r_t_detail_id) {
                            oldMenu.r_t_detail_qty-=1;
                        }
                        if (oldMenu.r_t_detail_qty>0) {
                            newItems.push(oldMenu);
                        }
                    }
                    if (newItems.length>0) {
                        newCart.push({
                            id: menuCart.id,
                            name: menuCart.name,
                            items: newItems
                        });
                    }

                } else {
                    newCart.push(menuCart);
                } 
            }
            this.cart = newCart;
            const newNotaList = [];

            for (let index = 0; index < this.cart.length; index++) {
                const e = JSON.parse(JSON.stringify(this.cart[index]));
                const temp = JSON.parse(JSON.stringify(e.items))
                for (let i = 0; i < temp.length; i++) {
                    const y = temp[i];
                    newNotaList.push(y)
                    
                }
            }    

            this.notaListMenu = newNotaList;

            const newSplitMenu = [...this.refundListMenu];
            
            if (newSplitMenu.length===0) {
                const addNewMenu = JSON.parse(JSON.stringify(menu));
                addNewMenu.r_t_detail_qty=1;
                newSplitMenu.push(addNewMenu);   
                this.refundListMenu = [...newSplitMenu];
            } else {
                const tempSplitMenu = [...this.refundListMenu]
                let found = false;
                for (let index = 0; index < newSplitMenu.length; index++) {
                    const e = newSplitMenu[index];

                    const oldMenu = JSON.parse(JSON.stringify(e));
                    
                    if(oldMenu.r_t_detail_id==newMenu.r_t_detail_id){
                        found = true;
                        oldMenu.r_t_detail_qty+=1;
                        tempSplitMenu[index].r_t_detail_qty = oldMenu.r_t_detail_qty;
                    } 
                    
                }
                if (!found) {
                    newMenu.r_t_detail_qty=1;
                    tempSplitMenu.push(newMenu)
                }
                this.refundListMenu = [...tempSplitMenu];
            }
            
            this.updateRefundCart()
            this.countRefundPrice()
        },
        removeFromSplitMenu(menu){

            const oldCart = [...this.refundCart];
            const newCart = [];
            const menuJenisOnCart = {
                id: "",
                name: "",
                items: []
            }
            const newMenu = JSON.parse(JSON.stringify(menu));

            // reduce from old split cart
            for (let i = 0; i < oldCart.length; i++) {
                const menuCart = JSON.parse(JSON.stringify(oldCart[i]));
                if (menuCart.id == menu.m_produk_m_jenis_produk_id) {
                    menuJenisOnCart.id = menuCart.id;
                    menuJenisOnCart.name = menuCart.name;
                    const newItems = []
                    for (let y = 0; y < menuCart.items.length; y++) {
                        const oldMenu = JSON.parse(JSON.stringify(menuCart.items[y]));
                        if (oldMenu.r_t_detail_id == menu.r_t_detail_id) {
                            oldMenu.r_t_detail_qty-=1;
                        }
                        if (oldMenu.r_t_detail_qty>0) {
                            newItems.push(oldMenu);
                        }
                    }
                    if (newItems.length>0) {
                        newCart.push({
                            id: menuCart.id,
                            name: menuCart.name,
                            items: newItems
                        });
                    }

                } else {
                    newCart.push(menuCart);
                } 
            }
            this.refundCart = newCart;
            const newNotaList = [];

            for (let index = 0; index < this.refundCart.length; index++) {
                const e = JSON.parse(JSON.stringify(this.refundCart[index]));
                const temp = JSON.parse(JSON.stringify(e.items))
                for (let i = 0; i < temp.length; i++) {
                    const y = temp[i];
                    newNotaList.push(y)
                    
                }
            }    

            this.refundListMenu = newNotaList;

            const newNotaMenu = [...this.notaListMenu];
            
            if (newNotaMenu.length===0) {
                const addNewMenu = JSON.parse(JSON.stringify(menu));
                addNewMenu.r_t_detail_qty=1;
                newNotaMenu.push(addNewMenu);   
                this.notaListMenu = [...newNotaMenu];
            } else {
                const tempMenu = [...this.notaListMenu]
                let found = false;
                for (let index = 0; index < newNotaMenu.length; index++) {
                    const e = newNotaMenu[index];

                    const oldMenu = JSON.parse(JSON.stringify(e));
                    
                    if(oldMenu.r_t_detail_id==newMenu.r_t_detail_id){
                        found = true;
                        oldMenu.r_t_detail_qty+=1;
                        tempMenu[index].r_t_detail_qty = oldMenu.r_t_detail_qty;
                    } 
                    
                }
                if (!found) {
                    newMenu.r_t_detail_qty=1;
                    tempMenu.push(newMenu)
                }
                this.notaListMenu = [...tempMenu];
            }
            
            this.updateAllCart()
            this.countRefundPrice()
        },
        updateAllCart(){
            this.cart = []
            const notaList = this.notaListMenu
            if (notaList.length>0) {
                const tempMenuJenis = []

                notaList.forEach(function(e) {
                    if (tempMenuJenis.indexOf(e.m_produk_m_jenis_produk_id) === -1) {
                        tempMenuJenis.push(e.m_produk_m_jenis_produk_id)
                    }
                });

                const menuList = this.stockMenuList
                const tempCart = []
                tempMenuJenis.forEach(function(e) {
                    const tempMenuList = []
                    let name
                    menuList.forEach(function (item) {
                        
                        if (item.m_jenis_produk_id == e) {
                            name = item.m_jenis_produk_nama
                        }
                    });
                    
                    notaList.forEach(function (item) {
                        if (e == item.m_produk_m_jenis_produk_id) {
                            tempMenuList.push(item)
                        }                                
                    });

                    const menuOnCart = {
                        id: e,
                        name: name,
                        items: tempMenuList
                    }
                    tempCart.push(menuOnCart)  
                });

                this.cart = tempCart;
            }
        },
        updateRefundCart(){
            this.refundCart = []
            const notaList = this.refundListMenu
            if (notaList.length>0) {
                
                const tempMenuJenis = []

                notaList.forEach(function(e) {
                    if (tempMenuJenis.indexOf(e.m_produk_m_jenis_produk_id) === -1) {
                        tempMenuJenis.push(e.m_produk_m_jenis_produk_id)
                    }
                });

                const menuList = this.stockMenuList
                const tempCart = []
                tempMenuJenis.forEach(function(e) {
                    const tempMenuList = []
                    let name
                    menuList.forEach(function (item) {
                        
                        if (item.m_jenis_produk_id == e) {
                            name = item.m_jenis_produk_nama
                        }
                    });
                    
                    notaList.forEach(function (item) {
                        if (e == item.m_produk_m_jenis_produk_id) {
                            tempMenuList.push(item)
                        }                                
                    });

                    const menuOnCart = {
                        id: e,
                        name: name,
                        items: tempMenuList
                    }
                    tempCart.push(menuOnCart)  
                });

                this.refundCart = tempCart;
                
            }
        },
        backPage(){
            this.$router.push({ name: 'dashboardPage'});
        },
        cancelSplit(){
            const back = this.backPage
            const router = this.$router;
            if (this.refundListMenu.length==0) {
                back()
            } else {
                this.$swal.fire({
                    title:"Anda Yakin?", 
                    text:"Batalkan Pengembalian Dana", 
                    icon:"warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Iya',
                    cancelButtonText: 'Tidak'
                }).then(function (success) {
                    if (success.isConfirmed) {
                        back()
                    }
                })
            }
        },
        countRefundPrice(){
            let total = 0
            let pajak = 0
            let sc = 0
            const currentSc = this.currentService
            const currentTax = this.currentPajak
            
            for (let i = 0; i < this.refundListMenu.length; i++) {
                const refundItem = JSON.parse(JSON.stringify(this.refundListMenu[i]));
                total += refundItem.r_t_detail_price * refundItem.r_t_detail_qty
                
                if (currentTax > 0 && refundItem.r_t_detail_nominal_pajak > 0) {
                    pajak += currentTax * (refundItem.r_t_detail_price * refundItem.r_t_detail_qty)
                    // pajak += refundItem.r_t_detail_nominal_pajak
                }
                if (currentSc > 0 && refundItem.r_t_detail_nominal_sc  > 0) {
                    sc += currentSc * (refundItem.r_t_detail_price * refundItem.r_t_detail_qty)  
                    // sc += refundItem.r_t_detail_nominal_sc  
                }
                
            }
            this.refundSubTotal = total;
            this.refundPajak = pajak
            this.refundServiceCharge = sc
            this.refundTotal = this.refundSubTotal + this.refundPajak + this.refundServiceCharge
            // const round = this.currentRound.toLowerCase();
            // if(round == "ya") {
            //     this.exchangeRounded = this.refundTotal - roundToDown(parseFloat(this.refundTotal), -2);
            // }else{
            //     this.exchangeRounded = 0;
            // }
            const round = this.currentRound.toLowerCase();
            if (round == "ya") {
                if(this.currencyPrefix == 'IDR'){
                    this.exchangeRounded = roundToUp(parseFloat(this.refundTotal), -2) -this.refundTotal;
                }else{
                    const round1 = roundToDown(parseFloat(this.refundTotal), 1);
                    const valRound = this.refundTotal - round1

                    let exc = 0
                    if(valRound >= 0.05){
                        exc = 0.1 - valRound
                    }else{
                        exc = 0.05 - valRound
                    }
                    this.exchangeRounded = roundTo(parseFloat(exc), 2)
                }
            } else {
                this.exchangeRounded =  0;
            }
            
            // const nominal_round = this.exchangeRounded; 
        },
        setNullPajak(){
            this.currentPajak = 0
            this.countRefundPrice()
        },
        setPajak(){
            this.currentPajak = this.notaDetailList.pajak
            this.countRefundPrice()
        },
        setNullService(){
            this.currentService = 0
            this.countRefundPrice()
        },
        setService(){
            this.currentService = this.notaDetailList.service_charge
            this.countRefundPrice()
        },
        backHome(){
            this.$router.push({name: 'dashboardPage'});
        },
        refund(){
            // Cek Akses before Execute
            axios.get('/akses-role')
            .then(resp => {
                if (resp.status == 200) {
                    this.cashierRole = resp.data
                    if (this.cashierRole.refund.defaultRole == 'deny' && this.cashierRole.refund.temporaryRole == 'deny') {
                        this.$swal.fire({
                            title:"Akses DiTolak!", 
                            text:"Hubungi Kacab/Asisten", 
                            icon:"warning",
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok'
                        })
                    }else{
                        document.getElementById("show-modal-refund").click()
                        axios.get('/open-laci/refund')
                        .then(resp => {
                            console.log(resp.data)
                            if(resp.data.struct != ''){
                                location.href = resp.data.struct
                            }
                        })
                        .catch(err => console.log(err))
                    }
                }
            })
            .catch(err => console.log(err))
        },
        cronJobWa(target,transId){
            axios.get(`/cashier/cronjob-wa/${target}/${transId}`).then(resp => {
                console.log(resp)
            }).catch ((err) => {
                if (err.response) {
                console.log(err.response.data)
                }
            });
        },
        strukCetak(rekapTransId,repeat){
            const home = this.backHome;
            this.$swal.fire({
                // title: 'Mencetak!',
                html: 'Mencetak Struk',
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                this.$swal.showLoading()
                axios.get(`/cashier/print-transaksi/${rekapTransId}/${repeat}`).then(resp => {
                    // console.log(resp.data)
                    if(resp.data.error == 0){
                        if(resp.data.struct != ''){
                            location.href = resp.data.struct
                        }
                    }
                    // document.getElementById('close-modal-refund').click()
                    // home()
                }).catch ((err) => {
                    if (err.response) {
                    console.log(err.response.data)
                    }
                })
                }
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === this.$swal.DismissReason.timer) {
                console.log('printing success')
                }
            })
        },
        strukCash(wa,print){
            if (wa == 1) {
            const hpbos = document.getElementById('nomor_hp_bos').value
                if (hpbos && hpbos.split('.').length == 1) {
                    this.kirimWa = hpbos
                    this.cetakStruk = 0
                    if (print == 1) {
                        this.cetakStruk = 1
                    }
                    this.payAction()
                }
            }else{
                this.cetakStruk = 1
                this.payAction()
            }
        },
        payAction(){
            // this.setupRole()
            // const free = parseFloat(document.getElementById("free_kembalian_refund").value);
            
            // if (!isNaN(free)) this.freeExchange = free
            
            const formData = new FormData()
            formData.append('rekap_transaksi_id', this.$route.params.rekapTransaksiId)
            formData.append('sub_total', this.refundSubTotal)
            formData.append('total', this.refundTotal)
            formData.append('round', this.exchangeRounded)
            formData.append('free_exchange', this.freeExchange)
            formData.append('nominal_pajak', this.refundPajak)
            formData.append('nominal_sc', this.refundServiceCharge)
            formData.append('current_tax', this.currentPajak)
            formData.append('current_sc', this.currentService)
            formData.append('refund_menu',JSON.stringify(this.refundListMenu))
            formData.append('cetak_struk',this.cetakStruk)
            formData.append('selisih',0)
            
            const router = this.$router;

            axios.post('/transaksi/refund', formData).then(res => {
            console.log(res);
                if(res.data.meta.error == true){
                    this.$swal.fire({
                        title:"Terjadi Kesalahan!", 
                        text:res.data.meta.message, 
                        icon:"warning",
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    })
                    // .then(function (success) {
                    //     if (success.isConfirmed) {
                    //         console.log('hide')
                    //         enable = false
                        
                    //     }
                    // }) 
                    this.buttonCloseStruk = false
                    document.getElementById('close-modal-refund').innerHTML = 'Batal'
                }
                if(res.status == 201){
                    this.updateAllCart()
                    const rekapTransId = res.data.data.rekap_id
                    let repeat = 2
                    if (this.kirimWa != 0) {
                        repeat = 1
                        this.$swal.fire({
                            // title: 'Mencetak!',
                            html: 'Mengirim Pesan Ke '+this.kirimWa,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: () => {
                            this.$swal.showLoading()
                            this.cronJobWa(this.kirimWa,rekapTransId)
                            }
                        })
                    }
                    if (this.cetakStruk == 1) {
                        this.strukCetak(rekapTransId,repeat)
                    }
                    document.getElementById('close-modal-refund').innerHTML = 'Selesai'
                    this.buttonStruk = true
                    this.buttonCloseStruk = false

                }
                
            }).catch ((err) => {
                console.log(err.response);
            })
            
            
        }
        
    },
    setup() {
        const user = ref(null);
        const router = useRouter();

        onMounted( async () => {
            try {
                const response = await axios.get('/user');
                user.value = response.data.data;
            } catch (e) {
                await router.push({ name: 'loginPage' });
            }
        });

        return {
            user,
        }

    },
}
</script>
